.pt_checkout {
	&#wrapper {
		#main {
			padding-top: 80px !important;
		}
	}
	
	input {
		font-family: $Helvetica;
	}
	.checkout-tab{
		width: 100%;
    	display: inline-block;
	}
	.shipping-section {
		.form-row-button {
			button, button span {
				font-family: $HelveticaMedium;
				font-size: $base-font + 2px;
			}
		}
		ul {
			&.shipping-promos {
				display: block;
			}
		}
	}

	.error-tender-rebuttal {
		font-family: $Futura;
	}

	.spc-shipping {
		.saved-address-block {
			.shipping-address-save {
				color: $mainly-blue;
			}
		}
	}
	/*.gift-section {
		.gift-right {
			.spc-shipping-btn:hover {
				span {
					color: $mainly-blue;
				}
			}
		}
	}*/
	//Q&A
	.qa-section {
		.question-title {
			padding: 19px 25px 22px 39px;
		}

		.qa-content {
			padding: 20px 17px 20px 43px;
    		letter-spacing: 1px;

    		&:nth-child(2n), &:nth-child(3n), &:nth-child(4n),&:nth-child(n) {
    			padding: 20px 17px 20px 43px;
    			letter-spacing: 1px;
    		}

    		.question {
    			&::after {
	    			background: url(../../../images/carat-down.svg) no-repeat;
					background-position: 99.6% 5px;
					background-size: 16px;
				}

				&.active {
					&::after {
						background: url(../../../images/carat-up.svg) no-repeat;
	    				background-position: 99.6% 5px;
	    				background-size: 16px;
	    			}
				}
    		}
    		.answer {
    			padding: 9px 0 3px 0;
    		}
		}
	}

	.gift-section {
		.gift-message-text {
			margin: 0 0 7px 0;

			.field-wrapper {
				margin: 0 0 0 5px;
				width: 81.5%;

				@media screen and (max-width: 480px) {
					height: 65px;
					margin: 0;
				}
			}

			.form-caption {
				margin: 0;
			}
		}
	}
	.make-label-absolute {
		.form-row {
			.field-wrapper {
				.custom-select {
					.selected-option {
						font-family: $Helvetica;
					}
				}
			}
		}
	}

	.checkout-billing, .spc-express-checkout {
		.payment-option-section {
			.tab-section {
				.left-content {
					.error-messages {
				   		.error {
						   	font-family: $MrEavesReg;
    						font-size: $base-font + 5px;
						}
					}
				}
				.right-content {
					.error-msg-content {
						&.coupon-error {
					   		.error{
					   			font-family: $MrEavesReg;
    							font-size: $base-font + 5px;
					   		}
					   	}
					}
					.form-row{
						input[type="text"]{
							border-top-left-radius: 15px;
						}
						&.pin{
							input[type="text"]{
								border-radius: 0;
							}
						}
					}
				}
			}
		}
	}
}

.customer-signin-section {
	.edit-link {
			color: $black;
	}
}

.pt_account {
	&.paymentinstruments, &.addresses {
		.left-cont, .list-sec-address-form {
			.form-row {
				.selected-option {
					font-size: $base-font + 1px;
					font-family: $Futura;
					text-transform: capitalize;
				}
			}
		}
	}
}

.address-validation-dialog {
	button {
		&.corrected-avs-button{
	        padding: 12.5px 20px;
		}
		&.update-avs-button {
		    padding: 12.5px 20px;
		}
	}
	.address-controls {
		button {
		    background: $mainly-blue;
		    border-color: $mainly-blue;
		    color: $white;
			font-family: $HelveticaMedium;
			font-size: $base-font + 2px;
			outline: 0;
			border-top-left-radius: 15px;
			padding: 15px 50px;
			border-bottom-right-radius: 15px;
			&#cancel-avs-button{
			    color: $mainly-blue;
			}
		}
	}
}
.pobox-exclusions {
	button {
		background: $mainly-blue;
	    border-color: $mainly-blue;
	    color: $white;
		font-family: $HelveticaMedium;
		font-size: $base-font + 2px;
		padding: 15px 30px;
		&.cancel-ship-method-except {
			color: $mainly-blue;
			font-size: $base-font + 2px;
		}
	}
	.pobox-items {
		.attribute {
			.price-promotion {
				.price-sales {
					color: $guardsman-Red;
				}
			}
		}
	}
}

/* Later we will move this code into asset */
.chk-out-header-right-section {
	font-family: 'Helvetica Neue LT W01_65 Md';
	font-size: 12px;
	.bcText a div{
		color: #331e53;
	}
    @media screen and (max-width: 1023px) {
		font-size: $base-font - 1px;
    }
	.live-chat {
		a {
			color: #9f1557;
		}
	}
}
.checkout-progress-indicator {
    .progress-text, .progress-count {
    	font-family: $HelveticaMedium;
	}
	.progress-text {
		font-family: $HelveticaMedium;
	}
	.progress-count  {
		font-family: $HelveticaBold;
	}
}
//login content styling
.spc-login {
	h2 {
		font-size: $base-font + 8px;
	    font-family: $Helvetica;
	}

	.login-data {
		.checkout-login-email {
			font-size: $base-font + 1px;
		    font-family: $HelveticaBold;
		}
	}

	button:not(.intl-shipping-btn) {
		font-size: $base-font + 2px;
		font-family: $HelveticaMedium;
		min-height: 50px;
	}

	.make-label-absolute {
		.form-row {
			&.login-rememberme {
				label {
					position: static;
				    -webkit-transform: none;
				    -ms-transform: none;
				    transform: none;
				    padding: 0 0 0 10px;

				    span {
				    	 font-family: $Helvetica;
				    	 font-size: $base-font + 1px !important;
				    	 -webkit-transition: none;
					     -moz-transition: none;
					     -o-transition: none;
					     -ms-transition: none;
					     position: static;
			    		 padding: 0;
					     -webkit-transform: none;
					    -ms-transform: none;
					    transform: none;
					   	margin: 0;
				    }
				}
			}
		}
	}
}

.checkoutlogin {
	.col-1 {
		h2 {
			&.heading {
				padding-bottom: 13px;
			    border-bottom: 1px solid $alto;
			   	margin: 12px 0 14px 0;
		   	}
		}

		.login-box-content {
			margin-top: 22px;
		}

		 button {
		 	&.oAuthIcon {
			 	border-top-left-radius: 0;
			 	-webkit-border-top-left-radius: 0;
			 	-moz-border-top-left-radius: 0;
	    		border-bottom-right-radius: 0;
	    		-webkit-border-bottom-right-radius: 0;
	    		-moz-border-bottom-right-radius: 0;
			}
		}
	}
	.col-2 {
		padding-bottom: 50px;
		h2 {
			&.heading {
				margin-bottom: 14px;
			}
		}

		.login-box {
			p {
				font-size: $base-font + 1px;
    			line-height: 21px;
    			margin: 2px 0 14px 0;
    			display: inline-block;
    			font-family: $Helvetica;
			}
		}
		.form-row {
			&.newsletter-signup {
				label {
					-webkit-transform: none;
				    -ms-transform: none;
				    transform: none;
				    margin: 0px 0 0 11px;

					 span {
						 font-family: $Helvetica;
				    	 font-size: $base-font + 1px ;
			    	}
				}
			}
		}
	}
}


.saved-address-block {
	.head-wrapper {

	}
	.shipping-address-save {
		color: $mainly-blue;
		border-radius:0 ;
	}
}

//save, cancel, edit buttons
.address-actions,.newcreditcard {
	color: $mainly-blue;

	span {
		color: $mainly-blue;
	}
}

@media screen and (min-width: 1024px) {
	.select-address {
		width: 100%;

		&:not(.hide) {
			display: inline-block;
		}
	}
}

.checkout-billing, .spc-express-checkout {
	.payment-option-section {
		.tab-section {
			.right-content {
				button {
					padding: 14px 20px;
    				border: 0;
				    border-top-left-radius: 0;
				    border-bottom-right-radius: 15px;
				    height: 50px;

    				&:hover {
    					background: $mainly-blue;
    					color: $white;
    				}
				}

				.giftcert-apply {
					button {
						border-top-left-radius: 15px;
						border-bottom-right-radius: 15px;
					}
				}
			}
		}
	}
	.form-row-button{
		&.billing-page-continue{
			button:not(.btn-ds) {
				border-top-left-radius: 15px;
   				border-bottom-right-radius: 15px;
   				font-family: $HelveticaMedium;
   				padding: 14px 50px;
   				font-size: $base-font + 2px;
			}
		}
	}
}


.checkout-order-summary {

    .review-disclaimer {
    	font-size: $base-font;
    	font-family: $Helvetica;

    }

    .form-row {

	    .button-fancy-large {
		    background: $mainly-blue;
		    font-size: $base-font + 2px;
		    font-family: $HelveticaMedium;

		    &:hover {
		    	background: $mainly-blue;
		    }
	    }
    }

    .secure-message {
    	font-size: $base-font;
    	font-family: $Helvetica;

	    @media screen and (max-width: 767px) {
	    	border-bottom: 1px solid $alto;
	    }
    }
}

.checkout-order-totals {
	.summary-heading {
		font-size: $base-font + 8px;
		text-transform: capitalize;
		font-family: $Helvetica;
		font-weight:normal;
	}

	.order-detail {
		padding: 0 0 20px 0;

		.label {
			font-size: $base-font + 2px;
			font-family:  $Helvetica;
			text-transform: capitalize;

			.shippingoverlay-link {
				background: url(../../../images/tips.svg) no-repeat;
				width:16px;
				height:16px;
				background-size:cover;
				vertical-align: bottom;
			}
		}

		.value {
			font-size: $base-font + 2px;
			font-family:  $Helvetica;
		}

		&.order-total {
			padding: 20px 0 10px 0;

			.label, .value {
				font-size: $base-font + 8px;
				font-family: $Helvetica;
				font-weight: normal;
			}
			.value{
				font-family: $HelveticaBold;
			}

			&.order-total-remaining {
				padding: 0 0 20px 0;

				.label, .value {
					font-size: $base-font + 2px;
					font-family: $Helvetica;
					text-transform: capitalize;
				}

				.value{
					font-size: $base-font + 2px;
					font-family: $Helvetica;
				}
			}
		}

		&.remaining-total {
			padding: 20px 0 10px 0;

			.label, .value {
				font-size: $base-font + 8px;
				font-family: $Helvetica;
				font-weight: normal;
			}

			.value{
				font-family: $HelveticaBold;
			}
		}

		&.discount {
			color: $guardsman-Red;
		}
	}
}

.spc-summary, .spc-billing, .spc-express-checkout {
	.checkout-tab-head {
		.section-heading {
    		 .edit-link {
    		 	font-family: $HelveticaBold;
    		 	font-size: $base-font - 1px;
    		 	color: $mainly-blue;
    		 	letter-spacing: 1px;
    		 }
		}

		.details {
			.inner-block {
			    font-family: $Helvetica;

			    .address {
				    font-size: $base-font + 1px;

				    .default {
				    	font-family: $HelveticaBold;
				    }
			    }

			    .minishipments-method {
			    	font-size: $base-font + 1px;
			    	font-family: $Helvetica;

			    	.option-heading {
			    		font-family: $HelveticaBold;
			    	}

			    	.promo {
			    		font-size: $base-font;
			    	}

			    	.form-caption {
			    		font-size: $base-font + 1px;
			    		font-family: $Helvetica;
			    		padding-top: 10px;
			    	}
			    }

			    .info {
			    	font-family: $Helvetica;
			    	font-size: $base-font + 1px;

			    	.label {
			    		font-family: $HelveticaBold;
			    	}
			    }
    		}
		}
	}
}

.billing-left-section, .billing-right-section {
    .mini-billing-address, .mini-payment-instrument, .reward-certificate, .promo-code, .gift-card {
    	font-family: $Helvetica;

    	h3 {
    		font-size: $base-font + 8px;
    		font-family: $Helvetica;
    	}

    	.detail {
		    font-size: $base-font + 1px;

		    .cc-owner {
				font-family: $HelveticaBold;
		    }
    	}
    }
}

.place-order {
	.order-summary-footer {
    	.left-section, .right-section {
    		font-family: $Helvetica;

    		.review-disclaimer {
    			font-size: $base-font;
    		}

    		.submit-order {
			    .form-row {
			    	.button-fancy-large {
			    		font-family: $HelveticaMedium;
    					letter-spacing: 2px;
					    font-size: 14px;
					    padding: 15px 50px;
			    		@media screen and (max-width: 1024px){
			    			width: 270px;
			    		}

			    		@media screen and (max-width: 767px){
			    			width: 100%;
			    			margin: 0;
			    		}
			    	}
			    }
    		}

    		.secure-message {
    			font-size: $base-font;
    		}
    	}
	}
}

.billing-cards-section {
	.checkout-rebuttal {
		.acquisition-rebuttal {
			color: $mainly-blue;
		}
	}
	.cvv-label{
		.content-asset{
			img{
				@media screen and (orientation: landscape) and (width: 1024px){
					margin-left:-7px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.checkoutlogin {
		.col-2 {
			padding-bottom: 9px;
			.login-box {
				p {
					font-size: $base-font + 1px;
				}
			}
		}
	}
}

@media screen and (min-width: 1260px) {
	.pt_checkout{
		.gift-section{
			.gift-message-text{
				.field-wrapper{
					width: 89%;
				}
			}
		}
	}
}


@media screen and (max-width: 1023px) {
	.checkoutlogin{
		.col-2{
			padding-bottom: 38px;
		}
	}
	.pt_checkout {
		.qa-section {
			.qa-content {
				padding: 25px 17px 20px 30px;

				&:nth-child(2n), &:nth-child(3n), &:nth-child(4n), &:nth-child(n) {
					padding: 25px 17px 20px 30px;
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.pt_checkout {
		#secondary {
			&.summary {
				background: none;
			}
		}

		.shipping-section {
			.form-row {
				.field-wrapper {
					width: 3%;
					input[type="radio"] {
						&:checked {
							+.custom-radio-icon {
								top: 0;
								left: 0;
							}
						}
					}
					.custom-radio-icon {
						top: 0;
						left: 0;
					}
				}
			}
		}
		.qa-section {
			.question-title {
				padding: 25px 0;
			}

			.qa-content {
				padding: 21px;

				&:nth-child(2n), &:nth-child(3n), &:nth-child(4n),&:nth-child(n) {
					padding: 21px;
				}
			}
		}
	}
	.checkoutlogin{
		.col-2{
			padding-bottom: 0;
		}
	}
	.checkout-order-totals{
		.summary-heading{
			font-size: 24px;
		}
	}
	.checkout-billing{
		.payment-option-section{
			.tab-section{
				.right-content{
					button{
						padding: 14px 24px;
						font-size: 14px;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	.pt_checkout {
		.shipping-section {
			.form-row {
				.field-wrapper {
					width: 6%;
				}
				.field-desc {
					width: 89%;
				}
			}
		}
	}
}

@media screen and(min-width:768px) and (max-width:1024px){

 #secondary{
	.checkout-order-summary{
		.order-summary-footer{
			.form-row{
				.add-to-email-list{
					.add-to-emaillist-warningmsg{
						margin-top:18px;
						@media screen and(width:1024px) and (orientation:landscape){
							margin-top:0;
						}

						.arrow-up{
							left:36px;
							@media screen and(width:1024px) and (orientation:landscape){
								left:0px;
							}
						}
					}
				}
			}
		}
	}
 }
}
#secondary{
	.checkout-order-summary{
		.order-summary-footer{
			.form-row{
				.add-to-email-list{
					.subscribeToEmail{
						margin-top:18px;
						@media screen and (max-width:767px){
							margin-top:19px;
						}
						@media screen and(min-width:768px) and (max-width:1024px){
							margin-top:17px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width:1023px){
	.checkout-order-summary{
		.form-row{
			width:auto;
			float:none;
			 .button-fancy-large{
				 float:none;
			 }
		}
	}
}
@media screen and (max-width:767px){
	#secondary{
		.checkout-order-summary{
			.order-summary-footer{
				.form-row{
					.add-to-email-list{
							div.content-asset{
								height:14px;
								&.wrapper-add-to-email{
									margin-top:-13px;
								}
						}
					}
				}
			}
		}
	}
}
.spc-summary,
.spc-express-checkout{
	.place-order{
	 .left-section{
		 .form-row{
		.add-to-email-list{
			.add-to-emaillist-warningmsg{
				.arrow-up{
					@media screen and(width:1024px) and (orientation:landscape){
					left:36px;
				}
			}
		}
	}
}
}
}
}
.checkoutlogin{
	.login-box-content.returning-customers .form-row{
		&.username,&.password{
			&.error-handle .input-focus{
				transform: translate(0, 8px);
			}
		}
	}
}

.defer-code-error {
	.error{
		font-family: $MrEavesReg;
		font-size: $base-font + 5px;
	}
}