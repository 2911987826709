.checkout-body:not(.plcc-redesign), #wrapper.pt_specials ~ .plcc-apply-error-model {
//PLCC Step1
.ui-dialog {
	&.checkout-plcc-modal,  &.checkout-plcc-modal-step2, &.plcc-acquisition-rebuttal-model {
		border: 1px solid $alto;
		box-shadow: none;
		
		.ui-dialog-title {
			margin: 21px 0 0 0;
		    font-size: $base-font + 2px;
		    font-family: $FuturaBold;
		    text-transform: uppercase;
		} 
	} 
}

.checkout-plcc-modal {
	.checkout-stepone-scroll {
		overflow: auto;
	}
	.plcc-section-1 {
		padding: 0 40px;
		margin-top: 11px;
	 	.plcc-banner {
		    width: 100%;
		    display: inline-block;
		    background: $dim-gray;
		 	color: $white;
	 	}
	 	
	 	.plcc-pre-approved-name {
		    font-size: $base-font + 16px;
			font-family: $MrEavesReg;
			margin: 21px 0 0 0;
	 	}
	 	
	 	.credit-card {
	 		font-size: $base-font + 5px;
	 		font-family: $MrEavesReg;
	 	}
	 	
	 	.banner-image {
 		    height: 118px;
			margin: 10px 0 0 12px;
			overflow: hidden;
			    
	 		img {
	 			vertical-align: bottom;
	 		}
	 	}
	 	.plcc-banner-main {
			 max-width: 435px;
		 }
	 	.bonus-reward {
			margin: 13px 0 7px;
		    h1 {
		    	font-size: $base-font + 18px;
				font-family: $FuturaBold;
				letter-spacing: 8px;
				margin: 0 10px 0 13px;
				word-spacing: 2px;
		    }
		    
		    h2 {
		    	font-size: $base-font + 6px;
		    	font-family: $MrEavesReg;
		    }
		    
		    ul {
	    		li {
					width: 33.33%;
					margin: 32px 0;
	    			.section {
	    				max-width: 190px;
	    			}
	    			
	    			span {
	    				font-size: $base-font + 8px;
	    				font-family: $MrEavesReg;
	    				margin: 15px 0 0 0;
	    				line-height: 23px;
	    			}
	    		}
		    }
	 	}
	}
	
	.pre-screen-notice {
	 	font-size: $base-font + 5px;
	 	font-family: $MrEavesBold;
		 padding: 0 40px;
		 
	 	.prescreen-message {
			padding: 22px 22px 24px 36px;
			border: 2px solid #000;
			font-size: $base-font + 9px;
	    	line-height: 24px;
	 	}
	}
	
	.legal-copy {
	 	margin: 23px 0;
	 	font-size: $base-font + 5px;
	 	font-family: $MrEavesReg;
		 color: $black;
		 padding: 0 42px;
		 line-height: 21px;
	}
	
	.form-row-button {
		bottom: 0;
		width: 100%;
		overflow: hidden;
		border-top: none;
		.form-row-button-fixed {
			background: #fff;
			padding: 20px 0;
			border-top: 1px solid #ddd;
		}
	    button {
	    	font-size: $base-font + 2px;
	    	font-family: $Futura;
	    	background: $grape; 
			margin: 0 0 0 42px;

	    	&.plcc-nothanks-button {
				border: 2px solid #666;
				color: #000;
				padding: 14px 41px;
			}
			
			&.plcc-get-it-button {
				padding: 16px 49px;
				margin: 0 40px 0 0;
			}
	    }
	}
}

//PLCC Step2 Dialog
.plcc-model2 {
	.plcc-lower {
		.plcc-modal-error {
			font-size: $base-font + 5px;
			font-family: $MrEavesReg;	
		}
	}
	
	.year {
		.custom-select {
			.selected-option {
				&:before {
					right: 5px;
				}
			}
		}
	}
	
	.pre-approved-copy {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
		margin: 0 0 30px 0;
		color: $dim-gray;
	}
	
	.plcc-model2-form {
		padding: 36px 20px 14px 28px;
		
		@media screen and (max-width: 767px) {
			padding: 25px 10px 10px 10px;
		}
		
		.plcc-form-row-1{
			.ssn{
				width: 68%;
				float: right;
			}	
			.plcc-masked-number{
				width: 32%;
			    background: #FFFFFF;
			    display: inline-block;
			    padding: 16px 15px;
			    box-sizing: border-box;
			    -moz-box-sizing: border-box;
			    -webkit-box-sizing: border-box;
			    font-family: $Futura;
			    font-size: 14px;
			    color: #000000;
			    margin-bottom: 18px;
			}
		}
	}
	
	.legal-copy {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
	}
	
	.accept-offer-copy {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
	    line-height: 25px;
	}
	
	.plcc-birth-label {
		font-family: $FuturaBold;
		margin: 0 0 20px 0;
		
		.question-mark-icon{
			
			@media screen and (max-width: 767px) {
				margin-top: -7%;
			}
		}
	}
	
	.contact-disclaimer {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
		letter-spacing: 0.5px;
		margin: 0 0 28px 0;
		color: #666;
	}
	
	.pre-screen-notice {
		font-size: $base-font + 5px;
		text-align: center;
		padding: 28px 23px;
		border: 2px solid $black;
		font-family: $MrEavesReg;
		margin: 0 0 30px 0;
		color: $black;
	}
	
	.plcc-personal-header {
		font-size: $base-font;
		font-family: $FuturaBold;
		margin: 0 0 17px 0;
	}
		
	.plcc-profile-summary {
		.plcc-profile-details {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
			margin: 0 0 11px 0;
			
			&.plcc-profile-email {
				margin: 0 0 13px 0;
			}
		}
		
		.plcc-edit-profile {
			font-size: $base-font;
			font-family: $FuturaBold;
			text-decoration: underline;
			text-transform: uppercase;
		}
	}
	
	&.plcc-main {
		.form-row {
			&.form-indent {
				margin-left: 0;
				label {
					font-family: $MrEavesBold;
					font-size: $base-font + 3px;
					color: #000;
					float: left;
					width: 90%;
					@media screen and (max-width: 767px) {
						font-size: $base-font + 3px;
					}
					
				}
			}				
		}
		.plcc-primary {
			.plcc-ssn-label {
				font-family: $FuturaBold;
				font-size: $base-font;				
			}

			.year {
				.custom-select {
					.selection-list {
						li {
							padding: 9px 10px 10px 16px;		
						}
					}
					&.current_item {
						.selected-option {
							text-indent: 0;
						}
					}
				}
			}
			
			.month {
				.custom-select {
					&.current_item {
						.selected-option {
							text-indent: 0;		
						}
					}
				}
			}
			
			.day {
				.custom-select {
					&.current_item {
						.selected-option {
							text-indent: 0;		
						}
					}
				}
			}
			
			.custom-select {
				.selection-list {
					li {
						padding: 9px 10px 10px 16px;		
					}
				}
			}
				
			.ssn {
				
				.label-text {
					font-family: $MrEavesReg;
					font-size: $base-font + 3px;
					color: $dim-gray;
				}
			}
			label {
				font-family: $MrEavesReg;
				font-size: $base-font + 3px;
				
				&.input-focus {
					font-size: $base-font + 5px;
					font-family: $MrEavesReg;
					transform: translate(5px, 5px);
					-webkit-transform: translate(5px, 5px);
					-ms-transform: translate(5px, 5px);
				}
			}
			.important-info {
				font-size: $base-font + 5px;
				font-family: $MrEavesReg;
				
				
				.credit-card-disclaimer {
					display: none;
				}
			}
			
			.form-row {
				&.form-indent {
					label {
						font-family: $MrEavesBold;
						font-size: $base-font + 5px;
						color: $black;
					}
				}				
			}
		}
	}	
}

//PLCC Apply Model

.plcc-apply-error-model  {
	border: 1px solid $alto; 
}

.plcc-apply-model {
	width: 72%;
    margin-bottom: 18%;
    .review-model {
    	margin: 14% 0 0 0; 
    }	
    
    button {
	    font-size: $base-font + 2px;
	    border-color: $mainly-blue;
    }
    .header {
    	font-size: $base-font + 18px;
    	font-family: $MrEavesReg;
    	margin: 0 0 1% 0;
    }
    
    .sub-header {
    	font-size: $base-font + 8px;
    	font-family: $MrEavesReg;
    	color: $dim-gray;
    	margin: 0 0 11.2% 0;
    }
    
    .continue-shopping {
    	border-color: $mainly-blue;
    }
    &.plcc-apply-error {
		width: 64%;
		.header {
	    	font-size: $base-font + 12px;
	    	font-family: $MrEavesReg;
	    	color: $guardsman-Red;
	    }
	    
	    .sub-header {
	    	font-size: $base-font + 8px;
	    	font-family: $MrEavesReg;
	    	color: $dim-gray;
	    	width: 79%;
		    margin: 0 auto;
		    margin-bottom: 11%;
	    }
	}  
}

.approved-model {
	margin-bottom: 15.5%;
	.header {
		font-size: $base-font + 18px;
		font-family: $MrEavesReg;
    	margin: 4% 0 2.6% 0;
	}
	
	.sub-header {
		font-size: $base-font + 8px;
		margin-bottom: 6.6%;
    	line-height: 19px;
    	font-family: $MrEavesReg;
    	width: 95%;
    	color: $dim-gray;
	}
	
	.continue-message {
		font-size: $base-font + 8px;
		margin: 0 0 6.3% 0;
		font-family: $MrEavesReg;
		color: $dim-gray;
	}
	
	button {
	    font-size: $base-font + 2px;
	    border-color: $mainly-blue;
	    font-family: $Futura;
    }
	
	.approved-buttons {
    	.guest-sign-in {
    		padding: 14px 40px;
    		margin-right: 25px;
    	}
    	.continue-shopping {
    		padding: 14px 52px;
    	}
	}
}

//PLCC Acquisition Rebuttal Model
.plcc-acquisition-rebuttal-model {
	border: 1px solid $alto;
}
.plcc-rebuttal-overlay {
	.rebuttal-banner-text {
		top: 47%;
		.welcome {
			color: $white;
			font-size: $base-font + 8px;
			font-family: $MrEavesReg;
			margin: 19px 0 22px 0;
		}
		
		.pre-approved {
			color: $white;
			font-size: $base-font + 8px;
			font-family: $MrEavesReg;
			width: 86%;
		}
		
		.accept {
			color: $white;
			font-size: $base-font + 8px;
			font-family: $MrEavesReg;
    		margin: 0 0px 0 11px;
		}
		
		.extra {
			font-family: $FuturaBold;
			font-size: $base-font + 17.3px;
			color: $white;
		}
		
		.plus {
			font-family: $FuturaBold;
			font-size: $base-font + 17.3px;
			margin: 0 0 2px 0;
    		letter-spacing: 8px;
    		color: $white;
		}
		
		.bonus {
			font-family: $FuturaBold;
			font-size: $base-font + 17.3px;
			color: $white;
		}
		
		.billing {
			color: white;
			font-size: $base-font + 8px;
			font-family: $MrEavesReg;
		}
	}
	.pre-screen-notice {
		font-family: $MrEavesBold;
	    padding: 21px 30px 28px 33px;
	    font-size: $base-font + 9px;
		line-height: 24px;
		margin: 19px 0 14px 0;
		border: 2px solid black;
	}
	
	.legal-copy {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
	    padding: 0 4px 2px 0;
	}
	
	.form-row-button {
		margin: 20px 40px 19px 40px;
		.plcc-nothanks-button {
			padding: 13px 41px;
			background-color: #fff;
			color: #000;
			border-color: #000;
		}
		
		.plcc-get-it-button {
			padding: 13px 37px;
			float: right;
		}
	}
}

@media screen and (max-width: 1023px) {
	//PLCC Step1
	.checkout-plcc-modal {
		.plcc-section-1 {
		    box-sizing: border-box;
		    -webkit-box-sizing: border-box;
		 	-moz-box-sizing: border-box;
		}
	}
}

@media screen and (max-width: 767px) {
	.plcc-model2 {
		.pre-approved-copy {
			font-size: $base-font;
		}
		
		.legal-copy {
			font-size: $base-font;
		}
		
		.accept-offer-copy {
			font-size: $base-font;
			line-height: 18px;
		}
		
		.contact-disclaimer {
			font-size: $base-font;
		}
		
		.pre-screen-notice {
			font-family: $MrEavesBold;
    		padding: 18px 4px;
		}
		
		.plcc-birth-label {
			font-family: $MrEavesReg;
			font-size: $base-font + 6px;
		}
		
		.plcc-lower {
			.plcc-accept-button,.plcc-nothanks-button {
				font-size: $base-font;
			}
		}
		
		.plcc-personal-header {
			margin: 0 0 13px 0;
		}
		
		&.plcc-main {
			.plcc-primary {
				.important-info {
					font-size: $base-font;
				}
				.form-row {
					&.form-indent {
						label {
							font-size: $base-font - 3px;			
						}
					}
				}
			}
		}
	}
	
	.checkout-plcc-modal {
		.plcc-section-1 {
			margin-top: 11px;
		 	.bonus-reward {
			    h1 {
			    	font-size: $base-font + 10.5px;
			    	letter-spacing: 6px;
			    	margin: 0;
			    }
			    
			    h2 {
			    	font-size: $base-font - 2px;
			    }
			    
			    ul {
		    		li {
						width: 45%;
						float: none;
						margin: 23px 0 35px;
						display: inline-block;
		    			span {
		    				font-size: $base-font + 2px;
		    			}
		    		}
			    }
		 	}
		}
		
		.pre-screen-notice {
		 	font-size: $base-font + 2px;
		}
		
		.legal-copy {
		 	font-size: $base-font;
		}
		
		.form-row-button {
			padding: 10px 0;

		    button {
				font-size: $base-font;
				margin: 0 0 0 10px;
				&.plcc-nothanks-button {
					padding: 15px 18px;
				}
				&.plcc-get-it-button {
					padding: 17px 28px;
    margin: 0 10px 0 0;
				}
		    }
		}
	}
	
	.plcc-apply-model {
		width: 79%;
		.header {
			font-size: $base-font + 8px;
    		margin: 0 0 15px 0;
		}
		
		.sub-header {
			font-size: $base-font + 1px;
		    width: 90%;
		    margin: 0 auto;
		    margin-bottom: 15px;
		}
		
		.continue-shopping {
			font-size: $base-font;
			padding: 15px 28px;	
		}
		
		&.plcc-apply-error{
			width: 76%;
			.header {
				margin-left: 0;
	    		font-size: $base-font + 8px;
			}
			
			.sub-header {
				font-size: $base-font + 1px;
				width: 100%;
			}
			 button {
			 	padding: 15px 42px;
			 }
		}
	}
	.approved-model {
		.header {
			font-size: $base-font + 8px;
		}
		
		.sub-header {
			font-size: $base-font + 1px;
		}
		
		.continue-message {
			font-size: $base-font + 1px;
		}
		
		.approved-buttons {
			width: 82%;
			margin: 0 auto;
			.guest-sign-in {
    			width: 100%;
				font-size: $base-font;
				margin: 0 0 20px 0;
			}
			.continue-shopping {
				width: 100%;
				font-size: $base-font;
			}
		}
	}
	.plcc-acquisition-rebuttal-model {
		.rebuttal-banner-text {
    		.welcome {
    			font-size: $base-font + 3px;
    		}
    		
    		.pre-approved {
    			width: 100%;
			    font-size: $base-font + 3px;
    		}
    		
    		
    		.accept {
    			font-size: $base-font + 3px;
    		}
    		
    		.reward-plus {
    			font-size: $base-font + 8px;
			    line-height: normal;
			   font-family: $FuturaBold;
			    color: $white;
			    text-transform: uppercase;
			    letter-spacing: 8px;
    		}
    		
    		.bonus-reward {
    			font-size: $base-font + 8px;
			    font-family: $FuturaBold;
    		}
    		
    		.billing  {
    			font-size: $base-font + 3px;
    		}
		}
		
		.pre-screen-notice {
			font-size: $base-font + 5px;
    		padding: 10px 8px;
		}
		
		.legal-copy {
			padding: 0 0 10px 0;
    		font-size: $base-font;
    		
		}
		
		.form-row-button {
			
			.plcc-nothanks-button {
				 font-size: $base-font;
				 border: 0.5px solid $black;
				 padding: 15px 19px;
			}
			
			.plcc-get-it-button {
				font-size: $base-font;
				padding: 15px 19px;
			}
		}
	}
}
}