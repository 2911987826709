#main {
    .pdp-main .pdp-horizontal {
        @media screen and (min-width: 1260px) {
            .pdp-max-width {
                width: 100%;
                min-width: 1260px;
                display: inline-block;
                padding: 0 65px;
                box-sizing: border-box;
                max-width: none;
            }
            .image {
                margin-right: 100px;
            }
            .top-wrap, .info {
                width: 620px;
                max-width: 620px;
                min-width: 620px;
            }
            .product-variations, .product-options {
                .color li a{
                    margin-right: 10px;
                }
            } 
        }

        .recommendations-h{
            width: 100%;
            max-width: 1258px;
            margin: 0 auto;
            float: none;
            clear: both;
            .recommendations {
                width: 100%;
                border: none;
                .recommendation-tiles {
                    max-width: 1250px;
                    margin: 0 auto;
                    button {
                        display: block;
                        margin: 0 auto;
                        padding: 12px;
                        text-indent: 100%;
                        position: absolute;
                        top:41%;
						left:auto;
                        &.slick-prev {
                            right: 100%;
                            top: 38%;
                            background: url("../../../images/Left_ArrowBlack.svg") no-repeat center right/contain;
                            &.slick-disabled {
                                background: url("../../../images/Left_ArrowGray.svg") no-repeat center right/contain;
                            }
                        }
                        &.slick-next {
                            left: 100%;
                            top: 38%;
                            background: url("../../../images/Right_ArrowBlack.svg") no-repeat center left/contain;
                            &.slick-disabled {
                                background: url("../../../images/Right_ArrowGray.svg") no-repeat center left/contain;
                            }
                        }
                    }
                }
                .product-tile a.name-link, .product-tile .product-image {
                    max-width: 220px;
                }

            }
            .recommendations-heading {
                padding: 40px 0 0 24px;
                font-size: 24px;
                letter-spacing: 1.03px;
                opacity: 0.5;
                text-align: center;
            }
        }
    }
} 
@media screen and (max-width: 1160px){
	#main .pdp-main .recommendation button{
		left:auto;
	}
}