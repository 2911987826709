.ui-widget-overlay {
	background: $mercury;
}

.ui-dialog {
	border: 1px solid $mercury;
	.ui-dialog-titlebar-close {
		height: 0;
		padding: 25px;
		width: 0;
		z-index: 1;
		overflow: hidden;
		&:hover {
			opacity: 1;
		}
	
		span {
			margin: 0;
			padding: 0;
		}
	}
}