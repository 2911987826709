
$mercury: #e1e1e1;

// mini cart slot
$minicart-slot-bg-color: $guardsman-Red;
$midium-grey: #9d9d9d;
$dark-blue: #4420fe;
$light-green: #26bf1e;
$grape-light: #331F57;
$dark-purple-red: #bd0304;
$cod-gray: #1E1E1E;
$grape-blue: #331E54;
$minsk: #472A74;
$link-color: #472A74;