.ui-dialog {
	&.cart-overlay {
		padding: 10px;
    	box-sizing: border-box;

		+.ui-widget-overlay {
			background: $mercury;
		}

		.pt_cart {
			#main {
				padding: 0 0;
			}
		}

		.primary-content {
			padding: 0 0;
		}
		.ui-widget-header {
			 border-bottom: 1px solid $white-smoke;
			 width: 100%;
			 text-align: center;
			 margin-bottom: 10px;

			 .ui-dialog-title {
				display: inline-block;
				font-size: 28px;
				font-family: $MrEavesReg;
			    text-align: center;
				text-transform: none;
			    font-weight: normal;
			    margin: 9px 0 0 -23px;
				background: url("../../../images/Checkmark_Icon_Green.svg") left center no-repeat;
				background-size: 25px;
				padding-left: 30px;
    			float: none;
    			width: auto;
    			margin-left: 13px;
			}
		}

		.ui-dialog-content {
			height: auto !important;
			@media screen and (max-width: 959px) and (min-width: 768px) {
				overflow: auto;
			}
		}

		.special-product-set-overlay {
			.right-content {
				.mini-cart-name {
					margin-bottom: 0;
					border-bottom: 0;
					padding-bottom: 5px;
				}
				div {
					font-size: $base-font;

					span.label {
						margin-bottom: 9px;
						font-size: $base-font;
					}
				}
			}
			div.price-promotion {
				margin-bottom: 7px;
			}

			.special-productset-child-wrapper {
				border-top: 1px solid $alto;
				border-bottom: 1px solid $alto;
				display: inline-block;
    			width: 100%;

				.special-productset-child {
				    display: inline-block;
				    width: 100%;
				    margin-top: 15px;

				    div {
						font-size: $base-font - 1px;

						span.label {
							font-size: $base-font - 1px;
						}
					}
				}

				.mini-cart-image {
					width: 60px;
    				float: left;
				}
				.mini-cart-product-info {
					width: 214px;
					float: left;
					padding-left: 15px;

					.mini-cart-name {
						font-family: $Futura;
						font-size: $base-font + 4px;
					}
				}
			}
		}
	}
}

.cart-overlay {
	.mini-cart-content {
		.mini-cart-totals {
			box-sizing: border-box;
			font-size: 14px;
   			text-transform: uppercase;
   			font-family: $FuturaBold;
			display: table;
			text-align: left;
			width: 100%;

   			.value  {
   				font-family: $FuturaMedium;
   			}
   			.hide {
   				display: block;
				border-top: 1px solid $white-smoke;
				padding: 17px 0 0 ;
   			}

   			&.only-for-mobile {
   				display: none;
   				@media screen and (max-width:767px){
   					display: block;
				    width: 77%;
				    margin: auto;
				    text-align: left;
   				}
   			}
   			&.hide-mobile {
   				@media screen and (max-width:767px){
   					display: none;
   				}
   			}
		}

		.mini-cart-product {
			width: 100%;
    		display: table;
    		@media screen and (max-width:767px){
    			display: inline-block;
    		}

    		div.price-promotion {
    			display: inline-block;
    			margin-bottom: 15px;
    		}

    		.slick-slide {
    			img {
    				margin: auto;
    			}
    		}
			.left-content {
				display:table-cell;
				width:50%;
    			vertical-align: middle;
    			.mini-cart-image {
    				max-width: 209px;
    				margin:0 auto;
					text-align: center;
					position: relative;
					margin-top:13px;
    				img {
    					margin : auto;
    				}
    				.brand-logo {
					    margin-top: 10px;
					    width: 100%;
					    text-align: center;
					    display: none;
    				}
    			}
			}

			.right-content {
				display:table-cell;
				width:50%;
    			max-width: 361px;
    			padding:0 40px 0 20px;
				vertical-align: middle;

				@media screen and (max-width:767px){
					vertical-align: top;
				}

    			div {
    				font-size: 12px;
    				font-family: $Futura;


    				span {
    					&.label {
    						font-family: $FuturaBold;
    						text-transform: uppercase;
							margin-bottom: 15px;
						    display: inline-block;
					        letter-spacing: 1px;
					        &.baseprice {
					        	margin-bottom: 0px;
					        }
    					}
    				}
    				span {
    					&.value ,&.is-in-stock {
    						text-transform: uppercase;
    					}
    					&.mini-cart-price {
    						color: $guardsman-Red;
    					}
    				}

				}
				.mini-cart-brand{
					text-transform:uppercase;
					color:$brand-name-color;
					font-size: $base-font - 2px;
					font-family: $WorkSans;
				}

    			.mini-cart-name {
    				font-size: 27px;
    				font-family: $MrEavesReg;
    				margin-bottom: 16px;
				    padding-bottom: 10px;
				    border-bottom: 1px solid $white-smoke;
				    text-transform: capitalize;
    			}

    			.product-availability-list {
    				margin: 0;
    				padding: 0;

    				li {
    					padding: 0 0 10px 0;
    				}
    			}
    			.mini-cart-pricing {
    				.price,.product-price {
    					float: left;
    					margin-right: 4px;
    				}
    				.product-price {
    					span {
                            color: $black;
                            &.price-standard-exist {
                                color: $guardsman-Red;
                            }
                            &.price-standard {
                                color: $black;
                                text-decoration: line-through;
                            }
                        }
    				}

    				.total-price {
    					clear: both;
    				}
    			}
			}
		}
	}
	//recommedation
	.you-may-like {
		background: $wild-sand;
	    padding: 10px 0 7px;
	    font-style: normal;
   		text-align: center;
	    display: inline-block;
	    width: 100%;
	    font-weight: normal;

		h2 {
			color: $black;
			font-size: $base-font + 8px;
			font-family: $MrEavesReg;
			font-style: normal;
    		padding: 2px 0 4px;
    		text-transform: none;
		    font-weight: normal;
		}
		.einstien-reommendations{
			ul.recommendation-tiles{
				@media screen and (min-width:768px) and (max-width:1024px){
					padding:0;
				}
			}	
		}
		.product-tile {
			padding: 10px 20px 0;
			@media screen and (min-width:768px) and (max-width:1024px){
				padding:10px 10px 0;
			}
		  letter-spacing: 0;
		  width:100%;
		    float: left;
		    margin: 0 0;
		    box-sizing: border-box;

		    .product-image {
		    	max-width: 80px;
		    	margin: auto;

		    	img {
	    		    height: auto;
	    		    max-width:100%;
		    	}
		    }
		    .product-name {
		    	font-size: $base-font + 4px;
	    	    margin-top: 4px;
		    }
		}
	}
}

@media screen and (max-width:767px){
	.ui-dialog {
		&.cart-overlay {
			box-shadow: none;
			max-width: 93%;
		    left: 3.5% !important;
		    overflow: auto;

		    .ui-dialog-titlebar-close {
				margin: 0 auto;
				float: right;

		    	span.ui-icon-closethick {
				   left:50%;
		    	}
		    }
		    +.ui-widget-overlay {
				background: #333333;
			}
			.pt_cart #main {
				margin: 0 0;
			}

		    .mini-cart-content {
		    	display: block;
		    	max-width: 100%;
			    position: static;
			    box-shadow: none;
			    margin: 0 0;
		    }

		    .ui-dialog-buttonpane {
		    	.ui-dialog-buttonset {
		    		width: 77%;
	    		    margin: 10px auto;

		    		button {
		    			width: 100%;
		    		}
		    		button:nth-of-type(1) {
						background: $mainly-blue;
						border-color: $mainly-blue;
						color: $white;
					}
		    		button:nth-of-type(2) {
		    			margin: 20px 0;
		    			background: transparent;
		    			border-color: $dim-gray;
						color: $dim-gray;
		    		}
		    	}
		    }
		    .ui-widget-header {
			 	.ui-dialog-title {
			 		@media screen and (max-width:360px){
				 		text-align: left;
					    width: 100%;
					    box-sizing: border-box;
					    margin-left: 1%;
					}
			 	}
			}

		    .mini-cart-content {
			    .special-product-set-overlay {
			    	.left-content {
			    		display: none;
			    	}

					.right-content {
						width: 100%;
						padding: 0 10px;
						max-width: 100%;
					}

					.special-productset-child-wrapper {
						.mini-cart-image {
							display: none;
						}
						.mini-cart-product-info {
							padding-left: 0;
						}
					}
				}
			}

		    .slick-dots {
		    	width: 100%;
   				text-align: center;
   				padding: 0;
			    list-style: none;

   				li {
   					display: inline-block;
				    width: 17px;
				    height: 17px;
				    margin: 0 7px;
				    padding: 0;
				    cursor: pointer;
				    background: $light-gray;
    				border-radius: 50%;

				    &.slick-active {
				    	background: $black;
				    }

				    button {
				    	font-size: 0;
					    line-height: 0;
					    display: block;
					    width: 100%;
					    height: 100%;
					    padding: 5px;
					    cursor: pointer;
					    color: transparent;
					    border: 0;
					    outline: none;
					    background: transparent;
				    }
   				}
		    }
		}
	}

	.cart-overlay {
		.mini-cart-content {
			.mini-cart-products  {
				padding: 0 0;
				border-bottom: 1px solid $white-smoke;
			}

			.mini-cart-product {
				border: 0;
			    padding: 0 0;

				.left-content {
					max-width: 270px;
   					 margin: auto;
				}

				.right-content {
		    		padding-left: 20px;
		    		width: 50%;
		    		div {
	    				span {
	    					&.label {
	    						margin-bottom: 10px;
	    						padding: 0;
	    					}
	    				}
	    			}
		    		.mini-cart-name,.mini-cart-pricing,.mini-cart-attributes {
		    			width:100%;
		    		}
		    		.mini-cart-attributes {
		    			span {
		    				&.value {
								//font-family:$Helvetica;;
		    				}
		    			}
		    		}
		    		.mini-cart-name {
		    			font-size: $base-font + 8px;
	    			    margin-bottom: 9px;
    					border-bottom: 0;
    					padding-bottom: 0;
		    		}
		    	}
			}

			.mini-cart-totals {
				float: none;
    			width: 100%;
			    margin-left: 0;
    			text-align: center;
    			padding-top: 0;

    			.mini-cart-subtotals {
    				padding: 15px 0;
    				font-size: 12px;
				    border-top: 0;

    				.value {
    					//font-family: $Helvetica;
    				}
    			}
			}
		}
	}
}

@media screen and (max-width:480px){
	.ui-dialog {
		&.cart-overlay {
			padding: 5px;

			.ui-widget-header {
			 	.ui-dialog-title {
		 		    /*font-size: 12px;*/
					   margin-top: 16px;
					   float: left;
			 	}
			}
			.ui-dialog-buttonpane {
			 	.ui-dialog-buttonset {
			 		button {
			 			padding: 10px 5px;
			 			/*font-size: 12px;*/
			 		}
			 	}
			}

			.mini-cart-content {
				.special-product-set-overlay {
					.right-content {
						width: 100%;
					}
				}
			}

			.slick-dots {
   				li {
				    width: 13px;
				    height: 13px;
				    margin: 0 5px;
				}
			}
		}
	}
	.cart-overlay {
		.mini-cart-content {
			.mini-cart-product {
				.left-content {
					width: 40%;
				}
				.right-content {
					width: 60%;
					padding:0 0 0 10px;
					div {
						/*font-size: 10px;*/

						span {
							&.label {
								/*font-size: 10px;*/
							}
						}
					}
				}
			}
		}
	}
}
