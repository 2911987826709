.product-monogramming, .product-hemmable {
	width: 100%;
    float: none;
    padding: 20px 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
 	-moz-box-sizing: border-box;
    border-top: 1px solid $light-gray;
    /*border-bottom: 1px solid $very-light-gray;*/
    
    &.error-msg {
    	.title {
    		color: $dark-red;
    	}
    }
    
    .heading {
    	width: 100%;
	    display: inline-block;
	    padding: 0 0 10px 0;
	    font-size: $base-font;
	    text-align: left;
	    
	    .title {
	    	width: auto;
    		float: left;
	    }
	    
	    .tips {
	    	width: auto;
    		float: right;
    		position: relative;
    		
    		.tips-link, .hemming-tips-link {
    			font-size: $base-font;
    			
    			&:before {
    				background: url('../../../images/tips.svg') no-repeat;
					width:16px;
					height:16px;
					background-size:cover;
				    padding: 0;
				    margin: 0;
				    content: '';
				    position: absolute;
				    left: -25px;
    			}
    		}
	    }
    }
    
    .form, .checkbox-content {
    	width: 100%;
    	float: none;
    	
    	label {
    		span {
    			text-transform: uppercase;
    			font-size: $base-font;
			    font-family: $FuturaMedium;
			    color: $black;
			    text-align: left;
			    line-height: 18px;
			    padding-left: 10px;
			    word-spacing: 3px;
			    font-weight: normal;
				letter-spacing: .1em;
    		}
    	}
    	
    	input[type="checkbox"] {
    		margin: 0 0 0 2px;
    	}
    }
    
    .input-fields-set {
    	.character-left {
    		position: relative;
    		width: 100%;
    		clear: both;
    		.monogramming-charactercount {
    			position: absolute;
			    width: auto;
			    right: 15px;
			    top: 50%;
			    font-family: $MrEavesReg;
    		}
    		
    		input[data-type="NAME"] {
				font-family: $MrEavesReg;
				font-size: $base-font + 2;
				padding-left: 15px;
    			
    			&::-webkit-input-placeholder { 
					text-transform: capitalize;
				}
				&::-moz-placeholder { 
				  	text-transform: capitalize;
				}
				&:-ms-input-placeholder {
				  	text-transform: capitalize;
				}
				&:-moz-placeholder {
				  	text-transform: capitalize;
				}
    		}
    	}
    }
    
    .personalized {
    	width: 48%;
		float: none;
		display: inline-block;
    	
    	@media screen and (max-width: 767px) {
    		width: 100%;
    	}
    	
    	&.monogramming-color {
    		float: right;
    	}
    	
    	&.monogramming-position {
    		width: 68%;
    		
    		@media screen and (max-width: 767px) {
				width: 100%;
				overflow: auto;
	    	}
    	}
    	
    	.custom-select {
			margin: 20px 0 0 0;
			float:none;
    		
    		&.current_item {
    			.selected-option {
	    			&.selected {
					    border-bottom: 0;
						border: 2px solid $dim-gray;
		    		}
	    		}
    		}
    		
    		&.mono-error {
    			.selected-option, select {
    				color: $guardsman-Red;
    			}
    		}
    		
    		select {
    			@media screen and (min-width: 768px) and (max-width: 1024px) {
    				padding: 0 38px 0 12px;
    			}
    		}
    	}
    	
    	.selected-option {
    		height: 48px;
    		font-size: $base-font + 2;
    		color: $dusty-gray;
    		border: 1px solid $dusty-gray;
    		text-transform: capitalize;
    		font-family: $MrEavesReg;
			letter-spacing: .1em;
			&.selected{
				color: black;
			}
    	}
    	
    	.selection-list {
    		li {
    			font-size: $base-font+ 2;
				text-transform: capitalize;
				font-family: $MrEavesReg;
				letter-spacing: .1em;
    		}
    	}
    }
    
    .input-fields {
    	height: 48px;
		margin: 20px 0 0 0;
		letter-spacing: .1em;
		text-transform: uppercase;
    	
    	&.mono-error {
    		&::-webkit-input-placeholder { 
			  color: $guardsman-Red;
			}
			&::-moz-placeholder { 
			  color: $guardsman-Red;
			}
			&:-ms-input-placeholder {
			  color: $guardsman-Red;
			}
			&:-moz-placeholder {
			  color: $guardsman-Red;
			}
		}
    }
    
    .monogramming-preview {
    	position: relative;
		font-family: $MrEavesReg;
    	
		textarea {
    		height: 100px;
    		margin: 20px 0 0 0;
    		width: 100%;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
			padding: 20px 15% 0 15px;
			position: relative;
			font-size: $base-font + 2;
			letter-spacing: .1em;
		}
		
		.refresh-link {
			background: url('../../../images/refresh.svg') no-repeat;
		    width: 26px;
		    height: 26px;
		    padding: 0;
		    margin: 0;
		    position: absolute;
		    top: 45%;
		    right: 0;
		    margin-right: 10px;
		    transform: translate(-50%);
		    -webkit-transform: translate(-50%);
		    -moz-transform: translate(-50%);
			-o-transform: translate(-50%);
			-ms-transform: translate(-50%);
		}
	}
}

.presonalised-msg-norefund {
	padding: 10px 0 0 0;
	font-size: $base-font + 1;
	font-family: $MrEavesReg;
	width: 100%;
    float: left;
	color: $mangotango;
	letter-spacing: .05em
}

.personalised-msg-valid-characters {
	font-size: $base-font + 1;
	font-family: $MrEavesReg;
	padding-top: 10px;
}

.hemming-drawer {
	width: 100%;
	float: left;
	margin: 20px 0 0 0;
	
	ul {
		li {
		    width: 40px;
		    border: 2px solid $black;
		    text-align: center;
		    vertical-align: middle;
		    cursor: pointer;
		    float: left;
		    margin-right: 10px;	
		    font-size: $base-font;
			font-family: $Futura;
			margin-bottom: 10px;
			
			&.selected {
				background: $black;
				color: $white;
			}
		}
	}
}

.hemming-error-msg {
	font-size: $base-font;
	font-family: $FuturaMedium;
	margin-top: 10px;
	color: $dark-red;
	width: 100%;
	float: left;
}

.ui-dialog {
	&.tips, &.product-hemming-tips {
    	.ui-dialog-title {
    		display: block;
    		font-size: $base-font - 1px;
			font-family: $HelveticaBold;
			padding-left: 25px;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
    		margin-top: 20px;
    	}
    	
    	.ui-dialog-content {
    		box-sizing: border-box;
    		-webkit-box-sizing: border-box;
 			-moz-box-sizing: border-box;
    		
    		p {
    			font-size: $base-font;
				font-family: $MrEavesReg;
				margin: 0 auto 17px;
				
				span {
    				font-family: $HelveticaBold;
    				text-transform: uppercase;
    			}
			}
			
    	}
	}
}

.single-selection {    
    span {
    	font-size: $base-font + 2px;
    }
}