.pt_product-search-noresult {
	.primary-content {
	    padding: 0 0 30px;
	   
	    .section-header {
	    	background-color: $mercury;
			padding: 15px 11px 5px 23px; 
			font-size: $base-font + 6px;
			font-family: $MrEavesReg;
		
			p {
			    padding: 1px 1px 0 2px;
				color: $dim-gray;
				letter-spacing: 0.8px;
			}
		
			.no-hits-search {
				input[type="text"] {
				    font-size: $base-font + 48px;
				    height: auto;
				    font-family: $MrEavesReg;
				    color: $grape;
				    letter-spacing: 2.3px;
				}
		
				button {
				    margin: 3px 20px 0 0; 
				    width: 34px;
    				height: 36px;
				}
			}
	    }
	}
}
.popular-searches {
    padding: 35px 0;
    font-family: $MrEavesReg;
  	
    .no-results-heading {
    	font-size: $base-font + 21px;
    	letter-spacing: 2px;
    }
  
    .search-term {
    	font-size: $base-font + 9px;
    	margin: 15px -5px 0 0;
    	letter-spacing: 1.25px;
    }
  
    .search-brands {
    	padding: 13px 0 0 0;
  
    	a {
    		font-size: $base-font + 2px;
    		font-family: $FuturaMedium;
    		letter-spacing: 2px;
    		font-weight: bold;
    	}
    }
  
    .did-you-mean {
    	font-size: $base-font + 6px;
    	margin: 16px 0 0 0;
    	font-weight: bold;
    	letter-spacing: 2px;
    	  
    	.no-hits-search-term-suggest {
    		font-family: $FuturaMedium;
    		color: $black;
    		letter-spacing: 4px;
    	}
    }
  
    .shop-all {
    	margin: 38px 0 0 0;
  
    	.button {
    		font-size: $base-font + 5px;
    		font-family: $FuturaMedium;
    		padding: 11px 22.5px;
		    border-width: 1px;
    		background:transparent; 
    		letter-spacing: 2.2px;
    		
    		&.shop-clearance{
    			display: none;
    		}
    	}
    }
}

@media screen and (min-width:768px) and (max-width: 1023px){
	.pt_product-search-noresult { 
		.header-promo-bottom {
			.promo-banner {
				margin: 14px auto 9px;
			}
		}
		.primary-content {
			.section-header {
				padding: 16px 11px 5px 34px;
				p {
					font-size: 17px;
					padding: 2px 3px 0 3px;
				}
				.no-hits-search {
					button {
						margin: 2px 26px 0 0;
					}
					input[type="text"] {
						letter-spacing: 2.5px;
					}
				}
			}
		}
	}
	.popular-searches {
		padding: 50px 0;
		.no-results-heading {
			letter-spacing: 3px;
		}
		.did-you-mean {
			padding: 23px 0 0px;
		    margin: -9px 0 0 0;
		    letter-spacing: 1px;
		    font-size: $base-font + 6px;
		    font-weight: bold;
			.no-hits-search-term-suggest {
				 letter-spacing: 6px;
			} 
		}
		.search-term {
			font-size: 20px;
    		margin: 4px -5px 0 0;
    		letter-spacing: 0.4px;
		}
		.search-brands {
			padding: 7px 0 0 0;
			a {
				font-size: 16px;
			}
		}
		.shop-all {
			margin: 21px 0 0 0;
			.button{
				 letter-spacing: 0.7px;
				 padding: 12px 32px;
				 border-top-left-radius: 16px;
    			 border-bottom-right-radius: 16px;
			}
		}
	} 
}

@media screen and (max-width:767px){
	.pt_product-search-noresult {
		.primary-content {
			.section-header {
				 font-size: $base-font + 3px;
				 padding: 23px 11px 17px 7px;
				 .no-hits-search {
				 	input[type="text"] {
				 		 width: 80%;
				 		 font-size: 30px;
				 	}
				 	button {
				 		width: 16px;
    					height: 30px;
    					background-position:0 3px;
    					margin: 0 5px 0 0;
				 	}
				 }
			}
		}
	} 
	.popular-searches {
		padding: 20px 0 0;
		.search-term {		
    		margin: 0;
    		font-size: $base-font + 6px;
		}
		.no-results-heading {
    		font-size: $base-font + 12px;
    		letter-spacing:0.5px;
    	}
    	.did-you-mean {
	   		font-size: $base-font + 4px;
    		margin: 0;
    		font-weight: bold;
    		letter-spacing: 0.2px;
    		margin-top: -6px;
    	}
		.search-brands {
			padding: 0;
			a {
				font-size: $base-font - 3px;
				font-weight: bold;
    			letter-spacing: 1px;
			}
		}
		.shop-all {
			margin: 7px 0 11px 0;
			.button {
				font-size: $base-font;
				padding: 7px 18px;
				border-top-left-radius: 16px;
    			border-bottom-right-radius: 16px;
			}
		}
	} 
}

//Trending Now
.trending-section {	
	.you-may-like {
		padding-top: 20px;
	}
	
	.recommendation-heading {
		margin: 10px 0 30px;
		
		span {			
			&.trending {
				font-family: $MrEavesReg;
				font-size: $base-font + 21px;
				letter-spacing: 1px;
				text-transform: capitalize;
				margin: 0 0 40px 0;
				
				@media screen and (max-width: 767px) {
					font-size: $base-font + 12px;
				}
			}
		}
	}
}
