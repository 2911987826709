/* RM-site breadcrumbs css start */
.breadcrumb { 
     font-family: $FuturaMedium;
     .breadcrumb-element.current-element {
    font-weight: bold;
}
/* breadcrumb tablet media query start */
 @media screen and (max-width: 1023px) {
	 

    }
/* breadcrumb tablet media query end */	

/* breadcrumb mobile media query start */
	@media screen and (max-width: 767px) {	

.breadcrumb-element.current-element {
    font-weight: normal;
}
    }
/* breadcrumb mobile media query end */

}

/* RM-site breadcrumbs css end */