.top-menu-utility {
	.menu-utility {

		.mini-cart-product-info{
			width: 65%;
		}
		.mini-cart-total {
			position: relative;
			float: none;
			display: inline-block;
			.mini-cart-link  {
				padding: 7px 21px 12px 25px;
				@media screen and (max-width:1023px) and (min-width:768px){
					padding:9px 21px 12px 25px;
				}
				display: inline-block;
				float: none;
				span {
					&.cart-bag {
						background: url('../../../images/bag-icon.svg') no-repeat;
					}
					&.cart-bag-qty {
						background: url('../../../images/bag-icon-white.svg') no-repeat;
					}
				}
				.minicart-quantity {
					background-color: $mainly-blue;
					border: 2px solid $white;
					top: 6px;
					@media screen and (max-width:767px){
						top:2px;
					}
				    right: 90px;
					font-family: $FuturaMedium;
    				font-size: .96em;
    				text-align: center;
					padding: 0 2px 1px 3px;
    				min-width: 19px;
    				line-height: 16px;
				}

				&:hover, &.minicart_open {
					span {
						&.cart-bag, &.cart-bag-qty {
							background: url('../../../images/bag-icon-black.svg') no-repeat;
						}
					}
				}

				&.mini-cart-empty {
					&:hover {
	                     span {
	                        &.cart-bag, &.cart-bag-qty {
	                           background: url('../../../images/bag-icon.svg') no-repeat;
	                        }
	                     }
	                 }
				}
			}
		}
		.mini-cart-content {
			box-shadow: 0 18px 30px 0 rgba(229, 229, 229, 0.5);
			-webkit-box-shadow: 0 18px 30px 0 rgba(229, 229, 229, 0.5);
			-moz-box-shadow:    0 18px 30px 0 rgba(229, 229, 229, 0.5);
			border: 1px solid #e1e1e1; //PXSFCC-1446
			border-top: 0 none;
			.slimScrollDiv{
				padding:14px 0 0;
			}
			.mini-cart-products {
				padding: 0 32px 0 40px;
				margin-right: 8px;
				.mini-cart-image{
					padding-top: 6px;
				}

				.mini-cart-name{
					padding: 0 0 6px 0;
					text-transform: capitalize;
				}

				.mini-cart-product {
					padding: 14px 0 14px 0;
					&:first-child{
					padding:0 0 14px 0;

					}
					span, a {
						&.label {
							font-family: $FuturaBold;
						}
						&.value {
							font-family: $MrEavesReg;
							font-size: $base-font + 4px;
							text-transform: capitalize;
						}
					}
					span{
						&.label, &.value{
							padding: 0 0 2px 0;
						}
					}
					.is-in-stock {
						font-size: $base-font + 4px;
						font-family: $MrEavesReg;
						text-transform: capitalize;
					}
					.product-price{
						width: auto;
    					display: inline-block;
						span{
							font-family: $MrEavesReg;
							font-size: $base-font + 4px;
						}
					}

					.attribute[data-attribute="size"] {
						span {
							&.value {
								text-transform: uppercase;
							}
						}
					}
				}
			}
			.slimScrollBar {
				background: $dim-gray !important;
			}
			.slimScrollRail {
				background: $gainsboro !important;
			}
		}

		.mini-cart-product {
			span {
				&.value {
					font-family: $MrEavesReg;
				}
			}
		}
		.mini-cart-name {
			a {
				font-family: $MrEavesReg;
				font-size: $base-font + 6px;
			}
		}

		.mini-cart-totals {
			button,
			.button {
				font-size: $base-font + 3px;
				padding: 15px 103px;
				background: $grape;
    			font-family: $FuturaMedium;
    			letter-spacing: 1px;
    			margin-bottom: 20px;
			}
			.mini-cart-link-cart{
				padding:15px 82px;
			}
			.mini-cart-subtotals {
				padding: 19px 0 22px 0;
				span {
					font-family: $MrEavesHeavy;
					font-size: $base-font + 6px;
					color: $black;
				}
			}
		}
		.mini-cart-slot {
			.minicartslot-content {
				.minicartslot {
					.discount-sec {
						font-size: $base-font + 38px;
						font-family: $FuturaBold;
						.discount-percent {
							font-size: $base-font + 38px;
							font-family: $FuturaBold;
						}
					}
					.product-sec {
						font-family: $MrEavesReg;
					}
				}
			}
		}
		#mini-cart { 
			position: relative;
			padding: 0;
			vertical-align: top;
			float: none;
		}
	}
	&.header_fixed{
		.top-menu-utility .menu-utility .mini-cart-total .mini-cart-link{
			display: inline-block;
		}
	}
}
