/* Body */
body {
	font-family: $FuturaMedium,$sans-serif;
	font-size: $base-font;
}

/* Text */ 
h1 {
	font-family: $Futura;
	font-size: $base-font + 38px;
}
h2:not(.recommendation-heading):not(.dont-use-legacy-sitegen) {
	font-family: $MrEavesBook;
	font-size: $base-font + 30px;
}
h3.bold {
	font-family: $FuturaBold;
	font-size: $base-font + 18px;
}
h4 {
	font-family: $MrEavesReg;
	font-size: $base-font + 10px;
}
p:not(.dont-use-legacy-sitegen) {
	font-family: $MrEavesReg;
	font-size: $base-font;
}


/* Navigation */
nav a {
    font-family: "Futura LT W01 Medium";
    font-size: $base-font;
	color: $black;
}

/* Form */
form label {
	font-family: $MrEavesBook;
    font-size: $base-font + 5px;
    color: $dim-gray;
}

input[type="checkbox"] {   
    &:checked {
    	background: url('../../../images/check.png') no-repeat center;
    }
}

/* CTA */
button:not(.btn-ds):not([class*="tt-o-button"]),
.button,
.buttonstyle,
input[type="button"] {
	background: $grape;
	border-color: $grape;
	color: $white;
	font-size: 15px;
	padding: 12.5px 42px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	border-top-left-radius: 15px;
	border-bottom-right-radius: 15px;
	letter-spacing: 1px;

	&.large {
		font-size: 1.35em;
		text-transform: uppercase;
	}
	&.raised {
		border-width: 0;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
		&:hover {
			background-color: darken($citrus, 10%);
			color: $white;
		}
	}
	&.simple {
		background-color: $white;
		color: darken($citrus, 10%);
	}
	&[disabled],
	&.disabled,
	&.atb-disabled {
		color: $white;
	}

	&.solid-wine-berry {
	    background: $grape;
	    border-color: $grape;
	    color: $white;
	    &:hover {
	    	@extend .solid-wine-berry;
	    }
	}

	&.transperent-site-specific {
		background: transparent;
	    border-color: $grape;
	    color: $grape;

		 &:hover {
	    	@extend .transperent-site-specific;
	    }
	}

	//This class removes the left top border of btn beside textbox in Romens site.
	&.remove-left-top-border {
		@media screen and (min-width: 767px) {
			border-top-left-radius: 0;
			-webkit-border-top-left-radius: 0;
			-moz-border-top-left-radius: 0;
		}
	}
	a {
		color: $white;
		&:hover {
			color: $citrus;
		}
	}
	i {
		&.left {
			margin-right: 0.6em;
		}
		&.right {
			margin-left: 0.6em;
		}
	}	
	//This class removes the left top border of btn beside textbox in Romens site.
	&.remove-left-top-border {
		@media screen and (min-width: 767px) {
			border-top-left-radius: 0;
			-webkit-border-top-left-radius: 0;
			-moz-border-top-left-radius: 0;
		}
	}
}

.buttonOulined {
	background: $white;
	border: 1px solid #666666;
	color: #666;
}

.button-text {
	color: $black;
}

/* Back To Top */
.pt_product-search-result {
	a{
		&.back-to-top {
			width: 42px;
			height: 42px;
			background: url('../../../images/rm/back-to-top.svg') no-repeat center 43% transparent;
		}
	}
}

@media only screen and ( max-width:767px ) {
	button:not(.btn-ds):not([class*="tt-o-button"]),
	.button,
	.buttonstyle,
	input[type="button"] {
		&.solid-wine-berry,&.transperent-site-specific{
			padding-left: 0;
			padding-right: 0;
			width:100%;
		}
	}
}
