// Order History - Order Details Styling

.pt_order,
.confirmation {
    .actions {
        padding: .5em 1%;
        a {
            margin-right: 2rem;
        }
    }
}
.catalog-item-number {
	font-family: $HelveticaMedium;
}
.pt_order {
	#secondary {
		.secondary-navigation-links {
			.navigation-links-row {
				li a:hover {
					color: $minsk;
				}
			}
		}
	}
	.primary-content {
		width: 100%;
    	float: left;
	}
	.order-payment-summary {
		.actions {
			float: left;
		}
	}
	.order-history {
		.international-orders{
			.check-order{
				.form-row{
					&.orderNumber, &.orderEmail{
						label{
							span{
								font-family:$FuturaHeavy;
							}
						}
					}
				}
			}
		}
	} 
	
	.internation-shipment-method {
		font-size: $base-font + 4px;
		font-family: $MrEavesReg;
		background: $light-black-two;
		color: $white;
		text-align: center;
		line-height: 18px;
    	padding: 6px 0 8px;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
		
		@media screen and (max-width: 767px) {
		    text-align: left;
		    padding: 6px 9px 8px;
		    
		    .shipping-method {
		    	margin-right: 15px;
		    }
		}
	}
	
	.order-details-section {
		.qa-section {
    		max-width: 1024px;
    		margin: 0 auto;
    		display: block;
    		
    		.question-title {
    			text-transform: inherit;
    			padding: 27px 41px;
    			font-size: $base-font + 16px;
       		}
    		
    		.qa-content {
    			padding: 25px 43px;
 	 			
 	 			.question, .question.active {
 	 				background-size: 16px;
 	 			}
 	 			
 	 			.answer {
 	 				font-size: $base-font + 5px;
 	 				padding: 17px 0 0 0;
 	 			}
    		}
    	}
	}
	
	.orderdetails {
    	margin: 24.5px auto 60px;
    	
    	.heading {
			font-family: $FuturaBold;
			margin: 0 0 10px 0;
			width: 100%;
			display: inline-block;
			font-size: $base-font - 1px;
			text-transform: uppercase;
		}
    	
    	.actions {
		    padding: 0;
		    margin-top: 35px;
		    
		    a {
			    padding: 16px 30px;
			    text-transform: uppercase;
			    letter-spacing: 1px;
			    font-size: $base-font + 3px;
			    font-family: $Futura;
			    border-top-left-radius: 15px;
    			border-bottom-right-radius: 15px;
		    }
    	}
    	
    	.optioninfo {
    		.option-info {
    			font-family: $Helvetica;
    			font-size: $base-font;
    			color: $mangotango;
    			
    			span {
    				text-transform: capitalize;
    				letter-spacing: 0;
    			}
    		}
    	}
    	
    	.order-information {
    		padding: 5px 0 0 0;
    		
    		.order-print {
    			h1 {
    				font-size: $base-font + 16px;
    				font-family: $MrEavesReg;
    				margin: 0 0 10px 0;
    			}
    		}
    		
    		.order-status {
    			.order-status {
    				display: none;
    			}
    			
	    		.mail-box {
					background: url('../../../images/mail-black.svg') no-repeat;
				}
				
				.receipt-message {
					font-family: $MrEavesReg;
					font-size: $base-font + 4px;
					margin: 13px 0 0 10px;
					display: inline-block;
				}
			}
    	}
    	
    	.order-detail-section {
    		padding: 16px;
    		  		
    		.section {
    			width: 33.33%;
    			
    			.heading {
    				margin: 0 0 10px 0;
    			}
    			
    			.value {
    				margin: 0 0 7px 0;
    				display: inline-block;
    				width: 100%;
    			}
    		}
    		
    		&.order-detail-response {
    			.order-status {
    				width: 25%;
    			}
    			
    			span {
    				&.img {
    					float: right;
    					margin-left: 10px;
    				}
    			}
    		
    			.order-payment-instruments {
    				width: 32%;
    			
    				.inner-block {
    					max-width: 200px;
    				}
    			}
    			
    			.order-coupon-reward {
    				width: 20%;
    			}
    		
    			.order-billing {
    				width: auto;
    			}	
    		}
    	}
    	
    	.order-shipments {
    		.order-shipment-table {
				&.e-gift-card {
					.order-shipment-section {
						.method {
						    width: 86%;
							float: left;
							
							@media screen and (max-width: 767px) {
								width: 100%;
							}
							
							.shipment-method {
							    max-width: 700px;
							    
							    @media screen and (max-width: 767px) {
									max-width: 100%;
									margin-left: 0;
								}
								
								.value {
									padding-bottom: 12px;
								}
							}
						}
					}
					
					@media screen and (min-width: 768px) and (max-width: 1023px) {
						.line-items {
							.line-item {
								.col-1 {
									.item-details {
										.line-item-quantity {
											display: none;
											
											&.gift-card {
												display: block;
											}
										}
									}
								}
							}
						}
					}
				}
				
				&.gift-card {
					.order-shipment-details {
						.method {
						    width: 20%;
							float: left;
							max-width: 160px;
							
							@media screen and (max-width: 767px) {
								width: 100%;
								max-width: 100%;
							}
						}
						
						.order-shipment-section {
						    width: 80%;
							float: left;
							
							@media screen and (max-width: 767px) {
								width: 100%;
							}
						}
					}
					
					@media screen and (min-width: 768px) and (max-width: 1023px) {
						.line-items {
							.line-item {
								.col-1 {
									.item-details {
										.line-item-quantity {
											display: none;
											
											&.gift-card {
												display: block;
											}
										}
									}
								}
							}
						}
					}
				}
			}	
    		
    		.shipmentnumber {
				padding: 0 0 15px 0;
				border-bottom: 1px solid $alto;
				
				h2 {
					padding: 0;
					border: 0;
					width: auto;
					float: left;
				}
				
				.trackingnumber {
					font-size: $base-font;
					font-family: $HelveticaBold;
					text-transform: uppercase;
					line-height: 30px;
					letter-spacing: 1px;
					
					.value {
						font-family: $HelveticaMedium;
						color: $grape;
					}
					
					.label {
						text-decoration: underline;
					}
				}
			}
    			
    		.order-shipment-section {
    			font-size: $base-font + 1px;
				text-transform: capitalize;
				font-family: $MrEavesReg;
    			
    			.method, .order-shipping-status {
    				width: 15%;
    				float: left;
    				
    				.shipment-method {
    					max-width: 100px;
    				}
    			}
    			
    			.order-shipping-status {
    				width: 14%;
    			}
    			
    			.order-shipment-address {
    				width: 86%;
    				float: left;
    				
    				.shipment-address {
    					max-width: 730px;
    				}
    			}
    		}
    		
    		.line-item {
    			width: 100%;
			    display: inline-block;
				border-top: 0px;
			    padding: 20px;
			    box-sizing: border-box;
			    -webkit-box-sizing: border-box;
 	 			-moz-box-sizing: border-box;
			    
    			.col-1 {
    				max-width: 500px;
    				width: 49%;
    				margin: 0;
    				float: left;
    				
    				.line-item-price {
    					display: none;
    				}
    				
    				.item-image {
					    width: 100px;
					    text-align: center;
					    
					    @media screen and (max-width: 767px) {
					    	width: 37%;
					    }
    				}
    				
    				.item-details {
    					float: left;
					    max-width: 66%;
					    padding-left: 35px;
					    box-sizing: border-box;
					    -webkit-box-sizing: border-box;
 	 					-moz-box-sizing: border-box;
					    font-size: $base-font - 1px;
					    text-transform: uppercase;
					    
					    @media screen and (max-width: 1024px) {
							width: 63%;
							max-width: 63%;
							
							.quick-order-badge-link::after{
								top: -25%;
							}
					    }
					    
    					.giftmessage {
    						word-break: break-word;
    					}
    				}
    				
    				.quick-order-badge {
    					display: none;
    					
    					@media screen and (max-width: 767px) {
    						display: block;
    						width: 100% !important;
							margin: 0;
							
							.heading {
								font-size: $base-font - 3px;
								line-height: 10px;
								text-decoration: underline;
								padding-left: 10px;
								float: right;
								margin: 0 5px 0 0;
							}
    					}
    				}
    			}
    			
    			.col-2 {
    				max-width: 170px;
    				width: 20%;
    				margin: 59px 0 0 0;
    				float: left;
    				
    				.line-item-quantity {
    					font-size: $base-font - 1px;
    					font-family: $FuturaMedium;
    					text-transform: uppercase;
    					
    					span {
    						width: auto;
    						display: inline-block;
    						
    						&.label {
	    						font-family: $FuturaBold;
	    					}
    					}
    				}
    			}
    			
    			.col-3 {
    				max-width: 180px;
    				width: 20%;
    				margin: 59px 0 0 0;
    				float: left;
    				
    				.label {
    					display: none;
    				}
    				
    				.line-item-price {
    					font-size: $base-font + 2px;
    					display: inline-block;
    					
    					&.discount-price {
    						color: $guardsman-Red;
    					}
    				}
    			}
    			
    			.col-4 {
				    text-align: right;
				    width: 30%;
				    font-size: $base-font - 1px;
    				text-transform: uppercase;
    				font-family: $FuturaBold;
    				letter-spacing: 1px;
    				float: left;
    				
    				&.quick-order-item {
    					margin: 0;
    					
    					.details-review {
    						margin-top: 15px;
    						display: inline-block;
    					}
    				}
    				
    				.quick-order-badge {
    					.quick-order-badge-link {
							margin-bottom: 5px;
							display:inline-block;	
							
							.heading {
								font-size: $base-font - 3px;
								line-height: 10px;
								text-decoration: underline;
								margin: 0 20px 0 0;
								float: right;
							}						
    					}
    				}
    			}
    		}
    	}
    	
    	.mini-address-name {
    		margin: 0 0 5px 0;
    	}
    	
    	.mini-address-location {
	    	address {
			    font-style: normal;
				line-height: 25px;
	    	}
    	}
	}
	
	//order History page
	.order-history {
		margin: 33px auto 20px;
		
		.top-content {
			h1 {
				margin: 0 0 10px;
				font-family: $MrEavesReg;
				font-size: $base-font + 16px;
			}
			.subheading {
				font-size: $base-font + 5px;
				font-family: $MrEavesReg;
			}
		}
		
		.order-history-table {
			.order-history-header {
				font-family: $FuturaBold;
				padding: 18px 20px 16px;
				.col{
					letter-spacing: .1em;
				}
			}
			.order-list-wrapper {
				.order-list {
					padding: 26px 20px 38px;
				}
				.value {
					font-family: $MrEavesReg;
					font-size: $base-font + 4px;
				}
				.button-text {
			    	font-family: $FuturaBold;
			    }
			}
		}

		.qa-section {
			.question-title {
				font-family: $MrEavesReg;
				font-size: $base-font + 16px;
				padding: 27px 41px;
			}

			.qa-content {
				padding: 25px 43px;
			}
		}		
	}
}

.confirmation-left-section:not(.ot) {
    max-width: 840px;
    float: left;
    margin-top: 0;
    width: 75%;
    
    h1 {
    	font-size: $base-font + 16px;
    	font-family: $MrEavesReg;
    	margin: 0 0 0 2px;
    	@media screen and (max-width: 1023px) {
    		font-size: 28px;
    	}
    }
    
    .specialmessaging {
		clear: both;
	}
}

.confirmation-message {
    border-bottom: 1px solid $alto;
    padding: 9px 0 13px 0;
}

.order-confirmation-details {
    .order-print {
    	width: 100%;
	    display: inline-block;
	    padding: 23px 0 12px 0;
	    
	    .order-number {
	    	float: left;
	    }
	    
	    .print-page {
	    	border: none;
		    float: right;
		    padding: 12px 33px 10px 36px;
		    font-size: $base-font - 2px;
		    font-family: $FuturaBold;
		    background-color: $white;
    		color: $black;
	    }
	}
	
	.line-item .col-3 {
		.quick-order-badge .quick-order-badge-link::after {
			top: -25%;
		}
	}
}

.orderdetails {
	.notshipped-message {
		font-family: $Helvetica;
		font-size: $base-font + 1px;
		color: $mangotango;
		border-top: 1px solid $alto;
		width: 100%;
		display: inline-block;
		padding: 20px 0 0 0;
		margin: 20px 0 0 0;
	}
	
	.login-create-account {
		h1 {
			margin: 0 0 12px 0;
		}
		
		.login-box-content {
			padding: 26px 27px 38px 32px;
		    box-sizing: border-box;
		    -webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;
		    background: $light-skygrey;
		    border-top: 1px solid $alto;
		}
		
		.login-box-left-content {
			width: 58.8%;
    		float: left;
    		
    		.inner-block {
    			max-width: 385px;
    			font-size: $base-font + 3px;
    			font-family: $MrEavesReg;
    			
    			h2 {
	    			text-transform: inherit;
				    margin: 0 0 8px 0;
				    font-family: $FuturaBold;
    				font-size: $base-font - 1px;
			    }
    		}
		}  
		
		.login-box-right-content {
			width: 40%;
		    float: left;
		    
		    .inner-block {
    			max-width: 290px;
    			
    			&.make-label-absolute {
	    			.form-row {
	    				position: relative;
	    			}
	    			
	    			label {
	    				font-weight: normal;
					    font-size: $base-font;
					    padding: 0;
					    position: absolute;
					    -webkit-transform: translate(5px, 20px);
					     -ms-transform: translate(5px, 20px);
					    transform: translate(5px, 20px);
					    left: 12px;
					    margin: 0;
					    top: -3px;
					    width: auto;
					    color: $dim-gray;
					    z-index: 1;
					    -webkit-transition: -webkit-transform .3s ease,font-size .3s,color .3s;
					    -ms-transition: -webkit-transform .3s ease,font-size .3s,color .3s;
					    transition: transform .3s ease,font-size .3s,color .3s;
					    
					    &.input-focus {
					    	-webkit-transform: translate(5px, 11px);
					    	-ms-transform: translate(5px, 11px);
    						transform: translate(5px, 11px);
					    }
					    
					    span {
					    	font-family: $MrEavesReg;
					    	font-size: $base-font + 1px;
						    text-transform: capitalize;
						    
					    	&.required-indicator {
					    		display: none;
					    	}
					    }
	    			}
	    			
	    			.field-wrapper {
	    				box-sizing: border-box;
	    				-webkit-box-sizing: border-box;
	 	 				-moz-box-sizing: border-box;
					    margin-bottom: 20px;
					    width: 100%;
					    
	    				input[type="text"], input[type="number"], input[type="password"], input[type="tel"], input[type="date"] textarea {
		    				padding: 20px 15px 14px 15px;
						    height: auto;
						    border-radius: 0;
					    }
					    
					    .pwd-show {
							font-family: $FuturaMedium;
					    }
	    			}
    			}
    		}
		}  
	}
}

.plcc-user {
	p {
		line-height: 27px;
    	margin: 0 0 11px 0;
    	font-size: $base-font + 4px;
	}
	
	ul {
		margin: 0;
		padding: 0 6px;
		@media screen and (max-width: 767px) {
			padding: 0 0 0 16px;
		}
		
		li {
			list-style: inherit;
			line-height: 25.1px;
			padding: 0;
		}
	}
}

.order-detail-section {
	width: 100%;
    display: inline-block;
    padding: 35px 45px;
    box-sizing: border-box;
    background: $white;
    border: 1px solid $mercuryy;

    margin: 40px 0;
    
    .section {
    	width: 25%;
    	float: left;
    	
    	&.order-payment-instruments {
    		width: 30%;
    		
    		@media screen and (max-width: 767px) {
    			width: 100%;
    		}
    	}
    	
    	&.discount-section {
    		width: 28.7%;
    		
    		@media screen and (max-width: 767px) {
    			width: 100%;
    		}
    	}
    	
    	&.order-billing {
    		width: 16.3%;
    		
    		@media screen and (max-width: 767px) {
    			width: 100%;
    		}
    	}
    	
    	.inner-block {
    		max-width: 144px;
    		font-size: $base-font + 3px;
    		font-family: $MrEavesReg;
    		word-wrap: break-word;
    		
    		.inner-block-section {
    			margin: 25px 0 0 0;
    		}
    	}
    }
}

.order-shipments {
	.order-shipment-details {
		h2 {
			font-size: $base-font + 16px;
			font-family: $MrEavesReg;
			margin: 0;
			text-transform: capitalize;
			padding: 0 0 15px 0;
    		border-bottom: 1px solid $alto;
    		font-weight: normal;
		}
		
		.heading {
			margin: 15px 0;
		}
		
		.address {
			font-size: $base-font + 4px;
			font-family: $MrEavesReg;
			width: 100%;
			padding-bottom: 15px;
			
			.default {
				border-right: 1px solid $black;
			}
			
			div {
				width: auto;
				display: inline-block;
				text-transform: capitalize;
    			padding-right: 5px;
			}
		}
	}
}

.order-payment-summary {
	padding: 40px 30px 30px 35px;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    background: $white;
    border: 1px solid $mercuryy;
    
    .order-detail-summary {
    	width: 50%;
	    float: right;
	    max-width: 290px;
	    
	    .order-detail {
		    width: 100%;
			display: inline-block;
			padding: 0 0 20px 0;
			
			.label {
				width: 70%;
				float: left;
				text-transform: capitalize;
				font-size: $base-font + 6px;
				font-family: $Futura;
			}
			
			.value {
				width: 30%;
				float: right;
				text-align: right;
				font-size: $base-font + 2px;
				font-family: $Futura;
			}
			
			&.order-total {
				border-top: 1px solid $alto;
				padding: 20px 0 10px 0;
				
				.label {
					font-size: $base-font + 12px;
					font-family: $MrEavesReg;
				}
				
				.value {
					font-size: $base-font + 6px;
					font-family: $MrEavesReg;
				}
			}
			
			&.discount {
				color: $guardsman-Red;
			}
		}
    }
    
    .actions {
    	width: 50%;
	    float: left;
	    padding: 0;
	    display: inline-block;
	    margin: 20px 0;
	    
	    a {
    	    margin: 0;
		    padding: 16px 40px;
		    border: 1px solid;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    font-size: $base-font + 3px;
		    font-family: $Futura;
		    border-top-left-radius: 15px;
    		border-bottom-right-radius: 15px;
	    }
    }
}

.order-shipment-table {
	.line-items {
		width: 100%;
	    display: inline-block;
    	
    	.line-item {
    		width: 100%;
	    	display: inline-block;
			border-top: 1px solid $alto;
	    	padding: 20px;
    		box-sizing: border-box;
    	}
    	
		.personalized-message {
			font-size: $base-font - 1px;
			font-family: $HelveticaBold;
			letter-spacing: 1px;
			text-transform: uppercase;
			margin: 0 0 15px 0;			
		}
	
		.giftcard {
			margin: 0 0 15px 0;
			
			.label {
				font-size: $base-font;
			    font-family: $HelveticaBold;
			    margin: 0 3px 0 0px;
			    letter-spacing: 1px;
			}
			
			.value {
				font-size: $base-font;
			    font-family: $Helvetica;
			    text-transform: capitalize;
			}
		}
		
		.giftmessage {
			font-size: $base-font;
			margin: 0 0 15px 0;
			word-break: break-word;
		}	
    	
    	.column {
			width: 33.33%;
			float: left;
			box-sizing: border-box;
			margin: 0 0;
			
			&.col-1 {
			    max-width: 555px;
    			width: 70%;
	   			
	   			.item-image {
					float: left;
					width: 100px;
					text-align: center;
				}
				
				.item-details {
					float: left;
					max-width: 66%;
				    padding-left: 35px;
					box-sizing: border-box;
					font-size:  $base-font - 1px;
					
					.name {
						font-family: $MrEavesReg;
						font-size:  $base-font + 12px;
						margin: 1px 0 9px;
					}
					
					.sku {
						font-family: $MrEavesReg;
						margin-bottom: 13px;
						font-size: $base-font + 1px;
					}
					
					.attribute {
						margin-bottom: 4px; 
						
						.label {
						    margin-right: 3px;
						    font-family: $FuturaBold;
						    font-size: $base-font - 1px;
						}
					}
					
					.price-promotion {
						.price-sales {
							color: $guardsman-Red;
						}
						
						.price-standard {
							margin: 0 5px 0 0;
							text-decoration: line-through;
						}
					}
					
					.product-option {
						margin-bottom: 9px;
					    letter-spacing: 1px;
					    text-transform: uppercase;
					    font-family: $HelveticaBold;
					}
					
					.optioninfo {
						font-family: $HelveticaBold;
						
						.hemmable {
							margin-top: 15px;
							margin-bottom:10px;				
						}
						
						span {
							letter-spacing: 1px;
							
		    				&.monogram, &.hemming {
		    					text-decoration: underline;
		    					margin: 10px 0;
		    					display: inline-block;
								cursor: pointer;
								text-transform: uppercase;
		    				}
						}
						
						.optionheading {
							text-transform: uppercase;
							font-family: $FuturaBold;
						}
						
						.optionvalue {
							font-family: $HelveticaMedium;
							line-height: 20px;
						}
					}
				}
				
				.line-item-price {
					display: none;
				}	
			}
			
			&.col-2 {
				max-width: 170px;
	    		width: 20%;
	    		margin: 59px 0 0 0;
			}
			
			&.col-3 {
				max-width: 200px;
	    		width: 10%;
				margin: 0;
				
				.quick-order-badge {
					width: 180px;
				
					.heading {
						display: block;
						font-size: 9px;
						width: auto;
					}
				}
	    	}
		}
	}
}

.pt_order-confirmation {
	.heading {
		font-family: $FuturaBold;
		width: 100%;
		display: inline-block;
		font-size: $base-font;
		text-transform: uppercase;
	}
	
	.receipt-message {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
		padding: 0 0 0 8px;
		vertical-align: top;
	}
	
	.line-item-price,.discount-price {
		font-size: $base-font + 2px;
		font-family: $Futura;
	}
	
	address {
		font-family: $MrEavesReg;
		font-size: $base-font + 3px;
	}
	
	.order-status {
		margin: 0 0 32px 0;
	}
	
	.profile-summary {
		.profile-name {
			margin: 0 0 11px 0;
		}
		
		.profile-label {
			font-size: $base-font - 1px;
			font-family: $FuturaBold;
			padding: 0 10px 0 0;
			text-transform: uppercase;
		}
		.profile-value {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
		}
		.edit-profile {
			color: $black;
			font-family: $FuturaBold;
			padding: 0 2px 0 0;
		}
		.profile-email {
			margin: 0 0 26px 0;
		}
	}
	
	.login-box-right-content {
		button {
			width: 100%;
			background-color: $mainly-blue;
			color: $white;
			border-color: $mainly-blue;
			letter-spacing: 2px;
		}
	}
	.order-shipments {
		.heading {
			font-size: $base-font;
		}
		
		.value {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
		}
		
		.line-items {
			.label {
				font-size: $base-font - 1px;
				font-family: $FuturaBold;
			}
			
			.qty-value {
				font-size: $base-font - 1px;
				font-family: $Futura;
			}	
			
			.mini-address-name {
				font-size: $base-font - 1px;
				font-family: $Futura;
			}
			
			.value {
				font-family: $MrEavesReg;
				font-size: $base-font + 1px;
				text-transform: uppercase;
			}
		}
	}
	.order-payment-summary {
		.order-detail-summary {
			.discount {
				.label {
					font-size: $base-font + 6px;
					font-family: $MrEavesReg;	
				}
			}
		}
	}
	.additional-offers {
		h2 {
			font-size: $base-font + 16px;
			font-family: $MrEavesReg;
			font-weight: normal;
		}
	}
	.option-info {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
	}
	.order-detail-section{
		margin-top: 31px;
		box-shadow: none;
    	border: 1px solid $alto;
    	padding: 35px 45px 35px 39px;
    	
    	.cc-number{
    		span{
    			display: none;
    		}
    	}
    	.payment-type{
    		display: none;
    	}
    	.login-box-content{
			margin-bottom: 9px;
		}
	}
	.mail-box {
		background-position: 1px 1px;
	}
	
	.orderdetails {
		.login-create-account {
			.login-box-right-content {
				.inner-block {
					&.make-label-absolute {
						.form-row {
							&.password {
								.field-wrapper {
									margin: 0 0 18px 0;
								}
							}
						}
					}
				}
			}
		}
	} 
}

.product-content-quickorder {
	.variation-add-to-cart-content {
		.product-add-to-cart {
			button {
				@media screen and (max-width: 768px) {
					width: 60%;
				}
				@media screen and (max-width: 480px) {
					width: 75.3%;
				}
			}
		}
	}
}

@media screen and (max-width: 1023px) {
	.pt_order {
		#secondary {
			display: block;
		}
		
		.order-details-section {
			margin: 0 20px;
		}
		
		.orderdetails {
			margin: 30px auto;
			
	    	.order-detail-section {
	    		padding: 35px 25px 40px 25px;
	    		
	    		&.order-detail-response {
	    			.order-status {
	    				width: 25%;
	    			}
	    		
	    			.order-payment-instruments {
	    				width: 30%;
	    			
	    				.inner-block {
	    					max-width: 190px;
	    				}
	    			}
	    		
	    			.order-billing {
	    				width: auto;
	    			}
	    			
	    			.order-coupon-reward {
	    				width: 23%;
	    			}

	    			span {
	    				&.img {
	    					margin: 4px 0 0 0;
	    				}
	    			}
	    		}
	    	}
	    	
	    	.order-shipments {
	    		.line-item {
	    			.col-1 {
	    				width: 60%;
	    				
	    				.callout-message {
	    					&.hide-tablet {
	    						display: none;
	    					}
	    				}
	    				
	    				.hide-tablet {
	    					.label {
    						    margin-right: 3px;
							    letter-spacing: 1px;
							    text-transform: uppercase;
							    font-family: $FuturaBold;
							    font-size: $base-font - 1px;
							    display: inline-block;
	    					}
	    					
	    					.value {
	    						font-family: $Futura;
							    font-size: $base-font - 1px;
	    					}
	    				}
	    			}
	    			
	    			.col-2 {
						display: none;
	    			}
	    			
	    			.col-3 {
	    				width: 20%;
	    			}
	    			
	    			.col-4 {
					    width: 20%;
	    			}
	    		}
	    	}
		}
	}
	
	.pt_order-confirmation{ 
		.confirmation-left-section{
			margin-top: 0;
			width: 100%;
		}
		
		.confirmation-message{
			padding-top: 5px;
		}
		
		.order-date{
			span{
				word-wrap: break-word;
			}
		}
		
		.order-detail-section{ 
			span{
				&.img{
					&.cc-number-symbol{
						display: block;
						margin-bottom: 15px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_order {
		.order-history {
			.order-history-table {
				.order-list-wrapper {
					.order-list {
						padding: 15px 20px 15px;

						.order-number {
		    				font-family: $FuturaMedium;
		    				
		    				.only-for-mobile {
		    					font-family: $FuturaMedium;
		    					font-size: $base-font + 1px;
		    				} 
						}
						
						.value {
							font-size: $base-font + 5px;
							line-height: 24px;
						}
						
						.only-for-mobile {
							font-family: $FuturaBold;
							font-size: $base-font + 1px;
						}
					}
				}
			}
		}
	}
	
	.pt_order-confirmation {
		.order-status {
			margin: 0 0 10px 0;
		}
		
		.profile-summary {
			display: inline-block;
    		width: 100%;
			.edit-profile {
				margin: 5px 0 0 0;
				float: right;
			}
		}

		.order-confirmation-details {
			.order-print {
				.print-page {
					padding: 7px 0;
					float: none;
				}
			}
		}
		
		.confirmation-left-section {
			padding: 0 10px;
		}
		
		.orderdetails {
			.login-create-account {
				.login-box-content {
					padding: 18px 10px 30px 10px;	
				}
				.login-box-right-content {
					.inner-block {
						max-width: 100%;	
					}
				}
			}
		}
		
		.order-detail-section{ 
			span{
				&.img{
					display: inline-block;
					
					&.cc-number-symbol{
						display: none;
						margin-bottom: 0;
					}
				}
			}
		}
		
		.confirmation-left-section{
			h1{
				font-size: 20px;
				width: 100%;
			}
		}
		
		.confirmation-left-section {
			.create-heading {
				font-size: $base-font + 14px;
				font-family: $MrEavesReg;
				padding: 0 0 8px 0;
			}
		}
		
		.order-detail-section {
			.view-more-section {
				.view-more-link {
					font-size: $base-font + 3px;
					font-family: $FuturaBold;
				}
			}
		}
		
		.order-shipment-table {
			.line-item-price {
				font-size: $base-font - 1px;
				font-family: $Futura;
		
				.subtotal-label {
					font-family:  $FuturaBold;
					display: inline-block;
				}		
			}
		}
		
		.login-box-right-content {
		 	button {
		 		padding: 12.5px 48px;	
		 	}
		 }
		
		 .order-shipment-table {
		 	.line-items {
		 		.column {
		 			&.col-1 {
		 				.line-item-price {
		 					display: block;				
		 				}
		 			}
		 		}
		 	}
		 }
	}
	
	.pt_order {
		.order-details-section {
			margin: 0;
			
			.qa-section {
	    		width: 100%;
    			display: inline-block;
	    		
	    		.question-title {
	    			padding: 25px 20px;
	    		}
	    		
	    		.qa-content {
	    			padding: 20px;
	 	 			
	 	 			.question {
	 	 				font-size: $base-font - 1px;
	 	 			}
	 	 			
	 	 			.answer {
	 	 				font-size: $base-font + 1px;
	 	 			}
	    		}
	    	}
		}
		
		.orderdetails {
	    	margin: 15px auto 30px;
	    	
	    	.actions {
			    width: 100%;
			    
			    a {
	    	        width: 100%;
				    display: inline-block;
				    box-sizing: border-box;
				    -webkit-box-sizing: border-box;
 	 				-moz-box-sizing: border-box;
				    text-align: center;
				    color: $white;
				    border: 1px solid $mainly-blue;
				    padding: 18px 30px;
				    background: $mainly-blue;
			    }
	    	}
	    	
	    	.optioninfo {
	    		.option-info {
	    			font-family: $Helvetica;
	    			font-size: $base-font;
	    			color: $mangotango;
	    			
	    			span {
	    				text-transform: capitalize;
	    				letter-spacing: 0;
	    			}
	    		}
	    	}
	    	
	    	.order-information {
	    		.order-status {
		    		.mail-box {
						width: 15px;
					}
					
					.receipt-message {
						font-size: $base-font + 2px;
						margin: 10px 0 0 0;
					}
				}
	    	}
	    	
	    	.order-payment-summary {
	    		padding: 25px 20px;
	    		
	    		.order-detail-summary {
	    			width: 100%;
	    			float: left;
	    			max-width: 100%;
	    		}
	    		
	    		.actions {
	    			display: none;
	    		}
	    	}
	    	
	    	.order-detail-section {
	    		padding: 25px 20px;
    			margin: 20px 0;
    			
    			&.order-detail-response {
			    	.section {
			    		width: 100%;	
			    	}
			    }
	    		
	    		.expanded-section {
	    			display: none;
	    			
	    			.method {
	    				width: 100%;
    					float: left;
    					
    					.shipment-method {
    						max-width: 100%;
    						margin-top: 30px;
    						
    						.heading {
							    margin: 0 0 10px 0;
							    font-size: $base-font;
							    font-family: $FuturaBold;
							    letter-spacing: 1px;
    						}
    					}
	    			}
	    		}
	    		
	    		.view-more-section {
    			    width: 100%;
				    float: left;
				    text-align: center;
				    font-size: $base-font;
				    text-transform: uppercase;
				    letter-spacing: 1px;
				    font-family: $FuturaBold;
				    margin: 20px 0 0 0;
	    		}
	    		
	    		.order-status {
	    			&.section {
		    			.inner-block {
		    				margin-top: 0;
		    			}
	    			}
	    			
	    			.order-date {
	    				width: 50%;
    					float: left;
	    			}
	    			
	    			.total {
	    				width: 50%;
					    float: right;
					    margin: 0;
					    padding-left: 15px;
					    box-sizing: border-box;
					    -webkit-box-sizing: border-box;
 	 					-moz-box-sizing: border-box;
	    			}
	    		}
	    		
	    		.section {
	    			width: 100%;
	    			
	    			.inner-block {
	    				max-width: 100%;
	    				margin-top: 30px;
	    			}
	    			
	    			.heading {
	    				margin: 0 0 10px 0;
	    				font-size: $base-font;
	    				font-family: $FuturaBold;
	    				letter-spacing: 1px;
	    			}
	    			
	    			span {
	    				margin: 0 0 7px 0;
	    				font-size: $base-font + 1px;
	    				font-family: $MrEavesReg	;
	    				letter-spacing: 1px;
	    			}
	    		}
	    	}
	    	
	    	.order-shipments {
	    		.shipmentnumber {
	    			border: 0;
	    			padding: 0;
	    			
					h2 {
						font-size: $base-font + 4px;;
					    letter-spacing: 1px;
					    width: 100%;
					    margin: 10px 0 0 0;
					}
					
					.trackingnumber {
						width: 100%;
						font-size: $base-font - 1px;
						line-height: normal;
						letter-spacing: 1px;
						
						.label, .value {
							width: auto;
							display: inline-block;
						}
						
						.value {
							font-family: $HelveticaMedium;
							color: $grape;
						}
					}
				}
	    		
	    		.order-shipment-details {
	    			padding-bottom: 20px;
	    			border-bottom: 1px solid $alto;
	    			margin-bottom: 10px;
	    			width: 100%;
    				float: left;
	    		}
	    			
	    		.order-shipment-section {
	    			font-size: $base-font - 1px;
	    			letter-spacing: 1px;
					margin: 0;
					text-transform: capitalize;
	    			
	    			.heading {
	    				text-transform: uppercase;
	    				width: auto;
    					display: inline-block;
    					margin: 15px 0 0 0;
	    			}
	    			
	    			.value {
	    				font-family: $MrEavesReg;
	    				font-size: $base-font + 2px;
	    				width: auto;
    					display: inline-block;
	    			}
	    			
	    			.address {
	    				font-size: $base-font + 2px;
	    				font-family: $MrEavesReg;
	    				width: auto;
    					display: inline-block;
    					padding: 0;
    					line-height: 20px;
	    			}
	    			
	    			.method, .order-shipping-status {
	    				width: 100%;
	    				
	    				.shipment-method {
	    					max-width: 100%;
	    				}
	    				
	    				.heading {
	    					&:after {
	    						content: ':';
	    					}
    					}
	    			}
	    			
	    			.order-shipment-address {
	    				width: 100%;
	    				
	    				.shipment-address {
	    					max-width: 100%;
	    					margin: 0;
	    					
	    					.heading {
		    					&:after {
		    						content: ':';
		    					}
	    					}
	    				}
	    			}
	    		}
	    		
	    		.line-item {
	    			padding: 10px 0 30px;
	    			border-top: 0;
	    			border-bottom: 1px solid $alto;
	    			
	    			.callout-message {
	    				&.hide-mobile {
	    					display: block !important;
	    				}
	    			}
	    			
	    			&:last-child {
	    				border-top: 0;
	    			}
	    			
	    			.col-1 {
	    				max-width: 100%;
	    				width: 100%;
	    				
	    				.line-item-price {
	    					display: none;
	    				}
	    				
	    				.line-item-quantity {
	    					margin-bottom: 9px;
	    				}
	    			}
	    			
	    			.col-3 {
	    				max-width: 100%;
	    				width: 63%;
	    				float: right;
	    				margin: 65px 0 0 0;
	    				padding-left: 35px;
	    				@include prefix(box-sizing, border-box, $prefixArray);
	    				
	    				.heading {
	    					width: auto;
						    display: inline-block;
						    text-transform: uppercase;
						    letter-spacing: 1px;
						    margin: 0;
	    				}
	    				
	    				.line-item-price {
	    					font-size: $base-font - 1px;
	    					width: auto;
    						display: inline-block;
	    					
	    					.product-option-price {
	    						display: inline-block;
	    					}
	    					
	    					&.discount-price {
	    						color: $guardsman-Red;
	    					}
	    				}
	    			}
	    			
	    			.col-4 {
					    max-width: 100%;
					    text-align: left;
					    width: 37%;
					    display: inline-block;
					    margin: 25px 0 0 0;
					    font-size: $base-font - 1px;
	    				letter-spacing: 1px;
	    				
	    				.buy-again {
	    					margin-top: 24px;
	    				}
	    			}
	    		}
	    	}
	    	
	    	.order-payment-summary {
	    		.actions {
	    			float: left;
	    		}
	    	}
	    	
	    	.mini-address-name {
	    		margin: 0 0 5px 0;
				display: inline-block;
				width: 100%;
	    	}
	    	
	    	.mini-address-location {
		    	address {
				    font-style: normal;
					line-height: 25px;
		    	}
	    	}
		}
	}
	.orderdetails {
		.login-create-account {
			.login-box-left-content {
				.inner-block {
					h2 {
						margin: 0 0 13px 0;				
					}
				}
			}
		}
	}
	
	.plcc-user {
		p {
			margin: 0 0 5px 0;
			line-height: normal;	
		}
	}
	
	.login-box-content {
		.login-box-right-content {
			.profile-summary {
				.profile-name {
					margin: 15px 0 0 0;		
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.login-box-content {
		.login-box-right-content {
			.profile-summary {
				.profile-name {
					margin: 15px 0 0 0;		
				}
			}
		}
	}
}

//Order Confirmation Recommendation
.pt_order-confirmation {
	.confirmation-right-content {
	    max-width: 280px;
	    float: right;
	    margin-top: 20px;
	    width: 23.1%;
	    
	    @media screen and (max-width: 1024px) {
	    	float: left;
	    }
	    
	    @media screen and (max-width: 1023px) {
	    	//display: none;
	    }
	}
    .confirmation-recommendations {
    	margin-top: 1rem;
    	    
        .you-may-like {
            background: $wild-sand;
        }
        h2.trending {
            font-size: $base-font + 18px;
            font-family: $MrEavesReg;
            color: $black;
		    font-style: normal;
		    font-weight: normal;
		    text-align: center;
		    text-transform: none;
		    padding: 20px 0 0 0;
		    margin: 0 0 20px 0;
            @media screen and (max-width: 1023px) {
                font-size: $base-font + 24px;
                border-bottom: none;
                margin:0 auto;
                text-transform: capitalize;
                text-align: center;
            }
            @media screen and (max-width: 767px) {
				font-size: $base-font + 33px;
				margin: 0 auto;
				text-align: center;
				text-transform: none;
            }
        }
        .product-tile {
            color: $black;
			border: 0;
			margin: 0 auto;
			padding: 0 0 10px 0;
            .product-name {
                font-size: $base-font + 6px;
                font-family: $MrEavesReg;
            }
            .product-price {
                font-size: $base-font;
                font-family: $Futura;
            }
            img {
            	width: auto;
            	height: auto;
            }
            .product-image {
                max-width: 100px;
    			margin: auto;
    		}
		}
		ul {
			&.slick-dots {
				list-style-type: none;
				position: relative;
				margin: 0 auto;
				width: 100px;
				@media screen and (max-width: 768px) {
					display: table;
					z-index: 3;
					padding: 10px 0 5px;
					li {
						display: table-cell;
						vertical-align: middle;
						margin: 0 auto;
						padding: 0;
						button ,button[type=button] {
							position: relative;
							border:none;
							border-radius:100%;
							width: 0;
							height: 0;
							padding: 2.5px;  
							background: $black;
							margin: 0 auto;
							color: transparent;
						}
						&.slick-active button{
							background: $light-gray;
							color: transparent;
						}
					}
				}
			}
		}
        @media screen and (max-width: 1024px) {
            .recommendations-heading {
                font-size: $base-font + 18px;
            }
            .product-tile {
				min-height: 275px;
				max-width: 220px;
                .product-name {
                    font-size: $base-font + 6px;
                }
            }
        }
        @media screen and (max-width: 1023px) {
        	width: 100%;
        	max-width: 100%;
        }
        @media screen and (max-width: 767px) {
            .product-tile {
                .product-name {
					font-size: $base-font + 8px;
					margin: 0 auto;
				}
				.product-image {
					img{
						width:100%;
						margin: 0 auto;
					}
				}
            }
        }
    }
}