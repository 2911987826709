.cart-top-banner {
    h1 {
        font-family: $MrEavesReg;
        font-size: $base-font + 38px;
        color: $mainly-blue;
    }
    .pid {
        font-family: $MrEavesBold;
        font-size: $base-font + 4px;
        font-weight: normal;
		}
		.paypal-button{
			.or-link{
				font-family: $FuturaBold;
			}
		}
    button {
        padding: 14px 65px;
        color: $white;
        background: $mainly-blue;
        font-size: $base-font + 3px;
        letter-spacing: 1.6px;
        float: none;
        margin-top: 22px;
        border: 0;
    }
    button {
        &:hover {
            color: $white;
            background: $mainly-blue;
        }
    }
}

.last-visited-section {
    .last-visited-heading {
        @media screen and (max-width: 767px) {
            font-size: $base-font + 15px;
        }
    }
    .product-tile {
        .product-name {
            font-size: $base-font + 5px;
        }
    } // button {
    //     &.slick-arrow {
    //         &.slick-prev {
    //             background-position: 0 -2px;
    //             &.slick-disabled {
    //                 background-position: 0 -2px;
    //             }
    //         }
    //         &.slick-next {
    //             background-position: 0 3px;
    //             &.slick-disabled {
    //                 background-position: 0 3px;
    //             }
    //         }
    //     }
    // }
}
.international-cart-message {
    font-family: $MrEavesReg;
    font-size: $base-font + 2px;
}

.pt_cart {
    //Q&A
    .qa-section {
        .question-title {
            padding: 19px 25px 22px 0;
        }
        .qa-desc {
            padding-bottom: 37px;
        }
        .qa-content {
            padding: 20px 17px 20px 43px;
            letter-spacing: 1px;
            &:nth-child(2n),
            &:nth-child(3n),
            &:nth-child(4n),
            &:nth-child(n) {
                padding: 20px 17px 20px 43px;
                letter-spacing: 1px;
            }
            .question {
                &::after {
                    background: url(../../../images/arrow-down.png) no-repeat;
                    background-position: 99.6% 5px;
                }
                &.active {
                    &::after {
                        background: url(../../../images/arrow-up.png) no-repeat;
                        background-position: 99.6% 5px;
                    }
                }
            }
            .answer {
                padding: 9px 0 3px 0;
            }
        }
    }
    .cart-right-content {
        //cart Recommendations
        .cart-recommendations {
            .you-may-like {
                background: $wild-sand;
                padding: 10px 0;
                &.loaded {
                    transition: 0.5s ease;
                    opacity: 1;
                }
                @media screen and (max-width: 1023px) {}
            }
            h2.trending {
                font-size: $base-font + 18px;
                font-family: $MrEavesReg;
                @media screen and (max-width: 1023px) {
                    font-size: $base-font + 24px;
                    border-bottom: none;
                    margin: 0 auto;
                    text-transform: capitalize;
                    text-align: left;
                    padding-left: 10px;
                }
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 15px;
                    margin: 0 auto;
                    text-transform: none;
                }
            }
            .product-tile {
                color: $black; // border: 0;
                // margin: 0 auto;
                // padding: 0 32px 28px;
                .product-name {
                    font-size: $base-font + 6px;
                    font-family: $MrEavesReg;
                }
                .product-price {
                    font-size: $base-font;
                    font-family: $Futura;
                }
                .b_product_badge img{
                    display: none;
                }
            }
            ul {
                &.slick-dots {
                    list-style-type: none;
                    position: relative;
                    margin: 0 auto;
                    width: 100px;
                    @media screen and (max-width: 768px) {
                        display: table;
                        z-index: 3;
                        padding: 10px 0 5px;
                        li {
                            display: table-cell;
                            vertical-align: middle;
                            margin: 0 auto;
                            padding: 0;
                            button,
                            button[type=button] {
                                position: relative;
                                border: none;
                                border-radius: 100%;
                                width: 0;
                                height: 0;
                                padding: 2.5px;
                                background: $black;
                                margin: 0 auto;
                                color: transparent;
                            }
                            &.slick-active button {
                                background: $light-gray;
                                color: transparent;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1023px) {
                .recommendations-heading {
                    font-size: $base-font + 18px;
                }
                .product-tile {
                    min-height: 275px;
                    max-width: inherit;
                    padding: 0 25px 10px;
                    .product-name-image-container {
                        max-width: inherit;
                        margin: 0 auto;
                        .product-image {
                            max-width: 220px;
                            margin: 0 auto;
                        }
                    }
                    .product-name {
                        font-size: $base-font + 6px;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .product-tile {
                    max-width: inherit;
                    .product-name-image-container {
                        max-width: inherit;
                        margin: 0 auto;
                        .product-image {
                            max-width: 220px;
                            margin: 0 auto;
                        }
                    }
                    .product-name {
                        font-size: $base-font + 8px;
                        margin: 0 auto;
                    }
                    .product-image {
                        img {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        } //ends recommendation
    }
}

.section-header {
    font-family: $MrEavesReg;
    font-size: $base-font + 15px;
}

.cart-row {
    .col-1 {
        .item-details {
            font-size: $base-font - 1px;
            .name {
                font-family: $MrEavesReg;
                font-size: $base-font + 12px;
                margin: 1px 0 5px;
            }
            .sku {
                font-family: $MrEavesReg;
                margin-bottom: 11px;
                font-size: $base-font + 3px;
            }
            .attribute {
                margin-bottom: 4px;
                .label {
                    margin-right: 3px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    font-family: $FuturaBold;
                }
                .value {
                    text-transform: uppercase;
                }
            }
            .price-promotion {
                .price-sales {
                    color: $guardsman-Red;
                }
                .price-standard {
                    margin: 0 5px 0 0;
                    text-decoration: line-through;
                }
            }
            .product-option {
                margin-bottom: 9px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: $Futura;
            }
            .optioninfo {
                font-family: $FuturaBold;
                .hemmable {
                    margin-top: 15px;
                }
                span {
                    letter-spacing: 1px;
                    &.monogram,
                    &.hemming {
                        text-decoration: underline;
                        margin: 10px 0;
                        display: inline-block;
                        cursor: pointer;
                        text-transform: uppercase;
                        @media screen and (max-width: 767px){
    						margin: 10px 0 0;
    					}
                    }
                }
                .optionheading {
                    text-transform: uppercase;
                }
                .optionvalue {
                    font-family: $Futura;
                    line-height: 20px;
                }
            }
        }
    }
    .col-2 {
        .custom-cart-update {
            margin: 44px 0 0 10px;
            @media screen and (max-width: 1023px) {
                margin: 44px auto auto;
            }
        }
        .not-available,
        .availability-msg {
            font-family: $MrEavesReg;
            font-size: $base-font + 2px;
        }
        .over-threshold {
            color: $guardsman-Red;
        }
    }
    .col-3 {
        .item-total {
            font-family: $Futura;
            font-size: $base-font + 2px;
            margin-top: 52px;
            text-transform: upperase;
            .price-total-strikeoff {
                color: $guardsman-Red;
            }
            .sub-total {
                font-family: $FuturaBold;
            }
        }
        .price-unadjusted {
            text-decoration: line-through;
            color: $guardsman-Red;
        }
        .price-adjusted-total {
            color: $guardsman-Red;
        }
    }
    .col-4 {
        .item-quantity-details {
            text-transform: uppercase;
            margin-top: 35px;
            font-size: $base-font - 2px;
            font-family: $FuturaBold;
            letter-spacing: 1px;
            .btm-space {
                margin-bottom: 7px;

                .in-wishlist {
                	letter-spacing: 0.3px;
                }
            }
            .button-text {
                width: 100%;
                text-align: right;
                font-size: $base-font - 2px;
                padding: 0 0;
                color: $black;
                text-transform: uppercase;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
            }
        }
    }
    &.bonus-product {
        .col-4 {
            .item-quantity-details {
                margin-top: 68px;
            }
        }
    }
    &.gift-Card {
        .col-1 {
            .item-details {
                width: 69%;
                max-width: 100%;
                .personalized-message {
                    font-size: $base-font - 1px;
                    font-family: $FuturaBold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin: 0 0 15px 0;
                }
                .attribute {
                    .value {
                        text-transform: capitalize;
                    }
                }
                .giftcard {
                    margin: 0 0 15px 0;
                    text-transform: uppercase;
                    .label {
                        font-size: $base-font - 1;
                        font-family: $FuturaBold;
                        margin: 0 3px 0 0px;
                        letter-spacing: 1px;
                    }
                    .value {
                        font-size: $base-font + 3;
                        font-family: $MrEavesReg;
                        text-transform: initial;
                    }
                }
            }
            .giftmessage {
                font-size: $base-font + 3;
                font-family: $MrEavesReg;
                margin: 0 0 11px 0;
                width: 100%;
                display: inline-block;
                word-wrap: break-word;
                line-height: 15px;
            }
        }
    }
    &.specialproductsetchild {
        border: none;
        .col-1 {
            .item-image {
                width: 80px;
                padding: 0 0 0 20px;
            }
        }
        .promo-adjustment {
            width: 84%;
            display: inline-block;
            float: right;
            margin: 10px 0 0 0;
        }
    }
    .not-available {
        color: $guardsman-Red;
        text-transform: none;
    }
    &.bonus {
        .price-promotion {
            display: inline;
        }
    }
    /*
    .col-1 .item-details{
		font-size: $base-font;
	}
	.col-4 .item-quantity-details {
		.button-text{
			font-size: $base-font - 1;
		}
	}
	@media screen and (max-width: 767px) {
		.column.col-1{
			img.cart-brand-logo {
				max-width: 70%;
			}
			.item-details{
			font-size: $base-font + 2;
			.name{
				font-size:$base-font +10;
			}
		}
		}
		.col-4 .item-quantity-details {

			.button-text{
				padding:0 0 0 10px;
				font-size: $base-font + 2;
			}
		}
	}
	*/
    &.outofstock {
        background-color: $wild-sand;
        .col-1 .item-details {
            font-size: $base-font;
        }
        .col-4 .item-quantity-details {
            margin-top: 75px;
            .button-text {
                font-size: $base-font - 1;
            }
        }
        @media screen and (max-width: 767px) {
            .item-quantity:before {
                max-width: inherit;
                width: auto;
                margin: 0;
                font-size: $base-font;
                letter-spacing: .15em;
            }
            .column.col-1 {
                img.cart-brand-logo {
                    max-width: 70%;
                }
                .item-details {
                    font-size: $base-font + 2;
                    .name {
                        font-size: $base-font +10;
                    }
                }
            }
            .col-4 .item-quantity-details {
                .button-text {
                    padding: 0 0 0 10px;
                    font-size: $base-font + 2;
                }
            }
        }
    }
}

.pt_checkout .cart-row.outofstock,
.pt_checkout .outofstock {
    .col-1 .item-details {
        font-size: $base-font - 1;
    }
    @media screen and (max-width: 767px) {
        .item-quantity:before {
            display: none;
        }
        .column.col-1 {
            .item-details {
                font-size: $base-font - 1;
            }
        }
    }
}

.cart-row-contents {
    .item-price {
        order: 5;
        text-align: center;
        flex-basis: 33.3333%;
        @media screen and (min-width: 768px) {
            text-align: left;
        }
    }
}

.bonus-product-calloutMsg {
    background: $grape;
    color: $white;
    font-size: $base-font + 3px;
    font-family: $MrEavesReg;
    &:before {
        border-color: transparent transparent transparent $grape;
    }
}

.qty-error-msg {
    font-size: $base-font - 1px;
    font-family: $Futura;
}

.cart-promo {
    margin: 11px 0 20px;
    padding: 15px 0;
    font-size: $base-font - 1px;
    color: $guardsman-Red;
    font-family: $Futura;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid $sliver-light;
    width: 100%;
    display: inline-block;
    @media screen and (max-width: 1023px) {
        border-right: 0;
        border-left: 0;
    }
    &.cart-promo-bonus {
        border: none;
        background: $wild-sand-dark;
        padding: 15px 0 15px 37px;
        text-align: left;
        margin: 10px 0;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }
    .bonus-item-actions {
        font-size: 1em !important;
        text-align: right;
        vertical-align: middle;
    }
}

.cart-footer {
    padding: 40px 50px 40px 45px;
    border: 1px solid $alto;
    border-top: none;
    @media screen and (max-width: 1023px) {
        padding: 43px 20px 40px 43px;
    }
    .payment-description {
        font-size: $base-font + 11px;
        font-family: $MrEavesReg;
        .heading {
            font-size: $base-font + 6px;
            font-family: $MrEavesReg;
            text-transform: none;
        }

        .brand-cards {
            font-family: $MrEavesReg;
            font-size: $base-font + 5px;
            line-height: 25px;
        }
        .see-details {
            font-size: $base-font - 2px;
            font-family: $FuturaBold;
        }
    }
    .cart-order-totals {
        .order-detail {
            padding: 0 0 20px 0;
            &.discount {
                color: $guardsman-Red;
            }
            .label {
                font-size: $base-font + 5px;
                font-family: $MrEavesReg;
            }
            .value {
                font-size: $base-font + 2px;
                font-family: $Futura;
            }
            &.order-total {
                padding: 20px 0 10px 0;
                .label {
                    font-size: $base-font + 13px;
                }
                .value {
                    font-size: $base-font + 6px;
                    font-family: $FuturaBold;
                }
                &.order-total-remaining {
                    padding: 0 0 20px 0;
                    .label {
                        font-size: $base-font + 5px;
                    }
                    .value {
                        font-size: $base-font + 2px;
                        font-family: $Futura;
                    }
                }
            }
            &.remaining-total {
                padding: 20px 0 10px 0;
                .label {
                    font-size: $base-font + 13px;
                }
                .value {
                    font-size: $base-font + 6px;
                    font-family: $FuturaBold;
                }
            }
            &.order-saving {
                .label {
                    //font-size: $base-font;
                    font-family: $MrEavesReg;
                    ;
                }
                .value {
                    font-size: $base-font;
                    font-family: $Futura;
                }
            }
            &.order-shipping {
                .label {
                    .shippingoverlay-link {
                        background: url(../../../images/tips.svg) no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        vertical-align: bottom;
                    }
                }
            }
        }
    }
    .cart-actions {
        .cart-action-checkout {
            margin: 30px 0 0 0;
            .button-fancy-large {
                font-size: $base-font + 3px;
                font-family: $Futura; //letter-spacing: 1px;
                letter-spacing: .1em;
                padding: 14.5px 50px;
            }
            .paypal-button {
                margin: 30px 0 0 0;
                .or-link {
                    font-size: $base-font + 2px;
                    font-family: $FuturaBold;
                    line-height: 52px;
                }
                .paypal-image {
                    padding: 0;
                    border: none;
                    float: right;
                    background: transparent;
                }
            }
        }
    }
}

.cart-coupon-code {
    float: left;
    width: 100%;
    padding: 25px 49px 25px 37px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    background: $wild-sand-dark;
    @media screen and (max-width: 1023px) {
        padding: 25px 20px 25px 40px;
    }
    @media screen and (max-width: 767px) {
        padding: 15px;
    }
    .cart-message {
        width: 65%;
        float: left;
        font-size: $base-font + 16px;
        font-family: $MrEavesReg;
        max-width: 465px;
        @media screen and (max-width: 767px) {
            width: 100%;
            font-size: $base-font + 13px;
        }
    }
    span {
        .coupon-tooltip,
        .coupon-tooltip-link {
            background: url(../../../images/tips.svg) no-repeat;
            width: 16px;
            height: 16px;
            background-size: cover;
            padding: 0;
            margin: 0 0 0 10px;
            display: inline-block;
        }
    }
    .error-messages,
    .input-fields {
        width: 35%;
        float: right;
        max-width: 290px;
        &.error-messages {
            margin: 0;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            margin-top: 20px;
            max-width: 100%;
            line-height: normal;
        }
    }
    .rowcoupons {
        font-size: 16px;
        .cartcoupon {
            span {
                font-family: $MrEavesBold;
                font-weight: 700;
            }
            button {
                font-family: $Futura;
                font-size: 16px;
                background: transparent;
                padding: 0 0 0 6px;
            }
        }
        .discount-item-details {
            font-family: $Futura;
            font-size: 12px;
            .discount {
                .see-details {
                    font-size: 11px;
                }
            }
        }
    }
    .cart-left-section {
        width: 100%;
        float: left;
        line-height: 40px;
    }

    input {
        width: 60%;
        height: 50px; //height: 50px;
        float: left;
        border-top-left-radius: 15px;
        font-family: $MrEavesReg;
        font-size: $base-font + 6px;
    }
    .error {
        padding-top: 0.4em;
        width: auto;
        font-family: $MrEavesReg;
		font-size: $base-font + 5px;
		padding-right: 0; 
        @media screen and (max-width: 767px) {
            float: left;
        }
        @media screen and (max-width: 1023px) {
            padding: 10px 0 0 0;
        }
    }
    .rw-error {
        padding: 10px 30px 0 0;
        @media screen and (max-width: 1023px) {
            padding: 10px 0 0 0;
        }
    }
    button {
        &#add-coupon {
            width: 40%;
            float: left; //padding: 9px 34px;
            padding: 0 34px;
            margin: 0;
            color: $white;
            background: $grape;
            border: none; //letter-spacing: 1px;
            letter-spacing: .1em;
            font-family: $Futura; //height: 40px;
            line-height: 50px;
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            @media screen and (max-width: 1023px) {
                padding: 11px 24px;
                padding: 0 0;
            }
            @media screen and (max-width: 767px) {
                padding: 0 24px;
            }
        }
    }
}

.order-value {
    white-space: nowrap;
}

.cart-actions {
    @extend %clearfix;
    .dw-apple-pay-button {
        font-size: 1.35em;
        padding: .5em 2em;
    }
}

.cart-empty {
    @extend %clearfix;
    .cat-banner {
        padding: 45px 0 58px;
        background: $wild-sand;
        h1 {
            font-family: $MrEavesReg;
            font-size: $base-font + 48px;
            letter-spacing: 3px;
            margin: 0 0 10px 0;
            color: $mainly-blue;
        }
        .cartempty-message {
            font-size: $base-font + 12px;
            letter-spacing: 1px;
            margin: 10px 0;
            font-family: $MrEavesReg;
            font-weight: normal;
        }
    }
    .cart-action-continue-shopping {
        margin-top: 40px;
        button {
            padding: 14px 66px;
            background: $mainly-blue;
            font-family: $Futura;
            font-size: $base-font + 3px;
            letter-spacing: 1.5px;
            a {
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .product-listing {
        @extend %clearfix;
        h2 {
            margin: 0 0 40px 0;
            font-family: $MrEavesReg;
            font-size: $base-font + 28px;
        }
        .carousel-recommendations {
            width: 100%;
            display: inline-block;
            text-align: center;
            .product-tile {
                width: calc(20% - 4px);
                width: -moz-calc(20% - 4px);
                width: -webkit-calc(20% - 4px);
                width: -o-calc(20% - 4px);
                float: none;
                display: inline-block;
                vertical-align: top;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                .product-swatches {
                    display: block;
                }
                .p-image-rollover {
                    display: none;
                }
            }
            button {
                &.slick-arrow {
                    width: 17px;
                    height: 23px;
                    border: none;
                    text-indent: -9999px;
                    position: absolute;
                    bottom: 0;
                    margin: 0 auto;
                    z-index: 9;
                    -webkit-transition: initial;
                    transition: initial;
                    -ms-transition: initial;
                    padding: 0;
                    top: 50%;
                }
                &.slick-prev {
                    left: -20px;
                    background: url(../../../images/trending-arrows-left.svg) no-repeat;
                    &.slick-disabled {
                        background: url(../../../images/recently-viewed-arrows-left-unactivated.svg) no-repeat;
                        cursor: not-allowed;
                    }
                }
                &.slick-next {
                    right: -20px;
                    background: url(../../../images/trending-arrows-right.svg) no-repeat;
                    &.slick-disabled {
                        background: url(../../../images/recently-viewed-arrows-right-unactivated.svg) no-repeat;
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

.promo-adjustment {
    font-size: $base-font + 3px;
    color: $guardsman-Red;
    font-family: $MrEavesReg;
}

.specialmessaging,
.specialmessaging p,
.specialmessag p.expeditedship{
    font-size: $base-font + 5px;
    font-family: $MrEavesReg;
    color:$mainly-blue;
}

.cart-row {
	.specialmessag {
		&.monogrammed {
			@media screen and (min-width: 768px) {
		    	.optioninfo {
		    		color: $black;

		    		font: {
		    			family: $FuturaBold;
		    			size: $base-font - 1px;
		    		}

	    			.optionheading {
	    				text-transform: uppercase;
	    			}

	    			.optionvalue {
	    				font-family: $Futura;
	    				line-height: 20px;
	    			}

		    		span {
	                    &.monogram,
	                    &.hemming {
	                        text-decoration: underline;
	                        text-transform: uppercase;
	                    }
	                }
		    	}
		    }
	    }
	}
}

.quick-order-badge {
    .quick-order-badge-link {
        font-family: $FuturaBold;
        color: $grape;
        line-height: 10px;
        text-decoration: underline;
        &::after {
            background: url('../../../images/rm/catalog-quick-order-icon-purple.svg') no-repeat;
        }
    }
}

.ui-dialog {
    &.shipping-overlay {
        .ui-widget-header {
            .ui-dialog-title {
                font-size: $base-font;
                font-family: $FuturaBold;
            }
        }
        #dialog-container {
            .order-detail {
                font-size: $base-font;
                font-family: $MrEavesReg;
                &.shippingtotal {
                    .value {
                        font-family: $FuturaBold;
                    }
                }
            }
        }
    }
}

.account-welcome {
    .welcome-message {
        font-size: 1.2em;
        font-style: italic;
    }
    .not-user {
        margin: .83em 0;
    }
}

#password-reset {
    white-space: nowrap;
}

.qa-section {
    .question-title {
        font-family: $MrEavesReg;
        font-size: $base-font + 24px;
    }
    .qa-content {
        .question {
            font-size: $base-font;
            font-family: $FuturaBold;
        }
        .answer {
            font-size: $base-font + 5px;
            font-family: $MrEavesReg;
        }
    }
}

//PLCC
.cart-plcc-section {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 35px;
    button {
        background: url(../../../images/arrow-down.png) no-repeat;
        background-position: 100% 12px;
        background-size: 11px;
        text-indent: -9999px;
        border: none;
        padding: 0;
        margin: 0;
        position: absolute;
        width: 11px;
        right: 12px;
        height: 20px;
        top: 9px;
        cursor: pointer;
        z-index: 1;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }
    b {
        font-family: 'mreavessanaltb';
    }
    .cart-combined {
        position: relative;
        width: 100%;
        padding: 10px 15px 8px 37px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background: #F5F5F5;
    }
    .cart-plcc-left {
        width: 49px;
        height: 30px;
        float: left;
    }
    .cart-plcc-right {
        display: inline-block;
        width: 91.5%;
        margin-left: 15px;
        font-size: 17px;
        font-family: 'mreavessanaltr';
        line-height: 29px;
        h1 {
            width: auto;
            display: inline-block;
            margin: 0;
            font-size: 20px;
            font-family: 'mreavessanaltb';
        }
    }
    .learn-more {
        text-transform: uppercase;
        font-size: 11px;
        font-family: 'Futura LT W01 Bold';
        letter-spacing: 1px;
        color: #331E53;
        margin-left: 42px;
    }
    .pre-screen {
        width: 100%;
        margin-top: 4px;
        font-size: 17px;
        font-family: 'mreavessanaltr';
        padding: 10px 40px 11px 36px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background: #F5F5F5;
        line-height: 20px;
    }
    &.active {
        button {
            background: url(../../../images/x-icon.svg) no-repeat;
            right: 13px;
            top: 19px;
        }
        .cart-combined {
            padding: 29px 15px 28px 33px;
        }
        .cart-plcc-left {
            width: auto;
            height: auto;
            margin-top: 6px;
        }
        .cart-plcc-right {
            width: 75%;
            margin-left: 27px;
            h1 {
                width: 100%;
                font-size: 28px;
                font-family: 'mreavessanaltr';
            }
        }
        .learn-more {
            margin: 0;
        }
        .hide {
            display: inline-block;
        }
        .text {
            display: none;
        }
    }
}

.ui-dialog {
    &.coupon-tooltip {
        .dialog-content {
            &.ui-dialog-content {
                padding: 0 14px 0 25px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    //PLCC
    .cart-plcc-section {
        button {
            display: none;
        }
        .cart-plcc-left {
            width: 23%;
            height: auto;
            margin-top: 17px;
        }
        .cart-plcc-right {
            width: 72%;
            margin-left: 30px;
            margin-top: 13px;
            h1 {
                width: 100%;
            }
        }
        .learn-more {
            margin: 0;
        }
        .hide {
            display: inline-block;
        }
        .text {
            display: none;
        }
        .pre-screen {
            padding: 13px 7% 11px 20px;
            margin-top: 10px;
        }
        &.active {
            button {
                display: none;
            }
            .cart-plcc-left {
                width: 23%;
                height: auto;
                margin-top: 17px;
            }
            .cart-plcc-right {
                width: 72%;
                margin-left: 30px;
                margin-top: 13px;
                h1 {
                    width: 100%;
                }
            }
            .learn-more {
                margin: 0;
            }
            .hide {
                display: inline-block;
            }
            .text {
                display: none;
            }
            .pre-screen {
                padding: 13px 7% 11px 20px;
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .cart-empty {
        .cat-banner {
            padding: 45px 0;
            h1 {
                font-size: $base-font + 30px;
            }
            .cartempty-message {
                font-size: $base-font + 2px;
            }
        }
        .cart-action-continue-shopping {
            margin-top: 35px;
        }
    }
    .pt_cart {
        .qa-section {
            .qa-content {
                padding: 25px 17px 20px 30px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 25px 17px 20px 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .cart-empty {
        .cat-banner {
            h1 {
                font-size: $base-font + 18px;
            }
        }
        .product-listing {
            h2 {
                // font-size: $base-font + 12px;
            }
        }
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .cart-coupon-code {
        input {
            width: 192px;
        }
        button {
            &#add-coupon {
                width: 120px;
                padding: 0 24px;
            }
        }
        .cart-message {
            width: auto;
        }
        .input-fields {
            width: auto;
            max-width: inherit;
        }
        .cart-right-section {
	        .error-messages {
	       		max-width: 310px;
	       		width: 46%;
	        }
	    }
    }
    .cart-footer {
        .cart-actions {
            .cart-action-checkout {
                .paypal-button {
                    margin: 35px 0 0;
                    float: left;
                    width: 100%;
                    +a {
                        margin: 45px 0 0 20px;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .cart-footer {
        padding: 0;
        border: none;
        .payment-description {
            margin-top: 20px;
            .heading {
                font-size: $base-font + 5px;
            }
            .brand-cards {
                max-width: 300px;
            }
            .see-details {
                font-family: $MrEavesReg;
                font-size: $base-font + 5px;
                font-weight: bold;
                text-transform: none;
                width: auto;
                display: initial;
            }
        }
        .cart-order-totals {
            margin-top: 30px;
            .order-detail {
                padding: 0 0 15px 0;
                .label {
                    font-size: $base-font + 6;
                }
                &.order-total {
                    .label {
                        font-size: $base-font + 15;
                    }
                    &.order-total-remaining {
                        .label {
                            font-size: $base-font + 6;
                        }
                    }
                }
                &.order-saving {
                    .label {
                        font-family: $ImperialRegular;
                        font-size: $base-font + 4;
                    }
                }
                &.remaining-total {
                    .label {
                        font-size: $base-font + 15;
                    }
                }
            }
        }
        .cart-actions {
            .cart-action-checkout {
                margin: 20px 0 0 0;
                .paypal-button {
                    .or-link {
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    .cart-row,
    .cart-row.gift-Card {
        .column {
            &.col-1 {
                .item-details {
                    font-size: $base-font + 2px;
                    letter-spacing: .05em;
                    .name {
                        font-size: $base-font + 10px;
                        margin: 0 0 10px;
                        line-height: 19px;
                    }

                    .sku {
                        font-size: $base-font + 1px;
                    }

                    .attribute {
                        margin-bottom: 10px;
                    }

                    .personalized-message {
                        font-size: $base-font - 2px;
                    }

                    .giftcard {
                        .label {
                            font-size: $base-font - 2px;
                        }

                        .value {
                            font-size: $base-font + 2px;
                        }

                        .giftmessage {
                            font-size: $base-font + 2px;
                        }
                    }
                }
            }

            &.col-2 {
                .custom-cart-update {
                    margin: 0 0;

                    .qty-arrows {
                        top: 40%;

                        &.qty-increase {
                            right: 10px;
                        }

                        &.qty-decrease {
                            left: 10px;
                        }
                    }
                }
            }

            &.col-3 {
                .item-total {
                    margin-top: 24px;
                    text-align: left;
                    .sub-total {
                        font-size: $base-font + 2;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        width: auto;
                        display: inline-block;
                    }
                    .price-unadjusted,
                    .price-adjusted-total {
                        width: auto;
                        display: inline-block;
                    }
                }
            }
            &.col-4 {
                .item-quantity-details {
                    margin-top: 10px;
                    text-align: left;
                    font-size: $base-font;
                    .btm-space {
                        margin-bottom: 20px;
                    }
                    .button-text {
                        text-align: left;
                        font-size: $base-font;
                    }
                }
            }
        }
        &.specialproductsetchild {
            .promo-adjustment {
                width: 100%;
                display: inline-block;
                padding: 0 0 0 20px;
            }
        }
        .specialmessag {
            display: inline-block;
            &:nth-child(2n) {
                display: none;
            }
        }
    }
    .qa-section {
        .question-title {
            font-size: $base-font + 15px;
        }
    }
    .cart-top-banner {
        h1 {
            font-size: $base-font + 18px;
            letter-spacing: 2px;
        }
        .pid {
            font-size: $base-font + 3px;
            letter-spacing: 0;
        }
        button {
            margin-top: 17px;
        }
    }
    .specialmessaging,
    .specialmessaging p {
        font-size: $base-font + 3px;
    }
    .cart-empty {
        .cat-banner {
            h1 {
                font-size: $base-font + 18px;
                letter-spacing: 2px;
            }
        }
    } //PLCC
    .cart-plcc-section {
        margin-top: 10px;
        .cart-combined {
            padding: 0 10px;
        }
        .cart-plcc-left {
            width: 20%;
            height: auto;
            margin-top: 20px;
            float: left;
        }
        .cart-plcc-right {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
            font-size: 15px;
            h1 {
                width: auto;
                font-size: 20px;
                margin-top: 20px;
                margin-left: 15px;
            }
        }
        .pre-screen {
            padding: 13px 10px 11px 10px;
            font-size: 15px;
        }
        &.active {
            margin-top: 10px;
            .cart-combined {
                padding: 0 10px;
            }
            .cart-plcc-left {
                width: 20%;
                height: auto;
                margin-top: 20px;
                float: left;
            }
            .cart-plcc-right {
                width: 100%;
                margin-left: 0;
                margin-top: 20px;
                font-size: 15px;
                h1 {
                    width: auto;
                    font-size: 20px;
                    margin-top: 20px;
                    margin-left: 15px;
                }
            }
            .pre-screen {
                padding: 13px 10px 11px 10px;
                font-size: 15px;
            }
        }
    }
    .pt_cart {
        .qa-section {
            .question-title {
                padding: 25px 0;
            }
            .qa-content {
                padding: 21px;
                &:nth-child(2n),
                &:nth-child(3n),
                &:nth-child(4n),
                &:nth-child(n) {
                    padding: 21px;
                }
            }
        }
    }
    .cart-empty {
        .product-slot-recommendation .you-may-like {
            .recommendation-heading h2 {
                margin: 0 auto;
            }
            .carousel-recommendations .product-tile {
                display: inline-block;
                margin: 0 auto;
                padding: 10px;
                min-height: 275px;
                max-width: inherit;
                .product-name-image-container {
                    max-width: inherit;
                    margin: 0 auto;
                    .product-image {
                        max-width: 220px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.cart-overlay-html .product-promo {
    display: none;
}
.cart-top-banner{
	.cart-action-checkout{
		.button-fancy-large{
			max-width: 290px;
			width: 23.6%;

			@media screen and (max-width:767px){
				width:100%;
			}
			@media screen and (min-width:768px) and (max-width:1024px){
				width:35%;
			}
		}
		.paypal-button{
			.checkout-paypal-btn{
				@media screen and (min-width:768px) and (max-width:1024px){
					margin-left:12px;
				}
				@media screen and (min-width:768px) and (max-width:1024px) and (orientation: landscape){
					margin-left:14px;
				}

			}
		}
	}
}
.pt_cart{
	.cart-top-banner{
		.cart-action-checkout{
			.button-fancy-large{
				@media screen and (min-width:768px) and (max-width:1024px) and (orientation: landscape){
					width:27.5%;
			}
			}
		}
	}
}
@media screen and (max-width: 1023px){
    .pt_cart {
        .cart-right-content {
            .cart-recommendations{
                button.slick-arrow{
                    &.slick-next{
                        left:97%;
                    }
                    &.slick-prev{
                        right:97%;
                    }
                }
            }
        }
    } 
}


// SC-4444 specific changes

#wrapper.pt_cart_template_b,
#wrapper.pt_cart{
	.cart-row.sps-cart-row {
        .column.col-1 .item-details{
            .attribute{
                .value{
                    text-transform: capitalize;
                    font-family: $FuturaBold;
                }

                .label{
                    text-transform: capitalize;
                    font-family: $Futura;
                }

                span:not(.label){
                    font-family: $FuturaBold;
                }
            }

            .specialmessaging{
                span, p{
                    font-size: 15px;
                }
                
             .finalsalemessage{
					font-weight: bold;
					font-size: 12px;
					letter-spacing: 0.6px;
				}
            }

        }

        .price-promotion .price-sales{
            color: #af3434;
        }

        .swsps-top-bottom-val{
            font-family: $FuturaBold;
            font-weight: normal;
        }
    }
}


#wrapper.pt_cart_template_b, #wrapper.pt_cart{
    .cart-row{
        .column.col-2 .availability-web{
            margin-left: 10px;
        }

        &.sps-cart-row{
            .column.col-2 .availability-web{
                margin-left: 10px;
            }
        }
    }
} 
.pt_checkout .specialmessaging{
	margin:10px 0 0;
 }
