.product-tile {
	.quickview {
		-webkit-border-top-left-radius: 12px;
		-webkit-border-bottom-right-radius: 12px;
		-moz-border-radius-topleft: 12px;
		-moz-border-radius-bottomright: 12px;
		border-top-left-radius: 12px;
		border-bottom-right-radius: 12px;
		width: 93%;
		letter-spacing: 1px;
		padding: 0.72em;
		-webkit-transform: translate(-50%);
		-moz-transform: translate(-50%);
		-ms-transform: translate(-50%);
		-o-transform: translate(-50%);
		transform: translate(-50%);
		font-family: $Futura;
	}

	.b_product_badge {
	    top: 0;
	    span {
	    	text-align: inherit;
	    }

	}

	.product-name {
		font-family: $MrEavesReg;
    	font-size: $base-font + 6px;
    	width: 100%;
    	text-transform: capitalize;
	}
	.product-pricing {
		margin: 0.5em 0 0;
		.product-sales-price {
			color: $guardsman-Red;
			display: block;
		}
	}
	.product-promo {
		font-size: 0.8em;
		margin: 0;

		.promotional-message {
			font-family: $Futura;
			color: $guardsman-Red;
			font-size: $base-font;
   		    text-transform: uppercase;
		}
	}
	.clearence-msg {
		font-family: $FuturaMedium;
		color: $guardsman-Red;
		font-size: $base-font;
	    text-transform: uppercase;
	}
	.product-feature-messages {
		font-family: $FuturaMedium;
		font-size: $base-font - 2px;
	}
	.product-special-messages {
		color: $special-product-messages-color;
		font-size: $base-font;
   		text-transform: uppercase;
   		font-family: $FuturaBold;
	}
	.product-swatches {
		margin-top: 5px;
		margin-bottom: 15px;
		display: none;
		float: none !important;

		ul {
			padding-left: 2px;            
			display: table;
			li {
				padding: 2px 1px 2px 1px;
			}
		}
		.swatch {
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			height: 18px;

			img {
				-webkit-border-radius: 30px;
				-moz-border-radius: 30px;
				border-radius: 30px;
			}
		 	@media screen and (max-width: 767px) {
		 		min-width: 25px;
		 	}
		}
		.product-swatches-all, .product-swatches-all-showless {
			font-family: $Futura;
			font-size: $base-font - 1px;
		}
		.product-swatches-all {
			top: 40px;
		}
	}
}

.search-result-items {
	.grid-tile {
		&.two-space-tile {
		    background: $light-skygrey;


			.product-promo-tile {
				.text-content {
					position: absolute;
				    top: 36%;
				    left: 50.5%;

				    .heading {
						font-family: $MrEavesReg;
						font-size: 24px;
						text-transform: capitalize;
						margin: 0;
					}
					.price {
						font-family: $Futura;
						font-size: 18px;
						clear: both;
	    				display: inherit;
	    				margin-top: 0px;
					}
				}
			}

			.offer-promo-tile {
				.text-content {
					position: absolute;
				    top: 17.7%;
				    left: 0;
				    width: 100%;
				    display: inline-block;
				    text-align: center;
				    color: $grape;

				    h2 {
				    	&.buy {
				    		font-family: $MrEavesReg;
							font-size: 46px;
							line-height: 46px;
							margin: 0 0;
							font-weight: normal;
				    	}
				    	&.get {
				    		font-family: $MrEavesReg;
							font-size: 60px;
							line-height: 49px;
							margin: 0 0;
							font-weight: normal;
				    	}
				    }
				    h1 {
				    	font-family: $MrEavesBook;
						font-size: 142px;
						line-height: 133px;
						margin: 0 0;
						text-transform: uppercase;
				    }
				    div.code {
				    	font-family: $MrEavesReg;
						font-size: 42px;
						text-transform: uppercase;
						margin-top: 19px;

				    	span {
				    		color: $guardsman-Red;
				    	}
				    }
				}
			}
		}

		&.one-space-tile {

			.onespace-tile-content {
				margin-top: 41.7%;
			}

			span {
				font-size: $base-font + 3px;
				font-family: $MrEavesHeavy;
				text-transform: uppercase;
				letter-spacing: 1px;
				color: $guardsman-Red;
			}

			h1 {
				color: $grape;
				font-size: 50px;
				font-family: $MrEavesBook;
			    margin: 9px 0 20px;
    			line-height: 35px;
			}

			p {
				font-size: $base-font + 6px;
				font-family: $MrEavesBook;
			    width: 73%;
			    color: $grape;
			}

			button {
				font-size: $base-font + 3px;
				font-family: $Futura;
				background: $grape;
		        width: 76%;
			    padding: 10px 0px;
			    margin-top: 13px;
			}

		}
	}
}

@media screen and (min-width:768px)  and (max-width:1023px) {
	.search-result-items{
		.grid-tile{
			&.two-space-tile{
				.product-promo-tile{
					.text-content{
						.price{
							font-size: 16px;
						}
					}
				}
				.offer-promo-tile{
					.text-content{
						h1{
							font-size: 150px;
						}
					}
				}
			}
			&.one-space-tile{
				p{
					font-size: 15px;
				}
				h1{
					font-size: 40px;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.product-tile {
		.product-swatches {
            margin-top: -10px;

			ul {
    			max-width: 200px;
			}

		}
	}
}


@media screen and (max-width:767px) {
	.product-tile {
	    .product-name {
	    	font-size: $base-font + 6px;
	    }
		.product-swatches {
            margin-top: -10px;
			ul {
				max-width: 155px;
			}

			li.hideswatch:nth-child(-n+6) {
				display: none;
			}
			li.hideswatch:nth-child(-n+5) {
                display: none;
			}
			li.hideswatch:nth-child(-n+4) {
		    	display: inline-block;
		    }
		    li.hideswatch:nth-child(5) {
		    	display: none;
			}
			&.show-color {
		    	li.hideswatch:nth-child(5) {
		    		display: inline-block;
				}
			}

	    }
	}

	//content tile design
	.search-result-items {
		.grid-tile {
			&.two-space-tile {
				.offer-promo-tile{
					.text-content{
						h1{
							font-size: 97px;
							line-height: 97px;
						}
						div{
							&.code{
								font-size: 29px;
							}
						}
					}
				}
				.product-promo-tile {
					.text-content{
						top: 33%;
					}
				}
			}
		}
	}
	.view-more{
		.viewmore-grid{
		    border-top-left-radius: 15px;
			border-bottom-right-radius: 15px;
		}
	}
}


@media screen and (max-width:480px) {
	.search-result-items {
		.grid-tile {
			&.one-space-tile {
				span {
					font-size: $base-font - 1px;
				}


				p {
					font-size: $base-font;
				}

				button {
					font-size: $base-font;
				}

			}

			&.two-space-tile {
				.product-promo-tile {
					.text-content {
					    .heading {

						}
						.price {
		    				margin-top: 3px;
						}
					}
				}

				.offer-promo-tile {
					.text-content {
						top: 19%;

					    h2 {
					    	&.buy {
								font-size: 30px;
								line-height: 30px;
					    	}
					    	&.get {
								font-size: 40px;
								line-height: 40px;
					    	}
					    }
					    h1 {

					    }
					    div.code {
							margin-top: 20px;
					    }
					}
				}
			}
		}
	}
}
.product-listing.last-visited{
	.product-tile .product-pricing .product-sales-price {
margin:0 auto;
	}
}