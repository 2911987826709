.js .simple-submit {
	display: none;
}

legend {
	background: $white-smoke;
	border-bottom: 1em solid $white;
	font-size: 1.1em;
	font-weight: 700;
	margin: 0;
	padding: 1.16em 2% .83em;
	position: relative;
	text-transform: uppercase;
	width: 96%;
	span {
		font-size: 12px;
		font-weight: 400;
		position: relative;
		text-transform: none;
		padding: 0 .5em;
		em {
			color: $night-rider;
		}
	}
}
.ie7 legend {
	width: 94%;
}

label {
	display: block;
	font-size: $base-font;
	font-weight: normal;
	line-height: normal;
	margin-bottom: 0;
	
}
.form-horizontal label {
	float: left;
	margin-top: .5rem;
	margin-bottom: 0;
	width: 100%;
	@media screen and (min-width: 768px) {
		text-align: right;
		width: 25%;
	}
}
.label-inline label {
	display: inline;
	float: none;
	font-size: 1em;
	font-weight: 400;
	margin-top: 0;
	text-align: left;
	width: auto;
}
.label-above label {
	margin-bottom: .3rem;
	width: auto;
}

.form-label-text {
	float: left;
	font-size: 1.1em;
	font-weight: 700;
	padding: 0.75em 0 0;
	text-align: right;
	width: 25%;
}


.field-wrapper {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
 	-moz-box-sizing: border-box;
	margin-bottom: .5rem;
	width: 100%;
}
.form-horizontal .field-wrapper {
	float: left;
	width: 76%;
	@media screen and (min-width: 768px) {
		padding-left: 2%;
		width: 50%;
	}
}
.label-inline .field-wrapper {
	float: left;
	padding-left: 0;
	width: auto;
}
.label-above .field-wrapper {
	float: none;
	padding-left: 0;
	width: auto;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="date"],
input[type="tel"],
textarea {
	font-family: $MrEavesReg;
    font-size: $base-font + 2;
}

select {
	background-color: $white; // set background color to make height work
	width: 100%;
	height: 2rem;
}


.form-caption {
	clear: left;
	width: auto;
	font-family: $MrEavesBook;
	font-size: 15px;
}
.form-horizontal .form-caption {
	@media screen and (min-width: 768px) {
		margin-left: 25%;
		float: right;
	}
}
.label-above .form-caption {
	margin-left: 0;
}
.form-indent .form-caption {
	margin-left: 0;
}

.form-indent {
	@media screen and (min-width: 768px) {
		margin-left: 27%;
	}
}

.error-message {
	border: 2px solid $sangria;
	background-color: $tutu;
	padding: 0.7em 0.5em;
	text-indent: 0;
	width: 49.5966%;
}

.error {
	//background-color: $tutu;
	//border-color: $sangria;
	color: $guardsman-Red;
}

span.error,
div.error {
	background: none;
}

.error-form {
	background-color: $stark-white;
	border: 1px solid $sangria;
	border-radius: .5em;
	color: $sangria;
	margin: 0.5em 0;
	padding: 0.8em;
	a {
    	color: inherit;
    }
}

.form-inline {
	form {
		padding: 0 0 2em;
		width: 98%;
	}
	label {
		text-transform: uppercase;
		width: auto;
	}
	input[type="text"], select {
		margin-left: 0;
		width: 95%;
	}
	button {
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.row-inline {
	display: inline;
	label {
		width: auto;
	}
}

// Required Indicator and Text
.dialog-required {
	display: inline;
	position: relative;
	padding: 0 .5em;
	em {
		color: $sangria;
		font-size: .65rem;
		font-style: normal;
		text-transform: uppercase;
	}
}
.required-indicator {
	color: $sangria;
	font-size: 1rem;
	padding: 0;
}

.form-field-tooltip {
	float: left;
	margin-left: 3%;
	padding-top: .75em;
	width: 20%;
}

ul {
	li {
		list-style-type: none;
	}
}

// override TBR
.footer-container .footer-email-signup .email-signup.make-label-absolute .form-row input[id*="emailsignup_email"] {
    padding: 15px;
	@media screen and (max-width: 1024px){
		    padding: 15px 0 16px 15px;
	}
}
.subscribe-action.clearboth .subscribe-emailid .field-wrapper input[type=email] {
    padding: 20px 0 20px 17px;
}

.email-signup-footer-error{
	color:$guardsman-Red;
}

.field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .address-list .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_checkout .make-label-absolute .form-row .field-wrapper .custom-select,
.pt_account .catalog-request .address-form-block .make-label-absolute .form-row .field-wrapper .custom-select,
.form-row .field-wrapper .custom-select,
.field-wrapper .custom-select {
    &.current_item {
        .selected-option,
        select {
			border-bottom: 1px solid $dark-gray;
        }
    }
}

// label animation on input focus change
.make-label-absolute:not(.catalog-quick-order__main) {
    .form-row {
        label {
            span {
                font-family: $MrEavesBook;
                font-size: $base-font;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
				-ms-transition: all 0.3s ease;
                &.error {
                    color: $guardsman-Red;
                }
            }
            &.input-focus {
                span {
                    font-size: $base-font - 1px;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                }
            }
        }
    }
}

.pt_account .make-label-absolute:not(.catalog-quick-order__main) {
	.form-row {
        label {
			&.input-focus {
                span {
					@media screen and (min-width: 767px) and (max-width: 1024px) {
						font-size: 12px;
					}
				}
			}
		}
	}
}
