.zoomLens {
	background: url("../../../images/RM-Zoom-Lens-PDP-White.svg") !important;
	opacity: .25 !important;
	background-size: cover;
}
#main {
	max-width: 1260px;
    margin: auto;
    
}
.primary-content {         
    @media screen and(max-width: 1023px){
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
     }
}