.pt_specials {
	
	#navigation {
		.menu-category {
			li {
				&.selected {
					a {
						color: $grape;
						border-bottom: 4px solid $grape;
					}
				} 
			}
		}
	}
	
	
	h1 {
		&.mm-heading {
		    word-spacing: 2px;
	    	letter-spacing: 2px;
	    	font-family: $MrEavesReg; 
		} 
	}
	
	.mixmatch-slide {
    	
		.special-result-content {
	    	
	    	
	    	.mm-prod-details {
			    
		        .product-variations {
    				.attribute {
    					
    					.custom-select{ 
    						.variation-select{
    							font-family: $FuturaBold;
							    font-size: $base-font - 1px;
    							letter-spacing: 1px;
    						}
    						
    						&.error {
    							.selected-option,.variation-select {
    								color: $guardsman-Red;
    							}
    						}	
    					}
    				}
			    }
			    .product-name {
			    	font-family: $MrEavesReg;
			    	font-size: $base-font + 5px;
			    }
			    
			    .disablemessage {
			    	color: $guardsman-Red;
			    	font-family: $FuturaMedium;
			    }
			    
			    .product-price {
			    	font-family: $Futura; 
		    	    font-size: $base-font;
		    	    
		    	    .price-sales {
		    	    	color: $guardsman-Red;
		    	    }
			    }
			    
			    .size-chart-link {
			    	font-family: $FuturaBold;
			    	font-size: $base-font - 1px;
			    	background: url("../../../images/size-chart.png") left center no-repeat;
			    }
			    
			    button {
			    	font-size: $base-font + 3px;
				    font-family: $Futura;
				    padding: 15px 0;
				    letter-spacing: 2px;
				    border: 0;
				    
				    &.add-to-cart {
			    	    background-color: $grape;
					    
				    }
			    }
			    
			    .special-product-view {
			    	font-family: $Futura;
			    	font-size: $base-font - 1px;
			    }
	    	}
			.slick-slider {
	    	    margin: 70px 0 50px;
		        
		    	.slick-slide {
		    		
		    		
		    		
	    		    
    		    	
	    		    &.slick-center {
	    		    	max-width: 270px;
	    		    	text-align: center;
	    		    	
			    	    .product-variations{
	    		    		 .attribute{
		    		    		border: 0;
		    		    	}
		    		    	
	    		    	}
	    		    }
		    	}
		    	    	
		    	.product-variations {
		    		.attribute{
		    			.color{
		    				li{
						        
							    &.selected {
							    	a {
							    		&.swatchanchor {
						    				border: 1px solid $black;
    										border-radius: 10px;
							    		}
							    	}
							    }
					    		a {
					    			&.swatchanchor {
									    width: 40px;
    									height: 20px;
	    								border: 1px solid transparent;
					    			}	
					    		}
					    		
					    		img {
								    box-sizing: border-box;
								    border-radius: 10px;
		    					}
					    	}
		    			}
		    			@media screen and (max-width: 767px) {
			    			.color{
						        
			    				li{
								    &.more-less-colors {
								    	a {
											font-family: $FuturaMedium;
											font-size: $base-font - 1px;
								    	}
								    }
								}
			    			}
				    	}
		    		} 
		    	}
				.slick-arrow {
				    top: 156px;
					&.slick-next {
				        background: url("../../../images/slick-next.png") right no-repeat;
	    				width: 15px;
	    				height: 35px; 
					}
					&.slick-prev {
				        background: url("../../../images/slick-prev.png") left no-repeat;
	    				width: 15px;
	    				height: 35px;
					}
				}
			}
		}
	}
}

// design only for 1024px layout
@media only screen and (width:1024px){
	.pt_specials {
		
		.mixmatch-slide {
			.special-result-content {
				
				.mm-prod-details {
				}
				.slick-slider {
					.slick-slide {
						.product-variations {
							
							.attribute {
								border: 0;
							}
						}
					}
				}
			}
		} 
	}
}
@media screen and (max-width:1023px) {
	.pt_specials {		
		.mixmatch-slide {		    
		    .special-result-content {
		    	border-bottom: 0;
		    	
			} 
	    }
    }    
}

@media screen and (max-width:767px){
	.pt_specials {
		.mixmatch-slide {
			h2 {
				&.togglemob {
					background-image: url("../../../images/arrow-closed.png");
				    font-family: $HelveticaBold;
				    font-size: $base-font;
			    }			    
			    &.expanded{
			    	background-image: url("../../../images/arrow-open.png");
		    	    border-bottom: 1px solid $very-light-gray;
			    }
			}
			.special-result-content {
				.slick-slider {
				    
				    .product-variations{
				    	.attribute{
				    		.color{ 
				    			li{ 
				    				img{
				    					padding: 2px;
				    				}
				    			}
				    		}
				    	}
				    }
				}
				.mm-prod-details {
					.product-name{
						font-size: 20px;
					}
					.custom-select{ 
						.selected-option{
							font-size: $base-font;	
						}
					}
				}
			} 
		}
	}
}
@media screen and (max-width:480px) { 
	.pt_specials {
		
		
		.mixmatch-slide {
			
		}
	}
}
/*size guide specific rule*/
.size-guide-container {  	
	.size-guide-navigation__dropdown_selected {
		color: $grape-blue;
	}
}
