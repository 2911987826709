.checkout-body.plcc-redesign {
.ui-dialog {
	&.checkout-plcc-modal,
	&.checkout-plcc-modal-step2,
	&.plcc-acquisition-rebuttal-model,
	&.checkout-plcc-modal-terms,
	&.checkout-plcc-modal-step3 {
		.approved-model, .review-model {
			.header {
				color: $minsk;
			}

			.plcc-card {
				background: url(../../../images/rm/plcc-card.png) no-repeat center;
				background-size: contain;
			}
		}
		.plcc-model-dialog .plcc-offer-main .plcc-greeting {
			color: $minsk;
		}

		.plcc-offer-banner {
			background: linear-gradient(270deg, $minsk 0%, #311D51 100%);

			.plcc-offer-banner-left {
				background: url(../../../images/rm/plcc-card.png) no-repeat center;
				background-size: contain;
			}
		}

		.plcc-application-banner {
			background: $minsk;

			.plcc-credit-card-image {
				background: url(../../../images/rm/plcc-card.png) no-repeat center;
				background-size: contain;
			}
		}
		.plcc-terms-banner-container {
			background: $minsk;
		}
		.bottom-buttons .approved-buttons .continue-checkout{
			font-size:13px;
		}
	}

	&.checkout-plcc-modal-terms > .ui-dialog-titlebar {
		background-image: url(../../../images/rm/plcc-card.png);
	}
}
.billing-cards-section .checkout-rebuttal .plcc-rebuttal-top-header:before {
	background: url(../../../images/rm/plcc-card.png) no-repeat center;
	background-size: contain;
}
}