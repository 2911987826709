//Catagory Banner
.content-banner-breadcrumb {
    
    &.absolute {
    	.category-banner-text {
    		position: absolute;
    		top: 39%;
    		left: 6%;
    		width: auto;
    	}
    }
}

.slot-grid-header {
	position: relative;
	display: table;

	.category-banner-text {
		display: none;
	}
	
	&.no-category-banner {
		height: 140px;
	    background: #f4f4f4;
	    display: table;
	    width: 100%;
	    
		@media screen and (max-width: 1023px) {
				height: 120px;
		}

		@media screen and (max-width: 767px) {
				height: auto;
		}
	    
	    .category-banner-text {
	    	text-align: center;
    		display: table-cell;
    		position: static;
    		top: 0;
			left: 0;
			vertical-align: middle;
			@media screen and (max-width: 767px) {
				display: block;
				text-align: left;
			}
	    }
   	}    
	.standard-contentslot {
		position: relative;
		z-index: 1;
		height:auto;
	}

	img {
		height: auto;
	    max-width: 100%;
	    width: 100%;
	}
	
	.category-banner-text {
		position: relative;
	    box-sizing: border-box;
		width: 100%;
		padding-bottom: 20px;
		@media screen and (max-width: 767px) {
			padding-bottom: 0;
		}
	    .category {
	    	font-size: $base-font + 28px;
		  	margin: 0;
		  	line-height: normal;
		    font-family: $FuturaLight;
		    text-transform: uppercase;
		    color: $black;
		    letter-spacing: 3px;
		    font-weight:700;

			@media screen and (max-width: 1023px) {
				font-size: $base-font + 23px;
			}

			@media screen and (max-width: 767px) {
				font-size: $base-font + 6px;
				margin: 10px 0 10px 14px;
				letter-spacing: 3px;
			}
	    }
	    

	    p  {
	    	font-size: $base-font + 8px;
			margin-bottom: 20px;
	    	font-family: $DidotItalic;
	    	color: $black;
	    	letter-spacing: 1px;
	    }
	}
}

@media screen and (max-width: 1024px) {
	.slot-grid-header {
		.category-banner-text {
			top: 22%;
    		left: 4%;
		}
	}
}

@media screen and (max-width: 1023px) {
	.slot-grid-header {
		.category-banner-text {
			top: 26%;
    		left: 4%;	
		}
	}
}

@media screen and (max-width: 767px) {
	.slot-grid-header {
		
		.category-banner-text {
			position: relative;
			left: 0;
			text-align: center;
			top: 0;
			transform: inherit;
	        -ms-transform: inherit;
    	    -webkit-transform: inherit;
    	    margin: 0 0 10px 0;
    	    width: 100%;
    	    display: none;
			p {
				font-size: $base-font + 8px;
			}
		}
	}
}

// SC-9149
.category-seo-text {
	.read-more, .read-less{
		color: $mainly-blue;
	}
}
// End: SC-9149