.wrapperopacity {
	opacity: 0.5;
    background-color: $white;
}

.return-label-heavy {
	&.ui-dialog {
		.ui-icon-closethick {
			background-image: url(../../../images/close-icon.png);
			width: 16px;
			height: 16px;
			top: 60%;
			left: 45%;
		}
	}
}

.heavy-item-dialog {
	&.ui-dialog-content {
		padding: 0 10% 23% 11%;
		box-sizing: border-box;
	}
}

.heavy-item {
	display: block;
	font-family: $MrEavesReg;
    font-size: $base-font + 16px;
	border-bottom: 1px solid $alto;
	padding: 0 0 10px 0;
}

.heavy-message {
	display: block;
	font-family: $MrEavesReg;
    font-size: $base-font + 6px;
	margin: 20px 0;
}

.heavy-cancel {
	margin: 0 20px 0 0; 
}

@media screen and (max-width: 767px) {
	.heavy-item-dialog {
		&.ui-dialog-content {
			padding: 0 3% 12% 3%;
		}
	}
	
	.heavy-cancel {
		width: 100%;
    	margin: 0 0 20px 0;
	}
	
	.heavy-continue {
		width: 100%;
	}
}

//Return Items

.return-items {
    margin: 20px 0 0 0;
        
    .item-error {
	    font-size: $base-font - 1px;
	    margin: 5px 0;
	    color: $guardsman-Red;
	    font-family: $Futura;
    }
    
    .custom-select {
    	&.error {
    		.selected-option {
    			color: $guardsman-Red !important;
    		}
    	} 
    }
    
    .left-section {
	    width: 60%;
	    max-width: 560px;
    }
    
    .right-section {
	    width: 40%;
		max-width: 380px;
		margin: 5px 9px 0 0;
		
		&.secondary-details {
			margin: 5.3% 8px 0 0;
		}
    }
    
    .return-header, .return-content {
    	width: 100%;
    	display: inline-block;
    }
    
    .return-order {
    	font-size: $base-font + 16px;
    	font-family: $MrEavesReg;
    	margin: 13px 0 20px 0;
    }
    
    .return-message {
    	font-size: $base-font + 5px;
    	font-family: $MrEavesReg;
    	line-height: 20px;
    	margin-right: 0;
    }
    
    .return-sub-header {
    	font-size: $base-font + 5px;
    	font-family: $MrEavesReg;
    	margin-top: 6px;
    }
    
    button {
    	&.submit-cancel, &.return-cancel {
		    text-transform: uppercase;
		    font-size: $base-font + 1px;
		    font-family: $FuturaBold;
		    background: transparent;
    		color: $black;
    		margin-left: 10px;
    	}
    	
    	&.return-list, &.return-submit {
    		font-family: $Futura;
    		padding: 14px 43px;
    		background: $grape;
    		font-size: $base-font + 3px;
    		text-transform: uppercase;
    	}
    }
    
    .return-line-items {
    	.heading {
    		font-family: $MrEavesReg;
    		font-size: $base-font + 16px;
    		margin: 35px 0 0 0;
    		padding: 0 0 12px 0;
    		text-transform: inherit;
    	}

    	.eligible-item {
    		input[type="checkbox"] {
    			margin-top: 10px;
    		}
    	}

    	.cart-row {
    		.col-1 {
    			.item-image {
    				margin-top: 9px;
    			}
    		}
    	}
    }
    
    .list-tems {
	    padding: 18px 12px;
	    padding-right: 0;
		
		&.non-eligible-item {
			padding: 17px 43px;
		}
    }
    
    input[type="checkbox"] {
    	margin: 0 10px 0 0;
    	width: 15px;
    	height: 15px;
    	background: none;
    }
   
    input[type="checkbox"]:checked {
    	background: url(../../../images/rm/checkmark.svg) no-repeat center;
    	background-size: 15px 15px;
    }

    .returned-list {
    	.line-item-quantity {
	    	&.returned {
	    		width: 20%;
	    		max-width: 160px;
	    		margin-top: 54px;
	    		
	    		.label {
				    font-family: $FuturaBold;
				    font-size: $base-font - 1px;
    			}
    			
    			.value {
    				font-family: $Futura;
    				font-size: $base-font - 1px;
    			}
	    	}
	    }
    }
    
    .line-item-quantity {
    	display: inline-block;
    }
    
    .non-eligible-list {
		.cart-row {
			.col-1 {
				.item-details {
					padding-left: 28px;
					
					.product-list-item {
						.name {
							line-height: 1em;
						}
					
						.sku {
							.value {
							    font-size: 16px;
								letter-spacing: 0.06em;
								margin-bottom: 9px;
							}
						}
					}
				}
			}
			
			.col-2 {
				margin-top: 92px;
			}
			
			.column-combine {
				display: none;
			}
		}
	}
	
	.returned-list {
    	.non-eligible-item {
    		padding: 20px 38px;
			
			.cart-row {
				padding: 6px 0 0 0;
				
				.col-2 {
					margin-top: 48px;
				}
			}
		}
    }
	
    .cart-row {
    	width: 97%;
    	    	
    	.col-1 {
    		width: 55%;
    		max-width: 460px;
    		
    		.item-details {
    			.returns-check {
    				margin: 0 10px 0 0;
    			}
    			
    			.label {
    				margin-right: 3px;
				    font-family: $FuturaBold;
				    margin-bottom: 5px;
    			}
    			
    			.value {
    				margin-bottom: 5px;
    				text-transform: uppercase;
    			}
    		}
    	}
    	
    	.col-2 {
    		margin-top: 56px;
    		max-width: 120px;
    		width: 14%;
	    	
	    	.item-total {	    		
	    		.price-total {
	    			font-family: $Futura;
	    			font-size: $base-font + 2px;
	    			
	    			&.price-promotions {
	    				color: $guardsman-Red;
	    			}
	    		}
	    	}
    	}
    	
    	.column-combine {
    		width: 38%;
    		max-width: 338px;
    		margin-top: 42px;
    		
    		.col-3 {
    			width: 45%;
    			max-width: 140px;
    		}
    		
    		.col-4 {
    			width: 55%;
    			max-width: 175px;
    			float: right;
    		}
    		
    		.current_item {    			
    			.selection-list {
    				li {
    					padding: 9px 10px 9px 15px;
    					font-size: $base-font + 3px;
    					font-family: $MrEavesReg;
    				}
    			}
    		}
    		
    		.selected-option {
    			font-size: $base-font + 3px;
    			font-family: $MrEavesReg;
    		}
    	}
    }	
}

//Return Item Confirmation
.return-confirmation {
	.return-order {
		margin: 15px 0 23px 0;
	}
	
	.return-list-download {
	    border: 0;
	    background: $grape;
	    color: $white;
	    letter-spacing: 1px;
	    font-family: $Futura;
		padding: 14px 34px;
	}
	
	.return-instructions {
	    max-width: 580px;
	    width: 100%;
	    margin: 28px 2px;
	    font-family: $Futura;
	    font-size: $base-font + 1px;
	    
	    h1 {
	    	font-family: $Futura;
	    	font-size: $base-font + 3px;
	    	margin-bottom: 5px;
	    }
	}
	
	.submission-text {
		margin-bottom: 20px;
	}
	
	ol {
	    padding: 0 0 0 50px;
		margin: 0 0 28px 0;
		line-height: 25px;
		
		span {
			width: 100%;
    		display: inline-block;
    		line-height: 25px;
    		padding-left: 22px;
    		
    		a {
    			color: $torea-bay;
    		}
		}
	}
	
	.item-returned {
		p {
			margin: 0 0 30px 0;
		}
	}
	
	.return-prod-section {
	    width: 100%;
		display: table;
		margin-bottom: 15px;
		margin-left: 0;
		
		.return-prodimage-section {
			width: 11.5%;
    		display: table-cell;
		    
		    a {
		    	display: inline-block;
		    	max-width: 60px;
		    }
		}
		
		.return-prod-quantity {
			width: 88%;
		    display: table-cell;
		    vertical-align: middle;
		    font-size: $base-font + 1px;
		}
	}
}
    
@media screen and (max-width: 1024px) {
	.return-items {
		.custom-cart-qty, .custom-return-reson {
	    	&.custom-select {
	    		select {
	    			position: absolute;
	    			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
				    filter: alpha(opacity=0);
				    -moz-opacity: 0;
				    -khtml-opacity: 0;
				    opacity: 0;
				    padding: 0;
	    		}
	    		
	    		&.current_item {
	    			.selection-list {
	    				display: block;
	    				z-index: 2;
	    			}
	    		}
	    		
	    		.selected-option {
	    			display: block;
	    		}  
	    	}
	    }
	}
}

@media screen and (max-width: 1023px) {
	.return-items {
		padding: 20px;
	    box-sizing: border-box;
	    -webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
	    padding-top: 0;
	    
	    .returned-list {
	    	.line-item-quantity {
	    		display: block;
	    		
	    		&.returned {
	    			display: none;
	    		}
	    	}
	    }

	    .non-eligible-list {
	    	.cart-row {
	    		.col-1 {
					width: 72%;
					.item-details {
						.name {
							margin-bottom: 10px;
						}
					}
				}

				.col-2 {
					margin-top: 92px;
				}
	    	}
	    }
	    
	    .return-header {
	    	button {
	    		&.submit-cancel {
	    			display: none;	
	    		}
	    	}	
	    }
	    
	    .left-section {
	    	width: 59%;
	    }
	    
	    .right-section {
	    	width: 36%;
	    	
	    	&.secondary-details {
	    		width: 100%;
	    	}
	    	
	    	button {
	    		&.return-list {
	    			padding: 14px 31px;
	    		}
	    	}
	    }
	    
	    .cart-row {
			width: 95%;

			.column-combine {
				margin: -75px 0 0 0;
				width: 36%;
				
				.col-3 {
					width: 100%;
					float: right;
				}
				
				.col-4 {
					width: 100%;
					margin-top: 20px;
				}
			}	    
	    }
	}
	
	//Return Item Confirmation
	.return-confirmation {
		.return-order {
			margin: 5px 0 23px 0;
		}
		
		.return-list-download {
		    padding: 16px 41px;
		}
		
		.return-instructions {
		    margin: 17px 4px;
		    
		    h1 {
		    	margin-bottom: 5px;
		    }
		}
		
		.submission-text {
			margin-bottom: 25px;
		}
		
		ol {
		    padding: 0 0 0 50px;
			margin: 0 0 28px 0;
			
			span {
	    		padding-left: 22px;
			}
		}
		
		.item-returned {
			p {
				margin: 0 0 30px 0;
			}
		}
		
		.return-prod-section {
			margin-bottom: 15px;
			margin-left: 20px;
			
			.return-prodimage-section {
				width: 12.5%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.return-items {
		padding: 10px;
		margin: 15px 0 0 0;
		padding-top: 0;
		
		.return-header {
			button {
				&.submit-cancel {
					display: block;
				}	
			}
		}
		
		.left-section {
			width: 100%;
    		max-width: 100%;
		}
		
		.right-section {
			width: 100%;
		    float: left;
		    text-align: center;
		    max-width: 100%;
		    margin-top: 15px;
		    
		    button {
		    	width: 100%;
		    	
		    	&.return-list {
		    		float: none;
		    	}
		    	
		    	&.submit-cancel {
		    		font-size: $base-font;
		    		line-height: normal;
    				margin: 20px 0 0 0;
		    	}
		    }
		    
		    &.secondary-details {
		    	width: 100%;
		    	
		    	button {
		    		width: 100%;
		    		margin-left: 0;
			    	
			    	&.return-list {
			    		float: none;
			    	}
			    	
			    	&.submit-cancel {
			    		font-size: $base-font;
			    		line-height: normal;
	    				margin: 20px 0 0 0;
			    	}
			    }
		    }
		}

		.non-eligible-list {
			.cart-row {
				.col-1 {
					width: 100%;
					
					.item-details {
						padding-left: 15px;
					}
				}
			}
		}
		
		.return-order {
			margin: 0 0 15px 0;
		}
		
		.return-message {
			font-size: $base-font + 5px;
			margin-right: 0;
		}
		
		.return-sub-header {
    		font-size: $base-font + 5px;
    	}	
    	
    	.list-tems {
    		padding: 15px 0;
    		
    		&.non-eligible-item {
    			padding: 15px 0;
    		}
    	}
    	 
    	.return-line-items {
    		.heading {
    			margin: 10px 0 0 0;
    		}
    	} 
    	
    	.cart-row {
    		width: 100%;
    		
    		.column.col-1 {
    			.item-details {
    				.attribute {
    					margin-bottom: 5px;
    				}
    			}
    		}

    		.col-1 {
    			width: 100%;
    			
    			.item-image {
    				width: 40%;
    			}
    			
    			.item-details {
					width: 60%;    			
    			}
    		}
    		
    		.col-2 {
    			display: none;
    		}
    		
    		.column-combine {
    			width: 100%;
    			margin: 15px 0 0 0;
    			
    			.col-4 {    				
    				.selected-option {
    					border-left: 1px solid transparent;
    				}
    			}
    			.current_item {
    				.selection-list {
    					li {
    						padding: 9px 10px;
    					}	
    				}
    			}
    		}
    	}
	}
	
	//Return Item Confirmation
	.return-confirmation {
		.return-order {
			margin: 5px 0 23px 0;
		}
		
		.return-instructions {
		    margin: 17px 4px;
		    
		    h1 {
		    	margin-bottom: 5px;
		    }
		}
		
		.submission-text {
			margin-bottom: 25px;
		}
		
		ol {
		    padding: 0 0 0 15px;
			margin: 0 0 25px 0;
			
			span {
	    		padding-left: 0;
			}
		}
		
		.return-prod-section {
			margin-left: 0;
			
			.return-prodimage-section {
				width: 30%;
			}
			
			.return-prod-quantity {
			    font-size: $base-font;
			}
		}
	}
}