@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.gift-heading {
	font-family: $Futura;
	font-size: 30px;
    color: #321e53;
    width: 100%;
    display: block;
    margin: 0 auto;
    
    @media screen and (max-width: 767px) {
    	font-size: 18px;
    }
}
.gift-paragraph {
	font-family: $MrEavesReg;
	font-size: 17px;
	@media screen and (max-width: 767px) {
    	font-size: 12px;
    }
}
.option-text {
    font-family: $MrEavesReg + 5px;
	font-size: $base-font;
}
.gift-card-section {
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	  -webkit-appearance: none; 
	  margin: 0; 
	}
	.gift-card-message, .gift-card-lookup-form-section {
	    -moz-box-shadow: -16px 16px 0px #321e53;
		-webkit-box-shadow: -16px 16px 0px #321e53;
		box-shadow: -16px 16px 0px #321e53;
	}
	.note-section {
		-moz-box-shadow: -16px 16px 0px #321e53;
		-webkit-box-shadow: -16px 16px 0px #321e53;
		box-shadow: -16px 16px 0px #321e53;
	    padding-left: 67px;
    	padding-right: 68px;
    	padding-bottom: 38px;
		@media screen and (min-width: 768px) and (max-width: 1023px){
			padding-bottom: 35px;
		}
		.note {
			font-family: $MrEavesReg;
			font-size: 15px;
			color: $black;
		}
		.another-card {
			color: #331E54;
			font-family: $MrEavesBold;
			font-size: 12px;
			text-transform: initial;
			text-decoration: underline;
			@media screen and (min-width: 768px) and (max-width: 1023px){
				font-size: 17px;
			}
		}
	}
	.make-label-absolute {
		label {
			span {
				font-family: $MrEavesReg;
				font-size: 17px;
			}
		}
	}
	.balance-result-section {
		-moz-box-shadow: -16px 16px 0px #321e53;
		-webkit-box-shadow: -16px 16px 0px #321e53;
		box-shadow: -16px 16px 0px #321e53;
	    padding: 0 21px 32px 20px;
		.balance-result-block {
			background: #321e53;
			font-family: $MrEavesReg;
			font-size: 15px;
	    }
	    .svs-card-amount {
			font-family: $MrEavesBold;
			font-size: 32px;
		}
		.svs-masked-number {
			font-family: $MrEavesReg;
			font-size: 17px;
		}
		.available-balance-text {
		    margin: 0 0 25px 0;
		}
	}
	.form-action {
		button {
			background: #321e53;
			color: $white;
			border-color: #321e53;
			font-family: $Futura;
			font-size: 15px;
		}
	}
	@media screen and (max-width: 767px) {
		.gift-card-message, .gift-card-lookup-form-section {
		    -moz-box-shadow: -10px 10px 0px #321e53;
	    	-webkit-box-shadow: -10px 10px 0px #321e53;
	    	box-shadow: -10px 10px 0px #321e53;
		}
		.gift-heading {
		    width: 91%;
		    font-size: 24px;
		    margin: 0 auto;
		}
		.gift-paragraph {
			font-size: 12px;
		}
		.form-action {
			button {
				line-height: 12px;
			}
		}
		.note-section {
			padding-left: 15px;
		    padding-right: 16px;
	        padding-bottom: 15px;
	        text-align: center;
	        width: 100%;
            z-index: 0;
            -moz-box-shadow: -10px 10px 0px #321e53;
	    	-webkit-box-shadow: -10px 10px 0px #321e53;
	    	box-shadow: -10px 10px 0px #321e53;
            .note {
        	    margin-bottom: 10px;
        	    font-size: 14px;
    	        line-height: 12px;
    			width: 100%;
            }
            .another-card {
            	font-size: 14px;
            }
		}
		.balance-result-section {
			-moz-box-shadow: -10px 10px 0px #321e53;
	    	-webkit-box-shadow: -10px 10px 0px #321e53;
	    	box-shadow: -10px 10px 0px #321e53;
			.svs-card-amount {
				font-size: 30px;
			}
			.balance-result-block {
				font-size: 14px;
			}
			.available-balance-text {
			    margin: 0 0 17px 0;
			}
		}
	}
}
.plcc-main {
	&.plcc-pre-approval-main {
		
		.plcc-main-heading {
			font-family: $Futura;
			font-size: $base-font + 6px;
		}
		.plcc-profile-summary {
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email, .plcc-edit-profile {
				font-family: $MrEavesReg;
				font-size: $base-font;
			}
			.plcc-profile-name , .plcc-profile-address,.plcc-city, .plcc-profile-email {
			    line-height: 25px;			
			}
		}
		.question-mark-icon {
		    margin-right: 15px;
		}
		.plcc-form-section {
			
			.plcc-masked-number {
				font-family: $Futura;
				font-size: $base-font + 2px;
			}
		}
		.plcc-primary {			
			label {
				color: $dim-gray;
			}
			.extra-label, .plcc-personal-address-section {
				font-size: $base-font;
			}
			.plcc-personal-address-section {
			    font-family: $MrEavesReg;
				font-size: $base-font + 3px;
				color: $slightblack-two;
			}
			
		}
	}
	.plcc-secondary {
		.see-benefits {
			font-family: $FuturaBold;
		    font-size: $base-font + 2px;
		    color: $slight-blue;
		}
	}
	.plcc-left-nav-heading {
		font-family: $MrEavesReg;
		font-size: $base-font + 14px;
		color: $black;
	}
	.plcc-left-nav-info {
		font-family: $MrEavesReg;
		font-size: $base-font + 5px;
		color: $black;
		line-height: 22px;
	}
	.plcc-primary {
		input[type=number]::-webkit-inner-spin-button, 
		input[type=number]::-webkit-outer-spin-button { 
		  -webkit-appearance: none; 
		  margin: 0; 
		}
		label {
			span {
				font-size: $base-font + 3px;
			}
		}
		.phone-number-msg, .child-support-section , .married-section, .important-info {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
			color: $dim-gray;
			line-height: 18px;
			
			.bold {
				font-weight: 700;
			}
		}
		.phone-number-label {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
			color: $slightblack-two;
		}
		.extra-label {
			font-family: $MrEavesReg;
			font-size: $base-font + 3px;
			color: $slightblack-two;
		}
		.plcc-ssn-label {
			font-family: $FuturaBold;
		    font-size: $base-font;
		    color: $black;
		}
		.note-section {
			font-family: $MrEavesBold;
		    font-size: $base-font + 5px;
		    color: $black;
		    border: 2px solid $black;
		    padding: 20px;
		}
		.form-action {
			.form-row-button {
				&.cancel-button {
					width: 130px;
					
					button,a {
						color: $black;
						font-size: $base-font + 2px;
						padding: 19px 32px;
					}
				}
				&.save-button {
					button {
						font-family: $Futura;
						font-size: $base-font + 2px;
					}
				}
			}
		}
		.review-form-error-msg {
			font-family: $MrEavesBold;
		    font-size: $base-font + 5px;
		    color: $plcc-error;
		    margin-bottom: 10px;
            margin-top: 10px;
		}
		.form-row {
			&.form-indent {
		    	label {
		    		font-family: $MrEavesBold;
					font-size: $base-font + 3px;
		    		color: $black;
	    		    line-height: 20px;
		    	}
		    }
		}
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea,input[type="tel"]  {
				border-radius: 0;
				-moz-border-radius: 0;
				-webkit-border-radius: 0;
				font-family: $MrEavesReg;
	    		font-size: $base-font + 5px;
	    		color: $black;	
			}
		}
		
	}
	@media screen and (min-width: 768px) and (max-width: 1023px) {
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea  {
	    		color: $black;	
			}
		}
		.question-mark-icon {
		    margin-right: 15px;
		}
		.plcc-primary {
			label {
				font-size: $base-font + 5px;
				color: $dim-gray;
				font-family: $MrEavesReg;
			}
			.important-info {
				font-size: $base-font + 5px;
			}
		}
		.plcc-secondary {
			.see-benefits {
				color: $mainly-blue;
			}
		}	
		&.plcc-pre-approval-main {
			.plcc-main-heading {
				display: none;
			}
			.plcc-primary {
				.phone-number-msg,.child-support-section,.married-section,.important-info {
					font-size: $base-font + 1px;
				}
			}
		}
	} 
	@media screen and (max-width: 767px) {
		margin-top: 20px;
		&.plcc-pre-approval-main {
			.plcc-primary {
				.form-action {
					.form-row-button {
						&.save-button {
							button {
								font-size: $base-font;
							}
						}
					}
				}
			}
			
			.plcc-form-section {
		        .plcc-masked-number {
		        	font-size: $base-font;
		        }
			}
			.plcc-main-heading {
				font-size: $base-font + 2px;
			}
		}
		.plcc-primary {
			.form-action {
				.form-row-button{
					&.cancel-button {
						width: 120px;
						    
						button,a {
					    	font-size: $base-font;
    						padding: 19px 25px;
						}
					}
					&.save-button {
						button {
							font-size: $base-font;
						}
					}
				}	
			}
			.plcc-form-section, .plcc-form-row {
			}
			.phone-number-msg,.child-support-section, .married-section,.important-info {
				line-height: 14px;
				font-size: $base-font;
			}
			label {
				font-size: $base-font + 5px;
				font-family: $MrEavesReg;
			}
			.form-row {
				&.form-indent {
					label {
					    float: left;
    					width: 88%;
    					font-size: $base-font;
					    line-height: 13px;		
					}
				}
			}
			.plcc-ssn-label {
				font-size: $base-font;
			}
			.extra-label, .phone-number-label {
				font-size: $base-font + 5px;
			}		
		}
		.plcc-left-nav-heading{
			font-size: $base-font + 8px;
		    line-height: 16px;
		    margin: 0;
		}
		.plcc-left-nav-info {
			font-size: $base-font;
			line-height: 18.5px;
		}
		.note-section {
		    font-size: $base-font + 5px;
		    line-height: 17px;
		    color: $black;
		    padding: 10px 15px 15px 15px;
		}
    	.plcc-secondary {
    		.see-benefits {
				font-size: $base-font;
				color: $mainly-blue;
			}
			.plcc-left-nav-heading{
			    margin: 0 0 5px 0;
			}
			.plcc-left-nav-info {
				font-size: $base-font + 1px;
				line-height: 16px;
				color: $dim-gray;
			}
    	}
    }
	.plcc-primary {
	    .phone-number-msg, .contact-disclaimer {
			display: block;
		}
	}
}

.address-main-heading {
	font-family: $MrEavesReg;
	font-size: $base-font + 16px;
}
.default-indicator {
	font-family: $MrEavesReg;
	font-size: $base-font + 4px;
}
.paragraph {
	font-family: $Futura;
	font-size: $base-font;
}
.address-list-sec {
	font-family: $MrEavesReg;
	font-size: $base-font + 5px;
	
	@media screen and (max-width: 767px) {
		width: 100%;
	}
	.mini-address-title {
		font-family: $FuturaBold;
		font-size: $base-font + 2px;
	}
	button.address-delete, button.address-edit, a, .cancel {
		color: $link-color;
		font-family: $FuturaBold;
	}
	.address-make-default {
		font-size: $base-font + 1px;
	}
	button.address-edit, button.address-delete {
		font-family: $FuturaBold;
		font-size: $base-font + 1px;
		color: $link-color;
	}
	.delete-content {
	    .delete-msg {
	    	font-family: $Futura;
			font-size: $base-font + 5px;
	    }
	    .delete-controls {
	    	.cancel, .address-delete {
		    	font-family: $FuturaHeavy;
				font-size: $base-font + 2px;
	    	}
	    }
	    
	}
}
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.profile-info {
		.make-label-absolute {
			.form-row {
				&.newpassword {
					.form-caption {
						color: $white;
					}			
				}	
			}
		}
	}
	
	.address-form-block {
		.make-label-absolute {
			label[for*="country"]{
				margin: -1px auto 0;
			}
			.form-row {
				&.form-row-button {
					margin-bottom: 0;
					.apply-button{
						font-family: $Futura;
						font-size: $base-font + 3px;
						padding-top: 13px;
						padding-bottom: 13px;
						max-height: 50px;
					}
					.cancel-address {
				        background: none;
					    color: $mainly-blue;
					    font-family: $FuturaBold;
					    font-size: $base-font;
					}
				}
			}
		}
	}
	.make-label-absolute {
		.form-row {						
			&.error-handle {
				label {
					span {
						&.error {
							color: $guardsman-Red;
						}
					}
				}
				.form-caption {
					color: $guardsman-Red;
				}
			}
			&.password.error-handle {
				label {
					.error {
						color: $guardsman-Red;
					}
				}
			}
		}
	}
	.form-validation-error-msg {
		&.error {
			font-family: $Helvetica;
			font-size: $base-font;
		}
	}
	.address-create {
		font-family: $FuturaBold;
		font-size: $base-font;
		&.no-click {
			color: $dim-gray;
			cursor: not-allowed;
		}
	}
	.make-label-absolute {
		.field-wrapper {
			input[type="text"],input[type="password"], select, textarea, input[type="tel"]  {
				padding: 19px 15px 9px 20px;
				font-family: $MrEavesReg;
				font-size: $base-font + 5px;
				color: $slightblack;
			}
			.custom-select select {
				cursor: pointer;
			}
		}
	}
	.profile-info {
		h1 {
			font-size: $base-font + 16px;
			font-family: $MrEavesReg;
			display: inline-block;
			margin: 35px 0 0 0;
		}
		h2 {
			font-size: $base-font + 5px;
			font-family: $MrEavesReg;
			margin: 8px 0 16px 0;
    		line-height: 21px;
		}
		.inner-content {
			padding: 0 7.3% 3.6% 7.1%;
		}
		.form-row-button {
			//float: right;
    		//padding: 0 17.5% 0 0;
		}
		legend {
			font-size: $base-font + 16px;
			font-family: $MrEavesReg;
			padding: 33px 0 12px 0;
		    margin: 0 0 31px 0;
		    display: inline-block;
		    width: 100%;
		}
		button {
		    color: $white;
		    letter-spacing: 1px;
		}
		.make-label-absolute {
			label {
				span {
					font-family: $MrEavesBook;
					font-size: $base-font + 5px;	
					color: $dim-gray;	
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
				}
				&.input-focus {
					span {
						font-size: $base-font + 3px;
						color: $dim-gray;
						-webkit-transition: all 0.3s ease;
						-moz-transition: all 0.3s ease;
						-o-transition: all 0.3s ease;
						-ms-transition: all 0.3s ease;
					}
				}
			}
		}
		.field-wrapper {
			button {
				&.pwd-show {
					font-family: $FuturaHeavy;
					font-size: $base-font - 1px;	
					letter-spacing: .1em;	
				}
			}
		}
		.personal-info {
			.form-row-button {
				margin-top: 15px;
			}
		}
	}
	.setnewpassword {		
		h1 {
     		font-family: $MrEavesReg;
     		font-size: $base-font + 16px;
     	}
     	button {
 			background: $mainly-blue;
		    border-color: $mainly-blue;
		  	font-family: $Futura;
		    font-size: $base-font + 2px;
		}
		.resetpassword {
			a {
				font-family: $Futura;	
			}
		}
	}
}

// Account payment page.
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
	  -webkit-appearance: none; 
	  margin: 0; 
	}
	.paymentslist {
		.no-card-msg {
			font-family: $MrEavesReg;
			font-size: $base-font + 7px;
		}
	}
	&.paymentinstruments {
		#secondary {
			nav {
				li {
					&.payment-methods {
						a {
							color: $minsk;
							font-family: $FuturaHeavy;
						}
					}
				}
			}
		} 
	}
	.paymentslist {
		.top-content {
			.default-msg {
				line-height: 30px;
				font-family: $MrEavesReg;
				font-size: $base-font + 3px;
			}
			h1 {
				font-family: $MrEavesReg;
				font-size: 2em;
			}
		}
		
		.payment-form {
			.right-cont {
				.billing-payment-description {
					.heading {
						font-family: $MrEavesReg;
						font-size: $base-font + 5px;
					}
					.brand-cards {
						span {
							font-family: $MrEavesReg;
							font-size: $base-font + 5px;
						}
					}
				}
			}
			
			.card-actions {
				.save-cancel {
					.cancel-button {
						font-size: $base-font;
						font-family: $FuturaBold;
						color: $mainly-blue;
					}
				}
			}
		}
		.payment-list {
			.showall {
				font-size: $base-font - 1px;
			    font-family: $FuturaBold;
			}
			.cardinfo {
				.carddetails {
	    			.plcc-card-message {
	    				font-family: $MrEavesReg;
	    				font-size: $base-font + 4px;
	    				b {
							font-family: $MrEavesBold;
							font-weight: normal;
						}	    				
						.rewards {
							color: $mainly-blue;
						}
	    			}
					.li {
						font-family: $MrEavesReg;
						font-size: $base-font + 4px;
					}
		    		.li.cardtype {
	    				font-family: $MrEavesBold;
						span.img.rm_plcc {
							//background-position: center;
							//background: url(../../../images/roamans-card-icon.svg) no-repeat;
						}
		    		}
		    		.li.delete-card {
		    			font-size: $base-font - 1px;
		 				font-family: $FuturaBold;
		    		}
		    		.li.make-default {
	    				font-family: $FuturaBold;
	    				color:  $mainly-blue;
	    				font-size: $base-font - 1px;
	    			}
				}
			}
		}
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			&.make-label-absolute .form-row {
				&.month,&.year {
					label {
						text-align: left;
						transform: translate(0,7px);
						width: 89%;
						max-width: 80px;
					}
				}
			}
		}
	}
}

// Delete card confirmation dialog
.ui-dialog {
	.delete-creditcard-overlay {
		h1 {
			font-family: $MrEavesReg;
			font-size: $base-font + 13px;
		}
		.waring-msg {
			font-family: $MrEavesReg;
			font-size: $base-font + 4px;
		}

	}
}

.setnewpassword {
	button.btn.btn-primary:not(.pwd-show) {
		height: 48px;
		top: -3px !important;
		border-top-left-radius: 0;
   }
   @media screen and (max-width: 1024px) {
		button.btn.btn-primary:not(.pwd-show) {
			border-top-left-radius:15px;
			left: 0px;
		}
	}
}

.forgot-password {
	.make-label-absolute {
		.field-wrapper {
			input[type="text"] {
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-webkit-appearance: none;
			}
		}
	}
	.wrapper-forgot-password {
		h1 {
			font-family: $MrEavesReg;
			font-size: $base-font + 16px;	
		}
		p {
			font-family: $MrEavesReg;
			font-size: $base-font + 5px;
			line-height: 20px;
			
			@media screen and (max-width: 767px) {
				br {
					display: none;
				}
			}
		}
		.passwordResetConfirmMsg{
			@include WorkSansMedium($WorkSans);
			@media screen and (max-width: 767px) {
				font-size:$base-font;
				line-height:22px;
			}
		}	
	}
	button {
		background: $mainly-blue;
	    border-color: $mainly-blue;
	    font-family: $Futura;
	}
	.close-button {
		font-family: $FuturaBold;
	}
}

.ui-dialog.tooltip-dialog .ui-dialog-content p {
	font-family: $MrEavesReg;
	font-size: 	$base-font	+ 2px;
}

//Email preferences page.

.legacy-sitegen .email-preferences {
	h1 {
		font-family: $MrEavesReg;
	} 

	.user-info {
		span {
			font-family: $MrEavesReg;
			font-size: $base-font + 4px;
		}
	}

	.edit-click {
		font-family: $FuturaBold;
		font-size: $base-font + 2px;
		padding: 0 5px;
	}
	
	.email-thankyou {
		h1 {
			&.offer {
				font-family: $FuturaMedium;
				color: $mainly-blue;
			}			
		}
		.code {
		    .value {
		    	font-family: $FuturaBold;
		    	color: $mainly-blue;
		    }
		}
	}
	
	.email-preference-row {
		.col-2 {
			.email-description {
				.left-text {
					font-family: $MrEavesReg;
					font-size: $base-font + 4px;
				}
			}
			.interest {
				font-family: $MrEavesReg;
				font-size: $base-font + 4px;
			}
			.preference-options {
				.options-checkbox {
					font-family: $MrEavesReg;
					font-size: $base-font + 4px;
					line-height: 20px;
					label {						
						font-size: $base-font + 4px;
						vertical-align: initial;
					}
				}
			}
		}
	}
	.morelink {
		color: #321e53;
	}
}

.custom-slider-checkbox {
	.slider-label {
		font-family: $FuturaBold; 
		text-transform: uppercase;
	}
	input:checked + .slider {
		background-color: $mainly-blue;
	}
}

.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.email-unsubscribe-guest {
		&.make-label-absolute {
			.form-row {
				@media screen and (min-width: 768px) {
					.emailsubscribeguest-unsubscribe {
						border-top-left-radius: 0;
	    				border-bottom-left-radius: 0;
						padding-bottom: 17.5px;
					}
				}	
			}
		}		
	}	
}

.subscribe-emailid {
	.form-row-button {
		button {
		    height: 56px;
		}
	}
	.email-box {
		input {
			font-family: $FuturaMedium;
			border-top-left-radius: 15px;
		}
	}
}

.email-frequency-modal {
	font-family: $MrEavesReg;
	.modal-msg {
		font-size: $base-font +7px;
	}
	.custom-radio-wrapper .label {
		font-size: $base-font + 4px;
	}
	.other-reason-textbox textarea {
		font-family: $MrEavesReg;
		font-size: $base-font + 2px;
	}
	.subscribe-action button {
		font-family: $FuturaMedium;
	}
}


//catalog preference page
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	.catalog-request{
		.catalog-request-header{
			font-family: $MrEavesReg;
			font-size: 28px;
			margin: 32px 0 15px 0;
		}
		.catalog-request-callout{
			font-family: $MrEavesReg;
			font-size: 17px;
			margin-bottom: 18px;
		}
		.address-form-block{
			.make-label-absolute{ 
				&.address-section {
					.catalog-prefernces{
					    padding: 10px 0 26px 11px;
						div{
							&.form-row{
								margin: 0 0 9px 0;
								label{ 
									span{
										&.label-text{
											font-family: $MrEavesReg;
											font-size: 17px;
										}
									}
								}
							} 
						}
					}
				}
				.form-row{
				
					label{
						span{
							font-family: $MrEavesReg;
							font-size: 17px;
						}
						&.input-focus{
							-webkit-transform: translate(0, 3px);
							-ms-transform: translate(0, 3px);
    						transform: translate(0, 3px);
							span{
								font-family: $MrEavesReg;
								font-size: 13px;
							}
						}
					}
				
					&.form-row-button{
						.apply-button{
							font-family: $Futura;
							padding: 12px 10px 13px;
							&:hover{
								background-color: $mainly-blue;
							}
						}
					}
					.selected-option{
						&.selected{
							font-family: $MrEavesReg;
							font-size: 17px;
							text-transform: none;
						}
					}
					input{
						font-family: $MrEavesReg;
						font-size: 17px;
						padding: 25px 14px 5px;
					}
				}
			}
			.catalog-request-step2, .catalog-request-step1{
				font-size: 17px;
				font-family: $MrEavesReg;
			    letter-spacing: 0.8px;
				span{
					font-family: $MrEavesBold;
				}
			}
			.catalog-request-step2{
				padding-bottom: 16px;
	    		line-height: 21px;
	    		width: 84%;
	    	}
		}
	}
	.catalog-registered{
		#secondary{ 
			.secondary-navigation{
				.navigation-links-row{
					li{
						&.catalog-preferences{
							a{
								color: $minsk;
								font-family: $FuturaHeavy;
							}
						}
					}
				}	
			}
		}
		
	}
}

// Address
.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
	&.addresses{
		.addressinfo{
			margin-top: 3.5%;
		}
	}
	
	.internation-shipment-method {
		font-size: $base-font + 4px;
		font-family: $MrEavesReg;
		background: $light-black-two;
		color: $white;
		text-align: center;
		line-height: 18px;
    	padding: 6px 0 8px;
		width: 100%;
		display: inline-block;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
 	 	-moz-box-sizing: border-box;
		
		@media screen and (max-width: 767px) {
		    text-align: left;
		    padding: 6px 9px 8px;
		    
		    .shipping-method {
		    	margin-right: 15px;
		    }
		}
	}
}

//Login Order History Page
.create-login {    
    &.login-order-history {    	
    	.login-section, .check-order {
    		background: $wild-sand;
    	}
    }
}

@media screen and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		&.email-subscription {
			.primary-content {
				padding: 0 0; 
			}
		}
		.profile-info {
			button {
				font-size: $base-font + 1px;
			}
		}
		&.profile {
		   .profile-info {
			   .form-row-button {
					padding: 0;
				}
			   .inner-content {
				   padding: 0 3.4% 5% 3.4%;
			   }
			   .custom-select {
			   		.form-row {
						width: 100%;   		
			   		}
			   	}
		    }
	    }
	}
}

@media screen and (max-width: 1024px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		.catalog-request{ 
			.address-form-block{ 
				.make-label-absolute{ 
					.field-wrapper{ 
						select{
							padding-bottom: 10px;
						}
					}
				}
			}
		}
		.profile-info {			//chnages
			button {
				padding: 12.5px 37px;	
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		.catalog-request{ 
			.catalog-request-header{
				margin: 23px 0 15px 0;
			}
			.address-form-block{ 
				padding: 36px 8px 45px 55px;
				 .make-label-absolute{ 
				 	 .form-row{
				 	 	width: 49%;
				 	 	padding: 0px 5.3% 0 0;
				 	 	&.postal{
				 	 		padding: 0px 3% 0 0;
				 	 	}
				 	 	&.state{
				 	 		width: 25.6%;
							padding: 0 5.4% 0 0;
				 	 	}
				 	 }
				 	.catalog-prefernces{
				 		padding: 11px 0 25px 11px;
				 		div{
				 			&.form-row{
				 				margin: 0 0 7px 0;
				 			}
				 		}
				 	}
				 }
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.legacy-sitegen .pt_account, .legacy-sitegen.pt_account {
		&.profile {
			.profile-info {
				.form-row {
					width: 100%;
					&.password {
						.field-wrapper {
							margin-bottom: 6px;
						}
					}
				}
				legend {
					padding: 20px 0 20px 0;
				}	
			}
		}
		.catalog-request{ 
			.catalog-request-header{
				margin: 0 0 9px 0;
			}
			.catalog-request-callout{
				line-height: 22px;
				margin-bottom: 12px;
			}
			.address-form-block{ 
				padding: 9px 10px 25px 10px;
				.catalog-request-step2{
					width: 100%;
				}
				.make-label-absolute{ 
					.catalog-prefernces{
						padding: 26px 0 10px 0px;
						div{
							&.form-row{
								margin: 0 0 22px 0;
							}
						}
					}
					.field-wrapper{ 
						select{
							padding: 14px 15px;
						}
					}
				}
			}
		}
	}
}
.legacy-sitegen .pt_account .address-form-block .make-label-absolute .form-row.makeDefault label span{
	top:0px;
}