$fatReg: 3px;
$hoverColor:#331e53;

#category-level-1 {
	li {
		.refinement-link {
			font-family: $MrEavesReg;
			font-size: $base-font + 2px;
			&.active {				
				font-family: $MrEavesBold;
				color: $hoverColor;
			}
		}
	}
	
	> li{
		> .refinement-link {
			font-family: $HelveticaBold;
			font-size: $base-font + 2px;
		}
	}
}

#category-level-2 {
	
	> li{
		&.expandable {
			font-family: $MrEavesBold;
			font-weight: bold;
			color: $hoverColor;
				.cr-section {
					a.refinement-link.active {
						font-family: $MrEavesHeavy;
					}
				}
		}
	}

	.currCategory .cr-section a.active{
		font-family: $MrEavesBold !important;
		color: $hoverColor !important;
	}
}

.category-refinement {
	#category-level-1 {
		li {
			&.expandable {
				
				&.non-active {
					.cr-section {
						font-family: $MrEavesBold;

						a {
							font-family: $FuturaBold;
						}
					}
				}
				
				&.active {		
					.cr-section-pl {
						.cr-section {							
							a {
								font-family: $FuturaBold;
							}							
						}
					}
				}
			}
			a {
				font-size: $base-font + 2px;
			}
			.cr-section {
				
				a {
					&.active {
						font-family: $FuturaBold;
						font-size: $base-font + 2px - $fatReg;
					}
					color:$grape;
				}
			}
		}

		#category-level-2 > li {
			&.expandable{
				.cr-section a{
						color:$black;
						font-family: $MrEavesReg;
						font-size: $base-font + 2px;
					&.active{
						font-size: $base-font + 2px;
					}
					&:hover{
						color:$hoverColor;
					}
				}
				#category-level-3 li{
					a{
						&.active{
							font-size: $base-font + 2px;
						}
						&:hover{
							color: $hoverColor;	
						}
					}
					
				}
				&.not-bold {
					>.cr-section >a:hover{
						color:$hoverColor;
					}
				}
				&.bold-cat {
					padding: 30px 0 0;
					&:first-child {
						padding-top: 0; 
					}
					>.cr-section {
						padding-bottom: 5px;
						>a{
							font-family: $FuturaBold;
							font-size: 12px;
						}
					}
					#category-level-3 {
						li{
							padding: 15px 0;
							&.active {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
.category-refinement {
	#category-level-1 {
		#category-level-2 > li.expandable{
			#category-level-3 > li{
				&:first-child {
					padding:15px 0;
				} 
				padding:30px 0 0;
				a{
					&:hover{
						font-weight: bold;
						
					}
				}
				#category-level-4 {
					>li{
						&:first-child{
							padding-top: 30px;
						}
					}
					a.refinement-link{
						margin-left: 30px;

					}
				}
			}
		}
	}
}

.bold-cat #category-level-3 {
	padding: 15px 0 !important;
}