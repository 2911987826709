.search-suggestion-wrapper {
	z-index: 20;
	padding: 0;
  
    .suggested-phrase {
    	display: none;
    }
        
	@media screen and (min-width: 768px) and (max-width: 1023px) {
	    .search-scrollbar-height {
			//padding: 15px 10px;
	    }
	    
		&.animate {
			.product-suggestions {
				padding-left: 30px;
			}
		}
		
		.slimScrollBar, .slimScrollRail {
			right: 4px !important;
		}
	}
	
	@media screen and (min-width: 1024px) {		
		&.animate {
			.product-suggestions {
				padding-left: 30px;
			}
		}
		
		.slimScrollBar, .slimScrollRail {
		    right: 4px !important;
			width: 5px !important;
		}
	}


	.hitgroup {
	
		.hit {
			font-size: $base-font + 2px;
			margin-left: 1rem;
			font-family: $MrEavesReg;
			.searched-key {
				font-family: $MrEavesBold;
				color: $minsk;
			}
		}
	
		.parent-category {
			font-family: $MrEavesReg;
		}
	}
}

.product-suggestion {
	//padding: 12px;taken from common
	
	.product-image {
		width: 25%;
	
	}
	
	.product-details {
		//padding-top: .5em;moved to common
		
		.product-name {
			font-size: $base-font + 6px;
		    font-family: $MrEavesReg;
		}

		.product-price {
		    font-family: $MrEavesReg;
		    font-size: $base-font + 4px;
		}
	}
}

.search-phrase {
    font-family: $MrEavesReg;
	
	a {
		span {
			font-family: $MrEavesBold;
		}
	}
	
	.completed {
		.original {
			color: $minsk;
			font-family: $MrEavesBold;
		}
	}
}
