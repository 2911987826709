$sans-serif: "Inter Tight", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$serif: TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$Helvetica: $sans-serif;
$HelveticaThin: $sans-serif;
$HelveticaMedium: $sans-serif;
$HelveticaBold: $sans-serif;
$HelveticaHeavy: $sans-serif;
$Futura: "Jost", $sans-serif;
$FuturaMedium: "Jost", $sans-serif;
$FuturaHeavy: "Jost", $sans-serif;
$FuturaBold: "Jost", $sans-serif;
$FuturaHeavy: "Jost", $sans-serif;
$MrEavesBook: $sans-serif;
$MrEavesReg: $sans-serif;
$MrEavesBold: $sans-serif;
$MrEavesHeavy: $sans-serif;
$sans-serif-alt: $sans-serif;
$ImperialRegular: $serif;
$ImperialMedium: $serif;
$BauerBodoniRegular: $sans-serif;
$FuturaLight: "Jost", $sans-serif;
$DidotItalic: $serif;