.gift-landing {
     
    .more-gift-cards {
    	margin: 0 0 50px 0 !important;
    }
    
    .main-slot, .gift-slot-1, .more-gift-cards {
	    
	    .terms {
	    	font-size: $base-font + 8px;
	    	font-family: $MrEavesReg;
	    	margin: 0;
	    	width: 100%;
			float: left;
			position: absolute;
			bottom: 30px;
			text-align: center;
			
			a {
				margin: 0 5px 0 0;
				border-bottom: 1px solid $black;
			}
	    }
	    
	    .button-gift {
	   		width: auto;
   	 		display: inline-block;
	    }
	    
	    .content {		    
		    .valign_height {
			    position: relative;
			    
			    .e-gift, .gift-card {					
					h2 {
						font-size: $base-font + 48px;
						font-family: $MrEavesBold;
					}
					
					button {
						font-size: $base-font + 3px;
					    padding: 14px 23.5px;
					    font-family: $Futura;
					}
			    }
			    
			    .description {
    				font-size: $base-font + 33px;
			    	font-family: $MrEavesBold;
			    	margin: 0 0 30px 0;
			    	text-transform: uppercase;
    				letter-spacing: 10px;
    				color: $white;
    				max-width: 440px;
    			}
    			
    			.js-gift-card-balance {
					margin: 20px;
					font-size: $base-font + 3px;
				    letter-spacing: 2px;
				    padding: 14px 31px;
				    font-family: $Futura;
					color: $white;
				}
		    }
	    }
    }
    
    .white-background {
    	
    	.more-gifts {
    		font-size: $base-font + 33px;
	    	font-family: $MrEavesBold;
	    	margin: 10px 0;
	    	text-transform: uppercase;
			letter-spacing: 10px;
    	}
    	
    	.shop-gift {
    		font-size: $base-font + 8px;
	    	font-family: $MrEavesReg;
	    	margin: 0 0 30px 0;
	    	text-transform: initial;
	    	letter-spacing: 1px;
    	}
    	
    	.cards {   
    		button {
			   	font-family: $Futura;
    		}
    	}
    }
}

@media screen and (max-width: 1023px) {
	.pt_content {
		.primary-content {
			padding: 0;
		} 
	}
	
	.gift-landing {
	    .main-slot, .gift-slot-1, .more-gift-cards {
		    .content {
			    left: 50%;
			    width: 100%;
			    
			    .valign_height {
			    	max-width: 470px;
	    			float: none;
				    
				    .e-gift, .gift-card {					
						h2 {
							font-size: $base-font + 40px;
							margin: 0 0 60px 0;
						}
						
						button {
							margin: 0 20px 0 0;
						}
				    }
			    }
		    }
	    }
	}
}	

@media screen and (max-width: 480px) {
	.gift-landing {
	    .main-slot, .gift-slot-1, .more-gift-cards {
		    .terms {
		    	bottom: 30px;
	    		font-size: $base-font + 3px;
		    }
		    
		    .button-gift {
		   		width: 100%;
		    }
		    
		    .content {
		    	left: 50%;
		    	
			    .valign_height {
				    .e-gift, .gift-card {					
						h2 {
							font-size: $base-font + 15px;
							margin: 0 0 20px 0;
							letter-spacing: 5px;
						}
						
						button {
						    margin: 20px 0 0 0;
	    					display: inline-block;
	    					font-size: $base-font + 2px;
	    					
	    					&.gift {
	    						padding: 14px 30px;
	    					}
						}
				    }
				    
				    .description {
    					font-size: $base-font + 15px;
    					margin: 0;
    					letter-spacing: 5px;
	    			}
			    }
		    }
	    }
	    
	    .gift-slot-1, .main-slot {
    		.content {
    			.valign_height {
    				.description {
    					max-width: 270px;
						margin: 0 auto;
    				}
    				
    				.e-gift {
    					h2 {
    						max-width: 270px;
							margin: 0 auto;
    					}
    				}				
    			}			
    		}	
    	}
	    
	    .white-background {
	    	.more-gifts {
				font-size: $base-font + 18px;
				letter-spacing: 5px;
	    	}
	    	
	    	.shop-gift {
	    		font-size: $base-font + 3px;
	    	}
	    }
	}
}

.gift-landing{
	.white-background {
		.cards {			
			button {
				@media screen and (max-width: 480px) {
					padding: 10px 15px;
				}								
			}
		}
	}
}

.ui-dialog {
	&.gc-checkbalance {
	    
	    
	    
	    .gc-balance {
	    	font-family: $HelveticaMedium;
	    	
	    	.form-row {
	    		
	    		&.form-row-pincode {
	    			.form-row {
					    &.form-row-button {
					    	
					    	button {
					    		background: $grape;
					    		border-color: $grape;
					    		padding-top: 14px;
					    		padding-bottom: 14px;
					    		font-family: $ImperialMedium;
					    	}
					    }
	    			}
	    			
	    		}
	    	}
	    	
	    	.gc-show-message {
	    		font-family: $HelveticaBold;
	    		
	    		.heading {
	    			font-family: $Helvetica;
	    		}
	    	}
	    }
	}
}