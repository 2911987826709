.pt_account {
}
.email-trouble-message {
	font-family: $FuturaBold;
	font-size: $base-font + 1px;
	text-transform: uppercase;
	background: $lightOrange;
}
.account-overview-heading {
	font-family: 'mreavessanaltr';
	font-size: 28px;
	
	@media screen and (max-width: 767px) {
		margin-top: 20px;
	}
}
.plcc-rewards-msg {
	font-family: 'mreavessanaltb';
	font-size: 	18px;
	line-height: 22px;
}
.plcc-links {
	a {
		color: $grape;
		font-family: $FuturaBold;
		font-size: $base-font + 1px;
		text-transform: uppercase;
		letter-spacing: 0.5px;
		
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			font-size: 8pt;
		}
		
	}
}
.plcc-ov-left-section {
	.plcc-top-content {
		background: #ffffff;
	}
}
//My Account Overview

#secondary {
	.secondary-navigation {
		margin: 20px auto 37px;
		
	    .main {
	    	margin: 7px 0;
	    	font-size: $base-font + 28px;
	    	font-family: $MrEavesBook;
	    	letter-spacing: 4px;
	    	color: $mainly-blue;
	    }
	    
	    .navigation-links-row {
    		li {
				margin: 20px 34px 0;
				letter-spacing: 0.8px;
				
				&.navigation-links-row-inner {
					li {
						margin: 31px 34px 0;
						word-spacing: 3px;
					}
				}
				
				a {
					font-size: $base-font + 2px;
					font-family: $Futura;
					letter-spacing: 1px;
				}
    		}
    	}
	}
}
#wrapper {
	@each $pagename in $pagenames {
		&.#{nth($pagename, 1)} {
			#secondary {
				.secondary-navigation {
					.secondary-navigation-links {
						li {
							&.#{nth($pagename, 2)} {
								a {
									color: $minsk;
									font-family: $FuturaHeavy;
								}
							}
						}
					}
				}
			}	
		}	
	}
}
@media screen and (max-width: 1023px) {
	#secondary {
		.secondary-navigation {
			.secondary-navigation-links{
				.navigation-links-row {
		    		li {
						&.navigation-links-row-inner {
							li {
								margin: 24px 15px 0;
								word-spacing: 3px;
							}
						}
		    		}
		    	}
		    }
	    }	
	}
}

@media screen and (max-width: 767px) {
	#secondary {
		&.nav {
			background: $light-skygrey;
		}
		
		nav {
			margin: 0 10px;
		}
		
		.secondary-navigation {
			margin: 20px auto;
		    
		    .secondary-navigation-links {
 	 			border: 2px solid $grape;
			    border-top: 0;
			    background: $white;
			    .navigation-links-row {
					li {
						&.navigation-links-row-inner { 
							li {
								margin: 25px 0 0 0;
							}
						}
					}
				}
		    }
		    
		    .navigation-links-row {
				li {
					
					&.navigation-links-row-inner {
						margin: 0;
						
						li {
							margin: 25px 0 0 0;
							padding: 0;
						}
					}
				}
	    	}
		    
		    .heading {
			    background: $white;
			    font-size: $base-font + 4px;
    			font-family: $Futura;
    			color: $grape;
    			letter-spacing: 1px;
    			border: 2px solid $grape;
    			
    			&.value-updated {
    				padding: 20px;
    			}
		    }
		}
	}
}