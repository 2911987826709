.legacy-sitegen.ui-dialog {
	&.bonus-product-quickview {
		&.ui-draggable {	
			.promotion {
				@media screen and (max-width: 767px) {
					font-size: $base-font - 2px;
				}
				
				b {
				    font-size: $base-font;
				    
				    @media screen and (max-width: 767px) {
						font-size: $base-font - 2px;
					}
				}
				
				a {
					font-size: $base-font - 1px;
				}
			}

			.bonus-product-list {
				.product-col-2 {
					.morelink {
						font-size: $base-font - 1px;
					}
				}
			}
			
			.product-name {
				font-size:  $base-font + 12px;
				font-family: $MrEavesReg;
			}
			
			.product-variations {
				.attribute {
					.attribute_label {
					    .selected-value {
						    height: 14px;
					    }
					}
					
					.errormsg {
						color: $guardsman-Red;
						
						&.selecting {
							color: $guardsman-Red;
						}
					}
					
					.value {
						.selecting {
							background-position: 93% 43%;
	    					&.active {
								background-position: 93% 45%;
		    					background-color: $dim-gray;
	    					}
	    					
	    					.swatch-image {
							    min-width: 43px;
							    border-radius: 30px;
	    					}
	    					
	    					span {
	    						width: 48%;
							    padding: 0 0 0 8px;
	    					}
						}
						
						.swatches {
	    					li {
	    						&:hover {
	    							background-color: $dim-gray;
	    						}
	    						
	    						a {
								    height: 31px;
								    
								    .swatch-image {
									    height: 23px;
								    }
								    span {
									    width: 59%;
								    }
	    						}
	    						
	    						&.selected {
	    							background-color: $dim-gray;
	    						}
	    					}
						}
					}
					.size-chart-link {
					    font-size: $base-font - 1px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.legacy-sitegen.ui-dialog {
		&.bonus-product-quickview {
			&.ui-draggable {
				.bonus-product-list { 
					.product-name {
						font-size: $base-font + 20px;
						margin: 0 0 10px 0;
					}
					
				}
				.product-variations {
					.attribute {
						.value {
							width: 100%;
							.swatches {
								&.color {
									li {
										a {
											width: 100%;
										}
									}
								}
							}
						}
					}
				}
				.bonus-product-list-footer {
					width: 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.legacy-sitegen.ui-dialog {
		&.bonus-product-quickview {
			&.ui-draggable {
				.bonus-product-list {
					.product-name {
						font-size: $base-font + 4px;
					}
				}
				.bonus-product-list-footer {
					width: 100%;
					padding: 20px 0 0 0;
				}
			}
		}
	}	
}

.bonusheading {
	font-size: $base-font + 2px;
	
	@media screen and (max-width: 767px) {
		font-size: $base-font - 2px;
	}
}

.promotion {
	font-size: $base-font;
}
.bonus-product-list {
	.bonus-product-item {
		
		.price {
			font-size: $base-font;
			padding: 0 0 15px 0;
		}
	}
	.bonus-product-list-footer {
		.add-to-cart-bonus {
		    background: $grape;
		}
	}
}

.pt_cart {
	#cart-table {
		&.item-list {			
			.cart-promo {
				background-color: $grape;
			    color: $white;
			    border-bottom: none;
			    font-size: $base-font - 1px;
			    
			    .bonus-item-details {
			    	font-size: $base-font + 3px;
			    	font-family: $MrEavesReg;
			    	text-transform:none;
			    	@media screen and (max-width: 767px) {
			    		font-size: $base-font + 1px;
			    	}
			    }
			    
			    .bonus-item-actions {
			    }
			}
		}
	}
}
.bonus-product-list {
	.bonus-product-item {
		@extend %clearfix;
		border-bottom: 1px solid $gainsboro;
		.product-col-1,
		.product-col-2 {
			@media screen and (max-width: 768px) {
				float: left;
				clear: none;
			}
		}
	}
	.product-add-to-cart {
		padding-bottom: 0;
		.inventory {
			width: auto;
		}
	}
	.product-name {
		float: none;
		margin: 1em 0;
	}
	.quantity-error {
		color: red;
		text-transform: inherit;
	}
	.bonus-product-list-footer {
		@extend %clearfix;
		padding-top: 1em;
		padding-bottom: 1em;
		.add-to-cart-bonus {
			float: right;
		}
	}
	.selected-bonus-item {
		margin: 1em 0 1em 1em;
		position: relative;
		.item-name {
			font-weight: bold;
		}
		.remove-link {
			cursor: pointer;
			color: $sangria;
			left: -1em;
			position: absolute;
			&:hover {
				color: $charcoal;
			}
		}
	}
}

.bonus-discount-container {
	.promo-details {
		display: none;
		&.visible {
			display: block;
		}
	}
}
