.error-page {
    padding: 50px 25px;
    background-color: $white-smoke;
    p {
        font-family: $MrEavesBold;        
    }
}

.pt_error {
	.trending-section {		
		.recommendation-heading {
			span {			
				&.trending {
					font-family: $MrEavesReg;
					font-size: $base-font + 28px;
					text-transform: uppercase;
					margin: 0 0 40px 0;
					
					@media screen and (max-width: 767px) {
						font-size: $base-font + 12px;
					}
				}
			}
		}
	}
}