.catalog-quick-order {
	.pdpForm {
		.form-row {
			label {
				letter-spacing: .05em;
				span {
					&.option-text {
						font-family: $FuturaMedium;
					}
				}
			}
		}
	}

	.pdp-main {
		.product-price {
			span {
				font-size: $base-font + 6px;
			}

			.price-standard {
				padding: 0 35px 0 0;

				@media screen and (max-width: 767px) {
					padding: 0 19px 0 0;
				}
			}

			.price-standard-exist {
				color: $reddish-monza;
			}
		}

		.savingmessage {
			color: $reddish-monza;
			margin: 0 0 13px 0;

			@media screen and (max-width: 1023px) {
				margin: 0 0 18px 0;
			}
		}

		.promotion {
			color: $reddish-monza;
			margin: 0 0 17px 0;

			@media screen and (max-width: 767px) {
				margin: 0 0 23px 0;
			}
		}

		.product-variations {
			.attribute {
				.attribute_label {
					margin: 19px 0 14px 0;

					@media screen and (max-width: 1023px) {
						margin: 20px 0 15px 0;
					}

					@media screen and (max-width: 767px) {
						font-size: $base-font + 2px;
						margin-bottom:10px;
					}

					.label {
						letter-spacing: 0.5px;
					}
				}
			}
		}

		.heading {
		    font-family: $FuturaHeavy;
		    font-size: $base-font;

		    @media screen and (min-width: 640px) and (max-width: 767px) {
				font-size: $base-font + 14px;
			}

			@media screen and (max-width: 639px) {
				font-size: $base-font + 1px;
			}
		}
		.product-col-2 .tab-content , .product-col-2 .shippingdrawer {
			font-family: $Futura;
			font-size: $base-font;
			font-weight: normal;
			font-style: normal;
			letter-spacing: 0.2px;

			@media screen and (min-width: 640px) and (max-width: 767px) {
				font-size: $base-font + 14px;
			}

			@media screen and (max-width: 639px) {
				font-size: $base-font + 1px;
			}
		}

		.clickhere, .read-more {
			color: $dim-gray;
			letter-spacing: normal;

			@media screen and (min-width: 640px) and (max-width: 767px) {
				font-size: $base-font + 14px;
			}

			@media screen and (max-width: 639px) {
				font-size: $base-font + 1px;
			}
		}
	}

	.catalog-quick-order__main {
		.product-name {
			.name-text {
				padding: 0 0 20px 0;

				@media screen and (max-width: 767px) {
					padding: 0 0 7px 0;
					font-size: $base-font + 12px;
				}
			}
		}

		.pdp-main {
			.product-price {
		    	margin: 0 0 17px 0;
		    }

		    .product-variations {
		    	.attribute {
		    		border-top: 1px solid $gainsboro;
		    		padding: 0 0 18px 0;

		    		@media screen and (max-width: 1023px) {
		    			padding: 0 0 16px 0;
		    		}

		    		@media screen and (max-width: 767px) {
		    			padding: 0 0 13px 0;
		    		}
		    	}
		    }

		    @media screen and (max-width: 767px) {
			    .expired-price {
					margin: 0 0 13px 0;
				}
			}
		}

		.product-add-to-cart {
			margin: 20px 0 25px 0;
		}
	}

	.catalog-item-number {
		margin: 10px 0 18px 0;

		@media screen and (max-width: 767px) {
			margin-top: 0;
		}
	}

	.product-content-quickorder {
		.product-variations-toggle {
			margin: 0 0 18px 0;	
			
			@media screen and (max-width: 767px) {
				margin: 0 -10px;
				width:100%;
			}
		}
	}
}