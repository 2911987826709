.pt_storefront {
	#main {
		h1 {
			font-size: 60px;
			margin: 0 0;
		}
		h2:not(.recommendation-heading) {
			font-size: 50px;
		}
		h3 {
			font-size: 40px;
			margin: 0 0;
			letter-spacing: 1px;
		}
		h4 {
			font-family: $FuturaMedium;
		}

		h1,h2,h3 {
			font-family: $FuturaMedium;
			color: $black;
			letter-spacing: 2px;
		}

		button {
			color: $white;
			font-family: $FuturaMedium;
			border-color: $white;

			&:hover {
				font-weight: bold;
			}
		}
		.text-parent {
			.text-content {
				.promo-red {
					background: $guardsman-Red;
				    font-family: $FuturaBold;
				}
			}
		}
		.banner {
			&.text-parent {
				.wrap-center {
					.text-content {
						span {
							font-family: $FuturaMedium;
						}
					}
				}
			}
		}

		.tall-small-wrapper {
			margin: 20px 0px 20px;
			.tall {
				&.text-parent {
					.wrap-center {
						top: 43%;
					}
				}
			}
			.small {
				.small-top {
					&.text-parent {
						.wrap-center {
							top: 52%;
						}
					}
				}
				.small-bottom {
					margin-top: 20px;

					&.text-parent {
						.wrap-center {
							top: 57.5%;
						}
					}
				}
			}
		}

		.promo-wide {
			background-color: $guardsman-Red;
		    padding: 35px 50px 20px 65px;
		    margin: 10px 0;
			h1 {
				color: $white;
				font-size: 70px;
				font-family: $FuturaMedium;
			}
			h1 {
				&.plus-sign {
					padding: 0 40px;
					float: left;
				}
			}
			.cc-code {
				margin-top: 3%;
				.code {
					font-family: $FuturaBold;
				    padding: 11.5px 50px;
				}
			}
		}


		// home-asset2 styles
		.heritage-collection {
			.heritagetile-wrapper {
			    .heritage-tile {

					h4 {
						margin-top: 17px;
					}
				}
		   	}
		}

		//ipad landscape and below
		@media screen and (max-width: 1024px) {
			.promo-wide {
				.cc-code {
					.code {
						font-size: $base-font + 6px;
					}
				}
			}
		}
		//iPad and below
		@media screen and (max-width:1023px){
			.tall-small-wrapper {
				margin: 20px 10px 20px;
				width: auto;
				.tall {
					margin: 0 10px 0 0;
					width: 48.6%;

					&.text-parent {
						.wrap-center {
							.text-content {
								h1 {
									font-size: 50px;
								}
							}
						}
					}
				}
				.small {
					margin: 0 0 10px 0;
					width: 48.6%;
					.small-top {
						margin-bottom: 10px;
					}
					.small-bottom {
						margin-top: 10px;
					}
				}
			}
			h3 {
				font-size: 35px;
			}
			h4 {
				font-size: 20px;
			}
			 .promo-wide {
			 	margin: 10px;
  				width: auto;
			 	.off-wrapper {
		 			display: inline-block;
		 		}
			 	.cc-code {
			 		width: 100%;
			 		.code {
			 			display: inline-block;
			 		}
			 		.condition {
			 			margin-top: 5px;
			 			text-align: center;
			 		}
			 	}
			 }

			.heritage-collection {
				h3 {
					font-size: 40px;
				}
			}
		}

		// only iPad
		@media screen and (min-width:768px) and (max-width:1023px) {
			button {
				padding: 7px 40px;
			}
			.tall-small-wrapper {
				.small {
					margin: 0 0 0;
					.small-top {
						&.text-parent {
							.wrap-center {
								top: 40%;
							}
						}
					}
					.small-bottom {
						&.text-parent {
							.wrap-center {
								top: 53.5%;
							}
						}
					}
				}
			}
		}

		@media screen and (max-width:767px){
			.banner {
				&.text-parent {
					.wrap-center {
						position: static;
    					width: 100%;
    					margin-top: 20px;
					}
				}
			}
			.tall-small-wrapper {
				margin: 20px 20px;
				.tall {
					width: 100%;
    				max-width: 100%;
    				float: none;
				}
				.small {
					width: 100%;
    				max-width: 100%;
    				float: none;
    				margin: 20px 0 0;
				}
			}

			.promo-wide {
				margin: 20px;
				.off {
					float: none;
    				width: 100%;
				}
				h1 {
					&.plus-sign {
						float: none;
					}
				}
				.cc-code {
					margin-top: 7%;
				}
			}

			.heritage-collection {
				margin: 20px 0 0;

				h3 {
					color: $black;
				}

				.heritagetile-wrapper {
				    .heritage-tile {
				    	width: 100%;
    					margin: 30px auto 50px;
    					float: none;
    					text-align: center;

						h4 {
							letter-spacing: 3px;
						}

    					&.last {
    						margin-right: auto;
    					}

					}
			   	}
			   	.gray-div {
			   		display: none;
			   	}
			}

		}
		@media only screen and ( max-width:480px ) {
			h1 {
				font-size: 30px;
			}
			h2 {

			}
			h3 {
				font-size: 28px;
			}
			.text-parent {
				.text-content {
					.promo-red {
					    font-size: 14px;
					}
				}
			}
			.banner {
				&.text-parent {
					.wrap-center {
						.text-content {
							span {
								font-size: 20px;
							}
							button {
								margin-top: 15px;
							}
						}
					}
				}
			}
			.tall-small-wrapper {
				margin: 10px;
				.tall {
					&.text-parent {
						.wrap-center {
							.text-content {
								h1 {
									font-size: 30px;
								}
								span {
									font-size: 17px;
								}
								button {
									margin-top: 20px;
								}
								.promo-red {
						  			font-size: 15px;
								}
							}
						}
					}
				}
			}

			.promo-wide {
				margin: 10px;
				.cc-code {
					.code {
						font-size: 16px;
					}
				}
			}
			.heritage-collection {
				h3 {
				    padding: 10px 0;
				}
			}
		}
	}
}

//Trending Now
.div-trending-section {
	.recommendation-heading {
		span {
			font-size: $base-font + 42px;
			font-family: $FuturaMedium;

			@media screen and (max-width: 767px) {
				font-size: $base-font + 20.5px;
			}

			&.trending {
				width: auto;
				display: inline-block;
			}

			&.week {
				width: auto;
				display: inline-block;
			}
		}
	}

	.carousel-recommendations {
		.product-tile {
			&:hover {
				.quickview {
					display: block;
				}
			}
		}

		button {
			&.slick-arrow {
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
			}

			&.slick-prev {
				background: url(../../../images/trending-arrows-left.svg) no-repeat;

			    &.slick-disabled {
					background: url(../../../images/recently-viewed-arrows-left-unactivated.svg) no-repeat;
				}
			}

			&.slick-next {
				background: url(../../../images/trending-arrows-right.svg) no-repeat;

				&.slick-disabled {
					background: url(../../../images/recently-viewed-arrows-right-unactivated.svg) no-repeat;
				}
			}
		}
	}
}
