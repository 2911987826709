@import "nav";
@import "menu_utility";
@import "country_selector";

//Checkout Header Sizing
.pt_checkout {
    #header {
        .primary-logo {
            a {
                width: 147px;
            }
        }
    }
}

.menu-toggle{
	span{
		background: #261c51;
	}
}

@media screen and (min-width: 768px) {
    .pt_checkout {
        #header { 
            .primary-logo {
                width: 147px;
            }
        }
        
    }
}
@media screen and (min-width: 1024px) {
	.pt_checkout {
		#header {
			.header-mian-banner {
				.primary-logo {
					width: 226px;
				}

			}

			.primary-logo {
				a {
					width: 226px;
					display: block;
				}
			}
		}
	}
}
//SC-3513
@media screen and (min-width: 1024px) {
	.menu-category {
		/*&.new-menu-l1{
			a {
				&:hover{
					color:$mainly-blue;
				}
			}
			.hoverIntent { 
				.has-sub-menu{
					&:after{ 
						background: $mainly-blue;
						border-top:3px solid $mainly-blue;
					}
				} 
			}
		}*/
		.hoverIntent {
			.level-2 {
				&.new-mega-menu-L2{
            		background:$white; 
            		border-bottom:4px solid $mainly-blue;	
            	}
				
				.sub-cat-wrapper {				
					&.new-mega-menu{	
						//	border-top: 1px solid $mainly-blue;
						.nonBoldCat{ 
							a {
								color: $black;
								&:hover{
									color: $mainly-blue;
								}
							}
						}
						.boldCat{ 
							a {
								color: $mainly-blue;
							}
						}
					}
				}
			}
		}
	}
}