// Store inventory
.availability-web {
    padding: 2px 0 10px;
	font-size: 14px;
    font-family: $MrEavesBold;    
	
	b {
		font-family: $FuturaMedium;
        font-size: $base-font - 1px;
	}
    .availability-novariation {
        font-family: $MrEavesReg;
    }
}