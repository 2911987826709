.custom-select {
	.selected-option {
		font-family: $MrEavesReg;
    	font-size: $base-font + 4px;
	    border-color: $dim-gray;
	}
	.selection-list {
	 	li {
	 	
	 		&:hover, &.hover{
               background: $dim-gray; 
         	}
	 	}	
	}
	&.current_item {
		.selected-option {
			&.selected {
				
			}
		}
		.selection-list {
			border-color:#aaaaaa;
		}
	}
}
#QuickViewDialog {
	.pdp-main {
		.custom-select {
			.selection-list {
	 			li {
	 				font-family: $Futura;
	 			}
	 		}
		}
	}
}

.pt_account {
	.form-row {
		label {
			+ .field-wrapper {
				.custom-select {
					.selected-option {
						font-family: $MrEavesReg;
						font-size: $base-font + 5px;
						color: #010101;
					}
				}			
			}
		}
	}
}
.pt_account {
	.form-row {
		.field-wrapper {
			.selection-list {
				li {
					font-family: $MrEavesReg;
					font-size: $base-font + 3px;
				}
			}
		}
	}
}

.mm-prod-details {
	.custom-select {
		.selected-option {
			font-family: $FuturaBold;
		}
	}
}

.pt_product-details {
	.custom-select {
		.selected-option{
			&:before {
				
			}
		}
		&.current_item {
			.selected-option {
				&:before {
					
				}
			}
		}
		@media screen and (max-width: 1024px) {
			&:after {}
		}
	}
	.inventory .quantity {
		.selected-option {
			font-family: $FuturaMedium;
			font-size: 15px;
		}
	}
}


@media screen and (max-width: 1024px) {
	.pt_product-search-result {
		.refinements {
			.sort-by {
				&.custom-select .selected-option {
					&:before {
					}
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_product-search-result {
		.refinements {
			.sort-by {
				&.custom-select .selected-option {
					&:before {
						top: 18%;
					    right: 0px;
					    width: 15px;
					    height: 8px;
					}
				}
				&.custom-select.current_item .selected-option {
					&:before {
						
					}
				}
			}
		}
	}
}

