.pt_product-details #main {
	.tabs-set-prod {
		.review-main-section {
			#TTreviews {
				.TTreview {
					border-top: 10px solid $wild-sand;
				}
			}
		}
	}	
}
