.pt_account {
	.inner-block {
		&.check-order {
			h2 {
				font-size: $base-font + 16px;
				font-family: $MrEavesReg;
			}
		}
	}
	.col-2  {
		h2 {
			font-size: $base-font + 5px;
			font-family: $MrEavesReg;	
		}
	}
	.make-label-absolute {
		.field-wrapper {
			button {
				&.pwd-show {
					font-family: $FuturaBold;
				}
			}
		}
	}
	.create-login {
		.form-caption {
			font-size: $base-font + 3px;
			font-family: $MrEavesReg;
		}
		.inner-block {
			.login-box {
				.error-form {
					border-radius: 0;
					font-size: $base-font + 5px;
					font-family: $MrEavesReg;
					padding: 10px;
    				line-height: 16px;
    				color: $guardsman-Red;
				}
				&.login-create-account {
					@media screen and (max-width: 767px) {
						-webkit-box-shadow: none;
					    -moz-box-shadow: none;
					   	box-shadow: none;
					   	border: 1px solid $cod-gray;
					}
					
					.view-more {
						font-family: $Futura;		
					}
				}
			}
		}
		.login-create-account {
			.or-section {
				.or-link {
					font-family: $Futura;		
				}
			}
		}
	}
	.make-label-absolute {
		.form-row {
			&.password {
				&.error-handle {
					.form-caption {
						color: $white;
					}
				}
			}
		}
	}
	.check-order {		
		p {
			font-size: $base-font + 5px;
			font-family: $MrEavesReg;
		}
	}
	.registration-reward {
		h2 {
			font-size: $base-font + 16px;
			font-family: $MrEavesReg;
		}
		h3 {
			font-family: $FuturaBold;
			font-size: $base-font + 1px;
		}
		p {
			font-size: $base-font + 5px;
			font-family: $MrEavesReg;
		}
	}
}
.create-login {
	.form-caption {
		font-family: $Futura;	
	}
}

@media screen and (max-width: 1024px) {
	.pt_account {
		.custom-select {
			&::after {
		    	background: url(../../../images/carat-down.svg) no-repeat;
		    	top: 67%;
		    	height: 20px;
		    	width: 20px;
		   		background-size: 15px 11px;		
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.pt_account {
		.create-login {
			button {
				padding: 14px 25px;
			}
		}
		.registration-reward {
			p {
				margin: 0 0 30px 0;	
			}
			h3 {
				margin: 0;	
			}
		}
		.benfits-content {
			.registration-reward {
				h2 {
					font-size: $base-font + 16px;
				}
			}
		}
		
		.inner-block{
			&.check-order {
				h2 {
					margin: 17px 0px 15px 0;
				}
			}
		}
		
		.create-login {
			.inner-block {
				.login-box {
					margin: 0 0 20px 0;
					
					.login-data {
						.login-email {
							width: 75%;
						}
						
						.login-brands {
							width: 100%;
						}
					}					
				}
			}
			.create-inner-block {
				.login-create {
					box-shadow: none;
					-webkit-box-shadow: none;
				    -moz-box-shadow: none;
				    border: 1px solid $alto;
				}
			}
			
			.col-1 {
				.form-row {
					&.login-rememberme {
						margin-top: 17px;
						label {
							span {
								font-size: $base-font + 5px;
								color: $black;
							}
						}	
					}
				}
			}
			
			.col-2 {
				margin: 0;
				width: 100%;
				
				.inner-block {
					width: 100%;
				}
			}
		}
		
		.make-label-absolute {
			label {
				span {
					font-family: $MrEavesReg;
					font-size: $base-font + 5px;
				}
				&.input-focus {
					span {
						font-size: $base-font + 3px;
					}
				}
			}
			.field-wrapper {
				button {
					&.pwd-show {
						font-family: $FuturaBold;
						padding: 16px 18px 16px 0;
						top: -21px;
						right: -13px;
					}
				}
			}
		}
	}
	.create-login {
		.inner-block {
			.login-box{
				&.login-create-account {
					.create-account {
						padding: 14px 48px;
					}
				}
			}
		}
	}
	.checkoutlogin {
		.col-1 {
			#password-reset {
				float: left;
			    width: 30%;
			    white-space: inherit;
			    clear: left;
			    margin: 30px 3px 0;
    			text-align: left;
			}
		}
	}
}
.login-box-content.returning-customers {	
	.form-row{
		&.password,&.username{
			&.error-handle {
				.input-focus + .field-wrapper:before{
					font-size: 9px;
					top: 8px;
				}
			}
		}
	}
}