@import "../common/variables";
@import "../common/mixin";
@import "font";
@import "variables";

.pt_customer-service .legacy-sitegen {
	.customer-service-landing-faq {
		margin: 0 0 3% 0;
	}

	.primary-content {
		max-width: 800px;
		margin: 0 auto;
		float: none;
	}

	.qa-section {
		border-top: none;

		.qa-content {
			padding: 18px 35px 11px 30px;
			margin: 0 0 2px 0;
		}

		.question-title {
			width: auto;
		    float: left;
		    display: inline-block;
		    padding: 10px 0 25px 0;
		    &.hide-desktop {
		    	display: none;
		    }
		    &.hide-tablet {
		    	display: none;
		    }
		}

		.qa-content {
			background-color: $gainsboro;

			&.active {
				background-color: $light-skygrey;
			}

			.answer {
				font-size: $base-font + 6px;
				padding: 30px 0 5px 0;
			}
			.question {
				font-family: $MrEavesBold;
				font-size: $base-font + 4px;
				letter-spacing: 1px;
			    cursor: pointer;

			    &::after {
				    background: url(../../../images/arrow-down.png) no-repeat;
				    background-position: 100% 5px;
			    }

			    &.active {
			    	cursor: pointer;

			    	&::after {
			    		background: url(../../../images/arrow-up.png) no-repeat;
    					background-position: 100% 5px;
			    	}
			    }
			}
		}

		.qa-desc {
			width: 100%;
			display: inline-block;
		}
	}
}
.customerservice-name {
	font-size: $base-font + 60px;
	margin: 11px 0 16px 0;
    font-family: $MrEavesReg;
    color: $mainly-blue;
}

.tiles-section {
	margin: 0 0 1px 0;
	display: flex;
	flex-wrap: wrap;
}

.content-asset-info {
	width: 31.25%;
	background-color: $gainsboro;
	margin: 0 25px 25px 0;

	&:last-child() {
		margin: 0 0 20px 0;
	}
	&:hover {
		background-color: $mainly-blue;
		.hovered-content {
			height: 150px;
		    width: 59%;
			ul {
				font-size: $base-font + 2px;
				font-family: $MrEavesBold;
				text-transform: uppercase;
			}
			li {
				margin: 0 0 8px 0;
			}
		}
	}

	.inner-asset-info {
	    display: table;
	}

	.hovered-title {
		display: block;
		font-family: $MrEavesHeavy;
		font-size: $base-font + 2px;
		color: $white;
		text-transform: uppercase;
		margin: 0 0 11px 0;
	}

	.title {
		font-size: $base-font + 6px;
		font-family: $MrEavesReg;
	}
}

.inner-asset-info {
	height: 150px;
    width: 100%;
	display: table;

    .icon {
		display:block;
		height: 50px;
		margin: 25px auto 0;
    }

    a {
    	display: block;

    	img {
			max-width: 100%;
			height:30px;
    	}
    }
}

.inner-service-landing-contacts {
	padding: 16px 0 7px 0;
    text-align: center;
    background-color: $gainsboro;

    h2 {
    	font-size: $base-font + 6px;
	    padding: 0 0 12px 0;
	    margin: 0;
	    font-family: $MrEavesHeavy;
	    float: left;
	   	width: 100%;
	   	display: inline-block;
	   	margin-top: 0;
	   	text-transform: uppercase;
	   	letter-spacing: 3px;
    }
}
.ways-contacts {
	width: 100%;
	display: table;
	border-spacing: 19px 0;
    box-sizing: border-box;

    img {
    	display:none;
	}
	span {
		float: none;
		width: auto;
		&.media-contacts,&.chat-contact,&.mail-contact{
			display: table-cell;
			float: none;
			width: 30%;
			padding: 0;
			span {
				float: none;
				width: auto;
			}
		}

		&.chat-contact span{
			display: block;
		}
	}
	@media screen and (max-width: 767px){
		span {

			&.media-contacts,&.chat-contact,&.mail-contact{
				display: block;
				float: none;
				width: 100%;
				padding:10px 0;
				span {
					float: none;
					width: auto;
				}
			}

			&.chat-contact span{
				display: block;
			}
		}
	}
}

.media-contacts {
	width: 41%;
    float: left;
    a {
    	width: 100%;
    	display: inline-block;
    }
}

.chat-contact {
	width: 23%;
    float: left;
    padding: 0 42px 0 20px;

    span {
		float: left;
		margin-left: 10px;
		width: 100%;
	}
}

.chat-text-available {
	color: #26bf1e;
	font-family: $MrEavesReg;
	font-size: $base-font + 4px;
	padding: 0 5px;
	text-transform: uppercase;
}

.chat-text-unavailable {
	color: #bd030c;
	font-family: $MrEavesReg;
	font-size: $base-font + 4px;
	padding: 0 5px;
	text-transform: uppercase;
}

.mail-contact {
	width: 27%;
    float: left;
}

.contact-title,.chat-title,.mail-title {
	font-size: $base-font + 4px;
	font-family: $MrEavesBold;
	text-transform: uppercase;
	letter-spacing: 1px;
	span{
		text-transform: none;
	}
}

.contact-time {
	font-size: $base-font + 4px;
	font-family: $MrEavesBold;
	display: inline;
	letter-spacing: 1px;
}

.contact-text {
	font-size: $base-font + 4px;
	font-family: $MrEavesReg;
	display: inline;
	letter-spacing: 1px;
	margin: 0;
}

.chat-text {
	font-size: $base-font + 4px;
	font-family: $MrEavesReg;
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: $light-green;

	div {
		display:inline;
	}
}

.not-available-now {
	font-size: $base-font + 4px;
	font-family: $MrEavesReg;
	display: block;
	text-transform: uppercase;
	color: $guardsman-Red;
	letter-spacing: 1px;
}
.mail-text {
	font-size: $base-font + 4px;
	font-family: $MrEavesReg;
	display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.view-all-faq {
	background: $dim-gray;
	color: $white;
	font-family: $MrEavesBold;
	font-size: $base-font + 3px;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1px;
    padding: 0;
	border: 0;
    box-sizing: border-box;
    border-radius: 0;
    cursor: pointer;
    &:hover {
	    background: $dim-gray;
	    color: $white;
		border: 0;
    }

	a {
		color: $white;
		padding: 16px 69.5px;
		float: left;
		box-sizing: border-box;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;

		&:hover {
			color: $white;
		}
	}
}

.customer-service-landing-faq {
	.view-all-faq {
		display: inline-block;
	}
}

//Article-pages
.pt_article-page .legacy-sitegen {
	@media screen and (min-width: 1024px) {
		.primary-content {
			width: 65.2%;
			float: left;
		}
	}

	@media screen and (min-width: 1024px) {
		#main {
			padding: 0 20px;
		}
	}

	.ways-contacts {
		padding: 0 16px;
	}

	.media-contacts {
		width: 30%;
    	padding: 0 24px;
	}

	.chat-contact {
		width: 27%;
	}

	.mail-contact {
		width: 27%;
	}

	#secondary {
		width: 25.5%;
		box-shadow:none;
		float:left;

		.secondary-navigation {
			max-width: 180px;
		    margin: 42px 0 0 20px;

		    ul {
		    	font-size: $base-font + 2px;
		    	font-family: $MrEavesReg;

		    	li {
		    		margin: 0 0 28px 0;
		    		letter-spacing: 1.3px;

		    		a {
		    			color: $black;
		    		}

		    		.currentpage {
		    			color: $mainly-blue;
		    			font-family: $MrEavesHeavy;
		    		}

		    		&:hover {
		    			color: $minsk;
    					font-weight: 700;

    					.currentpage {
			    			color: $mainly-blue;
			    			font-family: $MrEavesHeavy;
			    			font-weight: normal;
			    		}
		    		}
		    	}
		    }
		}
	}

	.customerservice-name {
	    border-bottom: 1px solid $alto;
	    margin: 0;
	    padding: 25px 0;
	    font-size: $base-font + 60px;
	}

	.qa-section {
		border-top: none;
		.qa-content {
			background-color: $gainsboro;
			&.active {
				background-color: $light-skygrey;
			}
			.question {
				font-size: $base-font + 4px;
				letter-spacing: 1px;
				font-family: $MrEavesBold;
			    cursor: pointer;

			    &::after {
			    	background: url(../../../images/arrow-down.png) no-repeat;
			    	background-position: 100% 5px;
			    }

				&.active {
			    	cursor: pointer;

			    	&::after {
						  background: url(../../../images/arrow-up.png) no-repeat;
	   					background-position: 100% 5px;
	   				}
				}
			}
			.answer {
				font-size: $base-font + 6px;
				font-family: $MrEavesReg;
			}
			&:nth-child(1n) {
				.answer {
					padding: 0;
				}
			}
		}
	}

	.account-nav-asset {
		display: none;
	}

	// Global elements in article pages
	.make-label-absolute {
		label {
			span {
				font-family: $MrEavesReg;
	    		font-size: $base-font + 5px;
			}
		}
	}

	input[type="text"], input[type="number"], input[type="password"], input[type="date"], input[type="tel"], select {
	    color: $black;
	    font-family: $MrEavesReg;
		font-size: $base-font + 5px;
	}
	textarea {
		width:100%;
	    box-sizing: border-box;
	    color: $black;
	    font-family: $MrEavesReg;
		font-size: $base-font + 5px;
		border: 1px solid $silver;
	}

	@media screen and (min-width: 1024px) {
		.primary-content {
			width: 65.1%;
		    max-width: 820px;
		    float: left;
		}
	}
}

//Contact page global elements
.contactpage-right-content {
	h1 {
		font-family: $MrEavesBold;
		font-size: $base-font + 18px;
		border-bottom: 0;
		letter-spacing: 2px;
    	text-transform: uppercase;
    	//padding-left: 37px;
    	margin: 36px 0 12px;
    	color: $mainly-blue;
    	@media screen and (max-width: 1023px) {
    		padding-left: 30px;
    	}
    	@media screen and (max-width: 767px) {
    		padding-left: 0;
    	}
	}
	.contactpage-chat-module {
		h1 {
			font-family: $MrEavesHeavy;
			font-size: $base-font + 8px;
			padding: 0;
			margin: 0 0 10px;
		}
		.chat-contact {
			box-sizing: border-box;
			width: 50%;
			padding: 0 5%;
			margin: 0;
			@media screen and (max-width: 767px){
				width: 100%;
			}
		}
		.media-contacts {
			box-sizing: border-box;
			width: 50%;
			padding: 0 5%;
			margin: 0;
			@media screen and (max-width: 767px){
				width: 100%;
			}
		}
	}

	.text-content {
		display:none;
		font-size: $base-font + 6px;
		font-family: $MrEavesReg;
		margin-bottom: 13px;


		a,strong,b,p {
			font-size: $base-font + 6px;
		}
		a,b,strong {
			color: $dark-blue;
			text-decoration: underline;
		}

		&.only-for-romansbrand {
			display: block;
		}
	}

	.thankyou-section {

		.form-wrapper {
			padding: 20px 40px 80px;
		}

		.text-content {
			display: block;
		}
		.contactusthankyouforcontacting {
			font-family: $MrEavesBold;
			font-size: $base-font + 18px;
			color: $mainly-blue;
		}
	}
}

//Contact us page
.contact-us-page {

	.form-wrapper {
		padding: 21px 20px 25px;
		margin-bottom: 25px;

		.right-cont {
			padding-right: 0;
		}
    	.form-row-button {
		    margin-bottom: 0;
    	}

		@media screen and (min-width: 1024px) {
			.left-cont {
				max-width: 382px;
    			width: 48.9%;
			}
			.right-cont {
				max-width: 380px;
    			width: 48.7%;
			}
		}
		.solid-wine-berry {
			width: 100%;
		}
	}
}

.contact-us-priority {
	.form-row-button {
		max-width: 380px;
		width: 49%;
	}
	.cp-email {
		max-width: 360px;
		width: 46.2%;
	}
	.form-wrapper {
		textarea {
			height: 190px;
		}
	}
}

.article-heading {
	  margin: 30px 0 0 0;
    padding: 0;
    font-size: $base-font + 18px;
    font-family: $MrEavesBold;
    color: $mainly-blue;
    text-transform: uppercase;
		letter-spacing: 2px;
		font-weight:400;
}

.secondary-article-heading {
	margin: 30px 0 28px 0;
	font-family: $HelveticaBold;
	font-size: $base-font;
	letter-spacing: 1px;
	display: none;
}

.shipping-info-section {
	margin: 20px 0 30px 0;
}

.merchandise-structure {
	width: 63.8%;
	display: inline-block;

	.col-1 {
		width: 56%;
	}

	.col-2 {
		width: 36%;
	}

	span {
		width: 100%;
		display: inline-block;
		margin: 0 0 20px 0;
		font-size: $base-font + 6px;
		font-family: $MrEavesReg;
	}
}

.shipping-heading {
	margin: 0 0 15px 0;
	font-size: $base-font + 6px;
	font-family: $MrEavesBold;
}

.menu-word {
	display: none;
}

.restriction-bold {
	font-family: $MrEavesReg;
}

.pt_article-page .legacy-sitegen {
	.customerservice-name {
	    padding: 13px 0 6px 0;
		letter-spacing: 3px;
	}
}

//Frequently Asked Questions
.cs-faq-rm {
	.article-heading {
		margin: 37px 0 30px 28px;
	}
}

.faq-content {
	margin: 0 0 30px 0;

	h2 {
	    margin: 0 0 2px 0;
		text-transform: uppercase;
		font-size: $base-font + 4px;
	    font-family: $MrEavesBold;
	    font-weight: 400;
	    padding: 19px 31px 14px;
	    color: $white;
	    background: $dim-gray;
	    letter-spacing: 1.3px;
	}

	.sections {
		margin-top: 23px;
	}

	.qa-section {
		.qa-content {
			padding: 16px 23px 13px 29px;

			&.active {
				padding: 16px 23px 34px 29px;
			}

			.question {
				font-size: $base-font + 4px;
	    		font-family: $MrEavesBold;
	    		text-transform: uppercase;
				letter-spacing: 1.2px;
				display: inline-block;
				width: 100%;

				span {
					width: 95%;
    				float: left;
				}

				&::after {
					background-position: 100% 6px;
					background-size: 11px;
					background-image: url('../../../images/rm/closed-arrow-FAQ.png');
					background-repeat: no-repeat;
				}

				&.active {
					margin: 0 0 9px;

					&::after {
						background-image: url('../../../images/rm/open-arrow-FAQ.png');
					}
				}
			}
		}
	}
}

//Privacy Policy
.privacy-policy-rm {
	.primary-content {
		width: 100%;
		max-width: 100%;
	}
}

.privacy-policy {
	.back-to-top {
		font-size: 18px;
		font-family: $MrEavesReg;
		text-transform: lowercase;
		margin-left: 20px;
	}

	a {
		color: $dark-blue;
		text-decoration: underline;
	}

	.heading {
	    margin: 0;
		padding: 33px 0 0 20px;
		font-size: 30px;
		font-family: $MrEavesBold;
 	 	color: $grape-light;
 	 	text-transform: uppercase;
 	 	letter-spacing: 2.6px;
	}

	.para-description-section {
		padding: 0;
	}

	.sub-heading {
		font-size: 22px;
		font-family: $MrEavesReg;
		text-transform: none;
		font-weight: normal;
		margin: 0 20px 22px;
	}

	.para-description {
		font-size: 18px;
		font-family: $MrEavesReg;

		&.section-1 {
		    padding: 15px 5px 15px 20px;
			background: $light-skygrey;
			box-sizing: border-box;
			-webkit-box-sizing: border-box;
	 	 	-moz-box-sizing: border-box;

	 	 	p {
				margin: 14px 0;
				line-height: 27px;

				&.para {
					margin: 14px 22% 14px 0;
				}
			}

			.list {
				h2 {
					text-transform: none;
					font-weight: normal;
					margin: 26px 0 0 0;
					font-size: 18px;
				}

				ol {
					margin: 23px 0 0 0;
					padding: 0 33px;;

					li {
						margin: 0 0 15px 0;
						font-family: $MrEavesReg;
						color: $black;

						p {
							margin: 10px 11px 10px;
							line-height: 22px;
						}
					}
				}
			}
		}

		&.section2 {
		    padding-bottom: 22px;
			border-bottom: 1px solid $alto;

			p {
			    line-height: 25px;
				margin: 3px 21px 27px;
				font-size: 18px;
			}
		}

		&.section5 {
			h1 {
				margin: 30px 0 20px 0;
			}

			.back-to-top {
				margin: 20px 0 0 0;
				display: inline-block;
			}

			h2 {
				margin: 0 0 20px 0;
				font-size: $base-font;
				text-transform: none;
			    width: auto;
			    display: inline-block;
			}
		}

		&.section3 {
		    padding-bottom: 41px;
			border-bottom: 1px solid $alto;
			margin: 25px 0 0 0;
			font-size: 18px;

			a {
			    color: black;
				text-decoration: none;

				&.back-to-top {
					color: $dark-blue;
					text-decoration: underline;
				}
			}

			p {
			    line-height: 25px;
				margin: 2px 21px 28px;
			}
		}

		&.section4 {
			font-size: 18px;
			margin: 28px 0 0 0;

			p {
			    line-height: 25px;
				margin: 0 21px 0;
			}
		}

		h1 {
			margin: 19px 0 0 21px;
			font-size: 22px;
			font-family: $MrEavesBold;
			text-transform: capitalize;
		}

		ul {
			padding: 0 0 0 23px;
		    margin: 5px 0 15px 0;
		    line-height: 24px;

			li {
				list-style: disc inside none;
				margin-bottom: 30px;
			}
		}
	}

	.qa-content {
	    padding: 25px;
	    background-color: $gainsboro;
	    margin: 0 0 2px 0;

	    &.active {
	    	padding: 25px;
	    	background-color: $light-skygrey;
	    }
	}

	.question {
	    font-size: $base-font + 4px;
	    letter-spacing: 1px;
	    width: 100%;
	    display: inline-block;
	    cursor: pointer;
	    font-family: $MrEavesBold;
	    text-transform: uppercase;

	    &::after {
	    	background-image: url(../../../images/dropdown-arrow-down.png);
		    background-repeat: no-repeat;
		    background-size: 15px;
		    background-position: 100% 5px;
	    }

	    &.active {
	    	margin: 0 0 15px;

	    	&::after {
    			background-image: url(../../../images/dropdown-arrow-up.png);
	    	}
	    }
	}

	.answer {
		&.active {
			font-size: $base-font + 6px;
			font-family: $MrEavesReg;


		}
	}
}

@media screen and (max-width: 1024px) {
	.pt_article-page .legacy-sitegen {
		.media-contacts {
			padding: 0;
    		width: 33%;
		}

		.chat-contact {
			padding: 0;
    		width: 34%;
		}

		.mail-contact {
			width: 33%;
    		padding: 0;
		}

		#secondary {
			width: 25.7%;

			.secondary-navigation {
				margin: 42px 0 0 0;
			}
		}

		.primary-content {
			width: 74.2%;
		}

		&.privacy-policy-rm {
			.primary-content {
				width: 100%;
			}
		}
	}

	.ways-contacts {
		padding: 0 10px;
	}
	.media-contacts {
		padding: 20px 13px 20px 0;
		width: 40%;
	}
	.chat-contact {
		padding: 20px 8px 20px 8px;
		width: 23%;
	}
	.mail-contact {
		padding: 20px 15px 20px 7px;
	}
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 20px;
		}
	}
	.merchandise-structure {
		width: 82.8%;
		.col-1 {
			width: 65%;
		}
		.col-2 {
			width: 35%;
		}
	}
	.content-asset-info {
		width: 31%;
	}
}

@media screen and (max-width:1023px) {
	.pt_customer-service .legacy-sitegen {

		.qa-desc {
			width: 100%;
			display: inline-block;
		}

		.qa-content {
			.answer {
				padding: 30px 0 0 0;
			}
		}
	}

	.customer-service-faq {
	    .view-all-faq {
	    	a {
	    		padding: 16px 11.5px;
	    	}
	    }
	}
	.content-asset-info {
		&:hover {
			background-color: $gainsboro;
			.hovered-content {
				display: none;
			}
			.inner-asset-info {
				display: table;
			}
		}
	}

	.customerservice-name {
		font-size: $base-font + 36px;
		margin: 30px 0 40px 0;
	}

	.content-asset-info {
		width: 30%;

		.title {
			padding: 0 69px;
    		box-sizing: border-box;
    		margin: 9px 0 0 0;
		}
	}

	.inner-service-landing-contacts {
		padding: 15px 0 0 0;
		margin-bottom: 0;
		h2 {
			font-size: $base-font + 6px;
			padding: 0;
		}
	}

	.ways-contacts {
		padding: 0 9px;
	}

	.media-contacts {
		width: 32%;
	}

	.chat-contact {
		width: 32%;
	}
	//Article-page
	.pt_article-page .legacy-sitegen {
		.second-section {
			background-color: $light-skygrey;
			width: 100%;
			display: inline-block;
		}

		#secondary {
			width: 46%;
			margin: 13px 20px 11px;

			.secondary-left-section {
				border: 3px solid $alto;
				border-top: none;
				width: 100%;
				display: none;
				background-color: $white;
				&.active {
					display: block;
					z-index: 9;
    				position: absolute;
    				box-sizing: border-box;
				}
				ul {
					font-family: $MrEavesBold;
					font-size: $base-font + 2px;
					width: 88%;
					margin: 0 auto;
    				margin-bottom: 22px;
    				border-bottom: 1px solid $alto;
    				&:nth-child(1n) {
    					margin-top: 15px;
    				}
    				&:last-child {
    					border-bottom: none;
    				}
					li {
						margin: 0 0 15px 0;
						.currentpage {
							font-family: $MrEavesBold;
							font-size: $base-font + 2px;
						}
					}
				}
			}

			.secondary-navigation {
				max-width: 100%;
				background-color: $light-skygrey;
				position: relative;
				margin: 0;

				.secondary-aticle-content {
					width: 100%;
					display: inline-block;
					border: 3px solid $mainly-blue;
					background-color: $white;
					box-sizing: border-box;
				}
			}
		}
		.customerservice-name{
			display: inline-block;
    		width: 100%;
    		padding: 18px 0 18px 38px;
    		border-top: 1px solid $alto;
    		font-size: $base-font + 36px;
    		box-sizing: border-box;
    		text-align: left;

    		@media screen and (max-width: 767px) {
    			display: none;
    		}

    		&.hide-tablet {
    			display: none;
    		}
    		&.hide-mobile {
    			display: none;
    		}
		}

		.page-content {
			padding: 0 20px;
			box-sizing: border-box;
		}

		.media-contacts {
			width: 36%;
		}

		.chat-contact {
			width: 29%;
		}
	}

	.secondary-article-heading {
		margin: 10px 20px;
		font-family: $Futura;
		font-size: $base-font + 6px;
		background: url(../../../images/arrow-down.png) no-repeat;
		background-position: 100% 8px;
	    background-size: 15px;
	    display: block;
	    &.active {
	    	background: url(../../../images/arrow-up.png) no-repeat;
			background-position: 100% 8px;
		    background-size: 15px;
	    }
	}

	.opacity-coloring {
		background-color: $white;
		opacity: 0.5;
	}

	.merchandise-structure {
		width: 82.1%;
		.col-1 {
			width: 65%;
		}
		.col-2 {
			width: 35%;
		}
	}

	.menu-word {
		display: inline;
		text-transform: uppercase;
	}

	.article-heading {
		padding: 0 0 0 30px;
	}
	.contactpage-right-content {
		.contactpage-chat-module {
			.chat-contact {
				float: right;
			}
			.media-contacts {
				float: left;
			}
		}
		.thankyou-section {
			.form-wrapper {
				padding: 20px 37px 20px;
			}
		}
	}
	.contact-us-page {
		.form-wrapper {
			padding: 21px 20px 20px;
			margin-bottom: 19px;
		}
	}

	//Frequently Asked Questions
	.pt_article-page .legacy-sitegen {
		.primary-content {
			width: 100%;
			padding: 0 0;
		}
	}

	.cs-faq-rm {
		.article-heading {
			margin: 13px 0 0 39px;
    		padding: 0;
		}
	}

	.faq-content {
		.sections {
			margin-top: 15px;
		}

		h2 {
			padding: 17px 41px 16px;
		}

		.qa-section {
			.qa-content {
				padding: 18px 29px 13px 40px;

				&.active {
					padding: 18px 29px 13px 40px;
				}
			}
		}
	}

	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 12px 0 0 41px;
		}

		.table {
			width: 100%;
		}

		.back-to-top {
			margin-left: 40px;
		}

		.sub-heading {
			margin: 0 39px 12px;
		}

		.para-description-section {
			padding: 0;
		}

		.para-description {
			&.section-1 {
				margin: 14px 0 0 0;
				padding: 4px 40px 20px;

				p {
					line-height: 25px;

					&.para {
						margin: 25px 9% 14px 0;
					}
				}
			}

			&.section2, &.section3 {
				p {
					margin: 3px 44px 27px;
				}
			}

			h1 {
				margin: 20px 0 0 43px;
			}

			ul {
				padding: 0 40px 0;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.contactpage-right-content {
		h1 {
			margin: 30px 0;
    		//padding: 0 0 0 21px;
    		padding: 0 0 0 0;
		}
		 .text-content {
		 	&.only-for-romansbrand {
		 		padding-left: 11px;
		 	}
		 }

		.contactpage-chat-module {
			h1 {
				font-size: $base-font + 3px;
				letter-spacing: 1px;
			}
			.chat-contact {
				float: none;
			    padding: 0 0;
			    margin-top: 23px;
			}
			.media-contacts {
				float: none;
				margin-top: 19px;
			}
		}
		.thankyou-section {
			.form-wrapper {
				padding: 21px 20px 20px;
			}
		}
	}
	.contact-us-page {
		.form-wrapper {
			padding: 21px 10px 20px;
   			margin-bottom: 19px;
		}
	}
	.contact-us-priority {
		.cp-email,.form-row-button {
			width: 100%;
			max-width: 100%;
		}
	}
	.pt_customer-service .legacy-sitegen {

		.qa-section {
			.question-title {
    			padding: 0 0 20px 0;
				letter-spacing: -0.5px;
				&.hide-mobile {
					display: none;
				}

				&.hide-tablet {
					display: inline-block;
				}

				&.hide-desktop {
					display: inline-block;
				}
			}

			.qa-content {
				padding: 20px 10px 20px 10px;

				.question {
					font-size: $base-font + 3px;
				}

				.answer {
					font-size: $base-font + 3px;
					padding: 15px 0 0 0;
				}
			}
		}
	}

	.content-asset-info {
		.inner-asset-info {
			display: table;

		}
		.title {
    		font-size: $base-font + 3px;
		}
	}
	.inner-service-landing-contacts {
		padding: 15px 0 15px 0;
		h2 {
			padding: 0;
			font-size: $base-font + 3px;
		}
	}
	.media-contacts {
	    a {
	    	display: inline;
    		float: none;
    		width: auto;
	    }
	}

	.contact-title {
		padding: 0 0 5px 0;
	    font-size: $base-font + 3px;
	    width: auto;
	    float: none;
	    display: inline;
	}

	.contact-time {
		font-size: $base-font + 3px;
	}

	.contact-text {
		margin: 0;
		width: 100%;
    	display: inline-block;
    	font-size: $base-font + 3px;
    	color: $dark-blue;
	}

	.chat-contact {
	    padding: 0;
	    span {
	    	margin: 0;
	    }
	}

	.chat-title,.chat-text,.mail-title,.mail-text {
		font-size: $base-font + 3px;
	}

	.not-available-now {
		font-size: $base-font + 3px;
	}

	.ways-contacts {
		padding: 0 20px;
	}

	.customer-service-landing-contacts {
		margin: 0 0 8% 0;
	}

	//Article-page
	.secondary-article-heading {
		font-size: $base-font + 4px;
	}

	.pt_article-page .legacy-sitegen {
		.media-contacts {
			padding: 20px 0 20px 0;
			width: 100%;
		}

		.chat-contact {
			padding: 0 42px 0 20px;
			width: 100%;
		}

		.mail-contact {
			padding: 20px 0 0 0;
    		width: 100%;
		}


		.primary-content {
			padding: 0 0;
			box-sizing: border-box;
		}

		.second-section {
			border-bottom: 1px solid $silver;
		}

		#secondary {
			width: 94%;
		    float: none;
		    margin: 13px 10px 11px;

			.secondary-navigation {
				ul {
					font-size: $base-font + 4px;
					li {
						.currentpage {
							font-size: $base-font + 4px;
						}
					}
				}
			}
		}

		.col-1 {
			width: 100%;
		}

		.col-2 {
			width: 100%;
		}

		.qa-section {
			.qa-content {
				.answer {
					font-size: $base-font + 3px;
				}

				.question {
					font-size: $base-font + 3px;
				}
			}
		}
		.media-contacts {
			width: 100%;
		}
		.chat-contact {
			width: 100%;
		}
	}

	.merchandise-structure {
		font-size: $base-font + 3px;

		span {
			font-size: $base-font + 3px;
		}
	}

	.restriction-bold {
		font-size: $base-font + 3px;
	}

	.article-heading {
		padding: 0;
	    text-align: center;
	    margin: 10px 0 0 0;
	}
	.shipping-heading {
		font-size: $base-font + 3px;
	}

	//Frequently Asked Questions
	.cs-faq-rm {
		.article-heading {
			margin: 13px 0 0 10px;
		    padding: 0;
		    text-align: left;
		}
	}

	.faq-content {
		.sections {
			margin-top: 15px;
		}

		h2 {
			padding: 17px 10px 16px;
			font-size: $base-font + 3px;
		}

		.qa-section {
			.qa-content {
				padding: 25px 15px;

				&.active {
					padding: 25px 10px;
				}
			}
		}
	}

	//Privacy Policy
	.privacy-policy {
		.heading {
			padding: 24px 20px 14px;
		}

		.back-to-top {
			margin-left: 10px;
		}

		.sub-heading {
			font-size: $base-font + 2px;
			margin: 0 20px 12px;
		}

		.para-description {
			font-size: $base-font + 3px;

			&.section-1 {
			    padding: 12px 15px 20px;

		 	 	p {
					line-height: 23px;
				}

				.list {
					h2 {
						font-size: $base-font + 3px;
					}

					ol {
						padding: 0 0 0 15px;
					}
				}
			}


			&.section2 {
				p {
					margin: 10px 10px 15px;
				}
			}

			&.section3 {
				margin: 5px 0 20px;
				padding-bottom: 25px;

				p {
					margin: 3px 10px 27px;
				}
			}

			h1 {
				margin: 20px 0 0 10px;
				font-size: $base-font + 5px;
			}

			ul {
				margin: 10px 0 15px 0;
				padding: 0 10px 0;
			}

			.qa-content {
			    padding: 20px 10px;

			    &.active {
			    	padding: 20px 10px;
			    }
			}

			.answer {
				&.active {
					font-size: $base-font;

					.customer-service {
						.contact {
						    border-right: 0;
							padding-right: 0;
						}

						.hearing {
							padding-left: 0;
							margin: 10px 0 0 0;
							width: 100%;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.pt_article-page .legacy-sitegen {
		.page-content {
			padding: 0 10px;
		}

		.qa-section {
			.qa-content {
				padding: 25px 10px;
			}
		}
	}
	.merchandise-structure {
		width: 100%;
	}
}