@mixin mrEvesfont() {
    font-family: $MrEavesReg;
    font-size: 15px;
}

@mixin faturafont() {
    font-family: $FuturaMedium;
    text-transform: uppercase;
}
$colHeight:100%;

.only-for-bh-section {
	.bh-choose-item-below {
		font-family: $FuturaBold;
		font-size: 16px;
		letter-spacing: 1px;
		@media screen and (min-width: 768px) and (max-width: 1023px) {
			font-size: 18px;
			line-height: 1.11;
		    color: $black;
		}
	}
}
//Trending Now
.div-trending-section {
    .recommendations-heading {
        text-align: center;
        margin-bottom: 45px;
        width: 100%;
        display: inline-block;
        text-transform: capitalize;
        font-size: $base-font + 20px;
        font-family: $MrEavesReg;
    }
    #carousel-recommendations {
        width: 100%;
        display: inline-block;
        text-align: center;
        .product-tile {
            width: calc(20% - 4px);
            width: -moz-calc(20% - 4px);
            width: -webkit-calc(20% - 4px);
            width: -o-calc(20% - 4px);
            float: none;
            display: inline-block;
            vertical-align: top;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            .product-swatches {
                display: block;
            }
            .product-name {
                margin: 20px 0 11px 0 !important;
                font-size: $base-font + 6px;
                line-height: 30px;
            }
            .p-image-rollover {
                display: none;
            }
        }
    }
}

// PDP Recommandation styles
.pt_product-details {
    .full-width {
        .primary-content {
            margin-top: 20px;
        }
    }
    #main {
        .pdp-main {
            .product-col-2 {
                &.e-giftcard-detail {
                    .product-add-to-cart {
                        button {
                            &.egift-prod {
                                border-top-left-radius: 15px;
                                -webkit-border-top-left-radius: 15px;
                                -moz-border-radius-topleft: 15px;
                                border-bottom-right-radius: 15px;
                                -webkit-border-bottom-right-radius: 15px;
                                -moz-border-radius-bottomright: 15px;
                            }
                        }
                    }
                }
            }
            .last-visited-section {
                .last-visited-heading {
                    border: none;
                    text-align: center;
                    padding-bottom: 0;
                    font-size: $base-font + 20px;
                }
                .product-tile {
                    text-align: center;
                    width: 100%;
                    .product-image {
                        margin-bottom: 10px;
                    }
                    .product-name {
                        line-height: normal;
                        font-size: $base-font + 6px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    img.p-image {
                        display: inline-block;
                    }
                }
                .search-result-items {
                    max-width: 954px;
                    margin: 0 auto;
                    padding-right: 0;
                    .grid-tile {
                        border: none;
                        padding: 0 10px 10px;
                        min-height: 275px;
                        margin: 0 auto;
                        float: none;
                        max-width: inherit;
			            .product-name-image-container{
			                max-width: inherit;
                            margin: 0 auto;
                            a.name-link{
                                max-width: 220px;
                            }
							.product-image{
				                max-width: 220px;
								margin: 0 auto;
			                }
			            }
                    }
                }
                @media screen and (max-width: 1024px) {
                    .product-tile {
                        .product-image {
                            max-width: 120px;
                            margin: 0 auto;
                        }
                    }
                }
                @media screen and (min-width: 768px) {
                    button {
                        &.slick-arrow {
                            top: 38%;
                        }
                    }
                }
                @media screen and (max-width: 1023px) and (min-width: 768px) {
                    .search-result-items {
                        padding: 0 25px;
                        .grid-tile {
                            padding: 0 10px 10px;
                            min-height: 275px;
                            margin: 0 auto;
                            float: none;
                            max-width: inherit;
                            .product-name-image-container{
                                max-width: inherit;
                                margin: 0 auto;
                                .product-image{
                                max-width: 220px;
                                margin: 0 auto;

                                }
                            }
                        }
                    }
                    .product-tile {
                        .product-name {
                            font-size: $base-font + 6px;
                            margin: 10px auto 0;
                        }
                        .product-image {
                            max-width: inherit;
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    .last-visited-heading {
                        text-transform: capitalize;
                        font-size: $base-font + 15px;
                        height:auto;
                        text-align:center;
                        padding-left:0;
                    }
                    .product-tile {
                        max-width: 200px;
                        .product-name {
                            font-size: $base-font + 14px;
                        }
                        .thumb-link {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .search-result-items {
                        .grid-tile {
                            width: auto;
                            padding: 0 10px 10px 0;
                            min-height: 275px;
                            margin: 0 auto;
                                float:none !important;
                                max-width: inherit;
                                .product-name-image-container{
                                    max-width: inherit;
                                    margin: 0 auto;
                                    .product-image{
                                    max-width: 220px;
                                    margin: 0 auto;

                                    }
                                }
                            .product-tile {
                                max-width: inherit;
                                float:none !important;
                            }
                        }
                    }
                }
            }
           
            .product-add-to-cart {
                .availability-web {
                    b {
                        font-family: $MrEavesReg;
                        font-size: 14px;
                    }
                }
            }
        }
        .standardproductset {
            .product-teasers {
                margin-bottom: 8px;
                display: inline-block;
    			width: 100%;
            }
            .product-set {
                #thumbnails {
                    .thumb {
                        @media screen and (max-width: 1023px) {
                            width: auto;
                            max-width: 73px;
                        }
                        @media screen and (max-width: 767px) {
                            max-width: 25%;
                        }
                    }
                }
            }
            .product-name {
                margin: 0 0 11px 0;
            }
            .product-price {
                margin-bottom: 21px;
            }
            .presonalised-msg-norefund {
                font-family: $MrEavesReg;
                font-size: $base-font + 3px;
                padding-top: 0;
            }
            .product-hemmable,
            .product-monogramming {
                .checkbox-content {
                    .checkbox {
                        margin-left: -2px;
                        input[type="checkbox"] {
                            background: url('../../../images/checkbox.svg') no-repeat;
                            width: 23px;
                            height: 23px;
                            border: none;
                            outline: none;
                            vertical-align: top;
                            opacity: inherit;
                            z-index: 9;
                            margin-left: 0;
                        }
                        input[type="checkbox"]:checked {
                            background: url('../../../images/checkmark.svg') no-repeat center;
                            width: 25px;
                            height: 23px;
                            border: none;
                            outline: none;
                            vertical-align: top;
                            opacity: inherit;
                            z-index: 9;
                            margin-left: 0;
                        }
                    }
                    label {
                        &:before {
                            display: none;
                        }
                        &:after {
                            display: none;
                        }
                        span {
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }
                    }
                }
                .hemming-drawer {
                    margin-top: 14px;
                    ul {
                        li {
                            border: 1px solid $dim-gray;
                            padding: 7px 11px 8px;
                            &.selected {
                                background: $dim-gray;
                                border: 1px solid $dim-gray;
                            }
                        }
                    }
                }
                .personalized {
                    .selected-option {
                        font-size: $base-font + 5px;
                        font-family: $MrEavesReg;
                        border: 1px solid $dim-gray;
                    }
                    .selection-list {
                        li {
                            font-size: $base-font + 5px;
                            font-family: $MrEavesReg;
                        }
                    }
                }
                .input-fields {
                    font-size: $base-font + 5px;
                    font-family: $MrEavesReg;
                    padding-left: 17px;
                    border: 1px solid $dim-gray;
                }
                .input-fields-set {
                    .character-left {
                        .monogramming-charactercount {
                            font-size: $base-font + 2px;
                            font-family: $Futura;
                        }
                    }
                }
                .monogramming-preview {
                    textarea {
                        font-size: $base-font + 2px;
                        font-family: $Futura;
                        border: 1px solid $dim-gray;
                        margin-bottom: 4px;
                    }
                }
            }
            .product-actions {
                border: none;
                @media screen and (max-width: 1023px) {
                    padding: 15px 0 0 0;
                }
            }
            .product-col-2 {
                .product-set-addall {
                    .product-add-to-cart {
                        .inventory {
                            width: 75px;
                        }
                        @media screen and (max-width: 1024px) {
                            .wishlist {
                                margin: 0;
                                @media screen and (max-width: 1023px) {
                                    margin: 0 0 0 30px;
                                }
                                @media screen and (max-width: 767px) {
                                    margin: 0;
                                    text-align: left;
                                }
                            }
                            &.top-add-all {
                                margin: 40px 0 0 0;
                                @media screen and (max-width: 767px) {
                                    margin: 25px 0 0 0;
                                }
                                .all-set-button {
                                    width: auto;
                                    float: right;
                                    padding: 11px 12px;
                                    @media screen and (max-width: 1023px) {
                                        width: 196px;
                                    }
                                }
                            }
                            .all-set-button {
                                width: auto;
                                float: right;
                                padding: 11px 12px;
                                @media screen and (max-width: 1023px) {
                                    width: 196px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    span {
                        &.product-variations-toggle {
                            font-family: $FuturaBold;
                            font-size: $base-font;
                        }
                    }
                }
            }
            .availability-web {
                .availability-novariation {
                    color: $dusty-gray;
                    font-family: $MrEavesReg;
                    font-size: $base-font + 2px;
                    font-style: normal;
                }
            }
            .add-sub-product {
                border-top: 1px solid $gainsboro;
                width: 74%;
                margin: 0;
                padding: 0;
                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }
            .promotion {
                width: 100%;
                @media screen and (max-width: 767px) {
                    float: none;
                    width: 100%;
                    color: $guardsman-Red;
                    margin: 0;
                    .promotion-title,
                    .promotion-callout {
                        font-size: $base-font + 2px;
                    }
                }
                .ps-product {
                    .promo-tooltip {
                        display: none;
                    }
                }
            }
            .product-set-addall {
                .product-add-to-cart {
                    margin: 25px 0 0 0;
                    @media screen and (max-width: 1024px) {
                        //margin: 0;
                        width: 100%;
                        .product-set-main-button-parent {
                            margin: 0;
                            width: 43%;
                            line-height: 18px;
                            padding: 0;
                        }
                        .shop-the-look {
                            float: left;
                        }
                    }
                    @media screen and (max-width: 1023px) {
                        margin: 20px 0 0 0;
                    }
                    @media screen and (max-width: 767px) {
                        margin: 0;
                    }
                }
                .add-sub-product {
                    margin: 0;
                    @media screen and (max-width: 1024px) {
                        width: 74%;
                    }
                    @media screen and (max-width: 767px) {
                        border-top: none;
                        width: 100%;
                    }
                }
            }
            .top-add-all {
                margin: 0;
            }
            .product-col-2 {
                .top-add-all {
                    &.product-add-to-cart {
                        .product-set-main-button-parent {
                            width: 38.3%;
                            text-align: right;
                            margin-right: 22px;
                            &.show-tablet-landscape {
                                display: none;
                                @media screen and (max-width: 1024px) {
                                    display: block;
                                }
                            }
                            @media screen and (max-width: 1024px) {
                                float: right;
                                width: 58%;
                                line-height: 50px;
                                &.hide-tablet-landscape {
                                    display: none;
                                }
                            }
                            @media screen and (max-width: 1023px) {
                                width: 42.9%;
                                &.hide-tablet-landscape {
                                    display: block;
                                    float: left;
                                    width: 47%;
                                }
                                &.show-tablet-landscape {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .product-add-to-cart {
                    .all-set-button {
                        margin: 0;
                    }
                    .inventory {
                        .custom-select {
                            .selected-option {
                                font-size: $base-font + 3px;
                                font-family: $FuturaMedium;
                            }
                            .selection-list {
                                font-family: $FuturaMedium;
                                font-size: $base-font + 5px;
                            }
                        }
                    }
                }
                .product-set-details {
                    .product-name {
                        margin: 0;
                        @media screen and (max-width: 1023px) {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
            .product-set {
                .product-set-list {
                    .product-set-item {
                        padding-top: 27px;
                        border-top: 1px solid $gainsboro;
                        &:first-child {
                            border-top: none;
                        }
                        @media screen and (max-width: 767px) {
                            padding-top: 0;
                            &.product-open {
                                .add-sub-product {
                                    border-bottom: 1px solid $mercuryy;
                                    padding-bottom: 15px;
                                }
                            }
                            .product-set-details {
                                padding: 12px 0 0 0;
                            }
                        }
                    }
                }
                .view-details {
                    padding: 9px 0 5px 0;
                    color: $dim-gray;
                    a {
                        color: $dim-gray;
                    }
                }
            }
            .product-variations {
                .attribute {
                    padding: 5px 0 12px;
                    &:last-child {
                        @media screen and (max-width: 767px) {
                            padding-bottom: 10px;
                        }
                    }
                    &:first-child {
                        border-top: 0;
                    }
                    &.Shop {
                        border-top: 0;
                        margin: 0 0 10px 0;
                        padding: 0 0 12px 0;
                        .attribute_label {
                            margin: 21px 0 10px;
                        }
                    }
                    &.Width {
                        border-top: 0;
                        margin: 0 0 10px 0;
                        padding: 0 0 12px 0;
                    }
                    &.size {
                        padding: 0 0 22px 0;
                        margin: 0;
                        @media screen and (max-width: 1024px) {
                            margin: 0;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 5px 0 0 0;
                        }
                        .attribute_label {
                            margin: 11px 0 12px;
                            @media screen and (max-width: 767px) {
                                margin: 0 0 12px 0;
                            }
                        }
                    }
                    &.color {
                        @media screen and (max-width: 767px) {
                            margin-bottom: 0;
                            padding: 0;
                        }
                    }
                    .variation-selection-error-msg {
                        text-transform: uppercase;
                    }
                }
            }
            .product-monogramming,
            .product-hemmable {
                padding: 10px 0 24px 0;
                clear: both;
                .heading {
                    padding: 0 0 2px 0;
                }
            }
            @media screen and (max-width: 1023px) {
                .product-thumbnails {
                    button {
                        &.slick-arrow {
                            &.slick-next {
                                display: none !important;
                            }
                            &.slick-prev {
                                display: none !important;
                            }
                        }
                    }
                    &.slick-arrow-bar {
                        &:before {
                            display: none !important;
                        }
                    }
                }
            }
        } // Handling special product set page
        .specialproductset {
            .prod-detail-page {
                .image {}
                .info {
                    .product-bundle-main {
                        width: 100%;
                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }
                        .product-col-2 {
                            &.product-set {
                                padding: 0;
                                box-sizing: border-box;
                                @media screen and (max-width: 1024px) {
                                    padding: 0;
                                }
                            }
                        }
                    }
                    .recommendation {
                        width: 16.1%;
                        @media screen and (max-width: 1024px) {
                            width: 100%;
                        }
                    }
                }
            }
            .product-set {
                border-bottom: 0;
            }
            .product-teasers {
                margin-top: 5px;
            }
            .product-name {
                margin: 16px 0 5px;
            }
            .product-set {
                .product-price {
                    margin-bottom: 12px;
                }
            }
            .product-set-list {
                border-top: 1px solid $gainsboro;
                margin-top: 24px;
                .product-set-item {
                    border-bottom: 6px solid $gainsboro;
                }
                .product-set-item:last-child {
                    border-bottom: 0;
                }
                .product-variations {
                    ul {
                        .attribute {
                            border-bottom: 1px solid $gainsboro;
                            padding: 0 0 17px 0;
                            &.size {
                                .attribute_label {
                                    margin: 24px 0 12px;
                                }
                                .swatches li a {
                                    margin: 3px 10px 7px 0px;
                                }
                            }
                        }
                        .attribute:last-child {
                            border-bottom: 0;
                        }
                    }
                }
                .promotion {
                    margin: 10px 0;
                }
            }
            .product-set-details .product-variations .attribute:nth-child(2n) {
                border: 0;
            }
            .product-add-to-cart {
                border-top: 1px solid $gainsboro;
                .availability-web {
                    padding: 15px 0;
                }
                .shippingdetails {
                    margin: 29px 0 0;
                }
            }
            .share-icon-container {
                margin: 0 0;
            }
            .tabs {
                margin: 37px 0 0 0;
            } //handling hemming and monogram
            .presonalised-msg-norefund {
                font-family: $MrEavesReg;
                font-size: $base-font + 5px;
            }
            .product-hemmable,
            .product-monogramming {
                .checkbox-content {
                    .checkbox {
                        input[type="checkbox"] {
                            background: url('../../../images/checkbox.svg') no-repeat;
                            width: 25px;
                            height: 25px;
                            border: none;
                            outline: none;
                            vertical-align: top;
                            opacity: inherit;
                            z-index: 9;
                            margin-left: 0;
                        }
                        input[type="checkbox"]:checked {
                            background: url('../../../images/checkmark.svg') no-repeat center;
                            width: 26px;
                        }
                    }
                    label {
                        &:before {
                            display: none;
                        }
                        &:after {
                            display: none;
                        }
                        span {
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }
                    }
                }
                .hemming-drawer {
                    ul {
                        li {
                            border: 1px solid $dim-gray;
                            &.selected {
                                background: $dim-gray;
                                border: 1px solid $dim-gray;
                            }
                        }
                    }
                }
                .personalized {
                    .selected-option {
                        font-size: $base-font + 5px;
                        font-family: $MrEavesReg;
                        border: 1px solid $dim-gray;
                    }
                    .selection-list {
                        li {
                            font-size: $base-font + 5px;
                            font-family: $MrEavesReg;
                        }
                    }
                }
                .input-fields {
                    font-size: $base-font + 5px;
                    font-family: $MrEavesReg;
                    padding-left: 17px;
                    border: 1px solid $dim-gray;
                }
                .input-fields-set {
                    .character-left {
                        .monogramming-charactercount {
                            font-size: $base-font + 5px;
                            font-family: $MrEavesReg;
                        }
                    }
                }
                .monogramming-preview {
                    textarea {
                        font-size: $base-font + 5px;
                        font-family: $MrEavesReg;
                        border: 1px solid $dim-gray;
                    }
                }
            }
        }
    }
}

//PDP page layouts settings
.pt_product-details {
    .b_product_badge {
        position: absolute;
        top: 0;
        left: 0;
    }
    @media screen and (min-width: 1025px) {
        #main {
            max-width: 100%; // Adding this class to div will adjust the content to center
            .adjust-inner-content {
                max-width: 1260px;
                margin: 0 auto;
                width: 100%;
            }
        }
    }
    #wrapper {
        #main {
            @media screen and (max-width: 1023px) {
                padding: 0;
                margin: 0;
                .adjust-inner-content {
                    margin: 0;
                    padding: 0 10px;
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        #main {
            margin: 0 0;
            .primary-content {
                padding: 0;
                margin-top: 20px;
            }
            .pdp-main {
                .product-col-wrapper {
                    margin-top: 0;
                }
                .product-variations {
                    .attribute {
                        &:last-child {
                            padding-bottom: 3px;
                        }
                    }
                }
                .product-actions {
                    .share-icon-container {
                        .share-icon-label {
                            padding-right: 3px;
                        }
                        a.share-icon {
                            padding: 0 18px;
                            &.share-mail {
                                padding: 0 9px;
                            }
                        }
                    }
                }
            }
            .product-col-1 {
                margin-right: 14px;
            }
            .product-name {
                font-size: $base-font + 6px;
                line-height: 32px;
                margin-top: 27px;
            }
            .product-price {
                margin-bottom: 9px;
            }
            .product-add-to-cart {
                button {
                    &.all-set-button {
                        width: 100%;
                    }
                }
                .add-to-cart {
                    width: 75.2%;
                    float: left;
                    margin-top: 0;
                    padding: 12px 0;
                    min-height: 50px;
                }
                .inventory {
                    width: 24.8%;
                    min-height: 50px;
                }
                .wishlist {
                    float: none;
                    width: 100%;
                    padding: 20px 0 0 0;
                    a {
                        float: none;
                        margin: 0 auto;
                        text-align: center;
                        display: inline-block;
                    }
                }
                .shippingdetails {
                    margin: 14px 0 0;
                }
            }
            .product-actions {
                margin-top: 7px;
            } // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none; //padding: 0 15% 0 0;
                        margin: 0 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        .product-col-2 {
                            padding: 0 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #main {
            .e-giftcard-detail {
                .product-add-to-cart {
                    button {
                        width: 100%;
                        border-top-left-radius: 15px;
                    }
                }
            }
            .pdp-main {
                .product-review {
                    display: block;
                }
            }

            .product-col-1 {
                &.product-set {
                    width: 100%;
                    padding-right: 0;
                    margin-right: 0;
                    .share-icon-container {
                        display: block;
                    }
                    .product-primary-image {
                        width: 48.7%;
                        float: left;
                        .product-primary-image {
                            width: 100%;
                        }
                    }
                    .product-col-1-productdetail {
                        float: right;
                        width: 46.4%;
                        @media screen and (max-width: 767px) {
							width: 100%;
							float: none;
							padding: 0 10px;
						}
                    }
                }
            }
            .product-col-2 {
                &.product-set {
                    width: 100%;
                    .product-add-to-cart {
                        button {
                            width: auto;
                            padding-left: 42px;
                            padding-right: 42px;
                            &.all-set-button {
                                float: right;
                            }
                        }
                        .inventory {
                            width: 13.33%;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        #main {
            margin: 0 0;
            .standardproductset {
                .product-col-1-productdetail {
                    padding:0;
                    width:100%;
                   	@include prefix(box-sizing, border-box, $prefixArray);
                }
                .product-thumbnails {
                    &.hide-mobile {
                        display: block;
                        .thumb {
                            width: 100%;
                            position: relative;
                            padding-right: 0;
                            a.thumbnail-link {
                                left: 0;
                                width: 100%;
                                height: auto;
                            }
                        }
                        .slick-dots {
                            margin: 15px 20px 15px 0;
                            float: right;
                            li {
                                float: left;
                                margin-left: 10px;
                                button {
                                    text-indent: -99999px;
                                    border: 1px solid #cccccc;
                                    background: #cccccc;
                                    width: 10px;
                                    border-radius: 50%;
                                    padding: 0;
                                    height: 10px;
                                }
                                &.slick-active {
                                    button {
                                        border: 1px solid #000000;
                                        background: #000000;
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-1 {
                    &.product-set {
                        .product-primary-image {
                            width: 100%;
                            .product-primary-image {
                               // display: none;
                            }
                        }
                    }
                }
                .product-col-2 {
                    &.product-set {
                        box-sizing: border-box;
                        .product-add-to-cart {
                            .inventory {
                                width: 80px;
                            }
                            button {
                                width: 250px;
                                padding-left: 0;
                                padding-right: 0;
                                &.all-set-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .product-col-wrapper {
                margin-top: 0;
            }
            .product-col-1 {
                width: 100%;
                margin-right: 0;
                height: 100%;
            }
            .product-col-2 {
                width: 100%;
                margin-left: 0;
                padding: 0;
            }
            .product-name {
                margin: 0 auto;
                padding: 10px 0 0;
                box-sizing: border-box;
                font-size: $base-font + 14px;
            }
            .product-pricing {
                font-size: $base-font + 6px;
            } // Handling special product set page
            .specialproductset {
                .product-col-1 {
                    &.product-bundle {
                        max-width: none;
                        width: 100%;
                        padding: 0 0;
                        margin: 0 0;
                    }
                }
                .product-bundle {
                    &.product-bundle-main {
                        width: 100%;
                    }
                }
                .product-set-list {
                    .promotion {
                        padding: 0 0;
                    }
                    .product-set-details {
                        padding: 0 0;
                    }
                }
            }
        }
        #main {
            .tabs {
                margin: 30px 0 0 0;
                .tab-label {
                    padding: 0 5px;
                }
            }
            .promotion {
                letter-spacing: 0.5px;
                margin: 0 auto;
                padding: 0;
            }
            .pdp-main .product-set .share-icon-container{
                //display: block;
            }
        }
    }
     #main {
    	.product-set-long-description {
    		border: 0;
		    .read-more-content {
		  		.read-more {
		    		color: $mainly-blue;
		    	}
		  	}
		  	&.max-height-limit{
		  		padding-bottom: 22px;

		  		@media screen and (max-width: 1023px) {
		  			padding-bottom: 27px;
		  		}
		  	}
	 	}
	}
}

.ui-dialog {
    &.terms-and-conditions {
        min-height: 260px !important;
        .ui-dialog-content {
            font-size: $base-font + 2px;
            font-family: $Helvetica;
            .terms-scrollbar-height {
                padding: 0 20px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
        }
    }
}

.js {
    .zoomContainer {
        &.js-zoom-container {
            z-index: 1;
        }
    }
    .zoomWindow {
        z-index: 99999 !important;
    }
    &.pdp-page {
        .zoomWindow {
            z-index: 1 !important;
        }
    }
}

.enlarge-video-sec {
    display: inline-block;
    width: 100%;
    padding: 25px 0 0 0;
    &.mobile-enlarge-video-sec {
        width: auto;
        padding: 0;
        position: relative;
        top: -7px;
    }
    .enlarge-video {
        display: inline-block;
        font-size: $base-font - 1px;
        font-family: $FuturaBold;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 21px;
        line-height: 19px;
        letter-spacing: 1.3px;
        &.enlarge {
            background: url('../../../images/rm/enlarge-icon.svg') no-repeat;
            float: right;
            background-size: 21% 100%;
            padding-left: 23px;
        }
        &.video-link {
            background: url('../../../images/rm/video-play-icon.svg') no-repeat;
            float: left;
            visibility: hidden;
            background-size: 28% 100%;
        }
        &.visible {
            visibility: visible;
        }
    }
}

.padding-eight {
    margin: 0 0 8px 0;
}

.quantity-ats-message {
    font-family: $MrEavesReg;
    font-size: $base-font + 2px;
    color: $guardsman-Red;
    font-family: "mreavessanaltr";
    letter-spacing: .05em;
    margin-top: -2px;
}

.max-cart-qty,
.max-cart-qty-set {
    font-family: $MrEavesReg;
    font-size: $base-font + 4px;
    margin: 0 0 10px 0;
    color: $guardsman-Red;
}

.max-cart-qty-set {
    margin-top: 10px;
}

.color-not-selected {
    font-family: $HelveticaMedium;
    color: $guardsman-Red;
    font-size: $base-font;
    &.hide-error {
        display: none;
    }
    &.show-error {
        display: block;
    }
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.full-width {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

@media screen and (max-width: 1023px) {
    .md-full-left {
        float: left;
    }
    .md-full-right {
        float: right;
    }
    .md-full-width {
        width: 100%;
    }
    .md-half-width {
        width: 46%;
    }
    .pt_product-details {
        #main {
            .standardproductset {
                .product-add-to-cart {
                    .wishlist {
                        margin: 0 0 20px 30px;
                        width: auto;
                    }
                }
            }
        }
    }
}

.enlarge-dialog {
    &.ui-dialog {
        max-width: 100%;
        .ui-icon-closethick {
            background-image: url(../../../images/dialog-close-icon.png);
            height: 23px;
            width: 23px;
            z-index: 9999;
        }
        .ui-dialog-titlebar-close {
            right: 25px;
            top: 22px;
            z-index: 99999;
        }
    }
    @media screen and (max-width: 1023px) {
        width: 100% !important;
        max-width: 100% !important;
    }
    .ui-widget-header {
        height: 0;
    }
    .enlarge-hero-image {
        width: 100%;
        overflow: hidden;
        @media screen and (min-width: 1024px) {
            width: 57.2%;
            display: inline-block;
            max-height: 700px;
            .pinch-zoom-container {
                a {
                    left: 0;
                }
            }
            .enlarge-hero-container {
                width: 100%;
                margin: 0 auto;
                max-height: 700px;
            }
            .thumb.slick-slide {
                height: 100%;
                text-align: center;
                img {
                    max-height: 100%;
                    display: inline-block;
                }
            }
        }
    }
    .enlarge-right-sec {
        padding: 20px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: $white;
        .pdpForm {
            float: left;
            width: 100%;
        }
        @media screen and (min-width: 1024px) {
            width: 35.56%;
            padding: 0;
            margin-right: 50px;
            margin-top: 65px;
            margin-bottom: 20px;
        }
        .slick-track {
            width: 100% !important;
            transform: initial !important;
            -ms-transform: translate(0cm) rotate(0deg) !important;
            /* Internet Explorer */
            -ms-transform-origin: top right !important;
        }
        .apply-scroll-bar {
            display: inline-block;
            width: 100%;
            max-height: 615px;
            @media screen and (min-width: 768px) and (max-width: 1023px) {
                max-height: 200px;
            }
            .product-variation-content {
                .product-variations {
                    .swatches {
                        &.color {
                            float: left;
                            width: 100%;
                            @media screen and (min-width: 768px) and (max-width: 1023px) {
                                max-width: 270px;
                            }
                        }
                    }
                }
            }
        }
        .slimScrollRail,
        .slimScrollBar {
            -moz-border-radius: 0 !important;
            border-radius: 0 !important;
            -webkit-border-radius: 0 !important;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)" !important; // IE8
            filter: alpha(opacity=1) !important; // IE 5-7
            opacity: 1 !important;
        }
    }
    .pdp-main {
        .product-name {
            margin: 0;
            line-height: normal;
            @media screen and (max-width: 1023px) {
                font-size: $base-font + 26px;
            }
        }
        .enlarge-thumb-image {
            height: auto;
            width: 100%;
            padding: 10px 0 0 0;
            @media screen and (min-width: 1024px) {
                padding: 10px 0 0 0;
            }
        }
        .product-variations {
            .attribute {
                border: none !important;
            }
        }
    }
    .enlarge-thumb-image {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 69px !important;
            @media screen and (min-width: 1024px) {
                width:73px !important
            }
            a {
                img {
                    margin-bottom: 10px;
                }
            }
        } // thumbnail image
        img {
            cursor: pointer;
            max-width: 100%;
            height: auto;
        }
        h2 {
            margin: 1rem 0 .3rem;
        }
    }
    button {
        &.slick-arrow {
            background: url('../../../images/enlarge-slider-lt-rt-icon.png') no-repeat;
            width: 15px;
            height: 34px;
            padding: 0;
            border: none;
            text-indent: -9999px;
            position: absolute;
            -webkit-transition: initial;
            -ms-transition: initial;
            transition: initial;
            z-index: 9999;
            top: 0;
            bottom: 0;
            margin: auto;
            &.slick-prev {
                background-position: 0 -68px;
            }
            &.slick-next {
                background-position: 0 4px;
            }
        }
    }
}

#product-nav-container {
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 160px;
    div {
        float: left;
        text-align: center;
        width: 77px;
    }
    img {
        max-height: 100%;
        max-width: 80%;
    }
    span {
        display: block;
        height: 65px;
    }
    .divided span {
        border-right: 1px solid $gainsboro;
        padding-right: 5px;
    }
}

.pdp-main {
    @extend %clearfix;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    label {
        padding: 0;
        text-align: left;
        text-transform: uppercase;
        width: auto;
    }
    .product-review {
        display: none;
    }
    .product-col-wrapper {
        margin-top: 25px;
    }
    .product-col-1 {
        float: left;
        width: 31.7%;
        @media screen and (max-width: 767px) {
            width: 100%;
        }
        &.product-set {
            width: 38.1%;
            box-sizing: border-box;
        }
        &.product-bundle {
            padding: 0;
            .product-primary-image {
                text-align: left;
            }
        }
    }
    .product-bundle {
        float: left;
        padding: 0;
        .product-col-2 {
            &.product-set {
                width: 100%;
                padding: 0;
                border-bottom: 1px solid $very-light-gray;
            }
            .product-thumbnails {
                display: none;
            }
            .promotion {
                margin: 0 0 31px 0;
            }
            .product-shortdescription {
                display: none;
            }
            .product-longdescription {
                display: none;
            }
            &.product-detail {
                .product-set-item {
                    padding: 20px 0 0 0;
                    border-width: 6px;
                    &:last-child {
                        border-bottom: 1px solid $very-light-gray;
                    }
                    .product-add-to-cart {
                        display: none;
                    }
                }
                .product-set-image {
                    width: 26.3%;
                    .view-details {
                        display: none;
                    }
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    width: 100%;
                    padding: 0;
                    .product-hemmable {
                        border-bottom: none;
                    }
                    .product-name {
                        display: none;
                    }
                    .product-price {
                        display: none;
                    }
                    .attribute {
                        display: none;
                    }
                    .promotion {
                        display: none;
                    }
                    .product-variations {
                        .attribute {
                            display: block;
                            margin: 0;
                            padding: 0 0 19px 0;
                            border-top: none;
                            &:nth-child(2n) {
                                border-bottom: 1px solid $very-light-gray;
                                padding: 0 0 15px 0;
                            }
                            &:nth-child(3n) {
                                /*border-top: 1px solid $very-light-gray;*/
                                padding: 0 0 15px 0;
                            }
                        }
                        .size_label {
                            text-transform: uppercase;
                        }
                    }
                }
            }
            .product-add-to-cart {
                margin: 0 0 29px 0;
                width: 100%;
                padding: 0;
                display: inline-block;
                float: left;
                border-bottom: none;
                .product-set-main-button-parent {
                    display: none;
                }
                .availability-web {
                    padding: 20px 0 15px 0;
                    border-top: 0;
                    .availability-novariation {
                        font-size: $base-font + 3px;
                        font-style: normal;
                        color: $black;
                        font-family: $MrEavesReg;
                    }
                }
                button {
                    background-color: $grape;
                    color: $white;
                    border-color: $grape;
                    margin: 0;
                    float: left;
                    padding: 12.5px 50px;
                    border-top-left-radius: 0;
                    -webkit-border-top-left-radius: 0;
                    -moz-border-top-left-radius: 0;
                }
            }
            .product-actions {
                padding: 0;
                float: right;
                border: none;
                .wishlist {
                    padding: 60px 0 0 0;
                }
                .gift-registry {
                    display: none;
                }
            }
        }
        .share-icon-container {
            display: inline-block;
            margin: 22px 0 0 0;
        }
    }
    .product-teasers {
        margin-bottom: 10px;
        .rating-text {
            font-size: $base-font - 1px;
            text-transform: uppercase;
            line-height: 21px;
            letter-spacing: 1px;
            margin: 1px 0 0 3px;
            cursor: pointer;
        }
        .TTratingBox {
            float: left;
            width: 113px;
        }
    }
    .turntofit {
        font-size: $base-font + 3px;
        font-family: $MrEavesReg;
        display: inline-block;
        width: 100%;
        margin: 7px 0 8px;
        letter-spacing: 0.5px;
        .turntofitcounts {
            font-family: $MrEavesBold;
            letter-spacing: 1px;
        }
    }
    .product-col-2 {
        float: left;
        width: 54%;
        padding: 0 9.5%;
        box-sizing: border-box;
        #zoom-image-container {
            display: inline-block;
            width: 100%;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
            padding: 0;
        }
        .product-review {
            display: block;
        }
        &.giftcard-detail {
            .inventory {
                display: block;
                margin-top: 10px;
            }
        }
        &.e-giftcard-detail {
            .inventory {
                display: none;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-name {
                line-height: 29px;
            }
            .custom-select {
                select {
                    &.error~.selected-option {
                        color: $guardsman-Red;
                    }
                }
            }
            .please-select {
                &.error {
                    color: $guardsman-Red;
                }
            }
            .product-special-messages {
                padding: 10px 0;
            }
            .tab-content {
                padding: 0;
                p {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0 0 15px 15px;
                }
            }
            .terms-conditions {
                margin: 0 0 29px 0;
            }
            .product-variations {
                &.gift-card {
                    padding: 0;
                }
                .attribute {
                    &:first-child {
                        padding: 0;
                        margin: 26px 0 0 0;
                        border-bottom: 1px solid $gainsboro;
                        border-top: 0;
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                            border-bottom: 0;
                        }
                    }
                    &:last-child {
                        margin: 25px 0 0 0;
                        padding: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding-top: 4px;
                border: 0;
            }
            .personal-message {
                margin: 16px 0 0 0;
                .label-inline {
                    .field-wrapper {
                        input[type="checkbox"] {
                            margin: 0 3px 0 2px;
                            width: 16px;
                            height: 16px;
                            border: 1px solid $white;
                            outline: 1px solid $black;
                        }
                    }
                }
            }
            .fields-address {
                .form-row {
                    margin: 14px 0 6px 0;
                    width: 100%;
                    input[type="text"],
                    textarea {
                        @media screen and (max-width: 1023px) {
                            width: 100%;
                        }
                        &.error {
                            &::-webkit-input-placeholder {
                                color: $guardsman-Red;
                            }
                            &::-moz-placeholder {
                                color: $guardsman-Red;
                            }
                            &:-ms-input-placeholder {
                                color: $guardsman-Red;
                            }
                            &:-moz-placeholder {
                                color: $guardsman-Red;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                button {
                    margin-top: 15px;
                }
            }
            .product-actions {
                clear: both;
            }
        }
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-variations {
                .swatches {
                    li {
                        a {
                            width: 85px;
                            height: 50px;
                            border-radius: 0;
                            -webkit-border-radius: 0;
                            -mox-border-radius: 0;
                            img {
                                border-radius: 0;
                                -webkit-border-radius: 0;
                                -mox-border-radius: 0;
                            }
                        }
                    }
                }
            }
            .gift-card {
                padding: 10px 0 0 0;
            }
            .product-add-to-cart {
                button {
                    margin-top: 10px;
                }
                .wishlist {
                    padding: 25px 0 0 0;
                }
            }
            .personal-message {
                margin: 20px 0 0 0;
            }
            .e-gift-email {
            	.form-row {
            		.option-text {
            			display: none;
            		}
            		&.error {
            			label {
            				span {
            					color: $guardsman-Red;
            				}
            			}
            		}
            	}
                .field-wrapper {
                    span {
                        &.error {
                            float: left;
                            width: 100%;
                            padding: 0;
                            display: block !important;
                            color: $guardsman-Red;
                        }
                    }
                }
                input[type="text"] {
                    width: 290px;
                    height: 50px;
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                    &.error {
                        &::-webkit-input-placeholder {
                            color: $guardsman-Red;
                        }
                        &::-moz-placeholder {
                            color: $guardsman-Red;
                        }
                        &:-ms-input-placeholder {
                            color: $guardsman-Red;
                        }
                        &:-moz-placeholder {
                            color: $guardsman-Red;
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .product-add-to-cart {
                .fields-address {
                	.form-row {
                		.option-text {
                			display: none;
                		}
	            		&.error {
	            			label {
	            				span {
	            					color: $guardsman-Red;
	            				}
	            			}
	            		}
	            	}
                    label {
                        //display: none;
                    }
                    .form-row {
                        input[type="text"] {
                            @media screen and (max-width: 767px) {
                                width: 100%;
                            }
                        }
                    }
                    .field-wrapper {
                        span {
                            &.error {
                                float: left;
                                width: 100%;
                                padding: 10px 0;
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
        &.giftcard-detail,
        &.e-giftcard-detail {
            .gift-card {
                padding: 20px 0 0 0;
                border-top: 1px solid $alto;
                .variant-dropdown {
                    border: none;
                    position: relative;
                    width: 180px;
                    display: inline-block;
                    .custom-select {
                        &::after {
                            right: 25px;
                        }
                    }
                    select {
                        width: 180px;
                        outline: none;
                        -moz-appearance: none;
                        appearance: none;
                        -webkit-appearance: none;
                        padding: 0 10px;
                        font-size: $base-font - 1px;
                        font-family: $HelveticaMedium;
                        letter-spacing: 1px;
                        height: 50px;
                    }
                    .custom-select {
                        .selected-option {
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
        .product-special-messages {
            padding: 5px 0;
            font-size: $base-font + 2;
            line-height: 20px;
            text-transform: uppercase;
            color: $special-product-messages-color;
            letter-spacing: 1px;
            @media screen and (max-width: 767px) {
                font-size: $base-font + 2px;
            }
        }
        .tab-content {
            font-size: $base-font;
            font-family: $Helvetica;
            padding: 20px 0;
            ul {
                list-style-type: disc;
                padding: 0 0 30px 15px;
                li {
                    list-style-type: disc;
                    line-height: 18px;
                }
            }
        }
        .terms-conditions {
            width: 100%;
            display: inline-block;
            margin: 0 0 25px 0;
            font-size: $base-font;
            font-family: $Helvetica;
            a {
                width: auto;
                text-decoration: underline;
                font-family: $HelveticaBold;
            }
            #dialog {
                display: none;
            }
        }
        .product-add-to-cart {
            >label {
                display: none;
            }
            .shop-the-look {
                display: none;
            }
            .product-price {
                display: none;
            }
            .all-set-button {
                float: right;
                padding: 13px 36px;
                margin: 40px 0 20px;
                background: $mainly-blue;
                color: $white;
                font-size: 15px;
                font-family: $Futura;
                border-color: $mainly-blue;
                max-width: 100%;
                border-top-left-radius: 15px;
            }
            .product-set-main-button-parent {
                margin: 40px 0 20px;
            }
        }
        .top-add-all {
            &.product-add-to-cart {
                width: 100%;
                border-top: 1px solid $gainsboro;
                border-bottom: 1px solid $gainsboro;
                padding: 12px 0;
                margin-top: 30px;
                .all-set-button,
                .product-set-main-button-parent {
                    margin: 0;
                }
                .shop-the-look {
                    line-height: 50px;
                    font-size: 32px;
                    font-family: $MrEavesReg;
                    display: inline-block;
                }
            }
        }
        .product-set-main-button-error,
        .variation-selection-error-msg,
        .product-set-product-button-error {
            color: $guardsman-Red;
            font-size: 18px;
            font-family: $MrEavesReg;
            .bold_label {
                font-family: $MrEavesBold;
            }
        }
        .attribute {
            .variation-selection-error-msg {
                font-size: 14px;
            }
        }
        .product-set-main-button-parent {
            display: inline-block;
            vertical-align: middle;
            line-height: 50px;
            padding-left: 20px;
            .product-set-main-button-error {
                padding: 0;
            }
        }
        .price-standard {
            border-right: 1px solid $gainsboro;
            padding-right: .5rem;
        }
    }
    .product-name {
        float: none;
        color: $black;
        font-size: $base-font + 20px;
        line-height: 39px;
        margin: 25px 0 15px 0;
        width: 100%;
        font-style: normal;
        font-family: $MrEavesReg;
        font-weight: inherit;
        .item-name {
            font-family: $MrEavesReg;
            font-size: 30px;
        }
    }
    .product-number {
        color: $very-light-gray;
        font-size: .65rem;
        margin-bottom: .5rem;
    }
    .product-price {
        font-size: $base-font + 6px;
        margin-bottom: 15px;
        font-family: $Futura;
        .price-standard {
            border-right: none;
            padding-right: 23px;
            color: $black;
            text-decoration: line-through;
        }
        .price-standard-exist {
            color: $guardsman-Red;
        }
        .price-tiered {
            color: $sangria;
            font-size: .6em;
            line-height: 1.5em;
        }
        .out-off-stock{
            color: $dark-purple-red;
        }
    }
    .product-review {
        @extend %clearfix;
        margin-bottom: 15px;
    }
    .product-main-attributes {
        .attribute {
            padding: .2em 0;
        }
        .label {
            padding: 0;
        }
    }
    .product-primary-image {
        position: relative;
        &.not-a-device {
            max-width: 100%;
            text-align: center;
            display: inline-block;
            .main-image {
                display: block;
                overflow: visible;
                position: relative;
            }
        }
    }
    .product-info {
        clear: both;
        padding-top: 1em;
        margin: 10px auto 0;
        ul {
            font-size: .8rem;
            list-style: square;
            padding: 0 5em;
            @media screen and (min-width: 768px) {
                padding-bottom: 1em;
                padding-top: 1em;
            }
        }
        .product-details-container {
            @media screen and (min-width: 768px) {
                display:flex;
                flex-direction: row-reverse;
                .product-details-description {
                    flex: 1;
                }
                .product-details-video {
                    min-width: 320px;
                    padding-left: 1em;
                }
            }
        }
    }
    .productmessage {
        span {
            color: #e7aa17;
            font-size: $base-font + 2px;
            font-family: $MrEavesReg;
            margin-bottom: 7px;
            display: inline-block;
            width: 100%;
            letter-spacing: 0.5px;
            margin-top: 0;
        }
    }
    .product-content-quickorder {
        .product-add-to-cart {
            overflow: inherit;
        }
    } // Product Add to Cart Area (Availability, Quantity)
    .product-add-to-cart {
        display: block;
        margin: 0;
        overflow: visible;
        border-top: 1px solid $gainsboro;
        padding-top: 14px;
        .quantity-ats-message,
        .color-not-selected {
            @media screen and (min-width: 768px) {
                margin-bottom: 17px;
            }
        }
        .wishlist {
            width: 100%;
            display: inline-block;
            font-family: $FuturaBold;
            font-size: $base-font - 1px;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 15px 0 0 0;
            @media screen and (min-width: 768px) {
				 margin-left: 24px;
			}
            a {
                .wishlist-logo {
                    display: inline-block;
                    line-height: normal;
                    float: left;
                    background: url('../../../images/wishlist.svg') no-repeat;
                    width: auto;
                    height: 18px;
                    padding-left: 30px;
                    &.added-to-wishlist{
						background: url('../../../images/wishlist-solid.svg') no-repeat;
					}
                }
            }
        }
        .inventory {
            width: 70px;
            float: left;
            white-space: nowrap;
            position: relative;
            label {
                float: none;
                text-align: right;
            }
            input {
                font-size: 1.5em;
                height: 50px;
                width: 50px;
                text-align: center;
                @media screen and (min-width: 768px) {
                    height: 37px;
                    width: 37px;
                }
            }
        }
        .shippingdetails {
            float: left;
            width: 100%;
            margin: 30px 0 0;
            border-top: 1px solid $gainsboro;
            border-bottom: 1px solid $gainsboro;            
            box-sizing: border-box;
            .heading {
                font-size: $base-font;
                margin: 0;
                text-align: left;
                font-family: $FuturaBold;
                background: url(../../../images/pdp-arrow-closed.png) no-repeat right;
                &.expanded {
                    background: url(../../../images/pdp-arrow-open.png) no-repeat right;
                }
            }
            .shippingdrawer {
                font-family: $MrEavesReg;
                font-size: $base-font + 3;
                .productinfodate {
                    p {
                        margin: 1px 0;
                    }
                }
            }
        }
        button.add-to-cart {
            margin-left: 0;
            padding-left: 0;
            padding-right: 0;
            font-family: $Futura;
            border-top-left-radius: 0;
            -webkit-border-top-left-radius: 0;
            -moz-border-top-left-radius: 0;
            display: inline-block;
            @media screen and (min-width: 1024px) {
                max-width: 270px;
                width: 100%;
            }
        }
        .dw-apple-pay-button {
            margin-left: .5rem;
            @media screen and (min-width: 768px) {
                width: 172px; // make sure apple pay button is as big as Add To Cart
            }
        }
        .availability-web {
            label {
                display: none;
            }
            .availability-msg {
                p {
                    font-family: $MrEavesReg;
                    color: $black;
                    font-size: 16px;
                }
            }
        }
    }
    .savingmessage {
        color: $guardsman-Red;
        font-size: $base-font + 2;
        ;
        font-family: $FuturaMedium;
        line-height: 20px;
        text-transform: uppercase;
        width: 100%;
        letter-spacing: 1px;
        @media screen and (min-width: 1024px) {
            margin-bottom: 5px;
        }
    }
    .promotion {
        display: inline-block;
        float: left;
        width: 100%;
        margin-bottom: 5px;
        .promotion-title {
            display: none;
        }
        .promotion-callout {
            color: $guardsman-Red;
            font-size: $base-font + 2px;
            font-family: $FuturaMedium;
            text-transform: uppercase;
            letter-spacing: 1px;
            i {
                display: none;
            }
            span.callout-message {
                display: inline-block;
                margin-top: 6px;
                &:nth-of-type(1) {
                    margin-top: 0;
                }
            }
            .promo-tooltip {
                font-size: .7rem;
                margin-left: 1px;
                text-transform: uppercase;
                color: $dim-gray;
                text-decoration: underline;
                font-family: $Futura;
                display: inline-block;
            }
        }
    }
    .product-feature-messages {
        font-size: $base-font + 2;
        font-family: $FuturaMedium;
        line-height: 20px;
        text-transform: uppercase;
        margin-top: 5px;
    }
    .product-actions {
        @extend %clearfix;
        padding: 21px 0;
        border-top: 1px solid $gainsboro;
        clear: both;
        .socialsharing {
            display: block;
            width: 100%;
        }
        .share_label {
            font-family: $FuturaBold;
            color: $black;
            font-size: $base-font;
            vertical-align: middle;
        }
        a {
            border: 0;
            color: $black;
            font-family: $HelveticaBold;
            padding: 0;
            height: 52px;
            line-height: 52px;
            &.share-icon {
                display: inline-block;
                line-height: 0;
                height: auto;
                vertical-align: middle;
                padding: 0 15px;
                font-size: $base-font + 9;
            }
        }
    }
    .product-shortdescription {
        color: $black;
        font-size: $base-font;
        font-family: $Futura;
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .product-longdescription {
        color: $black;
        font-size: $base-font + 4 !important;
        font-family: $MrEavesReg;
        margin-bottom: 20px;
        line-height: 25px;
        text-transform: none;
        font-weight: normal;
    }
    .heading {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        font-size: $base-font + 1px;
        font-family: $FuturaBold;
    }
    .share-icon-container {
        .share-icon {
            display: inline-block;
            vertical-align: middle;
            padding: 0 10px;
            img {
                width: 26px;
                padding-top: 2px;
            }
            &.share-facebook {
                padding: 0px 17px;
                img {
                    width: 18px;
                }
            }
            &.share-mail {
                padding: 0 14px;
                img {
                    width: 22px;
                }
            }
        }
        .share-icon-label {
            font-size: $base-font;
            font-family: $FuturaBold;
            color: $black;
            padding-right: 5px;
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
    .product-thumbnails,
    .product-thumbnails ul,
    .enlarge-thumb-image {
        @media screen and (min-width: 768px) {
            width: 95%;
            padding-top: 15px;
            overflow: hidden;
            height: 120px;
            &.remove-ht-ovr-hidden {
                overflow: visible;
                height: auto;
            }
            &.slick-arrow-bar {
                &::before {
                    content: "";
                    background: url('../../../images/rm/alt-lt-rt-icons.png') no-repeat;
                    background-position: -5px -44px;
                    width: 19px;
                    height: 1px;
                    display: inline-block;
                    position: absolute;
                    top: 64px;
                    right: -28px;
                }
            }
            button {
                &.slick-arrow {
                    background: url('../../../images/rm/alt-lt-rt-icons.png') no-repeat;
                    width: 6px;
                    height: 15px;
                    padding: 0;
                    border: none;
                    text-indent: -9999px;
                    position: absolute;
                    top: 0;
                    right: -19px;
                    -webkit-transition: initial;
                    -ms-transition: initial;
                    transition: initial;
                    &.slick-prev {
                        background: url('../../../images/thumb-arrow-left-b6.svg') no-repeat;
                        top: 78px;
                        transform: translateX(15px);
                        left: 100%;
                        &.slick-disabled {
                            background: url('../../../images/thumb-arrow-left-g6.svg') no-repeat;
                        }
                    }
                    &.slick-next {
                        background: url('../../../images/thumb-arrow-right-b6.svg') no-repeat;
                        top: 38px;
                        transform: translateX(15px); 
                        left: 100%;
                        &.slick-disabled {
                            background: url('../../../images/thumb-arrow-right-g6.svg') no-repeat;
                        }
                    }
                }
            }
        }
    }
    .main-product-set-details,
    .product-set {
        #thumbnails {
            padding-bottom: 30px;
            .thumb {
                padding-top: 0;
                width: 25%;
                padding-right: 15px;
                box-sizing: border-box;
                line-height: 0;
                float: left;
                @media screen and (max-width: 767px) {
                    padding-right: 0;
                }
                a {
                    @media screen and (max-width: 767px) {
                        width: 63.5px;
                        float: left;
                        height: 93.5px;
                        left: 0;
                    }
                }
            }
        }
        .product-price {
            font-size: $base-font + 6px;
            .price-standard,
            .price-standard-exist {
                font-size: $base-font + 6px;
                @media screen and (max-width: 767px) {
                    font-size: $base-font + 2px;
                }
            }
        }
        .share-icon-container {
            //display: none;
        }
    }
    .product-variations {
        width: 100%;
        display: inline-block; //To display 3 size attributes in mobile.
        @media screen and (max-width: 767px) {
            ul {
                li {
                    ul.size {
                        //max-width:340px;
                        float: left;
                    }
                }
            }
        }
        .attribute {
            padding: 5px 0 8px;
            border-top: 1px solid $gainsboro;
            &:first-of-type {
                margin-top: 17px;
            }
            &.size {
                .attribute_label {
                    margin: 21px 0 10px;
                }
            }
            .attribute_label {
                margin: 15px 0 10px;
                .label,
                .selected-value,
                .size-chart-link {
                    display: inline-block;
                    color: $black;
                    font-family: $FuturaBold;
                    font-size: $base-font;
                    margin: 0;
                    letter-spacing: 1px;
                    margin-right: 3px;
                }
                .selected-value, .final-sale-color-msg {
                    font-family: $Futura;
                    text-transform: uppercase;
                    letter-spacing: 0.64px;
					@media screen and (min-width: 320px) and (max-width: 767px) {
                        font-size: $base-font;
						letter-spacing: 0.55px; 
					}
                }
                .size-chart-link {
                    font-size: 11px;
                    float: right;
                    span.size_icon,
                    span.size_label {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span.size_icon {
                        width: 23px;
                        padding-top: 5px;
                        img {
                            width: 100%;
                        }
                    }
                    span.size_label {
                        padding-left: 3px;
                        text-transform: uppercase;
                    }
                }
            }
            &.size_border {
                .attribute_label {
                    // margin: 21px 0 11px;
                }
                .swatches {
                    li {
                        a {
                            margin: 5px 10px 5px 0;
                        }
                    }
                }
            }
        }
    } // Product Set Display
    .product-set {
        .product-set-list {
            border-top: 1px solid transparent;
            .product-set-details {
                padding-left: 4%;
                margin: 0;
                width: 78%;
                float: right;
                box-sizing: border-box;
                .product-price {
                    margin-bottom: 3px;
                    display: inline-block;
                    float: left;
                }
            }
            .product-image-container {
                float: left;
                box-sizing: border-box;
                background: transparent;
                .thumb {
                    border-bottom: 5px solid transparent;
                    float: left;
                    margin-right: 10px;
                    padding-top: 20px;
                    width: 25%;
                    &.selected {
                        a {
                            img {
                                border-bottom: 5px solid $wine-berry;
                                box-sizing: border-box;
                                cursor: pointer;
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
            .product-set-item {
                clear: both;
                overflow: inherit;
                display: inline-block;
                padding-top: 35px;
                padding-bottom: 40px;
                width: 100%; //border-bottom: 1px solid $light-gray;
                .product-variations {
                    width: 100%;
                    float: left;
                }
            }
            .product-set-image {
                width: 21.6%;
                display:inline-block;
                img {
                    width: 100%;
                }
            }
            .product-number {
                display: none;
            }
            .attribute {
                h3 {
                    width: 25%;
                }
                .label {
                    width: auto;
                    white-space: nowrap;
                }
            }
        }
        .product-add-to-cart {
            border: none;
            padding: 0 0 0 4%;
            width: 80%;
            float: right;
            box-sizing: border-box;
            border-bottom: none;
            label {
                float: left;
                font-size: .75rem;
                padding-top: .3em;
                padding-right: .5em;
            }
            .availability-web {
                padding: 15px 0;
                /*border-top: 1px solid $alto;*/
                font-size: $base-font + 4px;
                font-family: $MrEavesBook;
                label {
                    float: none;
                }
                .color_gray {
                    font-weight: normal;
                    color: $dim-gray;
                }
            }
            .inventory {
                width: 70px;
                float: left;
                white-space: nowrap;
                height: 50px;
                position: relative;
            }
            .sub-product-item {
                background: $mainly-blue;
                border: 0;
                border-top-left-radius: 0;
                -webkit-border-top-left-radius: 0;
                -moz-border-top-left-radius: 0;
                color: $white;
                cursor: pointer;
                display: inline-block;
                font-size: $base-font + 3px;
                padding: 0 42px;
                text-align: center;
                -webkit-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                text-transform: uppercase;
                outline: 0;
                font-family: $FuturaMedium;
                letter-spacing: 1px;
                width: auto;
                height: 50px;
                float: left;
            }
            >form {
                /*display: inline-block;
                width: auto;*/
            }
            >a {
                font-family: $FuturaBold;
                color: $black;
                font-size: 11px;
                line-height: 30px;
            }
        }
        .view-details {
            font-size: $base-font - 1px;
            font-family: $Futura;
            color: $black;
            text-align: center;
            text-transform: uppercase;
            padding: 8px 0;
        }
        .product-id {
            font-family: $Futura;
            color: $silver;
            font-size: $base-font;
            text-align: center;
        }
    } // Product Recommendations
    .recommendation {
        background: $wild-sand;
        .recommendations {
            background: $white;
            border:1px solid $light-gray;
        }
        .recommendations-heading {
            font-size: $base-font + 12px;
            font-family: $MrEavesReg;
        }
        .product-tile {
            color: $black;
            border: 0;
            .product-name {
                font-size: $base-font + 6px;
                font-family: $MrEavesReg;
            }
            .product-price {
                font-size: $base-font;
            }
            &:hover {
                .quickview {
                    display: block;
                }
            }
        }



        @media screen and (max-width: 1160px) {
            .recommendations-heading {
                font-size: $base-font + 20px;
            }
            .product-tile {
                .product-name {
                    font-size: $base-font + 6px;
                }
            }
            .product-sales-price,
            .product-standard-price {
                @include faturafont();
                font-size: $base-font;
            }
        }
        @media screen and (max-width: 767px) {
            .recommendations {
                background: $wild-sand; //margin-left: -10px;
            }
            .recommendations-heading {
                font-size: $base-font + 33px;
            }
            .product-sales-price,
            .product-standard-price {
                @include faturafont();
                font-size: $base-font + 3px;
            }
        }
    } //ends recommendation
    .tabs {
        width: 100%;
        display: inline-block;
        margin: 20px 0 0 0;
        .tab-label {
            font-size: $base-font;
            text-transform: uppercase;
            font-family: $FuturaBold;
            text-align: center;
            letter-spacing: 1px;
            color: $black;
            &.current {
                border-bottom: 4px solid $mainly-blue;
                &:after {
                    content: '';
                    width: 0;
                    border-style: solid;
                    border-width: 7px 0 7px 9px;
                    border-color: transparent transparent transparent $mainly-blue;
                    display: inline-block;
                    -webkit-transform: translate(-50%, -50%) rotate(90deg);
                    transform: translate(-50%, -50%) rotate(90deg);
                    position: absolute;
                    left: 50%;
                    right: 50%;
                    bottom: -22px;
                }
                &:hover {
                    border-bottom: 4px solid $mainly-blue;
                }
            }
            &:hover {
                border-bottom-color: transparent;
            }
        }
        .tab-content {
            background-color: $wild-sand;
            display: none;
            padding: 41px 0;
            &.current {
                display: block;
            }
            .tab-content-details {
                font-size: $base-font + 6px;
                font-family: $MrEavesReg;
                line-height: 22px;
                margin: 0 auto;
                text-align: left;
                display: inline-block;
                max-width: 886px;
                width: 100%;
                .tab-left {
                    width: 57%;
                    display: inline-block;
                    float: left;
                }
                .tab-right {
                    width: 39%;
                    padding: 0 0 0 37px;
                    display: inline-block;
                    box-sizing: border-box;
                }
                p {
                    font-family: $MrEavesReg;
                    font-weight: normal;
                    font-size: 18px;
                    @media screen and (max-width: 767px) {
                        font-size: 15px;
                    }
                }
                ul {
                    padding: 0;
                    li {
                        font-family: $MrEavesReg;
                        font-weight: normal;
                        margin: 0 0 5px 0;
                        font-size: 18px;
                        text-indent: 5px;
                        &:before {
                            content: "-";
                            text-indent: -5px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 15px;
                        }
                        &:last-child:not(.tt-o-page-list__number) {
                            margin: 0 0 19px 0;
                        }
                    }
                }
                .tab-detail-header {
                    text-transform: capitalize;
                }
                .tab-fit,
                .tab-care {
                    padding: 0 0 15px 0;
                }
            }
        }
    }
    .product-primary-image {
        .product-video-container {
            position: absolute;
            z-index: 1;
            background-color: #fff;
            display: none;
            width: 100%;
            height: 100%;
        }
    }
}

.main-image {
    .zoom_container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        top: 0;
        left: 120%;
        z-index: 999;
        display: none;
        #zoom-window {
            overflow: visible !important;
        }
    }
    &:hover {
        .zoom_container {
            display: block;
        }
    }
}

.primary-image {
    max-width: 100%;
}

.product-thumbnails {
    @media screen and (max-width: 767px) {
        margin-bottom: 21px;
    }
    ul {
        @extend %clearfix;
    }
    @media screen and (min-width: 768px) {
        // thumbnail
        .thumb {
            margin-right: 12px;
            width: 73px;
            float: left;
            position: relative;
            a {
                img {
                    margin-bottom: 10px;
                    display: inline-block;
                }

            }
        }
    } // thumbnail image
    img {
        cursor: pointer;
        max-width: 100%;
        height: auto;
    }
    h2 {
        margin: 1rem 0 .3rem;
    }
}

.rating {
    color: $mainly-blue;
    font-size: 16px;
    .product-info & {
        font-size: 2em;
    }
    i {
        padding-right: 3px;
    }
}

.product-variations,
.product-options {
    .attribute {
        margin: 10px 0;
        overflow: visible;
        padding: 0;
        position: relative;
        border-top: 1px solid $alto;
        h3,
        .label {
            font-size: $base-font;
            margin: 16px 0;
            text-transform: uppercase;
            display: inline-block;
            font-family: $HelveticaBold;
        }
        .value {
            width: 100%;
            display: inline-block;
        }
        ul {
            float: right;
            width: 100%;
        }
        .selected-value {
            border: 0 none;
            color: $black;
            text-transform: none;
            display: inline-block;
            font-family: $FuturaBold;
        }
        .size-chart-link {
            a {
                border-color: $white !important;
                padding: 0;
            }
        }
    }
    .swatches li {
        float: left;
        &.selected {
            a {
                border-color: $dim-gray;
                background: $dim-gray;
                color: $white;
            }
        }
        @media screen and (min-width: 1026px) {
            &:hover {
                a {
                    border-color: $dim-gray;
                    background: $dim-gray;
                    color: $white;
                }
            }
        }
        &.unselectable {
            a {
                border-color: $alto;
                opacity: .8;
                position: relative;
                color: $alto;
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    background: $alto;
                    top: 50%;
                    left: 50%;
                    -webkit-transform: translate(-50%, -50%) rotate(-19deg);
                    -moz-transform: translate(-50%, -50%) rotate(-19deg);
                    -ms-transform: translate(-50%, -50%) rotate(-19deg);
                    -o-transform: translate(-50%, -50%) rotate(-19deg);
                    transform: translate(-50%, -50%) rotate(-19deg);
                    width: 92px;
                }
            }
            &:hover {
                a {
                    border-color: $gainsboro;
                    background-color: $dim-gray;
                    opacity: .3;
                }
            }
        }
        &.unselectable {
            &.selected {
                a {
                    background-color: $dim-gray;
                    opacity: .3;
                }
                &:hover {
                   a {
                        border-color: $gainsboro;
                        background-color: $dim-gray;
                        opacity: .3;
                    }
                }
            }
        }

        a {
            font-family: $Futura;
            font-size: $base-font;
            text-transform: uppercase;
            box-sizing: border-box;
            color: $black;
            border: 1px solid $dim-gray;
            display: block;
            line-height: 30px;
            height: 32.5px;
            width: 90px;
            min-width: 90px;
            margin: 3px 10px 5px 0px;
            padding: 1px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .color li {
        a {
            width: 43px;
            min-width: 43px;
            height: 23px;
            //SC-9916
            // background: transparent !important;
            border: 0;
            margin-right: 8px;
            padding: 1px;
            line-height: 17px;
            &.color.color-swatch{
				background-clip: content-box !important;
                border: 1px solid $gainsboro;
                border-radius: 20px;
			}
            img {
                width: 100%;
                height: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                border-radius: 20px;
                border: 1px solid $gainsboro;
            }
        }
        &.selected {
            a {
                border: 1px solid $black !important;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                border-radius: 30px;
                padding: 2px;

            }
        }
        &.lightswatch {
            a {
                border: 1px solid $gainsboro;
                border-radius: 30px;
            }
        }
    }
    .sizefamily li {
        a {
            text-transform: uppercase;
        }
    }
    select {
        background-color: $white;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        height: 42px;
        width: 100%;
        margin-left: 0;
        @media screen and (min-width: 768px) {
            height: 32px;
        }
    }
}

// Tabs using just CSS, no javascript required
.tabs {
    position: relative;
    text-align: center;
}

.tab {
    .tab-switch {
        display: none;
        &:checked {
            +.tab-label {
                @media screen and (min-width: 768px) {
                    background: $white;
                    border-bottom-color: $mainly-blue;
                    color: $black;
                    font-weight: 700;
                    top: -0.0625rem;
                    transition: all 0.35s;
                    z-index: 1;
                }
            }
            +label+.tab-content {
                opacity: 1;
                transition: all 0.35s;
                z-index: 2;
            }
        }
    }
    .tab-label {    
		border: 0;
    	border-radius: 0;
        border-bottom: 3px solid $white;
        color: $nobel;
        display: block;
        font-weight: 400;
        line-height: 2.5em;
        margin-bottom: 0;
        position: relative;
        text-transform: none;
        top: 0;
        transition: all 0.25s;
        min-width: 100%;
        background: transparent;
        outline-offset: -4px;
        @media screen and (min-width: 768px) {
            cursor: pointer;
            padding: 0 1.25rem;
            &:hover {
                border-bottom-color: $nobel;
            }
        }
    }
    .tab-content {
        padding: 1em 0;
        text-align: left;
        z-index: 1;
        @media screen and (min-width: 768px) {
            left: 0;
            opacity: 0;
            position: absolute;
            top: 2.75em;
            transition: all 0.35s;
            width: 100%;
        }
    }
}

.pt_product-details {
    @media screen and (max-width: 1024px) {
        @media screen and (min-width: 960px) {
            .product-col-2 {
                .product-add-to-cart {
                    .all-set-button {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1023px) {
        #product-nav-container {
            div {
                float: right;
            }
        }
        .pdp-main {
            margin: 0;
            .product-set {
                &.product-col-1 {
                    width: 50%;
                    padding-right: 10px;
                    &.product-bundle {
                        width: 59.6%;
                        padding: 0 8% 0 0;
                    }
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0;
                    &.main-product-set-details {
                        width: 50%;
                        padding-left: 10px;
                    }
                    .product-review {
                        margin-bottom: 12px;
                    }
                    .product-shortdescription {
                        margin-bottom: 15px;
                    }
                    .product-set-list {
                        .product-set-image {
                            width: 20.6%;
                        }
                        .product-set-details {
                            width: 78.4%;
                        }
                    }
                }
            }
            .product-bundle {
                padding: 0;
                .product-col-2 {
                    &.product-detail,
                    &.product-detail-set {
                        width: 100%;
                        padding: 0;
                        &:nth-child(1n) {
                            border-bottom: none;
                        }
                        &:nth-child(2n) {
                            border-bottom: 1px solid $very-light-gray;
                        }
                        .product-name {
                            font-size: $base-font + 12px;
                        }
                        .product-thumbnails {
                            display: none;
                        }
                        .product-set-image {
                            width: 52.6%;
                        }
                        .product-set-details {
                            width: 100%;
                        }
                        .product-set-item {
                            padding: 20px 0 10px 0;
                        }
                        .product-add-to-cart {
                            width: 100%;
                            margin: 0;
                        }
                        .product-actions {
                            padding: 0;
                            width: 100%;
                            text-align: center;
                            .wishlist {
                                width: 100%;
                                padding: 20px 0;
                            }
                        }
                    }
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    @media screen and (max-width: 1023px) {
                        .fields-address {
                            .form-row {
                                .input-textarea {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .product-add-to-cart {
                        padding: 0 5px 20px;
                        @media screen and (max-width: 767px) {
                            padding: 0 0 20px;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 20px 0 0 0;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
            .tabs {
                margin: 0 auto;
                .tab-content {
                    padding: 41px 18px 41px 18px;
                    .tab-content-details {
                        width: 100%;
                    }
                }
            }
        }
        &#wrapper {
            width: 100%;
            padding-top: 0;
        }
        .primary-content {
            width: 100%;
            box-sizing: border-box;
            padding: 43px 0 0 0;
        }
    }
    @media screen and (max-width: 767px) {
        .promotion {
            float: none;
            display: inline-block;
            width: 100%;
            padding: 10px 0;
            .promotion-callout {
                line-height: normal;
            }
        }
        #main {
            padding: 0;
            .primary-content {
                margin-top: 0;
                padding: 13px 0 0;
            }
            .product-price {
                margin-bottom: 7px;
            }
            .product-variations {
                .attribute {
                    &:first-of-type {
                        margin-top: 12px;
                    }
                }
            }
            .standardproductset {
                .product-add-to-cart {
                    .wishlist {
                        width: 100%;
                        padding: 20px 0 0 0;
                        margin: 0;
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    width: 11.9%;
                }
                .wishlist {
                    width: 50%
                }
                .shippingdetails {
                    .heading {
                        font-size: 1.03em;
                        background-position: right 20px;
                    }
                    .shippingdrawer {
                        font-size: 1.27em;
                        letter-spacing: .04em;
                        .productinfo {
                            margin-top: 20px;
                        }
                    }
                }
            }
            .pdp-main .product-actions {
                padding-top: 12px;
                .socialsharing .share-icon-container {
                    .share-icon-label {
                        position: relative;
                        top: 2px;
                    }
                    a.share-icon {
                        padding: 0 14px;
                        img {
                            width: 22px;
                        }
                        &.share-facebook {
                            img {
                                width: 16px;
                            }
                        }
                        &.share-twitter {
                            img {
                                width: 27px;
                            }
                        }
                        &.share-mail {
                            padding: 5px 11px;
                        }
                    }
                }
            }
        }
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .pdp-main {
            .product-teasers {
                .TTratingBox {
                    //background-size: 313px 4152px;
                    //width: 94px;
                    //height: auto;
                    margin-right: 0;
                    float: left;
                }
                .rating-text {
                    letter-spacing: .05em;
                    margin: 0 0 0 3px;
                }
            }
            .promotion .promotion-callout span.callout-message {
                margin-top: 3px;
                &:nth-of-type(1) {
                    margin-top: 0;
                }
            }
            .product-feature-messages {
                margin-top: 3px;
            }
            .product-review {
                display: block;
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    &.product-bundle {
                        .product-set-image {
                            width: 48.4%;
                        }
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-set-list {
                            .product-set-item {
                                .product-variations {
                                    display: none;
                                }
                            }
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        #add-all-to-cart {
                            display: none;
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-image,
                        .product-set-details {
                            width: 100%;
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .inventory {
                        width: 70px;
                    }
                    .fields-address {
                        .form-row {
                            width: 100%;
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
                .product-feature-messages,
                .product-special-messages {
                    letter-spacing: .103em;
                }
                .product-special-messages {
                    padding-top: 2px;
                    margin-bottom: 5px;
                }
                .turn-to-fit {
                    letter-spacing: .05em;
                }
            }
            .top-add-all.product-add-to-cart .shop-the-look {
                font-size: 32px;
                line-height: normal;
                padding: 10px 0;
            }
            .product-set {
                &.product-col-1 {
                    width: 100%;
                    padding: 0;
                }
                &.product-col-2 {
                    width: 100%;
                    padding: 0 20px;
                    .product-add-to-cart {
                        .all-set-button {
                            width: 100%;
                            margin: 15px 0;
                        }
                    }
                    .product-set-main-button-parent,
                    .product-set-main-button-error,
                    .product-set-main-button-error,
                    .variation-selection-error-msg,
                    .product-set-product-button-error {
                        width: 100%;
                        padding-left: 0;
                        margin: 0;
                        font-size: 18px;
                        font-size: 5.6vw;
                    }
                    span.product-variations-toggle {
                        font-size: $base-font + 2;
                        font-family: $HelveticaBold;
                        line-height: normal;
                        font-size: 3.8vw;
                    }
                    &.main-product-set-details {
                        width: 100%;
                        padding: 0;
                        .product-price {
                            line-height: normal;
                            font-size: 14px;
                        }
                    }
                    .top-add-all.product-add-to-cart {
                        .all-set-button,
                        .product-set-main-button-parent {
                            display: none !important;
                        }
                    }
                    .availability-web {
                        font-size: 16px;
                        line-height: normal;
                        font-size: 3.8vw;
                        .availability-msg {
                            p {
                                font-size: $base-font + 4px;
                                line-height: normal;
                                font-size: 3.8vw;
                            }
                        }
                    }
                    .view-details,
                    .product-id {
                        display: none;
                    }
                    .product-set-list {
                        .product-set-item {
                            padding-top: 15px;
                            padding-bottom: 25px;
                            .product-set-image,
                            .product-set-details {
                                width: 100%;
                                padding: 12px 0;
                                .product-price,
                                .price-standard,
                                .price-standard-exist {
                                    font-size: 14px;
                                    line-height: normal;
                                    font-size: 4.4vw;
                                }
                            }
                        }
                    }
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0;
                    }
                }
            }
            .product-col-1 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                }
            }
            .product-col-2 {
                &.giftcard-detail,
                &.e-giftcard-detail {
                    width: 100%;
                    padding: 0;
                    .inventory {
                        width: 70px;
                    }
                    .fields-address {
                        .form-row {
                            .input-textarea {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .product-actions {
                a {
                    &.share-icon {
                        padding: 0 10px;
                    }
                }
            }
            .product-bundle {
                width: 100%;
                .product-review {
                    display: none;
                }
                .product-name {
                    display: none;
                }
                .promotion {
                    margin: 0;
                }
                &.product-col-1 {
                    width: 100%;
                    &.product-set {
                        width: 100%;
                        padding: 0;
                        .product-thumbnails {
                            ul {
                                li {
                                    text-align: center;
                                    .thumbnail-link {
                                        .productthumbnail {
                                            border-bottom: none;
                                        }
                                    }
                                }
                                &.slick-dots {
                                    float: right;
                                    li {
                                        text-align: right;
                                        float: left;
                                        margin: 0 20px 0 0;
                                        button {
                                            font-size: 0;
                                            line-height: 0;
                                            display: block;
                                            width: 10px;
                                            height: 11px;
                                            padding: 5px;
                                            cursor: pointer;
                                            color: $very-light-gray;
                                            border: 0;
                                            outline: none;
                                            background: $very-light-gray;
                                            border-radius: 50%;
                                        }
                                        &.slick-active {
                                            button {
                                                background: $black;
                                                color: $black;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .product-col-2 {
                    &.product-detail {
                        padding: 0;
                        .product-set-item {
                            padding: 15px 0 0 0;
                            .product-set-image {
                                width: 48.4%;
                            }
                            .product-set-details {
                                padding: 0 0 0 0;
                                .qv-productset-scrollbar {
                                    padding: 12px 0 0;
                                    display: block;
                                }
                            }
                            .product-variations-toggle {
                                display: none;
                            }
                            .product-variations {
                                display: block;
                                .attribute {
                                    padding: 0 0 35px 0;
                                    &:nth-child(2n) {
                                        padding: 0 0 40px 0;
                                    }
                                    .color {
                                        .swatchanchor {
                                            width: 70px;
                                            min-width: 70px;
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                        }
                        .product-actions {
                            .wishlist {
                                a {
                                    height: auto;
                                }
                            }
                        }
                        .product-add-to-cart {
                            margin: 0;
                            button {
                                margin: 0;
                                width: 73%;
                            }
                        }
                    }
                }
                .product-add-to-cart {
                    button {
                        width: 85%;
                    }
                }
                .product-actions {
                    a {
                        font-size: $base-font + 10px;
                    }
                }
            }
            .tabs {
                .tab-content {
                    &.current {
                        display: inline-block;
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .tab-content-details {
                        .tab-left,
                        .tab-right {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
            }
            &.specialproductset {
                .product-set {
                    &.product-col-2 {
                        &.main-product-set-details {
                            .product-set-list {
                                .product-set-item {
                                    .product-variations {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .pt_product-details {
            .pdp-main {
                .product-set {
                    &.product-col-1 {
                        &.product-bundle {
                            .product-primary-image {
                                .thumb {
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .main-product-set-details {
            #thumbnails {
                padding-bottom: 25px;
            }
        }
        .product-set {
            .product-add-to-cart {
                .sub-product-item {
                    width: 220px;
                    width: -webkit-calc(100% - 75px);
                    width: -moz-calc(100% - 75px);
                    width: calc(100% - 75px);
                }
            }
        }
        #main {
            .product-thumbnails {
                .slick-dots {
                    margin: 18px 1px 7px 0;
                    li {
                        margin-left: 11px;
                        button {
                            width: 6px;
                            height: 6px;
                            padding: 0;
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    width: 23.8%;
                }
                .wishlist {
                    width: 100%;
                    margin-bottom: 8px;
                }
            }
        }
        .pdp-main {
            .product-bundle {
                .bundle {
                    .product-add-to-cart {
                        button {
                            width: 73%;
                        }
                    }
                }
            }
            .tabs {
                .tab:nth-of-type(2) {
                    .tab-label {
                        &.current:after {
                            left: 38%;
                        }
                    }
                }
                .tab-label {
                    font-size: $base-font;
                }
                .tab-content {
                    .tab-content-details {
                        font-size: $base-font;
                    }
                }
            }
            .product-variations {
                .attribute {
                    padding-bottom: 0;
                    .swatches li.unselectable a {
                        &::after {
                            width: 105%;
                            transform: translate(-50%, -50%) rotate(-16deg);
                            -webkit-transform: translate(-50%, -50%) rotate(-16deg);
                            -moz-transform: translate(-50%, -50%) rotate(-16deg);
                            -ms-transform: translate(-50%, -50%) rotate(-16deg);
                            -o-transform: translate(-50%, -50%) rotate(-16deg);
                        }
                    }
                    &.size,
                    &.brabandsize {
                        .attribute_label {
                            margin-top: 15px;
                            .size-chart-link {
                                margin: -1px 4px 0 0;
                                span {
                                    &.size_icon {
                                        width: 20px;
                                        padding-top: 5px;
                                    }
                                    &.size_label {
                                        font-size: .95em;
                                        letter-spacing: .13em;
                                        padding-left: 0;
                                    }
                                }
                            }
                        }
                        .swatches li a {
                            margin: 5px 10px 5px 0;
                        }
                        .turntofit {
                            font-size: 16px;
                            letter-spacing: .025em;
                        }
                    }
                    &.color li a {
                        width: 41px;
                        min-width: 41px;
                        margin-right: 9px;
                    }
                }
            }
            .product-add-to-cart {
                .productmessage {
                    margin-top: 7px;
                    span {
                        font-size: 1.3em;
                    }
                }
                .shippingdetails .shippingdrawer {
                    padding-top: 6px;
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .pdp-main {
            .product-variations {
                .attribute {
                    &:not(.color) {
                        li {
                            width: 33%;
                            a {
                                min-width: auto;
                                width: calc(100% - (20px / 3));
                                margin: 5px 10px 5px 0;
                            }
                        }
                    }
                    &.color {
                        li {
                            a {
                                width: 45px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#QuickViewDialog {
    .pdpForm {
        fieldset {
            width: 100%;
        }
    }
    .product-thumbnails .thumb {
        @media screen and (min-width: 768px) {
            margin-right: 7px;
            width: 60px;
        }
    }
    .pdp-main {
        font-family: $FuturaBold;
        margin: 0;
        .prod-detail-page #pdp-image-container {
            .product-image-container .product-primary-image {
                .stickerToolTip{
                    font-family: $FuturaMedium;
                }
            }
        }
        .product-col-wrapper {
            margin-top: 0;
        }
        .product-actions {
            display: none;
        }
        .product-thumbnails,
        .enlarge-thumb-image {
            @media screen and (min-width: 768px) {
                &.slick-arrow-bar {
                    &::before {
                        top: 53px;
                    }
                }
                button {
                    &.slick-arrow {
                        &.slick-prev {
                            top: 60px;
                        }
                        &.slick-next {
                            top: 33px;
                        }
                    }
                }
            }
        }
        .product-set {
            &.product-col-1 {
                padding: 46px 30px 0;
                width: 163px;
                float: none;
                margin-left: -163px;
                left:100%;
                display: inline-block;
                box-sizing: border-box;
                background: $dim-gray;
                max-height: $colHeight;
                overflow-y: overlay;
                position: absolute;
                height: auto;
                top: 0;
                vertical-align: top;
                .heading {
                    font-size: $base-font;
                    color: #fcfafa;
                }
                .product-thumbnails {
                    .thumb {
                        width: 100%;
                        a {
                            img {
                                border-bottom: 4px solid transparent;
                            }
                        }
                        &.selected {
                            a {
                                img {
                                    border-color: $mainly-blue;
                                }
                            }
                        }
                    }
                }
            }
            .view-details {
                width: 72%;
            }
            &.product-col-2 {
                width: 83%;
                float: none;
                display: inline-block;
                box-sizing: border-box;
                padding: 0;
            }
            .product-set-list {
                .product-image-container,
                .product-set-image {
                    width: 38%;
                    display: inline-block;
                    .view-details,
                    .product-id {
                        display: none;
                    }
                }
                .product-set-details {
                    //padding: 13px 40px 0;
                    padding: 0 30px 0 63px;
                    width: 100%;
                    min-height: inherit;
                    max-height: 400px;
                    display: inline-block;
                    float: none;
                    vertical-align: top;
                    .product-name {
                        font-size: $base-font + 12px;
                        line-height: 28px;
                        margin-top: 0;
                    }
                    .product-variations {
                        .attribute {
                            .attribute_label {
                                .size-chart-link {
                                    font-family: $FuturaBold;
                                    text-transform: uppercase;
                                }
                            }
                        }
                        .swatches {
                            li {
                                a {
                                    font-family: $Futura; //border: 1px solid $black;
                                }
                            }
                        }
                    }
                }
                .product-set-item {
                    display: none;
                    border-bottom: none;
                    padding-bottom: 0;
                    max-height: 550px; //overflow: auto;
                    &.selected {
                        display: inline-block;
                        padding-top: 0;
                    }
                }
            }
            .product-add-to-cart {
                padding: 0 30px 0 63px;
                width: 100%;
                border: none;
                margin:20px auto 0;
                .availability-web {
                    display: block;
                }
                .wishlist {
                    width: 100%;
                    padding: 20px 0 0 0;
                    text-align: center;
                    display: inline-block;
                    font-family: $FuturaBold;
                    font-size: $base-font - 1px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    a {
                        .wishlist-logo {
                            display: inline-block;
                            line-height: 18px;
                            background: url('../../../images/wishlist.svg') no-repeat;
                            width: auto;
                            height: 18px;
                            padding-left: 30px;
                            &.added-to-wishlist{
								background: url('../../../images/wishlist-solid.svg') no-repeat;
							}
                        }
                    }
                }
            }
        }
        .product-name {
        	&.is-product-brand {
        		margin-bottom: 5px;
        	}
        }
        .product-brand {
            padding: 0;
        }
    }
    .product-col-1 {
        .product-primary-image {
            height: 394px;
        }
        .b_product_badge {
            position: absolute;
            z-index: 9;
            top: 0;
            left: 0;
        }
    }
    .product-col-2 {
        .product-name {
            .item-name {
                font-size: $base-font + 12px;
            }
        }
        .e-giftcard-detail,
        .giftcard-detail {
            .product-add-to-cart {
                .add-to-cart {
                    @media screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
                .wishlist {
                    margin-bottom: 20px;
                }
            }
        }
        .attribute {
            .attribute_label {
                .label {
                    font-family: $FuturaBold;
                }
                .selected-value {
                    font-family: $Futura;
                }
            }
        }
        .promotion {
            font-size: $base-font + 2px;
            width: 100%;
            .promotion-title {
                font-weight: normal;
            }
            .promotion-callout {
                font-size: $base-font + 2px;
                font-family: $Futura;
            }
        }
        .inventory {
            select {
                font-family: $Futura;
                font-size: $base-font + 3px;
                border-top-left-radius: 15px;
            }
        }
    }
    .pdp-main {
        &.specialproductset {
            .product-actions {
                .socialsharing {
                    display: none;
                }
            }
            .product-primary-image {
                height: 100%;
            }
            .product-price {
                padding: 0 0 15px 0;
                margin: 0;
            }
            .savingmessage {
                padding: 0 0 15px 0;
            }
            .product-col-1 {
                width: auto;
            }
            .product-bundle-main {
                .product-col-2 {
                    width: 100%;
                    padding: 0;
                    border: 0;
                    .product-name {
                        word-break: break-word;
                        margin-top: 0;
                    }
                    .product-add-to-cart {
                        button {
                            width: 74.5%;
                        }
                    }
                    .product-set-list {
                        box-sizing: border-box;
                        .product-set-item {
                            display: block;
                            max-height: initial;
                            border-bottom: 6px solid $light-gray;
                            box-sizing: border-box;
                            padding-right: 0;
                            &.selected {
                                border-top: 1px solid $light-gray;
                            }
                        }
                        .product-set-details {
                            width: 100%;
                            padding: 0;
                        }
                    }
                }
                form {
                    width: 100%;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 10px 0 20px 0;
                        margin: 0;
                        border-top: 1px solid $gainsboro;
                    }
                }
                .product-actions {
                    display: block;
                    border: none;
                    .product-add-to-cart {
                        width: 100%;
                        padding: 0 0 30px 0;
                        margin: 0;
                        .wishlist {
                            padding: 0;
                        }
                    }
                }
                .view-details {
                    width: 87.6%;
                    padding: 0 0 20px 0;
                }
            }
        }
    }
}

.product-variations {
    .attribute:last-child {
        border-bottom: none;
    }
}

.ui-dialog {
    &.product-set-quickview {
        &.quick-view {
            .ui-dialog-content {
                padding: 0;
            }
        }
        #QuickViewDialog {
            .pdp-main {
                .product-set {
                    .product-add-to-cart {
                        padding-top: 20px;
                    }
                    &.product-col-1 {
                        height: $colHeight;
                        .product-thumbnails {
                            .thumb {
                                padding: 0 0;
                                margin: 0 0;
                                text-align: center;
                            }
                        }
                    }
                    #thumbnails {
                        /*width: 90px;*/
                    }
                }
            }
        }
        .qv-productset-scrollbar {
            display: inline-block;
            width: 96% !important;
        }
    }
    &.quick-view {
        overflow: visible;
        .ui-dialog-content {
            //padding: 0 0 60px;
            position: relative;
            overflow: visible;
            .pdp-main {
                .product-col-1 {
                    padding: 0 0 0 25px;
                    box-sizing: border-box;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    .enlarge-video-sec {
                        .enlarge-video {
                            &.video-link {
                                float: left;
                            }
                        }
                    }
                    .promotionalbanner {
                        display: none;
                    }
                }
                .top-wrap,
                .info {
                    padding: 0;
                }
                .product-variations {
                    margin-top: 8px;
                    .attribute {
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        padding: 0;
                        margin: 10px 0 6px;
                        &.size_border {
                            .attribute_label {
                                margin: 15px 0 7px;
                            }
                        }
                        &.variant-dropdown {
                            border-top: none;
                        }
                        &.size {
                            .attribute_label {
                                margin: 14px 0 13px;
                            }
                        }
                    }
                }
                .product-monogramming,
                .product-hemmable {
                    border-bottom: none;
                }
                .product-col-2 {
                    padding: 0 5% 0 0;
                    .product-variation-content {
                        .product-col-wrapper {
                            margin-top: 0;
                        }
                        .product-name {
                            font-size: $base-font + 16;
                            line-height: $base-font + 16;
                            margin-top: 0;
                        }
                        .product-price {
                            font-size: $base-font + 2;
                            margin: 10px 0 5px;
                        }
                    }
                    &.giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 74%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }
                        }
                    }
                    &.e-giftcard-detail {
                        .gift-card {
                            border-top: none;
                        }
                        .product-add-to-cart {
                            .add-to-cart {
                                width: 100%;
                            }
                            .wishlist {
                                margin-bottom: 20px;
                            }

                            button {
	                            &.egift-prod {
	                                border-top-left-radius: 15px;
	                                -webkit-border-top-left-radius: 15px;
	                                -moz-border-radius-topleft: 15px;
	                                border-bottom-right-radius: 15px;
	                                -webkit-border-bottom-right-radius: 15px;
	                                -moz-border-radius-bottomright: 15px;
	                            }
	                        }
                        }
                    }
                    input[type="checkbox"] {
                        margin: 0 0 0 5px;
                    }
                    .product-special-messages {
                        padding: 20px 0 0 0;
                    }
                    .tab-content {
                        ul {
                            padding: 0 0 0 15px;
                        }
                    }
                    .product-add-to-cart {
                        padding: 20px 0 30px;
                        border-top: 1px solid $alto;
                        border-bottom: none;
                        .inventory {
                            width: 80px;
                            .custom-select {
                                .selected-option {
                                    border-top-left-radius: 15px;
                                }
                            }
                        }
                        button {
                            padding-top: 14.5px;
                            padding-bottom: 14.5px;
                            border-top-left-radius: 0;
                    		-webkit-border-top-left-radius: 0;
                    		-moz-border-top-left-radius: 0;
                            border: none;
                            background: $grape;
                            min-width: 71%;
                            letter-spacing: 2px;
                        }
                        .wishlist {
                            width: 100%;
                            padding: 20px 0 0 0;
                           margin: 0 auto;
                            a {
                                float: none;
                                display: inline-block;
                            }
                        }
                        .fields-address {
                            .form-row {
                                &:nth-child(3) {
                                    width: 100%;
                                }
                                .input-textarea {
                                    width: 100%;
                                }
                            }
                        }
                        .availability-web {
                        	padding: 2px 0 15px;
                        }
                    }
                    .product-variation-content {
                        max-height: 400px;
                        overflow: auto;
                        width: 100%;
                        float: left;
                        padding-right: 13px;
                    }
                }
            }
        }
    }
}

.pt_product-details {
    #main {
        .pdp-main {
            .product-col-2 {
                .product-set-addall {
                    .product-add-to-cart {
                        .all-set-button {
                            width: 268px; 
                            height: 50px;
                            padding: 0 25px;
                        }
                    }
                }
                &.e-giftcard-detail {
                    .product-add-to-cart {
                        .add-to-cart {
                            @media screen and (max-width: 480px) {
                                width: 100%;
                            }
                        }
                    }
                }
                &.giftcard-detail {
                    .product-add-to-cart {
                        .add-to-cart {
                            @media screen and (max-width: 480px) {
                                width: 75%;
                            }
                        }
                    }
                }
            }
            .product-add-to-cart {
                .inventory {
                    .custom-select {
                        .selected-option,
                        .quantityinput {
                            border-top-left-radius: 15px;
                            border: 1px solid $dim-gray;
                        }
                    }
                }
            }
            .ui-dialog {
                &.quick-view {
                    .ui-icon-closethick {
                        left: 43%;
                        top: 58%;
                    }
                }
            }
        	.product-set {
        		.product-name {
        			font-size: $base-font + 20px;

        			@media screen and (max-width: 767px) {
        				font-size: $base-font + 14px;
        			}
        		}
        	}
        }
    }
}

@media screen and (min-width: 1024px) {
    #main {
	    .pdp-main {
	    	&.standardproductset {
		        .product-set {
		            .product-add-to-cart {
		                .sub-product-item {
		                	width: 240px;
		                    max-width: 100%;
		                    padding-left:42px;
		                    padding-right:42px;
		                }
		            }
		        }
		    }
	    }
    }
}

@media screen and (max-width: 1023px) {
    .enlarge-dialog {
        &.ui-dialog {
            top: 0 !important;
            position: fixed !important;
            .pinch-zoom-container {
                a {
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    .productthumbnail {
                        max-height: 100%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}

#QuickViewDialog .view-details {
    font-family: $FuturaBold;
}

.pt_product-details {
	#main {
		.pdp-main {
			.prod-detail-page {
				.pdp-max-width {
					.product-name {
                        font-size: $base-font + 20px;
                        @media screen and (max-width: 767px) {
                            font-size: $base-font + 14px;
                        }
                    }
                }
            }
		}
	}
}

#TurnToReviewsContent {
    .TTpoweredby,
    .TTreviewDimsSingleSelectValue,
    #TT3RightLinks,
    a#TT3MyQALink,
    .TTreviewBody,
    .TTrevCol3,
    div#TTreviews li.TThelpful,
    div#TTreviews li.TTrevLinkLine a,
    div#TTreviews li.TTflagReview a,
    a#TT3AmqLink,
    .TTwriteReview {
        @include mrEvesfont();
    }
    .TTreviewSummary .TT4reviewDimRangeLabel {
        @include mrEvesfont();
        font-size: $base-font + 2px;
    }
    .TTreviewsCustomDimsSummaryWrap {
        display: inline-block;
    }
    div.TTreviewCount {
        @include faturafont();
    }
    #TT4breakdownLeftCol [id^='TT4Rating'],
    .TTreviewDimsTitle {
        @include faturafont();
    }
    div.TT4writeReviewBtn:hover {
        background-position: -102px 0;
    }
    select#TTreviewSort {
        border-radius: 0;
    }
}

#TTtraUserStateMain {
    .TTwriteRevLeftCol {
        @include faturafont();
    }
    div.TTMultiSelectDimensionColumn {
        min-width: 140px;
        float: none;
        display: inline-block;
    }
    div.TTwriteRevRightCol label {
        min-width: 140px;
    }
    div#TTwriteReviewScreen .TTrevGuidelineLink {
        @include mrEvesfont();
        margin: 10px auto 0;
    }
    div#TTwriteRevGreet p {
        @include mrEvesfont();
        font-size: 23px;
        margin: 0 auto;
    }
    div#TTwriteReviewScreen p.TTmediaSmallDesc {
        @include mrEvesfont();
        font-size: 32px;
        &:after {
            @include mrEvesfont();
            margin: 0 auto;
        }
    }
    #TTwriteRevForm input {
        padding: 7px;
    }
    #TTwriteRevMediaSelectedLabel {
        padding-bottom: 0;
    }
    div.TTwriteRevRow {
        margin: 20px auto;
    }
    #TTwriteRevHeader {
        margin: 10px 0 0 0;
    }
    a#TTtraWindowClose {
        border: none;
    }
    div#TTwriteRevForm textarea,
    #TTwriteRevForm .TTwriteRevRightCol input,
    .TTmediaSmallDesc,
    .TTMultiSelectDimensionColumnValue,
    span.TTcustDimRngCont,
    #TTwriteRevErrs {
        @include mrEvesfont();
    }
    #TTwriteRevErrs li {
        color: #bd0304;
    }
}

.pdp-main .product-info {
    @media screen and (max-width: 1023px) {
        padding-top: 30px;
    }
    @media screen and (max-width: 767px) {
        padding-top: 0;
    }
}

.pdp-main {
    .product-col-wrapper {
    	&:not(.e-giftcard-detail, .giftcard-detail) {
    		&:after {
	            display: table;
	            content: "";
	            border-top: 1px solid #e1e1e1;
	            width: 100%;
	            position: relative;
	            top: 30px;
	            @media screen and (max-width: 1024px) {
	                top: 12px;
	            }
	            @media screen and (max-width: 767px) {
	                top: 9px;
	            }
	        }
    	}
    }
    .last-visited-section {
        margin: 0 auto;
    }
}
 @media screen and (max-width: 1024px) {
    .pt_product-details #main .product-col-1.product-set{
        display: inline-block;
        margin-right: 0;
    }


    }
    @media screen and (max-width: 768px)and (max-height: 1024px) {
            .zoomWindowContainer,.zoomLens{
                display: none !important;
            }
    }

.tt-c-reviews-list{
    .tt-c-profile-attributes__property, 
    .tt-c-profile-attributes__value, 
    .tt-c-review__date, 
    .tt-c-review__incentivized, 
    .tt-c-review__product-attributes, 
    .tt-c-review__purchased, 
    .tt-c-review__recommend-text, 
    .tt-c-review__syndication, 
    .tt-c-review__text-date, 
    .tt-c-review__text-type-text, 
    .tt-c-review__update,
    .tt-c-review-toolbar__text,
    .tt-o-button--toolbar, 
    button.tt-o-button--toolbar,
    .tt-o-byline__author,
    .tt-c-dimension__label{
        font-size: 0.9rem;
    }
}

#main .pdp-main {
    .product-col-2 {
        &.e-giftcard-detail,
        &.giftcard-detail {
            .product-add-to-cart button {
                margin-top: 10px;
            }            
        }
    }
}