.search-result-options {
    .pagination {
        li {            
		    font-family: $FuturaMedium;
			line-height: 48px;
    		border: 1px solid $dim-gray;
        
            &.current-page {             
                background: $grape-blue;
                width: 48px;
			}

			&.dot-page {
				margin-left: -10px;
			}

			a {
				padding: 15px 19px;
				color: $dim-gray;
			}

		}
	}
}

.search-result-items {
    .grid-tile {
	    margin: 0 auto 32px;
	    border: 1px solid transparent;
		.product-tile {
			&:hover {
				border: 1px solid $mercury;
			}
		}
    }
}

.product-list-page {
	.search-result-items {
		.grid-tile {
			&.new-row {
				clear: both;
			}
		}
	}
}

.view-more {
    .viewmore-grid {
		background: $grape;
		border-color: $grape;
		border-style: solid;
		border-width: 2px;
		color: $white;
    	font-size: $base-font + 3px;
    	font-family: $FuturaMedium;
    }
}
