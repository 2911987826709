.plcc-landing-content {
	h1 {
		font-family: $MrEavesBold;
	}
	.plcc-top-banner {
		.jl,.ww {
			display: none;
		} 
		
		.rm {
			position: relative;
			
			.bck-images {
				text-align: center;
				
				img {
					height: 100%;
					max-height: 100%;
				}
			}
			.card-img {
				&.for-desktop {  
				    position: absolute;
				    top: 10%;
    				right: 1%;
				}			
			}
			.text-content {
				position: absolute;
			    width: 100%;
			    text-align: center;
			    top: 10%;
		        color: $white;
			    
			    h1 {
			    	font-size: $base-font + 14px;
			    	font-family: $MrEavesReg;
			    	
			    	&.light-heading {
			 			font-family: $Futura;
			 			font-size: $base-font + 33px;
	 			        margin: 0 0 1% 0;
	 			        text-transform: uppercase;
			 		}
			 		
			 		&.bold-heading {
			 			font-family: $FuturaBold;
			 			font-size: $base-font + 44px;
	 			        text-transform: uppercase;
			 		}
			    }
			    
			    .msg {
		    	    margin: 0 0 1.3% 0;
			    	font-size: $base-font + 8px;
			    }
		 		
		 		button {
	 			    min-width: 200px;
	 			    width: auto;
		 		}
			    
			    .preapproved-or-cardstatus {
				    display: inline-block;
				    margin: 4.8% auto 0;
			    	
			    	a {
			    		font-family: $FuturaBold;
						text-decoration: underline;
						font-size: $base-font + 2px;
						text-transform: uppercase;
					    letter-spacing: 2px;
			    	}
			    	
				    .preapproved,.cardstatus {
			    	    width: auto;
					    display: inline-block;
				    }
				}
			}
			
			
			
			&.authenticated-prescreened-non-pre-approved {
				.text-content {
					h1 {
						&.light-heading {
							margin: 0 0 2.1% 0;
						}
					}
				}
			}
			
			&.athenticated-preapproved {
				.msg {
					font-family: $FuturaBold;
					font-size: $base-font + 8px;
				}
			}
		}
	}
	
	.plcc-tabs {
		font-size: $base-font + 8px;
		padding: 17px 0 20px;
		
		.tab {
			letter-spacing: 5px;
			padding-bottom: 1px;
			
			&.active {
				color: $grapee;
				border-bottom: 3px solid $grapee;
			} 
		}
	}
	.benefits {
		.tiles-wrapper {
			.tile {
				h1 {
					font-size: $base-font + 14px;
				}
				span {
					font-family: $MrEavesReg;
					font-size: $base-font + 5px;
					color: $slightblack;
				}
				
				img {
				    margin-top: 0;
				}
				
				&.free-ship {
					img {
					    margin: 8px 0 5px;
					}
				}
			}
			
			.row {
				display: inline-block;
				width: 100%;
				
				.row-2 {
					&.tile {
						img {
					    	margin-top: 20px;
					    }
					    
					    &.perks {
					    	img {
				    	   		margin-top: 14px;
				    	   	}
					    }
					}
				}
			}
		}
		
		.brands-list {
		    margin: 39px 0 25px;
		    
			h1 {
				font-size: $base-font + 18px;
			}
		}
	}
	
	.rewards {
	    margin: 156px 0 52px;
	    
		.circle {
		    background: $mercuryy;
		    
		    .text-content {
		    	max-width: 300px;
	    	    margin: 29.5% auto 0;
		    	
			    h1 {
			    	color: $black;
			    	font-size: $base-font + 14px;
			    	
	    			&.bold {
	    				font-family: $FuturaBold; 
	    				color: $grapee;
    					font-size: $base-font + 12px;
    					text-transform: uppercase;
	    			}
	    		}
	    	}
		}
		.table-wrapper {
			.table-content {
   				box-shadow: none;
   				border: 1px solid $light-skygrey;
   				padding: 23px 30px;
			    padding: 3.3% 4.3%;
			}
		}
		table th {
			font-size: $base-font + 14px;
			font-family: $MrEavesReg;
		}
		table td, table th {
		    padding: 13px 10px;
	        padding: 2.2% 2.1%;
		}
		table td {
			color: $dim-gray
		}
		
		.reward-look {
			font-size: $base-font + 5px;
		    //width: 54%;
		    width: 375px;
			font-family: $MrEavesReg;
			
			u {
				font-family: $MrEavesBold;
			}
		}
	}
	.perks-email-wrapper {
	    margin: 45px auto 47px;
	    
		.perks-signup {
		    .plcc-landing-perks { 
		    	h1 {
		    		font-family: $FuturaBold; 
    				color: $grapee;
					font-size: $base-font + 21.6px;
					letter-spacing: 10px;
				    line-height: 42px;
				    margin-top: 0;
		    	}
		    }
		    .plccbenefits-n-email {
			    .plcc-platinumcard-benefits {
			    	.plcc-benefits-head {
			    		font-family: $MrEavesReg;
						font-size: $base-font + 14px;
					    margin: 0 0 5px;
			    	}
			    	.plcc-benefit-msg {
			    		font-size: $base-font + 5px;
			    		font-family: $MrEavesReg;
			    	}
			    	
			    }
			}
		}
		
		.perks-offer {
		    margin-top: 10px;
	    	
	    	.per-off-content {
    		    margin-top: 0;
    		    padding: 11px;
    		    
    		    span {
		    		font-family: $MrEavesReg;
					font-size: $base-font + 14px;
		    	}
		    	h1 {
		    		font-family: $FuturaBold;
		    		font-size: $base-font + 28px;
		    		color: $grapee;
	    		    margin: 4px 0;
	    		    line-height: 40px;
		    	}
	    	}
	    }
	}
	
	.plcc-bottom {
		.bottom-right {
			
			h1 {
				font-family: $FuturaBold;
	    		font-size: $base-font + 12px;
	    		text-transform: uppercase;
			}
		}
		.bottom-left {
			.qa-section {
				.qa-content {
				    padding: 18.5px;
				}
			}
		}
		
		.plcc-text-content {
			margin: 45px 0 0;
		}
	}
	.pre-approved-cta {
		h1 {
			font-family: $FuturaBold;
			font-size: $base-font + 12px; 
		}
		.approved {
			font-family: $MrEavesReg;
			font-size: $base-font + 14px;
			color: $mineshaft;
		}
		.sign-in {
			font-family: $FuturaBold;
			text-decoration: underline;
			color: $grapee;
		    margin-top: 7px;
		}
		button {
			margin: 20px 0 28px;
		}	
	}
	.cardstatus {
		h1 {
			font-family: $MrEavesReg;
		}
	}
}

@media screen and (max-width: 1023px) {
	.plcc-landing-content {
		.benefits {
			.tiles-wrapper {
				.row-1 {
					margin-top: 36px;
				}
				.row {
					.row-2 {
						margin-top: 0;
					}
				}
			}
			.brands-list {
			    margin: 9px 0 35px;
			}
		}
		.rewards {
			margin: 40px 0 32px;
			.circle {
				.inner-circle {
					margin: 3.6% auto 0 3.6%;
					display: inline-block; 
					
				}
			}
		}
		.perks-email-wrapper {
			
			.perks-offer {
			    padding-bottom: 0;
    			border-bottom: 0;
			}
		}
		
		.plcc-bottom {
			.bottom-right {
			    margin: 80px 0 50px;
			}
		}
	}
}
@media screen and (max-width: 1240px) {
	.plcc-landing-content {
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 5.2%;
				}
			}
		}
		.plcc-top-banner {
			.rm {
				.card-img {
					&.for-desktop {
						width: 29%;
						right: 0%;
					}
				}
				.text-content {
				    top: 6%;
					
					.logo {
					    margin-bottom: 10px;
					}
					
					h1 {
						&.light-heading {
							margin: 0 0 1% 0;
						}
						
						&.bold-heading {
							
						}
					}
					.preapproved-or-cardstatus {
						width: 50%;
					    margin: 3.8% auto 0;
					}
				}
				
				&.athenticated-preapproved {
					.msg {
						font-family: $FuturaBold;
						font-size: $base-font + 8px;
					}
				}           
			}
		}
	}
}
@media screen and (max-width: 1023px) {
	.plcc-landing-content {
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 3.2%;
				}
			}
		}
		.plcc-top-banner {
			overflow: hidden;
			    
			.rm {
				.bck-images {
			    	img {
			    		vertical-align: bottom;
			    	}
			    }
				.card-img {
					&.for-desktop {
						display: none;
					}
				}
				.text-content {
				    top: 2%;
					
					.logo {
					    width: 30%;
    					margin: 0 auto 10px;
					}
					
					h1 {
						margin: 0 0 5px;
						
						&.light-heading {
							font-size: $base-font + 20.33px;
							margin: 0 0 0;
						}
						
						&.bold-heading {
							font-size: $base-font + 36px;
							margin: 0 0 0;
						}
					}
					.preapproved-or-cardstatus {
					    width: 70%;
					    margin: 2% auto 0;
					}
				}
			}
		}
	}
}
@media screen and (max-width: 767px) {
	
	.plcc-landing-content {
		h1 {
			font-size: $base-font;
			line-height: normal; 
		}
		
		.plcc-top-banner {
			.rm {
			    width: 100%;
				.text-content {
					padding: 10px;
					box-sizing: border-box;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
				
					h1 {
						font-size: $base-font;
						
						&.light-heading {
							font-size: $base-font + 10.5px;
						}
						
						&.bold-heading {
							font-size: $base-font + 16px;
						}
					}
					.preapproved-or-cardstatus {
						width: 100%;
					}
					
					.msg {
						font-size: $base-font - 1px;
					}
					.preapproved-or-cardstatus {
						a {
							font-size: $base-font - 4px;
						}
					}
					.card-img {
						&.only-for-mobile {
							width: 38%;
   							margin: auto;
						}	
						&.hide-desktop {
							display: none;
						}			
					}
				}
			}
		}
		
		.plcc-tabs {
			font-size: $base-font - 2px;
			.tab {
			    letter-spacing: 2px;
			}
		}
		
		.benefits {
			.tiles-wrapper {
				.tile {
				    min-height: 213px;
				    
				    h1 {
   						margin: 13px 0 4px 0;
   						font-size: $base-font + 3px;
				    }
				    span  {
				    	font-size: $base-font;
				    }
				}
			}
			.pre-approved-cta {
				.approved {
					font-size: $base-font + 1px;
					margin-bottom: 2px;
				}
			}
		}
		
		.rewards {
		    margin: 21px 0 27px;
			
			.circle {
    			
				.text-content {
				    margin: 15% auto 0;
				    
					h1 {
					    font-size: $base-font + 6px;
					    margin-bottom: 7%;
					    
					    &.bold {
					    	font-size: $base-font;
					    }
					}
				}
			}
			table th {
				font-size: $base-font + 1px; 
				padding: 6.5px 5px;
			}
			table td {
				font-size: $base-font;
				padding: 6.5px 5px;
			}
			
			.reward-look {
    			font-size: $base-font + 1px;
			}
		}
		
		.perks-email-wrapper {
			.perks-signup {
				.plcc-landing-perks {
					h1 {
						font-size: $base-font + 16.77px;
						line-height: 35px;
						
					}
				}
				
				.plccbenefits-n-email {
				
					.plcc-platinumcard-benefits {
						.plcc-benefits-head {
							font-size: $base-font + 8px ;
						    margin: 1px 0 5px;
						}
						.plcc-benefit-msg {
							font-size: $base-font + 1px;
						}
					}
				}
			}
			
			.perks-offer {
		        margin-top: 4px;
		        
				.per-off-content {
				    padding: 17px 10px 20px;
					h1 {
						font-size: $base-font + 13px;
					    margin: 4px 0 6px;
					    line-height: 30px;
					}
					span {
						font-size: $base-font + 3px;
					}
				}
			}
		}
		
		
		.plcc-bottom {
			.bottom-right {
				margin: 65px 0 28px;
				
				h1 {
					font-size: $base-font + 8px;
					line-height: 27px;
				}
			}
			.plcc-text-content {
				font-size: $base-font - 3px;
			}
			
		}
		.pre-approved-cta {
			
			.approved {
				font-size: $base-font + 6px;
			}
			.sign-in {
				font-size: $base-font + 1px;
				margin-top: 0;
			}
			button {
		 		margin: 20px 0 10px;
			}
		}
	}
}


@media screen and (max-width: 480px) {
	.plcc-landing-content {
		.plcc-top-banner {
			.rm {
				.text-content {
					h1 {
						&.light-heading {
							font-size: $base-font + 7.5px;
						}
						&.bold-heading {
							font-size: $base-font + 11px;
						}
					}
				}
			}
		}
		.plcc-tabs {
			&.four-links {
				.tab {
					margin: 0 3.2%;
				}
			}
		} 
	}
}	