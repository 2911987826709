.catalog-thankyou-main {
	max-width: 970px;
	margin: 0 auto;
	
	p {
		font-size: $base-font + 4px;
	}
	.catalog-request-confirmation {
		font-size: $base-font + 5px;
		font-family: $MrEavesReg;
		width: 100%;
		display: inline-block;
		margin-bottom: 25px;
		box-sizing: border-box;
	}

	.catalog-thankyou {
		font-size: $base-font + 4px;
		font-family: $MrEavesReg;
		display: inline-block;
		width: 100%;
		margin: 20px 0 30px 0;
	}
	
	.catalog-thankyou-heading {
		font-size: $base-font + 9.33px;
		font-family: $MrEavesReg;
		margin: 0 0 5px 0;
		display: inline-block;
		width: 100%;
	}
	
	.catalog-address {
		margin: 0 0 15px 0;
		font-size: $base-font + 4px;
		font-family: $MrEavesReg;
		display: inline-block;
		width: 100%;
	}
	
	.catalog-current {
		margin: 0 0 27px 0;
		font-size: $base-font + 4px;
		font-family: $MrEavesReg;
		display: inline-block;
		width: 100%;
		text-transform: capitalize;
	}
	
	.current-brand-image {
		max-width: 117px;
		width: 100%;
	}
	
	.current-brand-name {
		.brand-logo {
			margin: 0 0 20px 0;
			width: 142px;
		   max-width: 100%;
		}
	}
	
	.current-brand-section {
		width: 100%;
	    display: inline-block;
	    padding: 0 0 30px 15px;
	    box-sizing: border-box;
	}
	
	.current-brand-benefits {
		font-size: $base-font + 9.33px;
		font-family: $MrEavesReg;
		width: 54%;
	}
	
	.sister-brands {
		width: 100%;
		display: inline-block;
	}
	
	.sister-brand-message {
		font-size: $base-font + 14px;
		font-family: $MrEavesReg;
	    padding: 15px 0 15px 13px;
	    width: 100%;
	    display: inline-block;
	    border-top: 1px solid $alto;
	    border-bottom: 1px solid $alto;
	   	margin: 0;
	    background-color: $light-skygrey;
	    box-sizing: border-box;
	}
	
	.sister-brand-rows{
		padding: 22px 38px 33px 17px;
		border-bottom: 1px solid $alto;
		width: 100%;
	    display: inline-block;
	    box-sizing: border-box;
	    
	    &.row-last {
	    	border-bottom: none;
	    }
	}
	
	.sister-brand-section {
		.form-row-button {
			float: right;
			margin: 0 0 4% 0;
		}	
	}
	
	.sister-brand-image {
		width: 15%;
		float: left;
	}
	
	.sister-image-size {
		max-width: 100px;
		width: 100%;
	}
	
	.sister-brand-right {
		width: 85%;
		float: left;
		
		.form-row {
			width: 17.6%;
		    float: right;
		    text-align: left;
		    font-size: $base-font + 4px;
			font-family: $MrEavesReg;
			line-height: 25px;
		}
		
		input[type="checkbox"] {
			border: none;
			background: url(../../../images/checkbox.svg) no-repeat center;
			width: 25px;
			height: 24px;
			outline: none;
			margin: 0px 4px 0 1px;
			&:checked {
				background: url(../../../images/checkmark.svg) no-repeat center;
				width: 25px;
				height: 24px;
			}
		}
		
		.brand-logo {
			width: 142px;
		}
	}
	
	.sister-request-submit {
		padding: 14px 83px;
		font-family: $FuturaMedium;
		letter-spacing: 1px;
		color: $white;
		background-color: $mainly-blue;
		border-color: $mainly-blue;
	}
}

//Catalog Preference confirmation
.catalog-confirmation {
    max-width: 1022px;
    margin: 0 auto;
    
    .catalog-request-header {
    	font-size: $base-font + 16px;
		font-family: $MrEavesReg;
	    display: inline-block;
	    width: 100%;
	    padding: 0 0 60px 3.2%;
    	margin: 31px 0 14px 0;
	  	box-sizing: border-box;
    }
    
    .catalog-print-preference {
	    .catalog-request-confirmation {
	 		font-size: $base-font + 6px;  
	 		font-family: $MrEavesReg;	
	    }
	}
}

@media screen and (max-width: 1024px) {
	.catalog-confirmation {
		margin: 0 20px;
	}
}

@media screen and (max-width: 1023px) {
	.catalog-thankyou-main {
		margin: 0 20px;
	
		.current-brand-section {
			padding: 0 0 30px 0;
		}
		
		.catalog-left {
			width: 20%;
		}
		
		.catalog-right {
			width: 79%;
		}
		
		.current-brand-benefits {
			width: 71%;
		}
		
		.sister-brand-rows {
			padding: 22px 18px 33px 0;
		}
		
		.sister-brand-image {
			width: 20%;
		}
		
		.sister-brand-right {
			width: 79%;
			.form-row {
				width: 18%;
				font-size: $base-font + 5px;
			}
		}
		
		.sister-request-submit {
		 	padding: 14px 76px;
		}
	}
	
	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 20px;
		
		.catalog-request-header {
			padding: 0 0 40px 0;
		}
		
		.catalog-request-confirmation {
			padding: 0;
		}
		
		.qa-section {
			.qa-content {
				.question {
					font-size: $base-font;
				}
				
				.answer {
					font-size: $base-font + 5px;
					font-family: $MrEavesReg;		
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {  
	.catalog-thankyou-main {
		margin: 0;
		
		.morecontent span {
		    display: none;
		}
		
		.morelink {
		    display: inline;
			font-family: $MrEavesBold;
			color: $mainly-blue;
			white-space: nowrap;
		}
		
		.sister-brand-message {
			font-size: $base-font + 8px;
		}
	
		.catalog-thankyou {
			box-sizing: border-box;
		}
		
		.catalog-thankyou-heading {
			font-size: $base-font + 5px;
		}
		
		.catalog-address {
			padding: 0 20px;
			box-sizing: border-box;
			font-size: $base-font + 5px;
		}
		
		.catalog-current {
			padding: 0 20px;
			box-sizing: border-box;
			font-size: $base-font + 5px;
		}
		
		.current-brand-section {
			padding: 0 20px 30px 20px;
			box-sizing: border-box;
		}

		.catalog-request-confirmation{
			padding: 0 20px;
		}
		
		.catalog-left {
			width: 22%;
			float: right;
		}
		
		.catalog-right {
			width: 78%;
		}
			
		.current-brand-benefits {
		    width: 83%;
		    font-size: $base-font + 5px;
		}
		
		.current-brand-name {
			.brand-logo {
				margin: 0 0 5px 0;	
			}
		}
		
		.sister-brand-rows {
			padding: 22px 20px 33px 20px;
	    	box-sizing: border-box;
	    	.brand-logo {
	    		margin: 0 0 20px 0;
	    	}
		}
		
		.sister-brand-image {
			width: 22%;
			float: right;
		}
		
		.sister-brand-right {
			width: 78%;
			float: right;
		}
		
		.sister-brand-section {
			.form-row {
				margin: 20px 0 0 0;
	    		font-size: $base-font + 1px;
	    		width: 100%;
    			float: left;
    			
    			input[type="checkbox"] {
    				margin: 3px 0 0 0;
    			}
    			
    			label {
    				width: 70%;
				    float: right;
				    margin: 0;
				    font-size: $base-font + 1px;
    			}
			}
			
			.form-row-button {
				float: none;
			    padding: 0 20px;
			    width: 100%;
			    box-sizing: border-box;
			}
		}
		
		.sister-request-submit {
			width: 100%;	
		}
	}
	
	//Catalog Preference confirmation
	.catalog-confirmation {
		margin: 0 10px;
	}
}

@media screen and (max-width: 480px) {
	.catalog-thankyou-main {
		.sister-brand-image {
			width: 33%;
			float: right;
		}
		
		.sister-brand-right {
			width: 67%;
		}
		
		.catalog-left {
			float: right;
			width: 33%; 
			margin: 13px 0 0 0;  
		}
		
		.catalog-right {
			width: 67%;
		}
	}
}