.legacy-sitegen .pt_wish-list {
	.create-login {
		label {
			&.input-focus {
				transform: translate(3px, 6px);
			}
		}
	}
	.primary-content {
		input[type="text"], input[type="number"], input[type="password"], input[type="tel"], input[type="date"] textarea {
		    padding: 18px 15px 12px 15px;
		    min-height: 50px;
		    -webkit-appearance: none; /* iOS fix for removing inner shadow from top of input fields */
		}

		.search-wishlist {
			h1 {
				font-family: $MrEavesReg;
				font-size: $base-font + 16px;
			}

			h2 {
				font-size: $base-font + 1px;
				font-family: $MrEavesReg;
			}

			.or-link {
				font-size: $base-font;
				font-family: $Futura;
			}

			.error-message {
				color: $guardsman-Red;
				font-size: $base-font;
				font-family: $HelveticaMedium;
			}

			.form-row {

			    &.form-row-button {

			    	button {
			    		color: $white;
			    		background: $grape;
			    		font-family: $Futura;
			    	}
			    }
			}

			label {
			    font-size: $base-font;

			    span {
			    	font-family: $MrEavesReg;
			    	font-size: $base-font + 5px;
			    }

			    &.input-focus {
					span {
						font-size: $base-font + 3px;
					}
			    }
			}
		}

		.search-wishlist-bottom {
			.search-wishilist-box {
				padding: 22px 50px;

	 	 		h1 {
				    padding: 0 0 13px 0;
				    font-size: $base-font + 15px;
					font-family: $MrEavesReg;
				}

				.form-row {
				    width: 21%;

				    &.email_wishlist {
				    	width: 28%;
				    }

				    &.form-row-button {
					    button {
					    	background: $grape;
					    	padding: 15px 66px 14px;
					    	font-size: 1.29em;
					    	min-height: 50px;
					    	font-family: $Futura;
					    }
				    }
					&.or-link {
						margin-top: 12px;
					}
				}

				label {
					top: -6px;
					&.input-focus {
						top: -9px;
					}
					span {
						font-family: $MrEavesReg;
						font-size: $base-font + 5px;
						letter-spacing: 1px;
						color: $dim-gray;
					}
				}
 	 		}
		}

		.results-found {
			.heading {
				font-size: $base-font + 12px;
				font-family: $Futura;
			}

			.section-header-list {

    			.section-header {
    				font-size: $base-font;
    				font-family: $HelveticaBold;
    			}
			}

			.item-list {
			    .section-item-list {

 	 				.col-1, .col-2, .col-3, .col-4 {
    					font-size: $base-font;
    					font-family: $MrEavesReg;
 	 				}

    				.col-4 {
    					font-size: $base-font - 1px;
    					font-family: $HelveticaBold;
    				}
			    }
			}
		}
	}
}

//Wishlist login page
.create-login {


    button {
	    background: $grape;
	    font-family: $Futura;
	}

    h1 {
		font-size: $base-font + 16px;
		font-family: $MrEavesReg;
	}

    .create-inner-block {
		.login-create {
			border: 1px solid $mercuryy;
    		box-shadow: none;
    		-webkit-box-shadow: none;
    		-moz-box-shadow: none;

 	 		.para {
 	 			font-size: $base-font + 1px;
				font-family: $Helvetica;
 	 		}

 	 		.step {
 	 			h2 {
 	 				font-family: $HelveticaBold;
 	 				font-size: $base-font + 1px;
 	 			}

 	 			p {
 	 				font-family: $Helvetica;
 	 				font-size: $base-font + 1px;
 	 			}
 	 		}

 	 		.create-account {
    			padding: 14.5px 65px;
 	 		}
		}
	}

    .login-create-account {
		h2 {
			font-size: $base-font + 1px;
		    font-family: $Helvetica;
	    }

	    .para {
 			font-size: $base-font + 5px;
			font-family: $MrEavesReg;
 		}

	    .or-section {

	    	.or-link {
			    font-size: $base-font;
			    font-family: $HelveticaMedium;
	    	}
	    }

	    .legal {
	    	font-size: $base-font + 3px;
		    font-family: $MrEavesReg;
		    u {
		    	text-decoration: none;
		    }
	    }
	}

    .col-1 {
		.form-row {
		    &.login-rememberme {
				margin: 18px 0 0 0;

				label {
					 top: -7px;
					 left: 15px;
				}

				@media screen and (max-width: 767px) {
					margin: 0 0 10px 0;
		    }

			span{
				@media screen and (max-width: 767px) {
					color: $black;
				}
			}
		}
    }

		.form-row-button {
			button {
				@media screen and (max-width: 767px) {
					margin: 10px 0 0 0;
				}
			}
		}
    }

    .form-caption {
    	font-size: $base-font;
		font-family: $Helvetica;
    }

    .inner-block {
    	.login-box {
		    background: $wild-sand;

		    h2, .description {
				font-size: $base-font + 5px;
			    font-family: $MrEavesReg;
		    }

		    .filled-wineberry {
    			padding: 14.5px 61px;
		    }

		    #password-reset {
			    font-size: $base-font + 5px;
			    font-family: $MrEavesReg;
				color: $dim-gray;
				margin-top:15px;

			    @media screen and (max-width: 767px) {
				    margin-top:20px;
			    }
		    }

		    .login-data {

			    span {
				    font-size: $base-font + 5px;
				    letter-spacing: normal;
				    font-family: $MrEavesBold;
				    padding-bottom: 5px;

				    &.login-brands {
				    	font-family: $MrEavesReg;
				    	span {
				    		font-family: $MrEavesReg;
				    	}
				    }
			    }
		    }
    	}
    }
}

//whishlist email popup
.ui-dialog {
	&.wishlist-email {
		.ui-dialog-titlebar {
			.ui-dialog-title {
			    font-size: $base-font + 12px;
			    font-family: $Futura;
			}
		}
	}
}

.legacy-sitegen .sendtofriend {
	.sub-heading {
		font-size: $base-font + 2px;
		font-family: $Helvetica;
	}

	.form-row {
		&.sendtome {
			margin: 40px 0 0 2px;

		    label {
		    	font-size: $base-font;
				font-family: $HelveticaMedium;
				word-spacing: 2px;
				display: inline-block;
    			vertical-align: top;
    			line-height: 15px;
		    }

		    input[type="checkbox"] {
	    	    width: 14px;
				height: 14px;
				outline: 1px solid $black;
				border: 2px solid $white;
				margin: 0 10px 0 0;
		    }
		}

		&.message {
			input[type="text"] {
				padding: 0 15px 14px 15px;
			}
		}

		&.form-row-button {
		    margin: 22px 0 0 0;

		    button {
		    	padding: 16px 44px;
		    	font-family: $Futura;
		    	background: $grape;
		    }
		}
	}

	input[type="text"], input[type="number"], input[type="password"], input[type="date"], input[type="tel"], textarea {
	    padding: 20px 15px 12px 15px;
	}
}

//My Wishlist share
.legacy-sitegen .list-share {
	.list-title {
    	padding: 25px;
 	 	font-size: $base-font + 12px;
		font-family: $Futura;
	}

	.top-section {
		margin: 34px 0 15px;
    	padding: 0 25px;

    	.heading {
    		font-size: $base-font + 16px;
    		line-height: $base-font + 15px;
    		padding-left: 3px;
    		font-family: $MrEavesReg;
    	}

    	.share-option {
    		font-size: $base-font;
    		font-family: $FuturaBold;
    		background: transparent;
    		border-radius: 0;
    		-webkit-border-radius: 0;
    		-moz-border-radius: 0;
    	}
	}

	.bottom-section {
	    padding: 10px 25px 7px;


	    .share-link-content {
    		.share-link-copy {
			    padding: 7px 20px 8px;
			    a {
			    	font-size: $base-font + 4px;
				    font-weight: bold;
				    font-family: mreavessanaltbk;
				    letter-spacing: .087em;
			    }
    		}

    		.copy-link {
    			font-family: $FuturaBold;
    		}
	    }
	}
}

//My Wishlist Items
.legacy-sitegen .item-list {
	.wishlist-row {
	    label {
	    	font-size: $base-font - 2px;
	    	line-height: 21px;

	    	span {
	    		&.label-text {
	    			font-family: $FuturaBold;
	    		}
	    	}
	    }

	    .column {
		    &.col-1 {
		    	width: 55%;
		    	max-width: none;

		    	.item-details {
				    font-size: $base-font - 1px;

				    .name {
				    	font-size: $base-font + 12px;
				    	font-family: $MrEavesReg;
				    }

				    span {
			    		font-family: $Futura;
			    		font-size: $base-font - 1px;
			    		text-transform: uppercase;

			    		&.label {
			    			font-family: $FuturaBold;
			    			text-transform: uppercase;
			    		}

			    		&.price-sale-standard, &.notavailable {
			    			color: $guardsman-Red;
			    		}

			    		&.optionitemprice {
			    			.label, .value {
			    				font-family: $Futura;
			    			}
			    		}
			    	}

			    	.product-special-messages {
			    		font-size: $base-font;
    					color: $special-product-messages-color;
    					font-family: $Futura;
			    	}
		    	}
		    }

		    &.col-2 {
		    	width: 14.8%;
		    	max-width: 320px;

			    .price {
			    	font-family: $Futura;
				    font-size: $base-font + 2px;
				    margin-top: 57px;

				    span {
				    	.price-sale-standard {
			    			color: $guardsman-Red;
			    		}
				    }
			    }
		    }

		    &.col-3 {
			    .option-date-added {

				    .label {
				    	font-family: $FuturaBold;
				    }

				    .value {
				    	font-family: $Futura;
				    	text-transform: uppercase;
				    }
			    }

			    .option-add-to-cart {
			    	.inventory {
			    		.selected-option, #Quantity {
				    		border-top-left-radius: 15px;
				    		font-family: $Futura;
				    		border-color: $dim-gray;
				    		font-size: $base-font + 3px;
				    		&:before {
				    			right: 5px;
				    		}
				    	}

				    	&::after {
				    		background: url(../../../images/rm/carat-down.svg) no-repeat;
				    		width: 15px;
				    		height: 10px;
				    	}

				    	&.open {
				    		&::after {
					    		background: url(../../../images/rm/carat-up.svg) no-repeat;
					    		width: 15px;
					    		height: 10px;
					    	}
				    	}
				    	.selection-list {
				    		border: 1px solid $dim-gray;
				    		li {
				    			&:hover, &.selected {
				    				background: $dim-gray;
				    				color: $white;
				    			}
				    		}
				    	}

				    }

			    	button {
				    	&.add-to-cart {
						    background: $grape;
						    padding: 11px 40px 15px;
 	 						font-family: $Futura;
 	 						font-size: $base-font + 3px;
 	 						letter-spacing: 1px;
 	 						border-top-left-radius: 0;
                    		-webkit-border-top-left-radius: 0;
                    		-moz-border-top-left-radius: 0;
						    border-bottom-right-radius: 15px;
						    -webkit-border-bottom-right-radius: 15px;
						    -moz-border-bottom-right-radius: 15px;
						    min-height: 50px;
 						}
				    }
			    }

			    .edit-section {
			    	margin: 13px 0 0 0;
				    font-size: $base-font - 2px;
				    font-family: $FuturaBold;
				    display: block;
				    clear: both;

				    .edit-wishlist-detail {
					    padding-right: 23px;
				    }

				    .option-update {
					    float: left;
						padding-left: 23px;

					    button {
					    	font-size: $base-font - 2px;
					    	padding: 0;
					    	border-radius: 0;
					    	-webkit-border-radius: 0;
					    	-moz-border-radius: 0;
					    }
				    }
			    }

			    .option-toggle-public {
		    	    margin: 21px 0 0 0;
		    	    width: auto;
		    	    text-align: right;

	    	    	.input-checkbox {
	    	    		margin: 2px 0 0 8px;
					    width: 17px;
					    height: 17px;
					    border: 1px solid $black;
					    outline: 0;

					    &:checked {
					    	background: url(../../../images/rm/checkmark.svg) no-repeat center;
					    	background-size: 15px;
					    }

					    &:focus {
							outline: 0;
						}
	    	    	}
			    }
		    }
	    }
	}
}

//Wishlist Empty
.legacy-sitegen .wishlist-empty {
	.heading {
		font-family: $MrEavesReg;
	}

	h2 {
		font-size: $base-font + 2px;
		font-family: $Helvetica;
	}

	.qa-section {
		.qa-content {

 			.answer {
 				font-size: $base-font + 2px;
 			}
		}
	}
}

@media screen and (max-width: 1023px){
	.legacy-sitegen .pt_wish-list {
		.primary-content {
			.item-list {
				.wishlist-row {
					.column {
						&.col-1 {
							width: 100%;

							.item-details {
								max-width: 74%;
							}
						}
					}
				}
			}

			//My Wishlist bottom section
			.search-wishlist-bottom {
				.search-wishilist-box {
					.form-row {
						width: 50%;

				    	&.email_wishlist {
				    		width: 42%;
				    		clear: left;
							max-width: 272px;

				    		input.email {
				    			border-top-left-radius: 15px;
				    		}
				    	}

					    &.form-row-button {
						    button {
						    	padding: 14px 67px;
						    	border-top-left-radius: 0;
						    	-webkit-border-top-left-radius: 0;
						    	-moz-border-top-left-radius: 0;
						    }
					    }
					}
		 		}
			}

		}
	}

	//Wishlist login page
	.create-login {
		.login-oauth {
			button {
				padding: 14.5px 45px;
			}
		}
	}

}

@media screen and (max-width: 768px) {
	.legacy-sitegen .list-share {
		.top-section {
			margin: 15px 0;
		}
	}
}

@media screen and (max-width: 767px) {
	.legacy-sitegen .pt_wish-list {
		#primary {
			.search-wishlist {
    			.form-row {
    				margin: 20px 0 0 0;
    			}

    			.search-wishilist-box {
    				padding: 20px;
    			}
			}

			//My Wishlist bottom section
			.search-wishlist-bottom {
				.search-wishilist-box {
					.form-row {
					    &.email_wishlist {
							input.email {
								border-top-left-radius: 0;
								-webkit-border-top-left-radius: 0;
								-moz-border-top-left-radius: 0;
							}
					    }

					    &.form-row-button {
					    	button {
					    		border-top-left-radius: 15px;
					    	}
					    }
					}
		 		}
			}

			.results-found {
				.item-list {
					.section-item-list {

						.mobile-label {
						    font-size: $base-font;
						    font-family: $HelveticaBold;
						}
					}
				}
			}
		}
	}

	//Wishlist login page
	.create-login {

	    .inner-block {
	    	.login-box {
		    	margin: 0 20px 20px 20px;

    			&.login-account,
				&.search-wishlist {
    				margin: 0 0 20px 0;
    			}

			    .login-data {
    				.login-email {
    					width: 78%;
    				}
    			}


			    &.login-create-account {
			    	.login-box-content {
			    		.form-row-button {
			    			button {
			    				padding: 14.5px 50px;
			    			}
			    		}
			    	}
			    }
	    	}
	    }
	}

	//whishlist email popup
	.ui-dialog {
		&.wishlist-email {
			.ui-dialog-titlebar {
				.ui-dialog-title {
				    font-size: $base-font + 8px;
				}
			}
		}
	}

	.legacy-sitegen .sendtofriend {
		.sub-heading {
			font-size: $base-font + 1px;
		}

		label {
			font-size: $base-font - 1px;
		}
	}

	//My Wishlist Items
	.legacy-sitegen .pt_wish-list {
		.primary-content {
			.item-list {
				.wishlist-row {
					position: relative;

					.wishlist-columns {
						padding: 25px 0;
					}

				    .column {
			    	    width: 100%;

					    &.col-1 {
					    	max-width: 100%;
					    	width: 100%;

					    	.item-image {
							    width: 100px;
					    	}

					    	.item-details {
							    max-width: 100%;
							    width: 64%;

							    .edit-wishlist-detail-mobile {
							    	margin: 10px 0 10px 0;
								    text-transform: uppercase;
								    letter-spacing: 1px;
								    font-size: $base-font - 1px;
								    color: $black;
								    font-family: $FuturaBold;
							    }
					    	}
					    }

					    &.col-3 {
					    	max-width: 100%;
						    width: 100%;
							margin: 17px 0 0 0;

						    .option-date-added {
						    	margin: 20px 0 0 0;
						    }

						    .item-dashboard {
						    	width: 100%;
						    }

						    .option-add-to-cart {
						    	width: 100%;

						    	.inventory {
						    		width: 20%;
						    	}

						    	button {
							    	&.add-to-cart {
								    	width: 80%;
									    height: 50px;
			 						}
							    }
						    }

						    .edit-section {
							    .option-update {
								    button {
								    	text-indent: -9999px;
									    position: absolute;
									    right: 0;
									    top: 10px;
									    background-image: url('../../../images/x-icon.svg');
										height: 16px;
						    			width: 16px;
								    }
							    }
						    }

						    .option-toggle-public {
				    	        width: 100%;
							    float: left;
							    margin: 20px 0;
							    text-align: left;
						    }
					    }
				    }
				}
			}
		}
	}

	//My Wishlist
	.legacy-sitegen .list-share {
		.top-section {
			padding: 0;
		}

		.bottom-section {
			padding: 0;

		    .share-options {
	    		.share-icon-container {
	    			a {
					    &.share-twitter {
					    	margin-right: 18px;
					    }
	    			}
	    		}
		    }

		    .share-option {
	    		font-family: $FuturaBold;
			    font-size: $base-font;
			    background: transparent;
			    border-bottom-right-radius: 0;
	    	}

		}
	}

	.legacy-sitegen .wishlist-empty {
		.heading {
			margin: 10px 0;
		}

		.qa-section {
    		.qa-content {
 	 			.question {
 	 				font-size: $base-font - 1px;
 	 			}

 	 			.answer {
 	 				font-size: $base-font - 2px;
 	 			}
    		}
    	}
	}
}