@media screen and (max-width: 767px) {
	.size-guide-content {
		&.active-menu {
			.size-guide-navigation__dropdown_selected {
			    .selected-category {
			    	color: $grape-blue;
			    }
			}
		}
	}
}

.size-guide-container {  
	.title {
		font-family: $MrEavesReg;
		font-size: $base-font + 16px;
		
		@media screen and (max-width: 767px) {
			font-size: $base-font + 18px;
		}
	}
	
	.size-guide-navigation__dropdown_selected {
        font-family: $Futura;
	}
	
	.size-guide-navigation {
		a { 
			font-family: $Futura;
			letter-spacing: .1em;
			position: relative;
			overflow: visible;
    		line-height: 21px;
    		border: none;
    		font-size: $base-font;
			
			&:hover,
			&.selected {
				color: $grape-blue;
				@media screen and (min-width: 768px) {
					font-family: $FuturaHeavy;
					&:after {
					    content: '';
					    width: 100%;
					    border: 5px solid $grape-blue;
					    bottom: 0;
					    position: absolute;
					    left: 50%;
					    transform: translateX(-50%);
					    -webkit-transform: translateX(-50%);
					    -moz-transform: translateX(-50%);
					    -o-transform: translateX(-50%);
					    -ms-transform: translateX(-50%);
					    top: 29px;
					    -webkit-border-radius: 10px;
					    -moz-border-radius: 10px;
					    border-radius: 10px;
					    z-index: 1;
					}	
				}		
			}
		}
	}
}
